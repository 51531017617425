// component for adding and editing addons
import * as React from "react";
import { useState } from "react"
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { IconButton } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from '@mui/material/Typography';
import { useMutation } from "@apollo/client";
import { ORDER_REFUND } from "../../Graphql/mutations";
import { formatter } from "../Common/commonUtils"
import { useForm } from "react-hook-form";
import {
    toggleSnackbar,
} from "../../ReactiveVariables/index";

export default function RefundModal(props) {
    const [value, setValue] = useState(0);
    const [refundAmountError, setRefundAmountError] = useState('')
    const [percentageError, setPercentageError] = useState('')
    const [reasonError, setReasonError] = useState('')
    const [percentageSelected, setPercentageSelected] = useState('')
    const [refundAmount, setRefundAmount] = useState('')
    const [customPercentage, setCustomPercentage] = useState('')
    let grandTotal = props?.orderData?.grandTotal;
    const {
        register,
        handleSubmit,
    } = useForm();

    const [refundOrder] = useMutation(ORDER_REFUND, {
        refetchQueries: ['GetOneOrder'],
        onCompleted: (res) => {
            props?.setInitatedRefund(false)
        }
    });

    function onSubmit(params) {
        if (!refundAmount && value === 0 || !percentageSelected && value === 1 || !params?.reason) {
            if (!refundAmount && value === 0) {
                setRefundAmountError('Refund Amount is required')
            }
            if (!percentageSelected && value === 1) {
                setPercentageError('Percentage is required')
            }
            if (!params?.reason) {
                setReasonError('Reason is required')
            }
            return
        }

        let request = {
            orderId: props?.orderData._id,
            refundText: params?.reason,
            refundAmount: value === 0 ? parseFloat(refundAmount) : value === 1 ? parseFloat(refundAmountFinder()) : parseFloat(grandTotal),
            refundType: value === 0 ? 'Amount' : value === 1 ? 'Percentage' : 'FullAmount',
            refundPercentage: percentageSelected === 'Custom' ? parseFloat(customPercentage) : parseFloat(percentageSelected),
            caterCash: caterCashDeduction(),
            newTotal: newTotalFinder()
        }
        if (value === 0 || value === 2) {
            delete request.refundPercentage
        }
        try {
            refundOrder({
                variables: request
            })
                .then((res) => {
                    if (res?.data?.orderRefund?.status) {
                        toggleSnackbar({
                            status: true,
                            message: "Refund initiated successfully",
                            variant: "success",
                        });
                    }
                    else {
                        const message = res?.data?.orderRefund?.message;
                        toggleSnackbar({
                            status: true,
                            message: message,
                            variant: "error",
                        });
                    }

                })
                .catch((err) => {
                    const message = err && err.message;
                    toggleSnackbar({
                        status: true,
                        message: message,
                        variant: "error",
                    });
                });
        } catch (err) {
            const message = err && err.message;
            toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
            });
        }

    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const refundAmountFinder = () => {
        if (percentageSelected === 'Custom') {
            let refundAmountValue = (grandTotal * customPercentage) / 100
            return (refundAmountValue.toFixed(2))
        } else {
            let refundAmountValue = (grandTotal * percentageSelected) / 100
            return (refundAmountValue.toFixed(2))
        }
    }

    const newTotalFinder = () => {
        if (value === 0) {
            return grandTotal - refundAmount
        }
        else if (value === 1) {
            return grandTotal - refundAmountFinder()
        } else {
            return 0
        }
    }
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <div>
                        <Typography>{children}</Typography>
                    </div>
                )}
            </div>
        );
    }

    const caterCashDeduction = () => {
        let deduction
        if (value === 0) {
            if (refundAmount) {
                let findPercentage = (refundAmount / grandTotal) * 100
                deduction = props?.orderData?.caterCashTotal * findPercentage / 100
                return deduction
            } else {
                return 0
            }
        }
        else if (value === 1) {
            if (percentageSelected === 0 || percentageSelected === '') {
                return 0
            }
            if (percentageSelected === 'Custom') {
                deduction = props?.orderData?.caterCashTotal * customPercentage / 100
                return deduction
            }
            else if (percentageSelected) {
                deduction = props?.orderData?.caterCashTotal * percentageSelected / 100
                return deduction
            }
        } else {
            return props?.orderData?.caterCashTotal
        }
    }

    const RefundDetailsTab = () => {
        return (
            <div>
                {(value === 0 || value === 1) ?
                    <h6 className="h6">Refund Amount</h6> : <h6 className="h6"> Full Refund Amount</h6>}
                {value === 0 &&
                    <div>
                        <div className="input-group flex-nowrap">
                            <span className="input-group-text fw-bold" id="addon-wrapping" style={{ color: '#FD4E3B' }}>$</span>
                            <input key="refundAmt"
                                autoFocus
                                onChange={(e) => e.target.value >=0 && setRefundAmount(e.target.value)}
                                value={refundAmount}
                                className="form-control" placeholder="Enter the amount" aria-label="Username" aria-describedby="addon-wrapping" />
                        </div>
                        {refundAmountError && (
                            <span className="error-msg">
                                {refundAmountError}
                            </span>
                        )}
                    </div>

                }
                {value === 1 &&
                    <div>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                row
                            >
                                <FormControlLabel value="10" control={
                                    <Radio
                                        checked={percentageSelected === "10"}
                                        onChange={(e) => {
                                            setPercentageSelected(e.target.value)

                                        }}
                                    />} label="10%" />
                                <FormControlLabel value="15" control={
                                    <Radio
                                        checked={percentageSelected === "15"}
                                        onChange={(e) => {
                                            setPercentageSelected(e.target.value)
                                        }}
                                    />} label="15%" />
                                <FormControlLabel value="20" control={
                                    <Radio
                                        checked={percentageSelected === "20"}
                                        onChange={(e) => {
                                            setPercentageSelected(e.target.value)
                                        }}
                                    />} label="20%" />
                                <FormControlLabel value="Custom" control={
                                    <Radio
                                        checked={percentageSelected === "Custom"}
                                        onChange={(e) => {
                                            setPercentageSelected(e.target.value)
                                        }} />} label="Custom" />
                            </RadioGroup>
                        </FormControl>
                        <div>
                            {percentageSelected === "Custom" &&
                                <div className="input-group flex-nowrap">
                                    <span className="input-group-text fw-bold" id="addon-wrapping" style={{ color: '#FD4E3B' }}>%</span>
                                    <input 
                                        autoFocus
                                        onChange={(e) => e.target.value >= 0 && e.target.value <= 100 && setCustomPercentage(e.target.value)}
                                        value={customPercentage}
                                        className="form-control" placeholder="Enter percentage"
                                        aria-label="Enter percentage" aria-describedby="Enter percentage" />
                                </div>}
                        </div>
                        {percentageError && (
                            <span className="error-msg">
                                {percentageError}
                            </span>
                        )}
                    </div>
                }
                {value === 2 && <h6 className="h6 fw-bold" style={{ color: '#FD4E3B' }}> -{formatter.format(grandTotal)}</h6>}
                <div className="textBox">
                    <h6 className="h6">Reason for Refund</h6>
                    <p>The customer will see this on the update reciept</p>
                    <input type="email" className="form-control" placeholder="eg: Delivered 30 min late etc." {...register("reason")} />
                </div>
                {reasonError && (
                    <span className="error-msg">
                        {reasonError}
                    </span>
                )}                <div className="refund-price">
                    <div className="refund-price-item">
                        <div className="refund-price-item-child d-flex align-items-center justify-content-between">
                            <b>Orginal total</b>
                            <b>{formatter.format(grandTotal)}</b>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="">{`Refund(visa ending ${props?.orderData?.cardDetails?.cardLastDigits})`}</span>
                            <b className="">-{formatter.format(value === 0 ? parseFloat(refundAmount ? refundAmount : 0) : value === 1 ? parseFloat(refundAmountFinder()) : parseFloat(grandTotal))}</b>
                        </div>
                    </div>

                    <div className="refund-price-item">
                        <div className="refund-price-item-child d-flex align-items-center justify-content-between">
                            <b className="">New total</b>
                            <b className="">{formatter.format(newTotalFinder())}</b>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="">CaterCash Deduction</span>
                            <b className="">-{formatter.format(caterCashDeduction())}</b>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Modal
            open={props?.initatedRefund}
            onClose={() => props?.setInitatedRefund(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="caterer-menu-modal"
        >
            <div className="caterer-menu-details refund-modal">
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className="caterer-menu-header">
                        <h5 className="h5">Refund Customer</h5>
                        <IconButton onClick={() => { props?.setInitatedRefund(false) }}>
                            <img src="../assets/images/modal-close.svg" className="img-fluid caterer-menu-close d-block" alt="close icon" />
                        </IconButton>
                    </div>
                    <div className="refund-tabs">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab
                                label="Amount"
                                onClick={() => { setPercentageSelected(''); setCustomPercentage(''); setReasonError(''); setRefundAmountError(''); }}
                            />
                            <Tab
                                label="Percentage"
                                onClick={() => { setRefundAmount(''); setReasonError(''); setPercentageError('') }}
                            />
                            <Tab
                                label="Full Refund"
                                onClick={() => { setPercentageSelected(''); setCustomPercentage(''); setRefundAmount(''); setReasonError('') }}
                            />
                        </Tabs>
                    </div>
                    <div className="caterer-menu-content">

                        <div className="refund-tabs-content">
                            <TabPanel value={value} index={0}>
                                <RefundDetailsTab />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <RefundDetailsTab />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <RefundDetailsTab />
                            </TabPanel>
                        </div>
                    </div>
                    <div className="caterer-menu-footer">
                        <Button
                            className="cancelBtn"
                            variant="outlined"
                            color="secondary"
                            onClick={() => { props?.setInitatedRefund(false) }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="saveBtn"
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Refund Customer
                        </Button>

                    </div>
                </form>
            </div>
        </Modal>
    );
}