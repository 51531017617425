//this page is for showing all the cuisines
import React, { useState } from "react";
import SideMenu from "../../../components/organisms/sidebar";
import LoggedHeader from "../../Common/loggedHeader";
import { Table, Select, MenuItem,Button,Menu} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_CUISINE_TYPE } from "../../../Graphql/queries";
import { DELETE_CUISINES } from "../../../Graphql/mutations";
import Pagination from "@mui/material/Pagination";
import { toggleSnackbar,authVar } from "../../../ReactiveVariables/index";
import Stack from "@mui/material/Stack";
import AddCuisines from "./addCuisines";
import DialogBox from "../../Common/dialogBox";
import {useNavigate } from "react-router-dom";
export default function CuisinesList(){
  const navigate = useNavigate()
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [openCuisines,setOpenCuisines] =useState(false)
  const open = Boolean(anchorEl);
  const cuisinesVariable = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: search
  }
  const {data,loading}= useQuery(LIST_CUISINE_TYPE,{variables:cuisinesVariable})
  const [deleteCuisines] = useMutation(DELETE_CUISINES,{refetchQueries:['ListCuisineType'],
  onCompleted:(res)=>{
    setSelectedItem(null)
      // setOpenCuisines(false)
  }})
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };
  //pagination
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  // for showing anchor
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // close menu
  const handleClose = () => {
    setAnchorEl(null);
  };
  //delete functionality for cuisines
  function handleDelete(){
    try { deleteCuisines({ variables: {cuisineTypeId: selectedItem?._id } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Cuisines deleted successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
  }
  return (
    <div>
      <LoggedHeader title="Cuisines" user="admin" />
      <SideMenu selected="Cuisines" user="admin" />
      {openCuisines&&<AddCuisines 
      selectedItem ={selectedItem}
      setSelectedItem ={setSelectedItem}
      openCuisines={openCuisines}
      setOpenCuisines={setOpenCuisines}/>}
      {openDialog && (<DialogBox
        setOpenDialog={setOpenDialog}
        handleDelete={handleDelete}
        openDialog={openDialog}
        title="Delete"
        discription="Are you sure you want to delete this cuisine type?"
      />
      )}
      <div className="contentWrap">
        <div className="contentWrapInner">
          <div className="location-head">
            <div className="location-head-title justify-content-between">
              {
                loading ?
                    <p>Loading...</p> :
                    <h5>{`${data?.listCuisineType?.count} Cuisines`}</h5>
              }
              <div className="d-flex align-items-center">
              <input
                placeholder="Search by Cuisines Name"
                className="form-control"
                value={search}
                onChange={(e) => {
                  setPage(1);
                  setSearch(e.target.value);
                }}
              />
              <Button className="ms-3" color="primary" variant="contained" onClick={()=>{setOpenCuisines(true); setSelectedItem(null)}} >
                  <img src="../assets/images/icon-add-menu.svg" className="img-fluid" alt="Icon" /> Add Cuisines
              </Button>
              </div>
            </div>
          </div>
          <TableContainer>
            <Table
              className="commonTabel"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : data && data?.listCuisineType?.data?.length > 0 ? (
                  data &&
                  data?.listCuisineType?.data?.map((item) => (
                    <TableRow style={{ cursor: "pointer" }}>
                      <TableCell component="th" scope="row">
                        {item?.cuisineName}
                      </TableCell>
                      <TableCell align="center">
                        <img
                          style={{ cursor: "pointer" }}
                            onClick={(e) => { setSelectedItem(item); handleClick(e) }}
                          src="../assets/images/more-horizontal.svg"
                          alt="img"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : search ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No data Found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No list added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select
              // variant="outlined"
              onChange={handlePageInputChange}
              value={rowsPerPage}
            >
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listCuisineType?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="menu-list-more"
        >
          <MenuItem
            onClick={() => {
                setOpenCuisines(true);
              handleClose();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem onClick={() => { setOpenDialog(true); handleClose() }}>Delete</MenuItem>
        </Menu>
      </div>
    </div>
  )
}