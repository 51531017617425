import React, { useState } from 'react';
import EditCustomerProfile from './editProfile';

export default function UserProfile(props) {
  const { data } = props;
  const [openProfile, setOpenProfile] = useState(false);
  const taxCert = data?.getOneUser?.taxCert;

  const toggleProfileEditing = () => {
    setOpenProfile(!openProfile);
  };

  return (
    <div className='customer-profile'>
      {openProfile && (
        <EditCustomerProfile
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          props={props}
        />
      )}
      <div className='customer-profile-title'>
        <h4 className='h4'>
          <img
            src="../assets/images/icon-contact-info.svg"
            className="img-fluid"
            alt="Contact Information Icon"
          />
          Profile
        </h4>
        <a className="ld-edit" onClick={toggleProfileEditing}>
          <img
            src="../assets/images/icon-edit-2.svg"
            className="img-fluid"
            alt="Edit Icon"
          />
          Edit
        </a>
      </div>
      <div className='row customer-profile-details'>
        {[
          { label: 'First Name', value: data?.getOneUser?.firstName },
          { label: 'Last Name', value: data?.getOneUser?.lastName },
          { label: 'Phone', value: data?.getOneUser?.phoneNumber },
          { label: 'Email', value: data?.getOneUser?.email },
          {
            label: `Tax Exempt`,
            value: data?.getOneUser?.taxExempt ? "Yes" : "No",
          },
          {
            label: `Sign Up Promo`,
            value: data?.getOneUser?.disableSignUpPromo ? "No" : "Yes",
          },
        ].map((item, index) => (
          <div key={index} className='col-md-6'>
            <label>{item.label} { (item.label === 'Tax Exempt' && taxCert) && <a href={taxCert} target="_blank" rel="noreferrer">View Cert</a>}</label>
            <p>{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
