import React from "react";
import moment from "moment";

import { TableCell, TableRow } from "@mui/material";
import { IAddressListItemResponse } from "typings/address.api";
import { useNavigate } from "react-router-dom";

interface IAddressListItemProps {
  item: IAddressListItemResponse;
  isLoading: boolean;
  onSelect(event: any, item: IAddressListItemResponse);
}

export function AddressListItem({ item, isLoading, onSelect }: IAddressListItemProps) {
  const navigate = useNavigate();

  function onItemClick() {
    if (isLoading) {
      return;
    }
    navigate(item._id);
  }

  function onSelectItem(e: React.MouseEvent<HTMLElement>) {
    if (isLoading) {
      return;
    }
    onSelect(e, item);
  }

  return (
    <TableRow style={{ cursor: "pointer" }}>
      <TableCell component="th" scope="row" onClick={onItemClick}>
        {item.locationName}
      </TableCell>
      <TableCell align="left" onClick={onItemClick}>
        {item.street}
      </TableCell>
      <TableCell align="left" onClick={onItemClick}>
        {item.existingOrderDate ? moment(item?.existingOrderDate).format("llll") : "---"}
      </TableCell>
      <TableCell align="center">
        <img
          style={{ cursor: "pointer" }}
          onClick={onSelectItem}
          src="../assets/images/more-horizontal.svg"
          alt="img"
        />
      </TableCell>
    </TableRow>
  );
}
