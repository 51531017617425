import { useCookies } from "react-cookie";
import { clearOrder } from "../../services/redux/order";
import { authVar, userDeatils } from "../../ReactiveVariables";
import { useAppDispatch } from "../../services/redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@constants/routes.constants";

interface IUseLogoutProps {
  userType: string;
  logoutCallback?(): void;
  isRedirect?: boolean;
}

export function useLogout({ userType, logoutCallback, isRedirect = true }: IUseLogoutProps) {
  const [cookies, setCookie, removeCookie] = useCookies([
    "user",
    "image",
    "userType",
    "@authToken",
  ]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function onLogout() {
    removeCookie("@authToken");
    removeCookie("user", {
      path: "/",
      domain: ".caterplace.com",
    });
    removeCookie("image", {
      path: "/",
      domain: ".caterplace.com",
    });
    removeCookie("userType", {
      path: "/",
      domain: ".caterplace.com",
    });
    localStorage.clear();
    sessionStorage.removeItem("orderId");
    sessionStorage.removeItem("uuId");
    dispatch(clearOrder());
    authVar({
      loaded: true,
      auth: false,
    } as any);
    userDeatils(null);
    if (logoutCallback) {
      logoutCallback();
    }

    if (isRedirect) {
      if (userType === "admin" || userType === "Admin") {
        navigate(ROUTES.admin);
      } else {
        navigate(ROUTES.login);
      }
    }
  }

  return {
    onLogout,
  };
}
