// this page is for the adding the hours details in new business
import React from 'react';

import { useState, useEffect } from "react";
import { Switch, Button, CircularProgress, FormControl, Box, Select, MenuItem, InputLabel } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useMutation } from "@apollo/client";
import { UPDATE_CATERE } from "../../../Graphql/mutations";
import {
  toggleSnackbar,
} from "../../../ReactiveVariables/index";
import { deliveryHoursCheck } from "../../Common/commonUtils";

const deliveryHrs = [
  {
    "day": "Sunday",
    "flag": false,
    "openHours": [
      { startTime: "", closeTime: "" }
    ],
  },
  {
    "day": "Monday",
    "flag": false,
    "openHours": [
      { startTime: "", closeTime: "" }
    ],
  },
  {
    "day": "Tuesday",
    "flag": false,
    "openHours": [
      { startTime: "", closeTime: "" }
    ],
  },
  {
    "day": "Wednesday",
    "flag": false,
    "openHours": [
      { startTime: "", closeTime: "" }
    ],
  },
  {
    "day": "Thursday",
    "flag": false,
    "openHours": [
      { startTime: "", closeTime: "" }
    ],
  },
  {
    "day": "Friday",
    "flag": false,
    "openHours": [
      { startTime: "", closeTime: "" }
    ],
  },
  {
    "day": "Saturday",
    "flag": false,
    "openHours": [
      { startTime: "", closeTime: "" }
    ],
  },
]
const timeDetails = [
  "12:00am",
  "12:15am",
  "12:30am",
  "12:45am",
  "1:00am",
  "1:15am",
  "1:30am",
  "1:45am",
  "2:00am",
  "2:15am",
  "2:30am",
  "2:45am",
  "3:00am",
  "3:15am",
  "3:30am",
  "3:45am",
  "4:00am",
  "4:15am",
  "4:30am",
  "4:45am",
  "5:00am",
  "5:15am",
  "5:30am",
  "5:45am",
  "6:00am",
  "6:15am",
  "6:30am",
  "6:45am",
  "7:00am",
  "7:15am",
  "7:30am",
  "7:45am",
  "8:00am",
  "8:15am",
  "8:30am",
  "8:45am",
  "9:00am",
  "9:15am",
  "9:30am",
  "9:45am",
  "10:00am",
  "10:15am",
  "10:30am",
  "10:45am",
  "11:00am",
  "11:15am",
  "11:30am",
  "11:45am",
  "12:00pm",
  "12:15pm",
  "12:30pm",
  "12:45pm",
  "1:00pm",
  "1:15pm",
  "1:30pm",
  "1:45pm",
  "2:00pm",
  "2:15pm",
  "2:30pm",
  "2:45pm",
  "3:00pm",
  "3:15pm",
  "3:30pm",
  "3:45pm",
  "4:00pm",
  "4:15pm",
  "4:30pm",
  "4:45pm",
  "5:00pm",
  "5:15pm",
  "5:30pm",
  "5:45pm",
  "6:00pm",
  "6:15pm",
  "6:30pm",
  "6:45pm",
  "7:00pm",
  "7:15pm",
  "7:30pm",
  "7:45pm",
  "8:00pm",
  "8:15pm",
  "8:30pm",
  "8:45pm",
  "9:00pm",
  "9:15pm",
  "9:30pm",
  "9:45pm",
  "10:00pm",
  "10:15pm",
  "10:30pm",
  "10:45pm",
  "11:00pm",
  "11:15pm",
  "11:30pm",
  "11:45pm"
]
export default function NewHours(props) {
  const currentData = props?.userValues?.updateCaterer;

  const [allDays, setAllDays] = useState(currentData?.status === "Open" ? false : true);
  const [cutOff, setCutOff] = useState(currentData?.dlvryShutDownFlag ?? false);
  const [nextMorningTime, setNextMorningTime] = useState(currentData?.cutOfTimeNxtOrder ?? null);
  const [morningTime, setMorningTime] = useState(currentData?.cutOfTimeMng ?? null);
  const [days, setDays] = useState(currentData?.deliveryHrs.length > 0 ? formatData(currentData?.deliveryHrs) : deliveryHrs);
  const [time, setTime] = useState(timeDetails)
  const [status, setStatus] = useState(currentData?.status ?? "Closed")
  const [updateCaterer, { loading }] = useMutation(UPDATE_CATERE, {
    onCompleted: (res) => {
      props.setUserValues(res);
      props.setActiveStep(3);
    },
  });
  // function for toggling Mark Shop as Closed for all days
  const closedForAllDays = (event) => {
    setAllDays(event.target.checked);
    if (event.target.checked === true) {
      setStatus("Closed");
    } else {
      setStatus("Open");
    }
  };
  // function for toggling Cut off time to accept next day morning orders
  const cutoffTime = (event) => {
    setCutOff(event.target.checked);
  };
  // function for formating data
  function formatData(data) {
    let array = [];
    data.forEach(element => {
      let arr = [];
      element?.openHours.forEach(hrsObj => {
        arr.push({
          closeTime: hrsObj.closeTime,
          startTime: hrsObj.startTime
        })
      });
      array.push({
        day: element.day,
        flag: element.flag,
        openHours: arr,
      })
    });
    return array;
  }
  // function for toggling the values of days to open /close
  const handleChangeDay = (event, checkedValue, index) => {
    let updatesDays = [...days]
    let obj = { ...updatesDays[index] }
    obj.flag = checkedValue
    updatesDays[index] = obj
    setDays(updatesDays)
  };
  // function for adding value to the start time and end time
  function addTime(index, index2, time, type) {
    let newHours = [...days]
    let obj = { ...newHours[index].openHours[index2] }
    obj[type] = time
    newHours[index].openHours[index2] = obj
    setDays(newHours)
  }
  //function for adding new start time and end time
  const addHours = (item, index) => {
    let newHours = [...days]
    newHours.forEach(element => {
      if (element.day === item.day) {
        let obj = {}
        obj["startTime"] = ''
        obj["closeTime"] = ''
        element.openHours.push(obj)
      }
    })
    setDays(newHours)
  }
  //function for removing new start time and end time
  const removeHours = (item, index) => {
    let newHours = [...days]
    newHours.forEach(element => {
      if (element.day === item.day) {
        element.openHours.splice(index, 1)
      }
    })
    setDays(newHours)
  }
  // function for updating the hours
  function UpdateHours(params) {
    // function to check whether the delivery hours are added sucessfully for open days
    if (!deliveryHoursCheck(days)) {
      return
    }
    let newDays = (days).map((key) => {
      //for adding only the datas required 
      return {
        day: key.day, flag: key.flag, openHours: key.openHours.map((hour) => {
          return { startTime: hour.startTime, closeTime: hour.closeTime }
        })
      }
    })
    let hoursData = {
      status: status,
      dlvryShutDownFlag: cutOff,
      cutOfTimeMng: nextMorningTime,
      cutOfTimeNxtOrder: morningTime,
      deliveryHrs: newDays,
      tabValue: "2"
    }
    try {
      currentData.adminFlg ? updateCaterer({ variables: { id: props?.createRes?.createCaterer?._id, catererId: props?.createRes?.createCaterer?.catererIdNew, data: hoursData } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Hours updated successfully",
            variant: "success",
          });
        }) :
        updateCaterer({ variables: { id: props.createRes?.createCaterer?.newUserId || props.createRes?.updateUserIdentity?._id, catererId: props.createRes?.createCaterer?.catererIdNew || props.createRes?.updateUserIdentity?.catererId, data: hoursData } })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Hours updated successfully",
              variant: "success",
            });
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  useEffect(() => {
    props?.setTitle("Add Hours")
  })
  return (
    <div>
      <div className="add-business-step-head">
        <h3 className="h5">Delivery Hours</h3>
        <p className="subhead">You can select multiple options as offered.</p>
      </div>
      <div className="create-step-form hours-form">
        <div className="container-fluid">
          <div className="addBusinessSet mt-0">
            <div className="d-flex align-items-center justify-content-between">
              <label className="hours-title-label">Mark Shop as Closed for all days</label>
              <Switch
                checked={allDays}
                onChange={closedForAllDays}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>

            {/* <div className="d-flex align-items-center justify-content-between">
              <label className="hours-title-label">Cut off time to accept next day morning orders</label>
              <Switch
                // disabled={allDays===true}
                // checked={allDays===true?false:cutOff}
                checked={cutOff}
                onChange={cutoffTime}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div> */}
          </div>
          <div>
            {cutOff && <div>
              <div className="order-cutoff-time">
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker

              // label="TIME"
              value={nextMorningTime}
              onChange={(newValue) => {
                setNextMorningTime(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider> */}
                <FormControl sx={{ minWidth: 160 }}>
                  <InputLabel id="time">Time</InputLabel>
                  <Select
                    label="time"
                    labelId="Time"
                    id="time"
                    value={nextMorningTime}
                    onChange={(e) => setNextMorningTime(e.target.value)}
                    className="hours-dropdown"
                  >
                    {time.map((item, index) => (<MenuItem value={item}><AccessTimeIcon />{item}</MenuItem>))}
                  </Select>
                </FormControl>
              </div>

              {/* <label className="hours-title-label">Early morning hours end at</label> */}
              <div className="order-cutoff-time">
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                className="order-cutoff-time"
                // label="TIME"
                value={morningTime}
                onChange={(newValue) => {
                  setMorningTime(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider> */}
                <FormControl sx={{ minWidth: 160 }}>
                  <InputLabel id="time">Time</InputLabel>
                  <Select
                    label="time"
                    labelId="Time"
                    id="time"
                    value={morningTime}
                    onChange={(e) => setMorningTime(e.target.value)}
                    className="hours-dropdown"
                  >
                    {time.map((item, index) => (<MenuItem value={item}><AccessTimeIcon />{item}</MenuItem>))}
                  </Select>
                </FormControl>
              </div>
            </div>}
            {days.map((item, index) => (
              <div className="weekly-hrs-wrap mt-3">
                <div className="weekly-hrs-outer">
                  <div className="weekly-hrs">
                    <h6>{item?.day}</h6>
                    <div className="weekly-hrs-open-close">
                      <Switch
                        // disabled={allDays===true}
                        checked={item?.flag}
                        onChange={(e) => handleChangeDay(item, e.target.checked, index)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <span className={item?.flag === true ? "shop-close-open-label shop-open-label" : "shop-close-open-label shop-close-label"}>{item?.flag === true ? "Open" : "Closed"}</span>
                    </div>
                  </div>
                  {item?.flag === true && item?.openHours?.map((time1, index1) => (
                    <div className="per-day-time">
                      <div className="d-flex align-items-center justify-content-between">
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        // label="START TIME"
                        value={time?.startTime}
                        onChange={(newValue) => {
                          addTime(index, index1, newValue, "startTime");
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider> */}
                        <FormControl sx={{ minWidth: 160 }}>
                          <InputLabel id="time">Start Time</InputLabel>
                          <Select
                            label="start time"
                            labelId=" Start Time"
                            id=" start time"
                            value={time1?.startTime}
                            onChange={(e) => addTime(index, index1, e.target.value, "startTime")}
                            className="hours-dropdown"
                          >
                            {time.map((item, index) => (<MenuItem value={item}><AccessTimeIcon />{item}</MenuItem>))}
                          </Select>
                        </FormControl>
                        <div className="time-separator">
                          <img src="../assets/images/small-line.svg" className="img-fluid d-block" />
                        </div>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        className="textFeild"
                        // label="END TIME"
                        value={time?.closeTime}
                        onChange={(newValue) => {
                          addTime(index, index1, newValue, "closeTime");
                        }}
                        renderInput={(params) => <TextField {...params} className="textFeild" />}
                      />
                    </LocalizationProvider> */}
                        <FormControl sx={{ minWidth: 160 }}>
                          <InputLabel id="time">End Time</InputLabel>
                          <Select
                            label="end time"
                            labelId=" End Time"
                            id="end time"
                            value={time1?.closeTime}
                            onChange={(e) => addTime(index, index1, e.target.value, "closeTime")}
                            className="hours-dropdown"
                          >
                            {time.map((item, index) => {
                              if (time1.startTime && moment(item, "hh:mma").isBefore(moment(time1.startTime, "hh:mma"))) return null;
                              return (<MenuItem value={item}><AccessTimeIcon />{item}</MenuItem>)
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      {index1 === 0 && <a className="add-hours" onClick={() => addHours(item, index1)}><img src="../assets/images/icon-plus.svg" className="img-fluid me-2" /> Add Hours</a>}
                      {index1 > 0 && <a className="add-hours" onClick={() => removeHours(item, index1)}><img src="../assets/images/hiphen.svg" className="img-fluid me-2" /> Remove Hours</a>}
                    </div>
                  ))}

                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="createStepBtn">
        <Button
          onClick={() => props.setActiveStep(1)}
          className="cancelBtn"
          variant="contained"
          color="secondary">Previous</Button>
        <Button
          onClick={() => UpdateHours()}
          className="saveBtn"
          variant="contained"
          color="primary"
        >
          {loading ? <CircularProgress color="inherit" size={20} className="m-auto" /> : "Save and Proceed "}<ArrowForwardIcon className="btnArrow" />
        </Button>
      </div>
    </div>
  );
}
