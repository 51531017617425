import React from 'react';

import LoginHeader from "../../Login/LoginHeader";
import Footer from "../../Common/footer";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate,Link } from "react-router-dom";
import { userDeatils, authVar } from "../../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import {Helmet} from "react-helmet";
export default function TermsCondition() {



  return (
    <div>
      <Helmet>
        <title>CaterPlace - Corporate Catering</title>
        <meta name="Keywords" />
        <meta name="description" content="What you are agreeing to by using the CaterPlace Platform" />
      </Helmet>
      <LoginHeader />
      <section className="about-banner overlay">
        <div className="container">
          <div className="about-banner-cnt m-auto" style={{ maxWidth: 'fit-content' }}>
            <h2 className="h2 text-center" >Terms of Use</h2>
          </div>
        </div>
      </section>
      <section className="general-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 text-center m-auto">
              <h2 className="h2">TERMS OF USE AGREEMENT
                <h6 className="h6">Updated  June 1st, 2021</h6>
              </h2>
              
            </div>
            <div className="col-lg-9 m-auto">
              <p className="terms_of_use_p">PLEASE READ THE TERMS OF USE AGREEMENT CAREFULLY.  THIS TERMS OF USE AGREEMENT (“AGREEMENT”) CONSTITUTES A LEGAL AND BINDING AGREEMENT BETWEEN YOU AND [CATERPLACE LLC, A COMPANY FORMED UNDER THE LAWS OF THE STATE OF DELAWARE], AND ITS SUBSIDIARIES AND AFFILIATED COMPANIES (COLLECTIVELY, “CATERPLACE,” “WE,” “US” OR “OUR”).</p>
              <p className="terms_of_use_p">IMPORTANT:  PLEASE CAREFULLY REVIEW THE DISPUTE RESOLUTION SECTION SET FORTH IN SECTION 2 BELOW.  THIS SECTION REQUIRES YOU TO RESOLVE DISPUTES WITH CATERPLACE THROUGH INDIVIDUAL BINDING ARBITRATION.  IN AGREEING TO SETTLING ANY DISPUTE WITH CATERPLACE THROUGH ARBITRATION, YOU ACKNOWLEDGE AND AGREE THAT YOU AND CATERPLACE EACH WAIVE THE RIGHT TO A TRIAL BY JURY.  YOU FURTHER ACKNOWLEDGE AND AGREE THAT YOU WAIVE YOUR RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING AGAINST CATERPLACE.</p>
              <h2>1. Acceptance of this agreement</h2>
              <p className="normal_p">
                If you access any CaterPlace website (www.caterplace.com), any of our mobile sites or any of our web-based applications, install or use any software supplied by CaterPlace, create a CaterPlace account or access any service offered by CaterPlace (the “Services), you, your heirs, assigns and successors (collectively, “you” or “your”) hereby represent and warrant that:
              </p><p className="p_left normal_p">(a) you are of legal age in the jurisdiction in which you reside to form a binding contract;<br/>
                (b) you will comply with all applicable laws and regulations related to your access and use of CaterPlace;<br/>
                  (c) if entering into this Agreement on behalf of an organization, you have the authority to enter into this Agreement; and<br/>
                    (d) you have read, understand and agree to be bound by this Agreement.</p>
                  <h2>2. Dispute Resolution</h2>
                  <p className="terms_of_use_p">PLEASE READ THE FOLLOWING SECTION CAREFULLY.  THIS SECTION REQUIRES YOU TO ARBITRATE ANY DISPUTE WITH CATERPLACE ON AN INDIVIDUAL BASIS AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF.</p>
                  <p className="p_left normal_p">
                    2.1  Mandatory Arbitration Agreement<br/>You agree that all claims, disputes, or disagreements whether currently existing or arising in the future between you and CaterPlace shall be resolved through arbitration on an individual basis.  You acknowledge and agree that you and Caterplace are each waiving the right to a trial by jury.  You further acknowledge and agree that you are waiving the right to bring or to participate as a plaintiff or class member in any class action, purported class, collective, coordinated or representative proceeding against CaterPlace.  For the avoidance of doubt, this Arbitration Agreement shall apply without limitation to all claims that arose or were asserted before the effective date of this Agreement.
                      <br/><br/>
                        This Arbitration Agreement shall be binding upon, and shall include any claims brought by or
                        against any third-parties, including but not limited to your spouses, heirs, third-party
                        beneficiaries and assigns, where their underlying claims are in relation to your use of the
                        Services. To the extent that any third-party beneficiary to this agreement brings claims
                        against the Parties; those claims shall also be subject to this Arbitration Agreement.
                      </p>
                        <p className="p_left normal_p">
                          2.2 Exceptions to Mandatory Arbitration<br/>
                            This Arbitration agreement shall not preclude nor prohibit (1) you from asserting a claim in small claims court so long as the matter remains in small claims court on an individual basis; or (2) you or CaterPlace from seeking injunctive or equitable relief to prevent the actual or threatened infringement, misappropriation or violation of a party’s intellectual property rights.
                            <br/><br/>
                              This Section 2.2 and the parties’ agreement not to require arbitration in these limited instances does not waive the enforceability of this Arbitration Agreement as to any other provision or the enforceability of this Agreement as to any other controversy, claim or dispute.
                            </p>
                              <p className="p_left normal_p">
                                2.3 Informal Resolution<br/>
                                  You and CaterPlace agree to engage in good faith informal (non-judicial) efforts to resolve disputes prior to demanding that such dispute be resolved through mandatory arbitration.
                              </p>
                              <p className="p_left normal_p">
                                2.4 Rules and Governing Law<br/>
                                  The arbitration will be administered by the American Arbitration Association ("AAA") in
                                  accordance with the AAA’s Consumer Arbitration Rules (the "AAA Rules") then in effect, except
                                  as modified by this Arbitration Agreement. The AAA Rules are available at www.adr.org or by
                                  calling the AAA at 1-800-778-7879. <br/><br/>
                                    The parties agree that the arbitrator ("Arbitrator"), and not any federal, state, or local court or
                                    agency, shall have exclusive authority to resolve any disputes relating to the interpretation,
                                    applicability, enforceability or formation of this Arbitration Agreement, including any claim
                                    that all or any part of this Arbitration Agreement is void or voidable. The Arbitrator shall also be
                                    responsible for determining all threshold arbitrability issues, including issues relating to
                                    whether the Terms are applicable, unconscionable or illusory and any defense to arbitration,
                                    including waiver, delay, laches, or estoppel. If there is a dispute about whether this Arbitration
                                    Agreement can be enforced or applies to a dispute, you and Uber agree that the arbitrator will
                                    decide that issue.
                                    <br/><br/>
                                      Notwithstanding any choice of law or other provision in the Terms, the parties agree and
                                      acknowledge that this Arbitration Agreement evidences a transaction involving interstate
                                      commerce and that the Federal Arbitration Act, 9 U.S.C. § 1 et seq. ("FAA"), will govern its
                                      interpretation and enforcement and proceedings pursuant thereto. It is the intent of the
                                      parties to be bound by the provisions of the FAA for all purposes, including, but not limited to,
                                      interpretation, implementation, enforcement, and administration of this Arbitration
                                      <br/><br/>
                                        Agreement, and that the FAA and AAA Rules shall preempt all state laws to the fullest extent
                                        permitted by law. If the FAA and AAA Rules are found to not apply to any issue regarding the
                                        interpretation or enforcement of this Arbitration Agreement, then that issue shall be resolved
                                        under the laws of the state where you reside when you accept these Terms.
                                        <br/><br/>
                                          Any dispute, claim, or controversy arising out of or relating to incidents or accidents resulting
                                          in personal injury (including but not limited to sexual assault or harassment claims) that you
                                          allege occurred in connection with your use of the Services, whether before or after the date
                                          you agreed to the Terms, shall be governed by and construed in accordance with the laws of
                                          the state in which the incident or accident occurred.
                                        </p>
                                          <p className="p_left normal_p">
                                            2.5.  Arbitration Process<br/>
                                              If after sixty (60) days the Informal Dispute Resolution Procedure above is unsuccessful in resolving the
                                              parties’ dispute, a party who desires to initiate arbitration must provide the other party with a written
                                              Demand for Arbitration as specified in the Applicable AAA Rules.  The parties will first attempt to agree on an arbitrator.  If the parties are unable to agree upon an arbitrator within twenty-eight (28) days of receiving the AAA’s list of eligible neutrals, then the AAA will appoint the arbitrator in accordance with the AAA Rules.
                                          </p>
                                          <p className="p_left normal_p">
                                            2.6. Arbitration Location and Procedure<br/>
                                              Unless you and CaterPlace otherwise agree, the arbitration will be conducted in the county where you reside.  . If the amount in controversy does not exceed $10,000 and you do not seek injunctive or declaratory relief, then the arbitration will be conducted solely on the basis of documents you and CaterPlace submit to the arbitrator, unless the arbitrator determines that a hearing is necessary. If the amount in controversy exceeds $10,000 or seeks declaratory or injunctive relief, your right to a hearing will be determined by the Applicable AAA Rules. Subject to the Applicable AAA Rules, the arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration. Unless otherwise prohibited by law, all arbitration proceedings will be confidential and closed to the public and any parties other than you and CaterPlace, and all records relating thereto will be permanently sealed, except as necessary to obtain court confirmation of the arbitration award.
                                          </p>
                                          <p className="p_left normal_p">
                                            2.7.  Batch Arbitration<br/>
                                              In the event there are in excess of 100 similar arbitration demands within a 30 day period with the assistance or coordination of the same law firm or organization to the AAA or similar aribitrator, CaterPlace reserves the right to group these arbitrations into a batch processing system for sake of efficiency and resolution.   Each 100 demands will be batched together, and the remaining demands left over will also be batched as one final batch. Each batch will be handled by a designated arbitrator.  You agree to cooperate in good faith with CaterPlace and for this batch arbitration process, the allowance of payment of a single filing for these claims. This “Batch Arbitration” provision shall in no way be interpreted as authorizing class arbitration of any kind.  CaterPlace does not agree or consent to class arbitration or consolidated claims, except outlined in this section 2.7
                                          </p>
                                          <p className="p_left normal_p">
                                            2.8.  Arbitrator’s Decision<br/>
                                              The arbitrator will render an award within the time frame specified in the AAA Rules.  The arbitrator’s decision will include the essential findings and conclusions upon which the arbitrator based the award.  Judgment on the arbitration award may be entered in any court having jurisdiction thereof.  The arbitrator will have the authority to award monetary damages on an individual basis and to grant, on an individual basis, any non-monetary remedy or relief available to an individual to the extent available under applicable law, the arbitral forum’s rules and this Agreement.  The arbitrator’s award of damages and/or other relief must be consistent with this Terms of Use Agreement.
                                              <br/><br/>
                                                Attorneys; fees will be available to the prevailing party in the arbitration only if authorized under the applicable substantive law governing the claims in the arbitration.  If the arbitrator finds that either the substance of your claim or the relief sought in your Demand for Arbitration was frivolous or was brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), CaterPlace will have the right to recover attorney’s fees and expenses.
                                              </p>
                                                <p className="p_left normal_p">
                                                  2.9. Fees.<br/>
                                                    Your responsibility to pay any AAA filing, administrative and/or arbitrator fees will be solely as set forth in the applicable AAA rules (as modified by Section 2.7 above).  If, however, you are able to demonstrate that the costs of arbitration will be prohibitive for you as compared to the costs of litigation, CaterPlace will pay as much of the filing, administrative and/or arbitrator fees as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive to you.  Any fees paid by CaterPlace on your behalf shall be deductible from any monetary damages, if any, awarded to you in arbitration.
                                                </p>
                                                <p className="p_left normal_p">
                                                  2.10.  Changes<br/>
                                                    CaterPlace reserves the right to changes this “Dispute Resolution” section, but any such changes will not apply to any individual claim(s) for which you have already provided notice to CaterPlace.  If CaterPlace changes this "Dispute Resolution" section after the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement), you agree that your continued use of the Services after such change will be deemed acceptance of those changes. If you do not agree to such change, you may reject any such change by providing CaterPlace written notice of such rejection by email from the email address associated with your account to: support@caterplace.com, within 30 days of the date such change became effective, as indicated in the "Effective" date above. In order to be effective, the notice must include your full name and clearly indicate your intent to reject changes to this "Dispute Resolution" section. By rejecting changes, you are agreeing that you will arbitrate any dispute between you and CaterPlace in accordance with the provisions of this "Dispute Resolution" section as of the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement, as applicable).
                                                </p>
                                                <h2>3. Vendors and Delivery Service Providers are Independent</h2>
                                                <p className="normal_p">
                                                  You understand, acknowledge and agree that CaterPlace provides a technology platform accessible as a website , software application or other medium that allows you to search, choose and order food and beverages for pick-up or delivery from a variety of independent, third-party vendors (“Vendors”).  Vendors may utilize the services of third-party delivery companies and/or independent third-party contractors who provide delivery services (“Delivery Service Providers” or “DSPs”).
                                                  <br/><br/>
                                                    You further understand, acknowledge and agree that CaterPlace has no involvement in the preparation, handling, delivery, quality control or other aspect of any item ordered by you from a Vendor using our service, and you expressly acknowledge that Caterplace cannot be held liable for any losses, damages, liabilities, lawyers’ fees, or other expenses caused by the acts or omissions of or any interaction between you and a Vendor or between you and a DSP.
                                                  </p>
                                                    <h2>4. User Account</h2>
                                                    <p className="normal_p">
                                                      CaterPlace requires that you create register for a password-protected account to use the Services.  By creating an account with CaterPlace, you agree that:
                                                    </p>
                                                    <p className="p_left normal_p">
                                                      (a) you are the sole authorized user of any account you create to use the Services;<br/>
                                                        (b) you are solely and fully responsible for all activities that occur under your password-protected account;<br/>
                                                          (c) you shall not authorize others to use your account;<br/>
                                                            (d) you shall monitor your account to prevent unauthorized use of your account by minors;<br/>
                                                              (e) you shall provide and maintain accurate, current and complete information during the registration process and at all other times when using the Services;<br/>
                                                                (f) you will immediately inform CaterPlace of any suspected unauthorized party accessing the Services through your account;<br/>
                                                                  (g) CaterPlace shall not be liable for losses, damages, liability, expenses and/or lawyers’ fees incurred by CaterPlace, a Vendor or DSP from an unauthorized person using your account and that you may be liable for such losses, damages, liability, expenses and/or lawyers’ fees regardless of whether you have notified CaterPlace of such unauthorized use; <br/>
                                                                    (h) CaterPlace has the right to suspend or terminate your account for any reason or no reason whatsoever and refuse to provide you any current or future use of the Services; and<br/>
                                                                      (i) you will not create an account or use the Services if you have been previously banned from use of the Services.<br/>
                                                                      </p>
                                                                      <h2>5. Communications with CaterPlace</h2>
                                                                      <p className="normal_p">
                                                                        By creating a CaterPlace account, you consent to receive communications including electronic communications from CaterPlace, Vendors and DSPs including via email to the email address provided by you to CaterPlace and text message, calls and notifications to the mobile phone number provided by you to CaterPlace.  You consent to receive communications generated by automatic telephone dialing systems and/or prerecorded messages sent by or on behalf of CaterPlace, Vendors or DSPs.  You agree that all agreements, notices, disclosures and other communications that CaterPlace provides to you electronically satisfy any legal requirement that such communication be in writing.</p>
                                                                      <h2>6. User Content</h2>
                                                                      <p className="normal_p">
                                                                        6.1 Responsibility for your content.<br/>
                                                                          You alone are responsible for your user reviews or published content and once posted to CaterPlace, it cannot always be withdrawn.  You represent that you own or have the necessary permissions to use and authorize the use of your content as described herein.  You may not imply that your content is in any way sponsored or endorsed by CaterPlace. You may expose yourself to liability if, for example, your content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, service mark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; violates or advocates the violation of any law or regulation; or violates these Terms.
                                                                      </p>
                                                                      <p className="normal_p">
                                                                        6.2 Our Rights Regarding User Content<br/>
                                                                          We may use your content in a number of different ways, including by publicly displaying it, reformatting it, incorporating it into advertisements and other works, creating derivative works from it, promoting it, distributing it, and allowing others to do the same in connection with their own websites and media platforms.  As such, you hereby irrevocably grant us world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use your content for any purpose.  We reserve the right to remove, screen, edit, or reinstate user content at our sole discretion for any reason or no reason, and without notice to you
                                                                      </p>
                                                                      <h2>7. Payments</h2>
                                                                      <p className="p_left normal_p">
                                                                        7.1  Payment Authorization<br/>
                                                                          You authorize CaterPlace to charge all amounts owed for orders you place to the payment method specified at the time of your purchase. If you pay any amounts with a credit card, CaterPlace may look for pre-authorization of your credit card account prior to your order to verify that the credit card is valid and has credit available.
                                                                      </p>
                                                                      <p className="p_left normal_p">
                                                                        7.2 Cancellations<br/>
                                                                          If you cancel your order, you may be charged some or all of the amount of the order, depending on the policy of the Vendor. CaterPlace reserves the right to charge you the full order amount, if you, or the person you designate, is not at the specified delivery location at the time the order is delivered or if you fail to pick up a “take-out” or “pick-up” order from a Vendor.
                                                                      </p>
                                                                      <p className="p_left normal_p">
                                                                        7.3 Refunds<br/>
                                                                          Charges paid by you for completed and delivered orders are final and non-refundable.  CaterPlace has no obligation to provide refunds or credits, but may grant them, in each case, in CaterPlace’s sole discretion.
                                                                      </p>
                                                                      <p className="p_left normal_p">
                                                                        7.4 Other Fees<br/>
                                                                          We reserve the right to establish, remove, and/or revise fees, or other amounts for any or all services or features we provide through CaterPlace.
                                                                      </p>
                                                                      <p className="p_left normal_p">
                                                                        7.5 CaterCash Rewards Program<br/>
                                                                          We currently offer “CaterCash” - a loyalty program where you can earn CaterCash points for purchases of goods or services or other activities (the “Rewards Program”). In our absolute discretion, we may also distribute points for other reasons from time to time.  You agree that we may change the terms and conditions of the Rewards Program, terminate the Rewards Program, or expire, deduct, limit, or modify your Rewards Program points at any time for any reason, including in the event we determine or believe that your participation in the Rewards Program was in error, fraudulent, illegal, or otherwise in violation of these Terms.
                                                                      </p>
                                                                      <p className="p_left normal_p">
                                                                        7.6 Redeeming Rewards<br/>
                                                                          You can redeem your CaterCash for gift cards, service credits or merchandise by going to the CaterCash tab to review your balance. Once redeemed, the CaterCash Rewards will be withdrawn from your account and a redemption code will appear in the website and/or be emailed to you at the email address you previously provided. Redeeming Rewards may require you to agree to additional terms and conditions that are established by the applicable provider or sponsor. Any additional terms and conditions will be presented to you in the website and you must agree to them before you can redeem your rewards. CaterCash points are for use only in CaterPlace and have no cash value or other value outside of CaterPlace.
                                                                      </p>
                                                                      <p className="p_left normal_p">
                                                                        7.7  Promotions, Offers, Referral Programs<br/>
                                                                          From time to time, we may offer courtesy/promotional credits or other discounts, promotions or offers. Any courtesy/promotional credits that you receive may only be used to purchase goods or services on our site. We reserve the right to expire, limit, or modify any courtesy/promotional credits or other discounts, promotions or offers at any time and with no advance notice provided to you. If your account is cancelled for any or no reason, you may forfeit any pending, current, or future credits and any other forms of unredeemed value in or associated with your account without prior notice to you.
                                                                      </p>
                                                                      <h2>8. License to Access CaterPlace Services</h2>
                                                                      <p className="p_left normal_p">
                                                                        8.1 Your License<br/>
                                                                          As long as you comply with these Terms and we have not otherwise revoked your right, we grant you a limited, non-exclusive, non-sublicensable, non-transferable, and revocable right to access and use CaterPlace only in a manner that complies with all legal requirements that apply to you or your use of CaterPlace, including the CaterPlace <Link to={'/terms-and-conditions'} style={{ cursor: "pointer",color:"#FD4E3B" }}>Terms of Service Agreement</Link> and <Link to={'/privacy-policy'} style={{ cursor: "pointer",color:"#FD4E3B" }}>Privacy Policy</Link> and these Terms. We may revoke this license at any time, in our sole discretion, for any or no reason. Upon any such revocation, you must immediately cease all access and use of CaterPlace. In addition, at our request you must destroy all materials downloaded or otherwise obtained from CaterPlace, as well as all copies of such materials, whether made in accordance with these Terms or otherwise.
                                                                      </p>
                                                                      <p className="p_left normal_p">
                                                                        8.2 Prohibited Uses<br/>
                                                                          Use of the CaterPlace for any illegal purpose—or for any other purpose not expressly permitted in these Terms—is strictly prohibited. Without limitation, you will not use CatePlace to:<br/>
                                                                            advocate illegal activity or discuss illegal activities with an intent to commit them; <br/>
                                                                              engage in money laundering or any other fraudulent activity; <br/>
                                                                                make available any material that contains any software, device, instructions, computer code, files, programs, and/or other content or features designed to interrupt, destroy, harm, or limit the functionality of any computer software or hardware or telecommunications equipment (including without limitation any time bomb, virus, malware, software lock, worm, self-destruction, drop-device, malicious logic, Trojan horse, trap door, “disabling,” “lock out,” “metering” device or any malicious code); <br/>
                                                                                  do anything that could disable, overburden, or impair the proper working of  CatePlace; <br/>
                                                                                    impede or otherwise prohibit communication or disrupt user discussion; <br/>
                                                                                      impersonate any person or entity in a manner that does not constitute parody; <br/>
                                                                                        solicit personal or sensitive information from other users; <br/>
                                                                                          send spam, commercial electronic messages, or other advertisements or solicitations, surveys, contents, pyramid schemes, promote commercial entities, or otherwise engage in commercial activity on or through CaterPlace; <br/>
                                                                                            violate, or facilitate violations of, these Terms, CaterPlace <Link to={'/privacy-policy'} style={{ cursor: "pointer",color:"#FD4E3B" }}>Privacy Policy</Link>, or any guidelines or policies posted by us; and/or <br/>
                                                                                              interfere with any other party’s use and enjoyment of CaterPlace.
                                                                                            </p>
                                                                                            <h2>9. Legal Compliance</h2>
                                                                                            <p className="normal_p">
                                                                                              You acknowledge, consent, and agree that we may access, preserve, and disclose your information and/or any User Content you submit or make available for inclusion on CaterPlace, if required to do so by law or in a good faith belief that such access, preservation, or disclosure is permitted by law or by our <Link to={'/privacy-policy'} style={{ cursor: "pointer",color:"#FD4E3B" }}>Privacy Policy</Link>.</p>
                                                                                            <h2>10. Privacy</h2>
                                                                                            <p className="normal_p">
                                                                                              For information about how we collect, use, and distribute the data we collect from and about you, please see our <Link to={'/privacy-policy'} style={{ cursor: "pointer",color:"#FD4E3B" }}>Privacy Policy</Link>, which is incorporated herein by reference into these Terms.</p>
                                                                                            <h2>11. Exclusions and Limitations</h2>
                                                                                            <p className="normal_p">
                                                                                              Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for damages such as below in the section entitled “Warranties, Disclaimers, and Limitation of Liability.” Accordingly, some of the below limitations may not apply to you. If you are a resident of a U.S. state that does not permit the exclusion of certain warranties and liabilities, then the limitations in the “Warranties, Disclaimers, and Limitation of Liability” section below shall apply to you only to the extent permitted by law.</p>
                                                                                            <h2>12. Disclaimer of Warranties; Limitation of Liability</h2>
                                                                                            &nbsp;<p className="normal_p">CATERPLACE PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS. YOU USE THE SERVICE AT YOUR OWN RISK. CATERPLACE EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND ANY OTHER WARRANTY THAT MIGHT ARISE UNDER ANY LAW. WITHOUT LIMITING THE FOREGOING, CATERPLACE MAKES NO REPRESENTATIONS OR WARRANTIES:
                                                                                            </p><p className="p_left normal_p">
                                                                                              (A) THAT THE SERVICE WILL BE PERMITTED IN YOUR JURISDICTION;<br/>
                                                                                                (B) THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE;<br/>
                                                                                                  (C) CONCERNING ANY CONTENT SUBMITTED BY ANY USER; <br/>
                                                                                                    (D) CONCERNING ANY THIRD PARTY’S USE OF CONTENT THAT YOU SUBMIT; <br/>
                                                                                                      (E) THAT ANY CONTENT YOU SUBMIT WILL BE MADE AVAILABLE ON THE SERVICE OR WILL BE STORED BY CATERPLACE;<br/>
                                                                                                        (F) THAT CATERPLACE WILL CONTINUE TO SUPPORT ANY PARTICULAR FEATURE OF THE SERVICE;<br/>
                                                                                                          (G) CONCERNING SITES AND RESOURCES OUTSIDE OF THE SERVICE, EVEN IF LINKED TO, FROM OR THROUGH FROM THE SERVICE.<br/>
                                                                                                            IN NO EVENT WILL CATERPLACE, ITS AFFILIATES AND/OR SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS AND/OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, UNDER ANY LEGAL THEORY OR CLAIM, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PROPERTY DAMAGE, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICE, ANY VENDORS OR OTHER ESTABLISHMENTS BOOKED OR CONTACTED THROUGH OR IN CONNECTION WITH THE SERVICE, ANY REWARDS ACQUIRED THROUGH THE SERVICE, ANY WEBSITES LINKED TO THE SERVICE OR ANY CONTENT ON THE SERVICE. SOME JURISDICTIONS (SUCH AS THE STATE OF NEW JERSEY) DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION OR LIMITATION MAY NOT APPLY TO YOU. THE FOREGOING DISCLAIMERS, EXCLUSIONS AND LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                                                                                                          </p>
                                                                                                          <h2>13. Third-Party Links and Services</h2>
                                                                                                          <p className="normal_p">CaterPlace may provide (i) information and content provided by third parties (for example, Vendors); (ii) links to third-party websites or resources (for example, links to our social media pages or social media pages of Vendors); and (iii) third-party products and services for sale directly to you (for example, food prepared by Vendors). CaterPlace is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for (x) any content, advertising, products, or other materials on or available from such third parties; (y) any errors or omissions by such third parties; or (z) any information handling practices or other business practices of such third parties. You further acknowledge and agree that CaterPlace shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any third-party websites, products, services, or other resources. Your interactions with such third parties will be governed by the third party’s own terms of service, <Link to={'/privacy-policy'} style={{ cursor: "pointer",color:"#FD4E3B" }}>privacy policy</Link>, and any other similar terms.</p>
                                                                                                          <h2>14. Modification and Termination</h2>
                                                                                                          <p className="p_left normal_p">
                                                                                                            14.1  Modification of CaterPlace<br/>
                                                                                                              We reserve the right at any time to modify or discontinue, temporarily or permanently, CaterPlace or any part thereof. We may do this with or without providing notice to you of the modification or discontinuance. We will not be liable to you or any third party for any modification, suspension, or discontinuance of CaterPlace.
                                                                                                          </p>
                                                                                                          <p className="p_left normal_p">
                                                                                                            14.2  Termination<br/>
                                                                                                              We may, in our sole and absolute discretion, terminate, suspend, or otherwise deny your access to all or part of CaterPlace at any time. If we terminate your right to access CaterPlace, these Terms will terminate, and all rights you have to access CaterPlace will immediately terminate. Even if these Terms terminate, certain provisions of these Terms will still apply post termination, including without limitation the “Mandatory Arbitration and Class Action Waiver” provisions. Termination of your account may also include, at our sole discretion, the deletion of your account and/or User Content. If we delete your account or User Content, you will not be able to retrieve any information related to your account except as required by applicable law.
                                                                                                          </p>
                                                                                                          <h2>15. Notice of Intellectual Property Infringement</h2>
                                                                                                          <p className="normal_p">We respect the intellectual property of others and require you to do the same. For this reason, it is our policy to terminate the account of a user who is deemed to infringe third-party intellectual property rights and/or to remove User Content that is deemed to be infringing. If you believe that your work has been copied in a way that constitutes copyright infringement and is displayed on CaterPlace, please send a written notice to our Copyright Agent at the e-mail address below that includes all of the following information:</p>
                                                                                                          <ul style={{marginLeft: '30px', listStyle:'none'}}>
                                                                                                            <li>
                                                                                                              (i) An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interests. For example, if you are the owner of a copyright, your notice to us must include your signature.</li>
                                                                                                            <li>
                                                                                                              (ii) A description of your copyrighted work or other intellectual property that you claim has been infringed. For example, this means that you could include a written description of the work you believe to be infringed or, for images, you could also copy-paste the image of the work you believe to be infringed into your notice to us.</li>
                                                                                                            <li>
                                                                                                              (iii) A description of where the material you claim is infringing is located on the Platform. For example, providing us with a website URL or screenshot of the relevant page of our mobile app is the quickest way to help us locate content quickly.</li>
                                                                                                            <li>
                                                                                                              (iv) Your address, telephone number, and email address.</li>
                                                                                                            <li>
                                                                                                              (v) A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
                                                                                                            <li>
                                                                                                              (vi) A statement by you made under penalty of perjury that the above information in your notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner’s behalf.</li>
                                                                                                          </ul>
                                                                                                          <p className="normal_p">
                                                                                                            We include the information in this section solely to comply with our rights and obligations under the Digital Millennium Copyright Act (“DMCA”) and other applicable law. However, you should not construe this section as legal advice to you. You should only send your notice to us after consulting with your legal counsel. Please also note that, pursuant to 17 U.S.C. § 512(c), any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability.
                                                                                                            Please email us at <a style={{color:'#FD4E3B'}} href="mailto:support@CaterPlace.com">support@CaterPlace.com</a>

                                                                                                          </p><h2>16. Governing Law</h2>
                                                                                                          <p className="normal_p">Except for the “Dispute Resolution” Section, the terms, conditions, and policies contained in this Agreement shall be governed by and construed in accordance with the laws of the State of New York, without regard to its conflict of laws principles.  The Federal Arbitration Act will govern the interpretation and enforcement of the “Dispute Resolution” Section.
                                                                                                            You must provide notice to CaterPlace, pursuant to the procedures in the Dispute Resolution Section, of any claim within one year of its accrual, or your claim will be waived and barred regardless of any statute or law to the contrary.
                                                                                                          </p>
                                                                                                          <h2>17. CHANGES TO THESE TERMS</h2>
                                                                                                          <p className="normal_p">
                                                                                                            We may revise these Terms at any time. If we revise these Terms, we will give you notice of any revisions in accordance with legal requirements. If you do not agree to, or cannot comply with, any modified Terms, you must stop using CaterPlace. Your continued use of the Platform after any such update constitutes your binding acceptance of such changes. The Terms were most recently updated on the last modified date at the top of this document.</p>
                                                                                                          <h2>18. GENERAL TERMS</h2>
                                                                                                          <p className="p_left normal_p">18.1&nbsp;Force Majeure.<br/> Under no circumstances shall CaterPlace or its licensors or suppliers be held liable for any delay or failure in performance resulting directly or indirectly from an event beyond its reasonable control.</p>
                                                                                                          <p className="p_left normal_p">
                                                                                                            18.2&nbsp;Waiver.<br/> If we fail to insist that you perform any obligations under these Terms, that will not mean that you do not have to comply with your obligations. If we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived such rights. If we do waive a failure or breach by you, we will only do so in writing and that will not mean that we automatically waive any future failure of breach by you.
                                                                                                          </p>
                                                                                                          <p className="p_left normal_p">
                                                                                                            18.3&nbsp;Severability.<br/> Each provision of these Terms operates separately. If any court or relevant authority decides that any provision is unlawful or unenforceable, the remaining provisions will remain in full force and effect.</p>
                                                                                                          <p className="p_left normal_p">
                                                                                                            18.4&nbsp;Survival.<br/> Any provision of these Terms that by its nature is reasonably intended to survive beyond termination of these Terms shall survive.
                                                                                                          </p>
                                                                                                          <p className="p_left normal_p">
                                                                                                            18.5&nbsp;Third-Party Beneficiaries.<br/> You agree that, except as otherwise expressly provided in these Terms, there shall be no third-party beneficiaries to these Terms. This means that unless we say otherwise in these Terms, no one other than you can benefit from the contract set forth between you and us in these Terms.
                                                                                                          </p>
                                                                                                          <p className="p_left normal_p">
                                                                                                            18.6&nbsp;Entire Agreement.<br/> These Terms (and all terms and conditions incorporated herein) constitute the entire agreement between you and CaterPlace, and take precedence over any prior agreements between you and CaterPlace relating to the Platform, except as expressly provided herein.
                                                                                                          </p>
                                                                                                          <p className="p_left normal_p">
                                                                                                            18.7&nbsp;Assignment and Succession. <br/>You may not assign or delegate any rights or licenses granted to you by these Terms. CaterPlace may assign or delegate any rights or licenses granted to us by these Terms without restriction. These Terms bind and inure to the benefit of your and our successors and those to which you or we have assigned legal responsibilities.
                                                                                                          </p>
                                                                                                          <p className="p_left normal_p">
                                                                                                            18.8&nbsp;Relationship Between You and Us. <br/>No agency, partnership, joint venture, or employee-employer relationship is intended or created by these Terms. In other words, CaterPlace is an independent contractor, and you agree that CaterPlace is not responsible for any information you convey, in any manner, and has no control over any actions you take or statements that you make.
                                                                                                          </p>
                                                                                                          <p className="p_left normal_p">
                                                                                                            18.9&nbsp;Electronic Agreement.<br/> You agree that these Terms, and any other agreements made by and between you and us, in electronic form are as legally binding as if made in physical written form.
                                                                                                          </p>
                                                                                                          <p className="p_left normal_p">
                                                                                                            18.10&nbsp;U.S. Government Users.<br/> If you are using CaterPlace for or on behalf of the U.S. government, your license rights do not exceed those granted to non-government consumers.
                                                                                                          </p>
                                                                                                          <p className="p_left normal_p">
                                                                                                            18.11&nbsp;Section Titles.<br/> The section titles in these Terms are for convenience only and have no legal or contractual effect.
                                                                                                          </p>
                                                                                                          <p className="p_left normal_p">18.12&nbsp;Notices. <br/>We may deliver notice to you by email, posting a notice on the Platform, or by any other method we choose. Any such notice will be effective when we send, post, or otherwise dispatch it. If you give notice to us, it will be effective when we receive it by mail at the address set forth in Section 19 below.</p>
                                                                                                          <h2>19. Contact</h2>
                                                                                                          <p className="normal_p">
                                                                                                            If you have any questions regarding this Agreement, please email us at <a style={{color:"#FD4E3B"}} href="mailto:support@CaterPlace.com">support@CaterPlace.com</a>. </p>
                                                                                                        </div>
                                                                                                      </div>

                                                                                                    </div>
                                                                                                  </section>

                                                                                                  <Footer />
                                                                                                </div>
                                                                                                );
}
