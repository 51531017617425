import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";

import { IAddonItem, ICartItem } from "@services/redux/order";
import { quantityPriceCalculator } from "../../../../Modules/Common/commonUtils";
import { LIST_ONE_MENU } from "../../../../Graphql/queries";

import {
  IGetMenuResponse,
  CartDietary,
  IAddonTypeItemResponse,
  IAddonTypeResponse,
  ISubAddonItemResponse,
} from "./cart-item.typings";
import { MAX_QUANTITY } from "@constants/order.constants";

interface ICartModalStateProps {
  cart: ICartItem[];
  cartItem: ICartItem;
  cartIndex: number;
  menuId: string;
  customerId: string;
  onUpdateCart(cart: ICartItem[]): void;
  onClose(): void;
}

export function useCartModalState({
  cart,
  cartItem,
  cartIndex,
  menuId,
  customerId,
  onClose,
  onUpdateCart,
}: ICartModalStateProps) {
  const [selectedAddons, setAddOnItems] = useState<IAddonItem[]>([]);
  const [instructions, setInstruction] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const { data, loading: isLoading } = useQuery<IGetMenuResponse>(LIST_ONE_MENU, {
    variables: { getOneMenuId: menuId },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (cartItem) {
      return setQuantity(cartItem.quantity);
    }

    if (data && data.getOneMenu.minQuantity) {
      return setQuantity(+data?.getOneMenu.minQuantity);
    }

    setQuantity(1);
  }, [cartItem, data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    let currentPrice = data.getOneMenu.price * quantity;
    selectedAddons.forEach((addon) => {
      currentPrice += addon.pricePerAddOn * quantity;
      addon.subAddOns.forEach((subAddon) => {
        currentPrice += subAddon.pricePerSub * quantity;
      });
    });

    setTotalPrice(currentPrice);
  }, [selectedAddons, quantity, data]);

  useEffect(() => {
    if (!cartItem) {
      return;
    }

    setInstruction(cartItem.cartInstruction);
    setAddOnItems(cartItem.addOns);
    setQuantity(cartItem.quantity);
  }, [cartItem]);

  function onSaveCart() {
    const payload: ICartItem = {
      uuid: uuidv4(),
      categoryId: data?.getOneMenu?.categoryId,
      cartType: "Menu",
      catererId: data?.getOneMenu?.catererId,
      customerId,
      itemName: data?.getOneMenu?.itemName,
      menuId: data?.getOneMenu?._id,
      orderId: null, // TODO: order id
      cartTotalPrice: totalPrice,
      pricePerUnit: data?.getOneMenu?.price,
      minimumQuantity: +data?.getOneMenu?.minQuantity,
      quantity: quantity,
      cartInstruction: instructions,
      isEditedOrder: false,
      totalPricePerUnit: data?.getOneMenu.price,
      addOns: selectedAddons,
      cartId: cartItem ? (cartItem._id ? cartItem._id : null) : null,
    };

    const newCart = cartItem
      ? cart.map((item, index) => (index === cartIndex ? { ...item, ...payload } : item))
      : [...cart, payload];

    onUpdateCart(newCart);

    onClose();
  }

  function onIncrement() {
    const newQuantity = isNaN(quantity) ? 1 : MAX_QUANTITY <= quantity ? quantity : quantity + 1;
    setQuantity(newQuantity);
    setTotalPrice(quantityPriceCalculator(totalPrice, newQuantity, "increment"));
    onUpdateAddonsQuantity(newQuantity);
  }

  function onDecrement() {
    if (!data) {
      return;
    }
    if (quantity > data.getOneMenu.minQuantity) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      setTotalPrice(quantityPriceCalculator(totalPrice, newQuantity, "decrement"));

      onUpdateAddonsQuantity(newQuantity);
    }
  }

  function onChangeQuantity(e: any) {
    if (parseInt(e.target.value) < 0) {
      e.target.value = -parseInt(e.target.value);
    }
    if (e.target.value === "") {
      e.target.value = "";
    } else if (e.target.value === "0") {
      e.target.value = 1;
    }
    e.target.value = e.target.value.slice(0, 4); // limit to 4 characters

    setQuantity(parseInt(e.target.value));
    setTotalPrice(
      quantityPriceCalculator(
        totalPrice,
        parseInt(e.target.value),
        quantity,
        data?.getOneMenu?.price,
      ),
    );
    onUpdateAddonsQuantity(+e.target.value);
  }

  function onUpdateAddonsQuantity(newQuantity: number) {
    setAddOnItems((addons) =>
      addons.map((addon) => ({
        ...addon,
        addOnQuantity: newQuantity,
        subAddOns: addon.subAddOns.map((sub) => ({ ...sub, subQuantity: newQuantity })),
      })),
    );
  }

  function isAddonSelected(item: IAddonTypeItemResponse) {
    return !!selectedAddons.find((addon) => addon.addOnId === item._id);
  }

  function isSubAddonSelected(
    selectedAddon: IAddonTypeItemResponse,
    subAddon: ISubAddonItemResponse,
  ) {
    const addon = selectedAddons.find((add) => add.addOnId === selectedAddon._id);
    if (!addon || !addon.subAddOns.length) {
      return false;
    }
    return !!addon.subAddOns.find((sub) => sub.subAddOnId === subAddon._id);
  }

  function isAddonCheckboxDisabled(type: IAddonTypeResponse, item: IAddonTypeItemResponse) {
    let limit = 0;
    let counter = 0;
    selectedAddons?.map((addon) => {
      if (type.addOnTypeName === addon.addOnTypeName) {
        limit = type.addOnTypeLimit ? type.addOnTypeLimit : 0;
        counter++;
      }
    });
    if (type.addOnTypeLimit === 1 || type.addOnTypeLimit === null) {
      return false;
    }
    if (selectedAddons?.find((element) => element.addOnId === item._id)) {
      return false;
    } else if ((counter !== 0 || limit !== 0) && counter >= limit) {
      return true;
    }
    return false;
  }

  function onSelectAddon(
    isChecked: boolean,
    addOnType: IAddonTypeResponse,
    addOnItem: IAddonTypeItemResponse,
  ) {
    if (isChecked) {
      const addOnObject: IAddonItem = {
        addOnTypeName: addOnType.addOnTypeName,
        addOnName: addOnItem.addOnName,
        pricePerAddOn: addOnItem.addOnPrice ?? 0,
        addOnQuantity: quantity,
        addOnId: addOnItem._id,
        totalPrice: quantityPriceCalculator(addOnItem.addOnPrice, quantity),
        subAddOns: [],
      };
      if (addOnType.addOnTypeLimit === 1 || addOnType.addOnTypeLimit === null) {
        setAddOnItems((prevAddons) =>
          prevAddons
            .filter((item) => item.addOnTypeName !== addOnType.addOnTypeName)
            .concat([addOnObject]),
        );
      } else {
        setAddOnItems((prevArr) => [...prevArr, addOnObject]);
      }
    } else {
      setAddOnItems((prevAddons) => prevAddons.filter((addon) => addon.addOnId !== addOnItem._id));
    }
  }

  function onSelectSubAddon(
    isChecked: boolean,
    addonItem: IAddonTypeItemResponse,
    subAddon: ISubAddonItemResponse,
  ) {
    if (isChecked) {
      const subAddOnObject = {
        subAddOnName: subAddon.subAddOnName,
        pricePerSub: subAddon.subAddOnPrice ?? 0,
        subQuantity: quantity,
        subtotalPrice: quantityPriceCalculator(subAddon.subAddOnPrice, quantity),
        subAddOnId: subAddon._id,
      };

      setAddOnItems((prevAddons) =>
        prevAddons.map((addon) => ({
          ...addon,
          subAddOns:
            addon.addOnId === addonItem._id
              ? addon.subAddOns
                ? [...addon.subAddOns, subAddOnObject]
                : [subAddOnObject]
              : addon.subAddOns,
        })),
      );
    } else {
      setAddOnItems((prevAddons) =>
        prevAddons.map((addon) => ({
          ...addon,
          subAddOns:
            addon.addOnId === addonItem._id
              ? addon.subAddOns.filter((sub) => sub.subAddOnId !== subAddon._id)
              : addon.subAddOns,
        })),
      );
    }
  }

  return {
    isLoading,
    name: data ? data.getOneMenu.itemName : "",
    dietary: data ? data.getOneMenu.dietory : ("None" as CartDietary),
    type: data ? data.getOneMenu.itemType : "",
    price: data ? data.getOneMenu.price : 0,
    addOnTypeList: data ? data.getOneMenu.addOnTypeList : [],
    itemDescription: data ? data.getOneMenu.itemDescription : "",
    isIndividualPackage: data ? data.getOneMenu.offerIndPkdFlg : false,
    totalPrice,
    addOnArray: selectedAddons,
    instructions,
    quantity,
    minQuantity: data ? data.getOneMenu.minQuantity : 0,
    setInstruction,
    onIncrement,
    onDecrement,
    onChangeQuantity,
    isAddonSelected,
    isAddonCheckboxDisabled,
    onSelectAddon,
    isSubAddonSelected,
    onSelectSubAddon,
    onSaveCart,
  };
}
