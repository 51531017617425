//this page is for listing the orders
import React, { useState } from "react";
import { Table, Select, MenuItem, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import DialogBox from "../Common/dialogBox";
import {
  userDeatils,
  toggleSnackbar,authVar
} from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import TableRow from "@mui/material/TableRow";
import { LIST_REFER_FRIENDS } from "../../Graphql/queries";
import { UPDATE_INVITATION } from "../../Graphql/mutations";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Menu from "@mui/material/Menu";
import { styled, alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useNavigate } from "react-router-dom";
export default function ReferFriendAdminView(props) {
  const navigate = useNavigate()
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [status, setStatus] = useState('');
  const [oldStatus, setOldStatus] = useState('');
  const [referId, setReferId] = useState('');
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState(false)

  const [updateStatus] = useMutation(UPDATE_INVITATION, { refetchQueries: ['ListReferFriends'] })
  const open = Boolean(anchorEl);
  const user = useReactiveVar(userDeatils);
  const request = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: search
  }
  const { data, loading } = useQuery(LIST_REFER_FRIENDS, { variables: request })


  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item)
  };
  const handleChangePage = (e, pages) => {
      setPage(pages);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
}
  const handleStatus = (status) => {
    setStatus(status);
    setOldStatus(selectedItem?.action)
    setReferId(selectedItem?._id)
    handleClose()
    setOpenDialog(true)
  };
  function statusUpdate(params) {
    try {
      updateStatus({ variables: { actionFrom: oldStatus, actionTo: status, referId: referId } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Status updated successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  const disableClassName = (item) => {
    if (item?.isRegistered && item?.isFirstOrder && item?.action === 'Pending') {
      return 'refer-friend-action-btn'
    } else {
      if (item?.action === "Accepted") {
        return 'refer-friend-action-btn'
      } else if (item?.action === "Pending") {
        return 'refer-friend-action-btn'
      } else if (item?.action === "Declined") {
        return 'refer-friend-action-btn'
      }
    }
  }

  return (
    <div>
      {openDialog && (<DialogBox
        setOpenDialog={setOpenDialog}
        handleDelete={statusUpdate}
        openDialog={openDialog}
        title={`${status} Refererral`}
        discription={`Are you sure you want mark this referrral as ${status}?`}
      />
      )}

      <div className="contentWrap">
        <div className="contentWrapInner">
          <div className="location-head">
            <div className="location-head-title justify-content-between">
            {data?.listReferFriends?.count ?<h5 style={{ color: '#202428', fontSize: '20px' }}>{`${data?.listReferFriends?.count} Referrals`}</h5>:<p>Loading...</p>}
              <input
                placeholder="Search by the Referrer name"
                className="form-control"
                value={search}
                onChange={(e) => {
                  setPage(1);
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <TableContainer>
            <Table
              className="commonTabel"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Referrer</TableCell>
                  <TableCell align="left">Referee</TableCell>
                  <TableCell align="left">Referred email</TableCell>
                  <TableCell align="center">Registered</TableCell>
                  <TableCell align="center">First Order</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  loading ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p> Loading...</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : data?.listReferFriends?.friendslist.length > 0 ? (
                    data?.listReferFriends?.friendslist.map((item) => (
                      <TableRow>
                        <TableCell style={{cursor:"pointer"}} onClick={()=>{navigate(`/customers/${item?.customerId}`)}}>
                          {item?.referrer}
                        </TableCell>
                        <TableCell>
                          {item?.refere}
                        </TableCell>
                        <TableCell>
                          {item?.referedEmail}
                        </TableCell>
                        <TableCell align="center">
                          {item?.isRegistered ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="center">
                          {item?.isFirstOrder ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            style={{ color: "#fff", backgroundColor: item?.action === 'Declined' ? '#6C757D' : item?.action === 'Accepted' ? '#00C643' : item?.action === 'Pending' ? '#E54566' : '' }}
                            disabled={(item?.isRegistered && item?.isFirstOrder && item?.action === 'Pending') ? false : true}
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={(e) => handleClick(e, item)}
                            endIcon={<KeyboardArrowDownIcon />}
                            className={disableClassName(item)}
                          // classNa?me="refer-friend-action-btn"
                          >
                            {item?.action}
                          </Button>
                          
                        </TableCell>
                      </TableRow>)))
                    : props?.searchValue ? (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <div
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <p>No data Found</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <div
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <p>No list added Yet!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
              </TableBody>

            </Table>
          </TableContainer>
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select
              onChange={handlePageInputChange}
              value={rowsPerPage}
            >
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listReferFriends?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
        <Menu
          id="basic-menu"
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className="menu-list-more"
        >
          {/* <MenuItem onClick={() =>handleStatus('Pending')} value={'Pending'}>Pending</MenuItem> */}
          <MenuItem onClick={() => handleStatus('Accepted')} value={'Accepted'}>Accepted</MenuItem>
          <MenuItem onClick={() => handleStatus('Declined')} value={'Declined'}>Declined</MenuItem>
        </Menu>
      </div>
    </div>
  );
}
