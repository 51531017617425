// This file is to maingtain the commonly used functions in diffrent components
import { toggleSnackbar } from "../../ReactiveVariables/index";

// price formatter in dollar
export const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

//delivery fee formatter
export const deliveryFeeformatter = (item) => {
    if (item?.deliveryFeeType === "Percentage") {
        return `${item?.deliveryFee}%`;
    } 
    const fee = formatter.format(item?.deliveryFee);

    return item?.deliveryFee > 0 ? `${fee}+` : fee;
}

export const tablewareFormatter = (tableware, isCharged) => {
    if (!isCharged) {
        return 'Included';
    }
    return formatter.format(tableware)
}

//delivery hours formatter
export const deliveryHoursCheck = (days) => {
    let daysArray = []
    days.map(day => {
        if (day?.flag && day?.openHours) {
            day?.openHours.map(openhour => {
                if (openhour?.closeTime === '' && openhour.closeTime === '') {
                    daysArray.push(day)
                }
            })
        }
    })

    if (daysArray.length > 0) {
        toggleSnackbar({
            status: true,
            message: "Add delivery hours for open days",
            variant: "error",
        });
        return false
    } else {
        return true
    }
}
// total price calculator
export const quantityPriceCalculator = (totalPrice, quantity, action, initialPrice) => {
    quantity = parseInt(quantity)
    if (totalPrice === 0 || totalPrice === undefined || totalPrice === Infinity) {
        totalPrice = initialPrice
    }
    if (isNaN(quantity)) {
        quantity = 1
    }
    if (totalPrice && quantity) {
        if (action === "decrement" || action === "increment") {

            let individualPrice = totalPrice / quantity

            if (action === "increment") {
                return individualPrice * (quantity + 1)
            }
            else if (action === "decrement") {
                return individualPrice * (quantity - 1)
            }
        } else if (action === "update") {
            let individualPrice = totalPrice / quantity
            return individualPrice * quantity
        }
        else if (!isNaN(action)) {
            let individualPrice = totalPrice / action
            return individualPrice * quantity

        }
        else {
            let sum = totalPrice * quantity
            return sum
        }
    } else {
        return 0
    }
}
//price calculation with addons and subaddons
export const priceCalculatorAddOns = (total, addOnObject, action, quantity, subAddOn) => {

    if (addOnObject) {
        // checking whether it is an addon or sub addon
        if (subAddOn === "subAddOn") {
            if (action === "add") {
                return total + (addOnObject.pricePerSub * quantity)

            } else {
                return total - (addOnObject.pricePerSub * quantity)

            }
        }
        // if subaddons are unchecked after checked
        else if (subAddOn === "delsubAddOn") {
            let delAdd = total - (addOnObject.pricePerAddOn * quantity)
            if (addOnObject?.subAddOns.length > 0) {
                addOnObject?.subAddOns.map(item => (
                    delAdd = delAdd - (item.pricePerSub * quantity)
                ))
            }
            return delAdd
        }
        else {
            if (action === "add") {
                return total + (addOnObject.pricePerAddOn * quantity)

            } else {
                return total - (addOnObject.pricePerAddOn * quantity)

            }
        }

    }
}
export const totalPriceCalculatorAddOnsAndSubAddons = (totalPrice, quantity, data, initialTotalPrice) => {
    if (totalPrice === 0) {
        if (data) {

            let addOnSum = ''
            const pricePerAddOnSum = data.reduce((acc, cur) => acc + cur.pricePerAddOn * 1, 0);
            const pricePerSubSum = data.reduce((acc, cur) => {
                const subAddOnsPrice = cur.subAddOns.reduce(
                    (subAcc, subCur) => subAcc + subCur.pricePerSub * 1,
                    0
                );
                return acc + subAddOnsPrice;
            }, 0);

            addOnSum = pricePerSubSum + pricePerAddOnSum + initialTotalPrice
            return addOnSum

        } else {
            return initialTotalPrice
        }
    } else if (totalPrice > 0) {
        let individualPrice = totalPrice / quantity
        return individualPrice * quantity
    } else {
        return 0
    }
}
//sum of total food and bevarage
export const foodAndBeverageTotal = (cartData) => {
    if (cartData === undefined || cartData?.length === 0) {
        return 0
    }
    return parseFloat(cartData?.reduce((total, item) => item.cartTotalPrice + total, 0).toFixed(2))
}

//drivertip calculator
export const driverTipCalculator = (orderData, catererData, totalCost) => {
    if (catererData?.driverTipFlag === "mandatory") {
        if (catererData?.driverTipType === "Percentage") {
            return parseFloat(((totalCost * catererData?.driverTip) / 100).toFixed(2))
        } else {
            return parseFloat(catererData?.driverTip.toFixed(2))
        }
    }
    // if drivertip is already in orderData
    else if (orderData?.driverTipType && orderData?.driverTip) {
        if (orderData?.driverTipType === "Custom") {
            return orderData?.driverTip
        } else {
            return parseFloat(((totalCost * orderData?.tipPercentage) / 100).toFixed(2))
        }
    } else {
        return 0
    }
}

export const driverTipPercentageCalculator = (total, percentage) => {
    return parseFloat((total * percentage / 100).toFixed(2))
}

//salestax calculator hardcoded
export const salesTaxCalculator = (totalCost, percentage) => {
    return parseFloat(((totalCost * percentage) / 100).toFixed(2))
}

export const completeOrderCost = (foodAndBeverage, deliveryFee, totalDriverTip, salesTax, tablewareTotal = 0) => {
    let total = foodAndBeverage + deliveryFee + totalDriverTip + salesTax + tablewareTotal; 
    return parseFloat(total.toFixed(2))
}

// expiry date format
export function formatExpDate(value) {
    if (value) {
        if (value && value.length === 3 && value[2] != "/" && !value.includes("/")) {
            return value.substring(0, 2) + "/" + value.substring(2, 3);
        }
        else if (value.length === 4 && value.includes("/")) {
            return value.substring(0, 2) + value.substring(2, 4);
        }
        else if (value.length === 4 && value[2] != "/") {
            return value.substring(0, 2) + "/" + value.substring(2, 4);
        }

        else return value.substring(0, 5);
    }
}

// formate card number
export function formateNumber(e, oldNumber) {
    let number = e.target.value;
    if (number.length <= 19) {
        number = number
            .replace(/[^\dA-Z]/g, "")
            .replace(/(.{4})/g, "$1 ")
            .trim();
        return number;
    } else return oldNumber;
}

//price per head
export function pricePerHead(price, count) {
    return price / count
}

// Phone Number Formate
export const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
        x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
        x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
        x =
            "(" +
            newNum.slice(0, 3) +
            ") " +
            newNum.slice(3, 6) +
            "-" +
            newNum.slice(6, 10);
    }
    return x;
};

//CaterCash calculation
export const calculateCaterCash = (total, catererData) => {
    let caterCash = catererData?.caterCash
    let totalCaterCash = total * caterCash / 100 || 0;
    return totalCaterCash;
}

// calculating the distance between two points
function degreesToRadians(degrees) {
    var radians = (degrees * Math.PI) / 180;
    return radians;
}

export const calcDistance = (coords, data) => {

    if (data?.cordinates && coords[0]) {
        let startingLat = degreesToRadians(data?.cordinates[1] || data?.cordinates?.lat || data?.coordinates[1]);
        let startingLong = degreesToRadians(data?.cordinates[0] || data?.cordinates?.lng || data?.coordinates[0]);
        let destinationLat = degreesToRadians(coords && coords[1]);
        let destinationLong = degreesToRadians(coords && coords[0]);

        // Radius of the Earth in kilometers
        let radius = 6571;

        // Haversine equation
        let distanceInKilometers = Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) +
            Math.cos(startingLat) * Math.cos(destinationLat) *
            Math.cos(startingLong - destinationLong)) * radius;
        return (distanceInKilometers * 0.621371).toFixed(2);
    }
}

export const calcDistanceNew = (coords, data) => {

    var R = 6371; // km
    var dLat = toRad(coords && coords[1] - data?.coordinates[1]);
    var dLon = toRad(coords && coords[0] - data?.coordinates[0]);
    var lat1 = toRad(data?.coordinates[1]);
    var lat2 = toRad(coords && coords[1]);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; //Distance in km
    return (d * 0.621371).toFixed(2);


    // Converts numeric degrees to radians
    function toRad(Value) {
        return Value * Math.PI / 180;
    }
}
// export const calcDistanceNew= (coords,data)=>{

//     if(data?.coordinates){
//     let startingLat = degreesToRadians(data?.coordinates[1]);
//     let startingLong =  degreesToRadians(data?.coordinates[0]);
//     let destinationLat = degreesToRadians(coords&&coords[1]);
//     let destinationLong = degreesToRadians(coords&&coords[0]);

//     // Radius of the Earth in kilometers
//     let radius = 6571;

//     // Haversine equation
//     let distanceInKilometers = Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) +
//     Math.cos(startingLat) * Math.cos(destinationLat) *
//     Math.cos(startingLong - destinationLong)) * radius;
//     return (distanceInKilometers * 0.621371).toFixed(2);
//     }
// }

// for making the textfield accept only postive values

export const postiveValueConverter = (event, type) => {
    let positiveValue
    // for making the textfield accept only postive values with decimal accepted
    if (type === 'withDecimal') {
        let inputValue = (event.target.value).replace(/[^0-9.]/g, ''); // Replace all non-numeric characters with an empty string

        // Restrict to two decimal places
        const [integerPart, decimalPart] = inputValue.split('.');
        if (decimalPart && decimalPart.length > 2) {
            inputValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
        }

        const decimalCount = (inputValue.match(/\./g) || []).length;
        if (decimalCount > 1) {
            inputValue = inputValue.slice(0, inputValue.lastIndexOf('.'));
        }
        if (inputValue < 0) {
            positiveValue = -(inputValue)
        } else {
            positiveValue = inputValue
        }
        return positiveValue;
    }
    // for making the textfield accept only postive values without decimal accepted
    else if (type === 'withoutDecimal') {
        const inputValue = event.target.value.trim().replace(/[^0-9]/g, '');
        // const inputValue = parseInt(event.target.value);
        if ((/^-?\d+$/).test(inputValue)) {
            if (inputValue === 0) {
                positiveValue = inputValue
            }
            else if (inputValue) {
                if (inputValue < 0) {
                    positiveValue = -(inputValue)
                } else {
                    positiveValue = inputValue
                }
            } else {
                positiveValue = event.target.value
            }
        } else {
            positiveValue = ''
        }
        return positiveValue;
    }
};

// for converting time to 24 hrs
export const convertTime = (time) => {
    const splitTime = time?.split(':')
    const hour = Number(splitTime[0])
    const splitMinute = splitTime[1]?.split('')
    const minute = splitMinute && splitMinute[0] + splitMinute[1]
    const ampm = splitMinute && splitMinute[2] + splitMinute[3]
    let hour24;
    if (ampm === 'pm') {
        hour24 = hour === 12 ? 12 : hour + 12
    }
    else {
        hour24 = hour === 12 ? '00' : hour < 10 ? '0' + hour : hour
    }
    return `${hour24}:${minute}`;

}

export const convertTimeFormat = (time) => {
    const date = new Date(time);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
  }

//check browser
export function checkBrowser(userAgent){
    let browserName;
    if (userAgent.includes('Chrome')) {
      return browserName = 'Google Chrome';
    } else if (userAgent.includes('Firefox')) {
     return browserName = 'Mozilla Firefox';
    } else if (userAgent.includes('Safari')) {
      return browserName = 'Apple Safari';
    } else if (userAgent.includes('Edge')) {
      return browserName = 'Microsoft Edge';
    } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      return browserName = 'Opera';
    } else if (userAgent.includes('Trident') || userAgent.includes('MSIE')) {
     return browserName = 'Internet Explorer';
    } else {
     return browserName = 'Unknown Browser';
    }
}

export function calculateTablewareTotal (amount, headCount) {
  return amount * headCount;
}

export function getLast6Months() {
    const months = [];
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1); // Move back one month to exclude the current month

    for (let i = 0; i < 6; i++) {
        const month = currentDate.getMonth();
        const year = currentDate.getFullYear();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);

        const monthObject = {
            monthString: firstDay.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }),
            firstDay: firstDay,
            lastDay: lastDay,
        };

        months.unshift(monthObject); // Add each month object to the beginning of the array
        currentDate.setMonth(currentDate.getMonth() - 1); // Move back one more month
    }

    return months;
}

export function downloadCSV(csvData, fileName) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}
