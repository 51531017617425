import React, { useState, createRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { Button, Alert } from "@mui/material";

import LoginHeader from "../../Modules/Login/LoginHeader";
import { CatererFilter } from "../../components/organisms/caterer-filter";

import Footer from "../../Modules/Common/footer";
import { CartItemModal } from "./organisms/cart-item-modal";
import CatererReview from "../../Modules/Customer/ReviewCaterer/reviewCaterer";
import DialogBox from "../../Modules/Common/dialogBox";

import { CatererMainInfo } from "./atoms/caterer-main-info";
import { CatererAvailabilityModal } from "./atoms/caterer-availability-modal";
import { CatererSchedule } from "./atoms/caterer-schedule";
import { UnavailableItemsModal } from "./atoms/unavailable-items-modal";
import { CatererCategories } from "./molecules/caterer-categories";
import { CatererMenuList } from "./molecules/caterer-menu-list";
import { CatererLocationInfo } from "./molecules/caterer-location-info";
import { CartView } from "./organisms/cart-view/cart-view";

import { deliveryFeeformatter, formatter } from "../../Modules/Common/commonUtils";
import { authVar, userDeatils, toggleSnackbar } from "../../ReactiveVariables/index";

import { useCatererDetailsState } from "./caterer-details.state";
import { ICartItem } from "@services/redux/order";

export const CatererDetails = () => {
  const {
    isEdit,
    order,
    customerId,
    search,
    catererId,
    availability,
    catererData,
    mobileCartModal,
    alert: { alertStatus, setAlertStatus },
    cart,
    unavailableItems,
    onUpdateOrder,
    onUpdateCart,
  } = useCatererDetailsState();

  const [totalAmount, setTotalAmount] = useState(null);
  const [category, setCategory] = useState("");
  const [isCartModalOpen, setCartModalOpen] = useState(false);
  const [menuId, setMenuId] = useState(null);
  const [cartItem, setCartItem] = useState<ICartItem>(null);
  const [cartIndex, setCartIndex] = useState(-1);
  const [openReview, setOpenReview] = useState(false);
  const user = useReactiveVar(userDeatils);
  const auth = useReactiveVar(authVar);

  const location = useLocation();
  const locationData = location.state;

  const cartItemsQuantity = useMemo(() => {
    return order.cartDetails.reduce((sum, item) => sum + item.quantity, 0);
  }, [order.cartDetails]);

  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;

  const onAddMenuItem = (menuItemId) => {
    if (!search.deliveryDate.value) {
      setAlertStatus("date");
      return;
    }

    if (!search.zip.value) {
      setAlertStatus("zip");
      return;
    }

    setCartModalOpen(true);
    setMenuId(menuItemId);
  };

  function onAddReview() {
    if (!auth.auth) {
      return toggleSnackbar({
        status: true,
        message: "Please log in to add a review",
        variant: "error",
      });
    }
    setOpenReview(true);
  }

  const datepickerRef = createRef();
  const addressRef = createRef();

  function onCartItemClick(item: ICartItem, index: number) {
    if (item.cartType === "Custom") {
      toggleSnackbar({
        status: true,
        message: "Custom item cannot be edited",
        variant: "error",
      });

      return;
    }
    setCartModalOpen(true);
    setMenuId(item.menuId);
    setCartItem(item);
    setCartIndex(index);
  }

  function onConfirmDateAlert() {
    if (!alertStatus) {
      return;
    }
    if (alertStatus === "date") {
      onDateFocus();
    }
    if (alertStatus === "zip") {
      onAddressFocus();
    }

    if (alertStatus === "conflict") {
      return cart.onResolveCatererConflict();
    }

    setAlertStatus(null);
  }

  function onCloseCartModal() {
    setCartModalOpen(false);
    setCartItem(null);
    setCartIndex(-1);
  }

  function onAddressFocus() {
    const addressInput = addressRef.current;
    (addressInput as any).focus();
    pageScroll();
  }

  function onDateFocus() {
    const datepickerElement = datepickerRef.current;
    (datepickerElement as any).setFocus(true);
    pageScroll();
  }

  function pageScroll() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const isCanEditOrder =
    (userType === "Admin" && isEdit) || (userType !== "Admin" && userType !== "Caterer");

  const modalTextMap = useMemo(() => {
    return {
      date: {
        title: "Choose Date and Time",
        description:
          "Before you can add this item, please select the date and time for the delivery.",
      },
      zip: {
        title: "Please verify your delivery address",
        description:
          "Before adding your first item, lets make sure that your full address is correct.",
      },
      conflict: {
        title: "Replace cart item(s)?",
        description: `You currently have items in your cart from ${cart.currentCaterer}. Do you want to discard those item(s) and start a new cart from ${cart.newCaterer}?`,
      },
    };
  }, []);

  return (
    <div className="caterer-menu-list-wrap">
      <DialogBox
        setOpenDialog={setAlertStatus}
        value=""
        date={alertStatus === "date" ? "date" : ""}
        handleDelete={onConfirmDateAlert}
        openDialog={!!alertStatus}
        title={alertStatus ? modalTextMap[alertStatus].title : ""}
        discription={alertStatus ? modalTextMap[alertStatus].description : ""}
      />
      <CatererAvailabilityModal
        isOpen={availability.isAvailableAlert}
        type={availability.isAvailable}
        urlInfo={{
          address: search.street.value,
          date: search.deliveryDate.value,
          coordinates: search.coordinates.value,
        }}
        onClose={availability.onCloseAvailabilityModal}
      />
      <UnavailableItemsModal
        isOpen={unavailableItems.items && !!unavailableItems.items.length}
        items={unavailableItems.items}
        onClose={unavailableItems.onClose}
      />
      <CartItemModal
        isOpen={isCartModalOpen}
        menuId={menuId}
        cartItem={cartItem}
        cartIndex={cartIndex}
        customerId={customerId}
        cart={order.cartDetails}
        onClose={onCloseCartModal}
        onUpdateCart={onUpdateCart}
      />

      <CatererReview openReview={openReview} setOpenReview={setOpenReview} catererId={catererId} />
      <LoginHeader />
      <div className="caterer-menu-listing-wrap">
        {isEdit && <Alert severity="info">You are editing an existing order.</Alert>}
        <div className={mobileCartModal.isOpen ? "d-none" : "listing-filter-primary"}>
          <CatererFilter
            headCount={search.headCount.value}
            setHeadCount={search.headCount.onChange}
            address={search.street.value}
            setAddress={search.street.onChange}
            setDate={search.deliveryDate.onChange}
            onLocationOptions={search.onSelectAddressOptions}
            date={search.deliveryDate.value}
            coordinates={search.coordinates.value}
            zip={search.zip.value}
            isShowZip={true}
            isEdit={isEdit}
            datepickerRef={datepickerRef}
            addressRef={addressRef}
          />
        </div>
        <div className={mobileCartModal.isOpen ? "d-none" : "detail-title-bar"}>
          <CatererMainInfo
            name={catererData ? catererData.getCatererDetails.businessName : ""}
            cash={catererData ? catererData.getCatererDetails.caterCash : 0}
            reviewRate={catererData ? catererData.getCatererDetails.totalRating?.starRate : 0}
            reviewCount={catererData ? catererData.getCatererDetails.totalRating?.count : 0}
            address={catererData ? catererData.getCatererDetails.locationName : ""}
            zip={catererData ? catererData.getCatererDetails?.catererZipCode : ""}
            phone={catererData ? catererData.getCatererDetails.phoneNumber : ""}
            minOrder={catererData ? catererData.getCatererDetails.minOrderAmt : 0}
            deliveryFee={catererData ? deliveryFeeformatter(catererData?.getCatererDetails) : ""}
          />
        </div>
        <div className={mobileCartModal.isOpen ? "d-none" : "container padding-0"}>
          <CatererCategories
            catererId={catererId}
            selectedCategory={category}
            onSelectCategory={setCategory}
          />
        </div>

        <div className="menu-category-listing">
          <div className="container">
            <div className="row">
              <div
                className={
                  mobileCartModal.isOpen
                    ? "d-none"
                    : order.cartDetails.length > 0 && order.catererId === catererId
                    ? "col-xl-9 col-lg-12 col-md-12"
                    : "col-md-12 col-lg-12 col-xl-12"
                }>
                <CatererMenuList
                  catererId={catererId}
                  category={category}
                  isCanEditOrder={isCanEditOrder}
                  onAddItem={onAddMenuItem}
                />
                <CatererReview
                  userType={userType}
                  onAddReview={onAddReview}
                  catererName={catererData ? catererData.getCatererDetails.businessName : ""}
                  catererId={catererId}
                />

                <CatererLocationInfo
                  address={`${search.street.value} ${search.zip.value || ""}`}
                  name={catererData ? catererData.getCatererDetails.businessName : ""}
                  tagLine={catererData ? catererData.getCatererDetails?.tagLine : ""}
                  deliveryInstruction={
                    catererData ? catererData.getCatererDetails.specialDeliveryInstn : ""
                  }
                  catererLocation={catererData ? `${catererData.getCatererDetails.locationName} ${catererData.getCatererDetails.catererZipCode || ''}` : ""}
                />

                <CatererSchedule
                  status={catererData ? catererData.getCatererDetails?.status : null}
                  deliveryHours={catererData ? catererData.getCatererDetails.deliveryHrs : []}
                />
              </div>
              <div
                className={
                  mobileCartModal.isOpen
                    ? "col-xl-3 cart-view-col"
                    : "col-xl-3 col-lg-4 col-md-12 cart-view-col d-none d-xl-block"
                }>
                {order.catererId === catererId && order.cartDetails.length > 0 && (
                  <CartView
                    customerId={customerId}
                    cartData={order.cartDetails}
                    catererData={catererData}
                    headCount={order.headCount}
                    onCartItemClick={onCartItemClick}
                    address={order.street}
                    date={search.deliveryDate.value}
                    zip={order.zip}
                    onAddressFocus={onAddressFocus}
                    onUpdateOrder={onUpdateOrder}
                    order={order}
                    user={user}
                    onCloseCart={mobileCartModal.onClose}
                    isAvailable={availability.isAvailable}
                    onUpdateTotalCost={setTotalAmount}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {cartItemsQuantity > 0 && order.catererId === catererId && (
          <div
            className={mobileCartModal.isOpen ? "d-none" : "mob-cart-button-area d-flex d-xl-none"}>
            <Button onClick={mobileCartModal.onOpen} variant="contained" color="primary">
              <label>
                {cartItemsQuantity} {cartItemsQuantity > 1 ? "Items" : "item"}
              </label>
              <span>{formatter.format(totalAmount)}</span>
            </Button>
          </div>
        )}
      </div>
      {!mobileCartModal.isOpen && <Footer />}
    </div>
  );
};
