import React from "react";

import { Select, MenuItem, Pagination, Stack } from "@mui/material";

interface ITablePaginationProps {
  page: number;
  rowsPerPage: number;
  itemsLength: number;
  setRowsPerPage(value: number): void;
  setPage(value: number): void;
}

export function TablePagination({
  page,
  rowsPerPage,
  itemsLength,
  setRowsPerPage,
  setPage,
}: ITablePaginationProps) {
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }

  const handleChangePage = (e, pages) => {
    setPage(pages);
  };

  return (
    <div className="table-pagenation">
      <div className="rowCount">
        <Select onChange={handlePageInputChange} value={rowsPerPage}>
          <MenuItem value={10}>{10}</MenuItem>
          <MenuItem value={25}>{25}</MenuItem>
          <MenuItem value={50}>{50}</MenuItem>
          <MenuItem value={100}>{100}</MenuItem>
        </Select>
      </div>

      <Stack spacing={2}>
        <Pagination
          className="tableCount"
          count={Math.ceil(itemsLength / rowsPerPage)}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChangePage}
        />
      </Stack>
    </div>
  );
}
