// dialog box for delete options
import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { setId } from "../../ReactiveVariables";

export default function DialogBox(props) {
  const id = useReactiveVar(setId);
  const navigate = useNavigate();
  function handleClick() {
    props?.setOpenDialog(false);
    navigate(`/caterers/${id}`);
    props?.closeModal(false);
  }
  function handleYesClick() {
    props?.setOpenDialog(false);
    props?.handleDelete();
  }
  function handleCancel() {
    props?.setOpenDialog(false);
    props?.setHeadCountFlag && props?.setHeadCountFlag(false);
    props?.setDriverFlag && props?.setDriverFlag(false);
  }
  return (
    <div>
      <Dialog
        open={props?.openDialog}
        onClose={() => props?.setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={
          props?.date === "headCountFlag"
            ? "common-dialog-box headcount-dialog-box"
            : "common-dialog-box"
        }>
        <DialogTitle id="alert-dialog-title">
          <div className="dialog-box-title text-center">
            <h5 className="h5 w-100">{props?.title}</h5>
            {/* <IconButton onClick={() => props?.setOpenDialog(false)}>
              <img src='../assets/images/modal-close.svg' className='img-fluid' alt='Close' />
            </IconButton> */}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="dialog-box-text">
              <h5 className="h5">{props?.discription}</h5>
              {props?.description2 && <h6 className="h6 mt-2">{props?.description2}</h6>}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props?.date !== "date" &&
            props?.value !== "minQuantity" &&
            props?.refund !== "refund" && (
              <Button
                className={
                  props?.date === "headCountFlag"
                    ? "w-auto m-auto dailog-cancel-btn m-auto headcount-btn"
                    : "dailog-cancel-btn m-auto"
                }
                variant="outlined"
                color="secondary"
                onClick={() => {
                  props?.title === "Replace cart item?" ? handleClick() : handleCancel();
                }}>
                {props?.date === "driverFlag"
                  ? "Add Tip"
                  : props?.date === "headCountFlag"
                  ? "Back to menu"
                  : "No"}
              </Button>
            )}
          <Button
            className={props?.date === "headCountFlag" ? "w-auto m-auto headcount-btn" : "m-auto"}
            variant="contained"
            color="primary"
            onClick={() => {
              props?.title === "Replace cart item?" ? handleYesClick() : props?.handleDelete();
              props?.setOpenDialog(false);
            }}
            autoFocus>
            {props?.date === "date" || props?.refund
              ? "OK"
              : props?.date === "driverFlag"
              ? "Skip Tip"
              : props?.date === "headCountFlag"
              ? "Look's good, I'm ready to checkout"
              : props?.value !== "minQuantity"
              ? "Yes"
              : "OK"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
