// this page is for adding new business details
import React from 'react';

import {
  IconButton,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  userDeatils,
  toggleSnackbar,
} from "../../../ReactiveVariables/index";
import { useForm, Controller } from "react-hook-form";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormGroup from "@mui/material/FormGroup";
import { useMutation,useLazyQuery } from "@apollo/client/react/hooks";
import { UPDATE_CATERE, CREATE_CATERER, UPDATE_USER } from "../../../Graphql/mutations";
import { IMAGE_UPLOAD } from '../../../Graphql/queries';
import FormControlLabel from "@mui/material/FormControlLabel";
import { useReactiveVar } from "@apollo/client";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Visibility from "@mui/icons-material/Visibility";
import imageCompression from 'browser-image-compression';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import CropImage from '../../Common/cropImage';
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import axios from "axios";
import { postiveValueConverter } from'../../Common/commonUtils';
import { useState, useRef, useMemo, useEffect } from "react";
const packageOptionsArray = [
  { id: "0", value: "Same Day Orders" },
  { id: "1", value: "Individually wrapped meals" },
  { id: "2", value: "Offers Packages" },
  { id: "3", value: "Disposable plates/utensils and napkins" },
];
const StyledCheckbox = styled(Checkbox)`
    svg {
      color: #fd4e3b;
      font-size: 22px;
    }
  `;
const customDelivery = [
  {
    from: "",
    to: "",
    minimumOrder: "",
    deliveryFee: "",
  },
];
// we use material ui autocomplete location for fetching the location.
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function NewBusiness(props) {

  const currentData =
    props?.currentDetails?.getOneCaterer || props?.userValues?.updateCaterer;
  const user = useReactiveVar(userDeatils);
  const userPhone = (user?.data?.currentUserDetails?.phoneNumber || user?.data?.login?.phoneNumber || user?.data?.createCaterer?.phoneNumber);
  const [value, setValue] = useState(currentData?.locationName ?? null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [cordinates,setCordinates] = useState(currentData?.location?.coordinates??'')
  const [zip ,setZip] = useState(currentData?.catererZipCode??'')
  const [selectedImage, setSelectedImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(props.createRes?.createCaterer?.phoneNumber ? props.createRes?.createCaterer?.phoneNumber : props.createRes?.updateUserIdentity?.phoneNumber ? props.createRes?.updateUserIdentity?.phoneNumber : "");
  const [deliveryOptions, setDeliveryOptions] = useState(
    currentData?.deliveryOption ?? "Pickup and Delivery"
  );
  const [driverTip, setDriverTip] = useState(
    currentData?.driverTipFlag ?? "non-mandatory"
  );
  // const [customfee, setCustomfee] = useState(
  //   currentData?.customDeliveryFlag ?? false
  // );
  const [businessPhone, setBusinessPhone] = useState(
    currentData?.businessPhone || phoneNumber
  );
  const [fax, setFax] = useState(currentData?.fax ?? null)
  const [contactNumber, setContactNumber] = useState(
    currentData?.contactNumber
  );
  const [sameEmail, setSameEmail] = useState(currentData?.sameEmail ?? false);
  const [sameContact, setSameContact] = useState(
    currentData?.sameContact ?? true
  );

  const [selectedOptions, setSelectedOptions] = useState(
    currentData?.option ?? []
  );
  const [adminCheck, setAdminCheck] = useState(currentData?.adminFlg ? currentData?.adminFlg : props.createRes?.createCaterer?.adminFlg ? props.createRes?.createCaterer?.adminFlg : false);
  // const [cusDelivery, setCusDelivery] = useState(currentData?.customDelFees.length > 0 ? currentData?.customDelFees : customDelivery);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailArr, setEmailArr] = useState((currentData?.deliveryEmail && currentData?.deliveryEmail?.length > 0) ? currentData?.deliveryEmail : [""]);
  const loaded = useRef(false);
  const schema = yup.object().shape({
    name: adminCheck ? null : yup.string().required("Admin Name is required"),
    email: adminCheck ? null : yup.string().required("Email is required").email("Please enter a valid email address"),
    phoneNumber: adminCheck ? null : yup.string().required("Phone is required").min(14, "The phone number must be 10 digits."),
    password: adminCheck ? null : props.createRes !== "" ? yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
      "Password must contain atleast 8 characters, including letters, numbers and special characters."
    )
    // .test("password", "Password should be 6 characters minimum ", (value) => {
    //   if (value && value.length < 6) return false;
    //   else return true;
    // }) 
    : yup.string().required("Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
      "Password must contain atleast 8 characters, including letters, numbers and special characters."
    ),
    // .min(6, 'Password should be 6 characters minimum.').max(16, 'Password should be 16 characters maximum.'),
    confirmPassword: adminCheck ? null : props.createRes !== "" ? yup
      .string().oneOf([yup.ref("password"), null], "Password and confirm password do not match") :
      yup.string()
        .required("Confirm Password is required")
        .oneOf([yup.ref("password"), null], "Password and confirm password do not match"),
    businessName: yup.string().required("Business Name is required"),
    locationName: yup.string().nullable().required("Location is required"),
    businessPhone: yup.string().required("Phone is required").min(14, 'The phone number must be 10 digits.'),
  });
  const {
    control,
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    setValue: newSetValue,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: props.createRes?.createCaterer?.phoneNumber || props.createRes?.updateUserIdentity?.phoneNumber,
      businessPhone: currentData?.businessPhone || userPhone,
      deliveryFeeType: currentData?.deliveryFeeType ?? "Percentage",
      convenienceFeeType: currentData?.convenienceFeeType ?? "Percentage",
      driverTipType: currentData?.driverTipType ?? "Percentage",
      locationName: currentData?.locationName
    },
  });
  // mutation for creating new caterer
  const [createCaterer, { loading }] = useMutation(CREATE_CATERER, {
    onCompleted: (res) => {
      props.setCreateRes(res)
    }
  })
    // Busines details mutation
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER, {
    onCompleted: (res) => {
      props.setCreateRes(res)
    },
  })
  // mutation for updating new caterer
  const [updateCaterer] = useMutation(UPDATE_CATERE, {
    refetchQueries: ["ListCaterer"],
    onCompleted: (res) => {
      props.setUserValues(res);
      props.setActiveStep(1);
    },
  });
    // image upload mutation
  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/userprofile",
  };
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });
    // this function is for uploading the image 
  function handleUploadClick(imagefile) {
    let obj = {};
    obj["fileName"] = imagefile.name;
    obj["url"] = URL.createObjectURL(imagefile);
    obj["file"] = imagefile;
    setSelectedImage(obj);
    upload();
  }
  //for checking the image with certain criteria that we matches
  function checkImage(e) {
    let file = e.target.files[0];
    if (
      !(
        file?.type == "image/jpeg" ||
        file?.type == "image/png" ||
        file?.type == "image/jpg"
      )
    ) {
      toggleSnackbar({
        status: true,
        message: "This file format is not supported. Please upload only .png or .jpg files",
        variant: "error",
      });
      return false;
    }
    else if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    }
    else {
      var options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 300,
        useWebWorker: true,
      };
          // compressing the image
      imageCompression(file, options)
        .then((compressedFile) => {
          setCropImage(compressedFile);
          setCropOpen(true);
        })
        .catch((err) => {
          //console.log("err: ",err)
        })
    }
  }
    // this allows us to call the upload image function after the crop is done.
  function handelCropDone(result) {
    setCropImage(null);
    handleUploadClick(result?.file);
  }
    // function for uploading the url
  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    setImageUploadLoading(false);
    onSubmit(params)
  }
    // for autocomplete to use the key for genarating the location
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );
  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  function onSubmit(params) {
    let location = {
      type: "Point",
      coordinates : cordinates
    }
    let data1 = {
      name: params["name"],
      email: params["email"]?params["email"]: props?.userDetails?.data?.currentUserDetails?.email||user?.data?.login?.email||user?.data?.createCaterer?.email,
      phoneNumber: params["phoneNumber"],
      password: adminCheck ? params["password"] = "123456" : params["password"],
      role: "Admin",
      firstName: "Admin",
      createType: 'AddBusiness',
      adminFlg: adminCheck,
      userSrc: "AddBusiness",
      userId:user?.data?.createCaterer?._id||user?.data?.login?._id ||props?.userDetails?.data?.currentUserDetails?._id || props.createRes?.createCaterer?._id || props.createRes?.updateUserIdentity?._id
    };
    let data3 = {
      name: params["name"],
      email: params["email"],
      phoneNumber: params["phoneNumber"],
      password: params["password"],
      catererId: props.createRes?.createCaterer?.catererIdNew || props.createRes?.updateUserIdentity?.catererId,
      _id: props.createRes?.createCaterer?.newUserId || props.createRes?.updateUserIdentity?._id
    };
    // let newDelFee = cusDelivery.map((key) => {
    //   return {
    //     from: parseInt(key.from),
    //     to: parseInt(key.to),
    //     minimumOrder: parseInt(key.minimumOrder),
    //     deliveryFee: parseInt(key.deliveryFee),
    //   };
    // });
    let data2 = {
      businessName: params["businessName"],
      tagLine: params["tagLine"],
      specialDeliveryInstn: params["specialDeliveryInstn"],
      deliveryFeeType: params["deliveryFeeType"],
      deliveryRadius: params["deliveryRadius"],
      convenienceFeeType: params["convenienceFeeType"],
      convenienceFee: params["convenienceFee"],
      driverTipType: params["driverTipType"],
      preprationTime: params["preprationTime"],
      cancellationTime: params["cancellationTime"],
      cancellationPolicy: params["cancellationPolicy"],
      location :location,
      catererZipCode: parseInt(zip),
      locationName: params["locationName"],
      option: selectedOptions,
      fax: fax,
      businessPhone: businessPhone,
      contactNumber: contactNumber,
      sameContact: sameContact,
      sameEmail: sameEmail,
      imageUrl: uploadImageData?.imageUpload?.publicUrl,
      tabValue: "0",
      minOrderAmt: isNaN(parseInt(params["minOrderAmt"])) ? 0 : parseInt(params["minOrderAmt"]),
      driverTipFlag: driverTip,
      driverTip: isNaN(parseInt(params["driverTip"])) ? 0 : parseInt(params["driverTip"]),
      deliveryFee: isNaN(parseInt(params["deliveryFee"])) ? 0 : parseInt(params["deliveryFee"]),
      deliveryOption: deliveryOptions,
      // customDeliveryFlag: customfee,
      deliveryEmail: emailArr,
      // customDelFees: newDelFee,
      adminFlg: adminCheck,

    }


    // if (params.customDelFees) {
    //   Object.keys(params.customDelFees).map((key) => {
    //     delete params.customDelFees[key].__typename
    //   })
    // }

    try {
      // check if i will do this later is clicked or not if clicked createCaterer
      adminCheck ? createCaterer({ variables: { data: data1 } })
        .then((res) => {
          // after the response update caterer is called
          updateCaterer({ variables: { id: res?.data?.createCaterer?._id, catererId: props.createRes?.createCaterer?.catererIdNew ?? res?.data?.createCaterer?.catererIdNew, data: data2 } })
            .then((res) => {
              toggleSnackbar({
                status: true,
                message: "Business details updated successfully",
                variant: "success",
              })
            })
        })
        :
        // check if there is value in createRes variable, if yes updateuser is called
        props.createRes !== "" ? updateUser({ variables: { id: props.createRes?.createCaterer?.newUserId || props.createRes?.updateUserIdentity?._id, data: data3 } })
          .then((res) => {
            updateCaterer({ variables: { id: res?.data?.updateUserIdentity?._id || props.createRes?.createCaterer?._id || props.createRes?.updateUserIdentity?._id, catererId: props.createRes?.createCaterer?.catererId || res?.data?.updateUserIdentity?.catererId, data: data2 } })
            toggleSnackbar({
              status: true,
              message: "Account Updated successfully",
              variant: "success",
            });
          }) :
          // else creater caterer is called followed bu update caterer
          createCaterer({ variables: { data: data1 } })
            .then((res) => {
              updateCaterer({ variables: { id: res?.data?.createCaterer?.newUserId, catererId: props.createRes?.createCaterer?.catererIdNew ?? res?.data?.createCaterer?.catererIdNew, data: data2 } })
              toggleSnackbar({
                status: true,
                message: "Account Created successfully",
                variant: "success",
              });
            }).catch((err) => {
              const message = err && err.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  // function for the phone number formating
  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x =
        "(" +
        newNum.slice(0, 3) +
        ") " +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  };

    // for allowing only positive values
    const handleInputChange = (event) => {
      event.target.value = postiveValueConverter(event, 'withDecimal');
    };
    const handleInputChangeWithoutDecimal = (event) => {
      event.target.value = postiveValueConverter(event, 'withoutDecimal');
    };
  
   // function for adding the value of custom delivery field
  // function addDelDetails(value, index, type) {
  //   let newDelivery = [...cusDelivery]
  //   if (index < cusDelivery.length - 1 && type === "to") {
  //     let obj1 = { ...newDelivery[index + 1] };
  //     obj1["from"] = parseInt(value);
  //     newDelivery[index + 1] = obj1;
  //   }
  //   let obj = { ...newDelivery[index] };
  //   obj[type] = parseInt(value);
  //   newDelivery[index] = obj;
  //   setCusDelivery(newDelivery)
  // }
    //adding new delivery field for custom delivery field
  // function addNewDelivery(item, index) {
  //   let newDelivery = [...cusDelivery]
  //   let length = newDelivery.length;
  //   let obj = {}
  //   obj["from"] = length >= 1 ? newDelivery[length - 1].to : "";
  //   obj["to"] = "";
  //   obj["minimumOrder"] = "";
  //   obj["deliveryFee"] = "";
  //   newDelivery.push(obj);
  //   setCusDelivery(newDelivery)
  // }
    // function for removing the delivery field
  // function removeNewDelivery(item, index) {
  //   let newDelivery = [...cusDelivery]
  //   newDelivery.splice(index, 1);
  //   setCusDelivery(newDelivery);
  // }
  //toggling between text/ password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 //for getting the zip from the location 
 async function handleValueSelect(value) {
  setValue(value);
  var config = {
    method: "get",
    url: `https://maps.googleapis.com/maps/api/geocode/json?place_id=${value?.place_id}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
    headers: {},
  };

  axios(config)
    .then(function (response) {
      let cordArray =[]
      let lat = response?.data?.results[0]?.geometry?.location?.lat
      let lng = response?.data?.results[0]?.geometry?.location?.lng
      let zip =''
      cordArray.push(lng,lat)
      setCordinates(cordArray)
      let arr = response?.data?.results?.[0]?.address_components
      let position = arr.findIndex((element) => element.types && element.types[0] == "postal_code");
      if(position!== -1)
        zip = response?.data?.results?.[0]?.address_components?.[position]?.["long_name"];
        setZip(zip)
    })
    .catch(function (error) {
      console.log(error);
    });
}
  //setting the title of drawer
  useEffect(()=>{
    props?.setTitle("Add Business")
  })
  const reglocation = register("locationName")
  return (
    <div>
      <div className="add-business-step-head">
        <h5 className="h5">Admin Account Details</h5>
      </div>
        {/* this component helps in croping the image */}
      <CropImage
        open={cropOpen}
        setOpen={setCropOpen}
        image={cropImage}
        handelCropDone={handelCropDone}
        aspectRatio={1}
      />
      {/* for opening the file to upload the image */}
        <input type={"file"}
          className="d-none"
          id="add-user-input"
          onChange={checkImage}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
      <form onSubmit={handleSubmit(uploadImage)}>
        <div className='create-step-form'>
          <div className='container-fluid'>
            <div className="row">
              <div className="col-12">
                <div className="textBox">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={adminCheck}
                          onChange={(e) => setAdminCheck(e.target.checked)}
                          // {...register("tandc")}
                          color={"primary"}
                          icon={
                            <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                          }
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={<p>I will add an admin later</p>}
                    />
                  </FormGroup>
                </div>
              </div>
              {adminCheck === false && <><div className="col-md-12 textBox">
                <TextField
                  id="createAccountAdminName"
                  disabled={adminCheck}
                  defaultValue={props.createRes?.createCaterer?.fullName || props.createRes?.updateUserIdentity?.name}
                  label="ADMIN NAME*"
                  autoFocus
                  variant="outlined"
                  className="textField allFeild"
                  {...register("name")}
                />
                {errors.name && <span className="error-msg">{errors.name.message}</span>}
              </div>
                <div className="col-md-6 textBox">
                  <Controller
                    control={control}
                    name="email"
                    defaultValue={props.createRes?.createCaterer?.email || props.createRes?.updateUserIdentity?.email}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="createAccountLoginEmail"
                        disabled={adminCheck}
                        value={value}
                        label="LOGIN EMAIL*"
                        variant="outlined"
                        className="textField allFeild"
                        onChange={(e) => {
                          onChange(e);
                          if (sameEmail) {
                            let arr = [...emailArr];
                            arr[0] = e.target.value
                            setEmailArr(arr);
                          }

                        }}
                      />
                    )}
                  />
                  {errors.email && <span className="error-msg">{errors.email.message}</span>}
                </div>
                <div className="col-md-6 textBox">
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="createAccountPhoneNumber"
                        label="PHONE NUMBER*"
                        disabled={adminCheck}
                        variant="outlined"
                        className="textField allFeild"
                        value={phoneNumber}
                        onChange={(e) => {
                          if (!isNaN(e.target.value.replace(/[-,(,), ]+/g, "")) && (e.target.value.replace(/[-,(,), ]+/g, "").length <= 10)) {
                            register("phoneNumber").onChange({ target: { value: e.target.value, name: "phoneNumber" } })
                            if (sameContact) {
                              setBusinessPhone(phoneNumberFormat(e.target.value));
                              newSetValue("businessPhone", e.target.value)
                              setContactNumber(phoneNumberFormat(e.target.value));
                              newSetValue("contactNumber", e.target.value)
                              trigger("businessPhone")
                            }
                            setPhoneNumber(phoneNumberFormat(e.target.value))
                          }
                        }}
                      />
                    )}
                  />
                  {errors.phoneNumber && <span className="error-msg">{errors.phoneNumber.message}</span>}
                </div>
                <div className="col-md-6 textBox">
                  <TextField
                    id="createAccountPassword"
                    label="PASSWORD*"
                    disabled={adminCheck}
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    className="textField allFeild"
                    {...register("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.password && <span className="error-msg">{errors.password.message}</span>}
                </div>
                <div className="col-md-6 textBox">
                  <TextField
                    id="filled-basic"
                    label="CONFIRM PASSWORD*"
                    disabled={adminCheck}
                    variant="outlined"
                    type={showConfirmPassword ? "text" : "password"}
                    className="textField allFeild"
                    {...register("confirmPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.confirmPassword && <span className="error-msg">{errors.confirmPassword.message}</span>}
                </div></>}

            </div>

            <h5 className="h5">Business Details</h5>
            <p className="subhead"></p>
            <div className="row addBusinessSet">
            <div className='text-center'>
              <div className='upload-img-wrap'>
                <img
                  alt="Remy Sharp"
                  src={selectedImage?selectedImage.url:currentData?.imageUrl?currentData?.imageUrl:
                 "../assets/images/upload-image.svg"}
                  className={currentData?.imageUrl||selectedImage?'upload-img img-fluid': 'upload-img img-fluid upload-no-image' }
                  onClick={(e) => document.getElementById("add-user-input").click()}
                />
                <img onClick={(e) => document.getElementById("add-user-input").click()} src='../assets/images/icon-upload-plus.svg' className='upload-icon img-fluid' />
              </div>
            </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="outlined-basic"
                  label="BUSINESS NAME*"
                  defaultValue={currentData?.businessName}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("businessName")}
                />
                {errors.businessName && (
                  <span className="error-msg">{errors.businessName.message}</span>
                )}
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="outlined-basic"
                  label="TAGLINE"
                  defaultValue={currentData?.tagLine}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("tagLine")}
                  inputProps={{ maxLength: 40 }}
                />
                <p className="error-msg" style={{ color: "#9F9E9E" }}>Maximum 40 characters</p>
              </div>
              <div className="col-md-12 textBox">
                <Autocomplete
                  className="textField allFeild"
                  id="google-map-demo"
                  sx={{ width: 300 }}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.description.replace(", USA", "")
                  }
                  noOptionsText={"Enter your location"}
                  filterOptions={(x) => x}
                  options={options ?? []}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  {...reglocation}
                  value={value}
                  onChange={(event, newValue) => {
                    setOptions(newValue && newValue != null ? [newValue, ...options] : options);
                    setValue(newValue?.description.replace(", USA", ""));
                    reglocation.onChange({ target: { value: newValue?.description.replace(", USA", ""), name: "locationName" } })
                    handleValueSelect(newValue)
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="LOCATION*" fullWidth />
                  )}
                  renderOption={(props, option) => {
                    const matches =
                      option?.structured_formatting?.main_text_matched_substrings;
                    if (!matches) return false
                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map((match) => [
                        match.offset,
                        match.offset + match.length,
                      ])
                    );

                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Box
                              component={LocationOnIcon}
                              sx={{ color: "text.secondary", mr: 2 }}
                            />
                          </Grid>
                          <Grid item xs>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}

                            <Typography variant="body2" color="text.secondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
                {errors.locationName && (
                  <span className="error-msg">{errors.locationName.message}</span>
                )}
              </div>
              <div className="col-md-12">
                <div className="row align-items-center">
                  <div className="col-md-12 ">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            checked={adminCheck ? false : sameContact}
                            disabled={adminCheck}
                            onChange={(e) => {
                              setSameContact(!sameContact);
                              if (e.target.checked) {
                                setBusinessPhone(phoneNumber);
                                newSetValue("businessPhone", phoneNumber)
                                setContactNumber(phoneNumber);
                                newSetValue("contactNumber", phoneNumber)
                                trigger("businessPhone")
                              }
                            }}
                            color={"primary"}
                            icon={
                              <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                            }
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        className=""
                        style={{ fontSize: '12px' }}
                        label="Business Contact Number is same as the User Contact Number"
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-12 textBox">
                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="outlined-basic"
                          label="BUSINESS CONTACT NUMBER*"
                          variant="outlined"
                          disabled={adminCheck === false && sameContact === true}
                          name="businessPhone"
                          className="textField allFeild"
                          value={businessPhone}
                          onChange={(e) => {
                            if (!isNaN(e.target.value.replace(/[-,(,), ]+/g, "")) && (e.target.value.replace(/[-,(,), ]+/g, "").length <= 10)) {
                              register("businessPhone").onChange({
                                target: { value: e.target.value, name: "businessPhone" },
                              });
                              setBusinessPhone(phoneNumberFormat(e.target.value));                             
                            }
                          }}
                        />
                      )}
                    />
                    {errors.businessPhone && (
                      <span className="error-msg">{errors.businessPhone.message}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div className="row addBusinessSet">
              <h6 className="h6">Delivery Notification options</h6>
              <div className="col-md-6">
                <TextField
                  id="outlined-basic"
                  label="TEXT NOTIFICATION#"
                  variant="outlined"
                  className="textField allFeild"
                  value={contactNumber}
                  InputLabelProps={{ shrink: contactNumber&&true }}
                  onChange={(e) => {
                    if (!isNaN(e.target.value.replace(/[-,(,), ]+/g, ""))) {
                      register("contactNumber").onChange({
                        target: { value: e.target.value, name: "contactNumber" },
                      });
                      setContactNumber(phoneNumberFormat(e.target.value));
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div className="row addBusinessSet">
              <div className="col-md-12">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={adminCheck ? false : sameEmail}
                        disabled={adminCheck}
                        onChange={(e) => {
                          setSameEmail(!sameEmail);
                          if (e.target.checked) {
                            let arr = [...emailArr];
                            arr[0] = getValues("email")
                            setEmailArr(arr);
                          }
                        }}
                        color={"primary"}
                        icon={
                          <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                        }
                        checkedIcon={<CheckCircleIcon />}
                      // {...others}
                      />
                    }
                    className=""
                    label="Delivery email address is same as the login email address"
                  />
                </FormGroup>
              </div>
              {emailArr.map((item, index) => (
                <div className="row  align-items-center">
                  <div className="col-md-6 textBox">
                    <TextField
                      id="outlined-basic"
                      defaultValue={currentData?.deliveryEmail}
                      value={emailArr[index]}
                      disabled={index === 0 && sameEmail}
                      label="DELIVERY EMAIL ADDRESS"
                      variant="outlined"
                      className="textField allFeild"
                      onChange={(e) => {
                        let arr = [...emailArr];
                        arr[index] = e.target.value;
                        setEmailArr(arr);
                      }}
                    />
                    {errors.deliveryEmail && (
                      <span className="error-msg">
                        {errors.deliveryEmail.message}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    {index !== 0 && (
                      <a
                        className="more-delivery-address-email remove"
                        onClick={() => {
                          let arr = [...emailArr];
                          arr.splice(index, 1);
                          setEmailArr(arr);
                        }}
                      >
                        Remove
                      </a>
                    )}
                  </div>
                </div>
              ))}
              <div className="mb-3">
                <a
                  className="more-delivery-address-email"
                  onClick={() => {
                    let arr = [...emailArr];
                    arr.push("");
                    setEmailArr(arr);
                  }}
                >
                  Add more email addresses for delivery notifications
                </a>
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div className="row addBusinessSet">
              <h6 className="h6">Delivery Instructions</h6>
              {/* <div className="col-md-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={
                          deliveryOptions === "Pickup and Delivery" ? true : false
                        }
                        onChange={() => setDeliveryOptions("Pickup and Delivery")}
                        color={"primary"}
                        icon={
                          <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                        }
                        checkedIcon={<CheckCircleIcon />}
                      // {...others}
                      />
                    }
                    label="Pickup and Delivery"
                  />
                </FormGroup>
              </div> */}
              {/* <div className="col-md-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={deliveryOptions === "Pickup Only" ? true : false}
                        onChange={() => setDeliveryOptions("Pickup Only")}
                        color={"primary"}
                        icon={
                          <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                        }
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label="Pickup Only"
                  />
                </FormGroup>
              </div> */}
              {/* <div className="col-md-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={
                          deliveryOptions === "Delivery Only" ? true : false
                        }
                        onChange={() => setDeliveryOptions("Delivery Only")}
                        color={"primary"}
                        icon={
                          <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                        }
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label="Delivery Only"
                  />
                </FormGroup>
              </div> */}

              <div className="col-md-12">
                <TextField
                  id="outlined-basic"
                  multiline
                  defaultValue={currentData?.specialDeliveryInstn}
                  label="Any special delivery instructions or any other information to share with the customer?"
                  variant="outlined"
                  className="textField allFeild"
                  {...register("specialDeliveryInstn")}
                />
              </div>
              <h6 className="h6">Options</h6>
              {packageOptionsArray.map((field, index) => (
                <div className="col-md-12">
                  <FormGroup key={field.id}>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={selectedOptions.includes(field.value)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              let arr = [...selectedOptions];
                              arr.push(field.value);
                              setSelectedOptions(arr);
                            } else {
                              let arr = [...selectedOptions];
                              let pos = arr.findIndex(
                                (element) => element === field.value
                              );
                              if (pos > -1) arr.splice(pos, 1);
                              setSelectedOptions(arr);
                            }
                          }}
                          color={"primary"}
                          icon={
                            <CheckCircleOutlineIcon
                              style={{ color: "#9F9E9E" }}
                            />
                          }
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={field.value}
                    />
                  </FormGroup>
                </div>
              ))}
            </div>
            <div>
              <hr />
            </div>
            <div className="row addBusinessSet">
              <h6 className="h6">Minimum Order / Delivery Radius</h6>
              <div className="col-md-6">
                <TextField
                  id="outlined-basic"
                  label="MINIMUM ORDER AMOUNT"
                  // inputProps={{ type: 'number', min: "0" }} 
                  defaultValue={currentData?.minOrderAmt === 0 ? "" : currentData?.minOrderAmt}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("minOrderAmt")}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-md-6">
                  <TextField
                    id="outlined-basic"
                    label="Delivery Radius"
                    defaultValue={currentData?.deliveryRadius === 1 ? 1 : currentData?.deliveryRadius}
                    variant="outlined"
                    className="textField allFeild"
                    {...register("deliveryRadius")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mi</InputAdornment>
                      ),
                    }}
                  />
                </div>
              <h6 className="h6">Delivery Fee</h6>
              <div className="col-md-4">
                <Controller
                  control={control}
                  name="deliveryFeeType"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      label="DELIVERY FEE"
                      // inputProps={{ type: 'number', min: "0" }} 
                      variant="outlined"
                      defaultValue={currentData?.deliveryFee === 0 ? "" : currentData?.deliveryFee}
                      className="textField allFeild"
                      {...register("deliveryFee")}
                      onChange={handleInputChange}
                      InputProps={{
                        endAdornment: value === "Percentage" && (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        startAdornment: value === "Amount" && (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-4">
                <FormControl fullWidth className="textField allFeild">
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Controller
                    control={control}
                    name="deliveryFeeType"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Type"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <MenuItem value={"Percentage"}>Percentage</MenuItem>
                        <MenuItem value={"Amount"}>Amount</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
{/* 
              <div className="col-md-12">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={customfee}
                        onChange={() => setCustomfee(!customfee)}
                        color={"primary"}
                        icon={
                          <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                        }
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label="Custom Delivery fee"
                  />
                </FormGroup>
              </div> */}
              {/* {customfee && (
                <div className="col-12">
                  <h6 className="h6">Delivery Distance</h6>
                  <p className="error-msg inst-msg" style={{ color: "#9F9E9E" }}>Note: Distance is in miles and Delivery fee is in USD</p>
                  {cusDelivery.map((item, index) => (
                    <div className="custom-delivery-field-wrap">
                      <div className="cd-flex-1">
                        <TextField
                          id="outlined-basic"
                          value={item.from}
                          inputProps={{ type: 'number', min: "0" }} 
                          label="From"
                          variant="outlined"
                          className="textField allFeild"
                          onChange={(e) => addDelDetails(e.target.value, index, "from")}
                        />
                      </div>
                      <div className="cd-flex-1">
                        <TextField
                          id="outlined-basic"
                          value={item.to}
                          inputProps={{ type: 'number', min: "0" }} 
                          label="To"
                          variant="outlined"
                          className="textField allFeild"
                          onChange={(e) => addDelDetails(e.target.value, index, "to")}
                        />
                      </div>
                      <div className="cd-flex-2">
                        <TextField
                          id="outlined-basic"
                          value={item.minimumOrder}
                          inputProps={{ type: 'number', min: "0" }} 
                          label="Min Order"
                          variant="outlined"
                          className="textField allFeild"
                          onChange={(e) => addDelDetails(e.target.value, index, "minimumOrder")}
                        />
                      </div>
                      <div className="cd-flex-2">
                        <TextField
                          id="outlined-basic"
                          value={item.deliveryFee}
                          label="Delivery Fee"
                          inputProps={{ type: 'number', min: "0" }} 
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">$</InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          className="textField allFeild"
                          onChange={(e) => addDelDetails(e.target.value, index, "deliveryFee")}
                        />
                      </div>
                      {index === 0 &&
                        <div className="">
                          <Button className="custom-delivery-field-wrap-btn" color="primary" variant="contained" onClick={() => addNewDelivery()} ><img src="../assets/images/icon-add-plus.svg" className="img-fluid" alt="Icon" /> Add</Button>
                        </div>
                      }
                      {index >= 1 &&
                        <div className="">
                          <Button className="custom-delivery-field-wrap-btn" color="error" variant="contained" onClick={() => removeNewDelivery(item, index)}><img src="../assets/images/icon-add-minus.svg" className="img-fluid" alt="Icon" /> Remove</Button>
                        </div>
                      }
                    </div>
                  ))}

                </div>
              )} */}
              {/* {customfee &&
                fields.map((item, index) => (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="delivery-distance-text">
                          <label>Delivery Distance*</label>
                          <span>{item.deliveryDistance}</span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <TextField
                          id="outlined-basic"
                          // type={"number"}
                          defaultValue={item?.minimumOrder}
                          label="MINIMUM ORDER($)"
                          variant="outlined"
                          className="textField allFeild"
                          // {...register(`customDelFees.[${index}].minimumOrder`)}
                          onChange={(e) => {
                            let value
                            if (e.target.value === "")
                              value = null;
                            else if (isNaN(e.target.value))
                              value = e.target.value
                            else value = parseInt(e.target.value);
                            register(`customDelFees.[${index}].minimumOrder`).onChange({ target: { value: value, name: `customDelFees.[${index}].minimumOrder` } })
                          }}
                        />
                        {errors?.customDelFees?.[index]?.minimumOrder && (
                          <span className="error-msg">{errors.customDelFees[index].minimumOrder.message}</span>
                        )}
                      </div>
                      <div className="col-md-4">
                        <TextField
                          id="outlined-basic"
                          defaultValue={item?.deliveryFee}
                          label="DELIVERY FEE($)"
                          // type={"number"}
                          variant="outlined"
                          className="textField allFeild"
                          // {...register(`customDelFees.[${index}].deliveryFee`)}
                          onChange={(e) => {
                            let value
                            if (e.target.value === "")
                              value = null;
                            else if (isNaN(e.target.value))
                              value = e.target.value
                            else value = parseInt(e.target.value);
                            register(`customDelFees.[${index}].deliveryFee`).onChange({ target: { value: value, name: `customDelFees.[${index}].deliveryFee` } })
                          }}
                        />
  
                        {errors?.customDelFees?.[index]?.deliveryFee && (
                          <span className="error-msg">{errors.customDelFees[index].deliveryFee.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                ))} */}
            </div>
            <div>
              <hr />
            </div>
            {/* <div className="row addBusinessSet">
              <h6 className="h6">Convenience Fee</h6>
              <div className="col-md-6">
                <Controller
                  control={control}
                  name="convenienceFeeType"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      // type={"number"}
                      label="CONVENIENCE FEE"
                      variant="outlined"
                      defaultValue={currentData?.convenienceFee === "0" ? "" : currentData?.convenienceFee}
                      className="textField allFeild"
                      {...register("convenienceFee")}
                      InputProps={{
                        endAdornment: value === "Percentage" && (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        startAdornment: value === "Amount" && (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <FormControl fullWidth className="textField allFeild">
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Controller
                    control={control}
                    name="convenienceFeeType"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Age"
                        // {...register("convenienceFeeType")}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <MenuItem value={"Percentage"}>Percentage</MenuItem>
                        <MenuItem value={"Amount"}>Amount</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            </div> */}

            <div>
              <hr />
            </div>
            <div className="row addBusinessSet">
              <h6 className="h6">Driver Tip</h6>
              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={driverTip === "non-mandatory" ? true : false}
                          onChange={() => setDriverTip("non-mandatory")}
                          color={"primary"}
                          icon={
                            <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                          }
                          checkedIcon={<CheckCircleIcon />}
                        // {...others}
                        />
                      }
                      label="Non-mandatory"
                    />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={driverTip === "mandatory" ? true : false}
                          onChange={() => setDriverTip("mandatory")}
                          color={"primary"}
                          icon={
                            <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                          }
                          checkedIcon={<CheckCircleIcon />}
                        // {...others}
                        />
                      }
                      label="Mandatory"
                    />
                  </FormGroup>
                </div>
              </div>
              {driverTip === "mandatory" && (
                <div className="col-md-4">
                  <Controller
                    control={control}
                    name="driverTipType"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="outlined-basic"
                        label="DRIVER TIP"
                        defaultValue={currentData?.driverTip === 0 ? "" : currentData?.driverTip}
                        variant="outlined"
                        className="textField allFeild"
                        {...register("driverTip")}
                        InputProps={{
                          endAdornment: value === "Percentage" && (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          startAdornment: value === "Amount" && (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {driverTip === "mandatory" && (
                <div className="col-4">
                  <FormControl fullWidth className="textField allFeild">
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Controller
                      control={control}
                      name="driverTipType"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={value}
                          label="Age"
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                        >
                          <MenuItem value={"Percentage"}>Percentage</MenuItem>
                          <MenuItem value={"Amount"}>Amount</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </div>
              )}
            </div>
            <div>
              <hr />
            </div>
            <div className="row addBusinessSet">
              <h6 className="h6">Preparation Time</h6>
              <div className="col-md-6 textBox">
                <TextField
                  id="outlined-basic"
                  defaultValue={currentData?.preprationTime === "0" ? "" : currentData?.preprationTime}
                  label="MIN PREPARATION TIME"
                  variant="outlined"
                  // type={"number"}
                  className="textField allFeild"
                  {...register("preprationTime")}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">HRS</InputAdornment>
                    )
                  }}
                />
                <p className="error-msg" style={{ color: "#9F9E9E" }}>1.5 hours = 90 minutes</p>
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="outlined-basic"
                  defaultValue={currentData?.cancellationTime === "0" ? "" : currentData?.cancellationTime}
                  label="MIN CANCELLATION TIME"
                  variant="outlined"
                  // type={"number"}
                  className="textField allFeild"
                  {...register("cancellationTime")}
                  onChange={handleInputChangeWithoutDecimal}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">HRS</InputAdornment>
                    )
                  }}
                />
                {/* <p className="error-msg" style={{ color: "#9F9E9E" }}>1.5 hours = 90 minutes</p> */}
              </div>
              {/* <div className="col-md-12 mt-4">
                <TextField
                  id="outlined-basic"
                  multiline
                  defaultValue={currentData?.cancellationPolicy ?? "If you cancel your order, you may be charged some or all of the amount of the order, depending on the policy of the Vendor. CaterPlace reserves the right to charge you the full order amount, if you, or the person you designate, is not at the specified delivery location at the time the order is delivered or if you fail to pick up a “take-out” or “pick-up” order from a Vendor"}
                  label="YOUR CANCELLATION POLICY"
                  variant="outlined"
                  className="textField"
                  {...register("cancellationPolicy")}
                />
                <p className="error-msg" style={{ color: "#9F9E9E" }}>This is the standard cancellation policy and is editable based on your preference</p>
              </div> */}

            </div>
          </div>
        </div>

        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => { props?.setOpenNewBusiness(false); props?.setUserValues(null); }}>
            Cancel
          </Button>
          <Button
            className="saveBtn"
            variant="contained"
            type="submit"
            color="primary"
          >
            {loading || updateLoading ? (
              <CircularProgress color="inherit" size={20} className="text-center m-auto"/>
            ) : (
              "Save and Proceed"
            )}{" "}
            
            <ArrowForwardIcon className="btnArrow" />
          </Button>
        </div>

      </form>
    </div>
  );
}
