import React from "react";

import { OutlinedTextFieldProps, TextField } from "@mui/material";
import { useController } from "react-hook-form";

interface IInputFormProps extends OutlinedTextFieldProps {
  id?: string;
  label?: string;
  className?: string;
  control: any;
  name: string;
  rules: object;
  onUpdate?(name: string, value: string): void;
  onFormat?(value: string): string;
}

export function InputForm({
  id,
  label,
  className,
  control,
  name,
  rules,
  onUpdate,
  onFormat,
  ...props // props related to MUI component
}: IInputFormProps) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });

  function onChangeValue(event) {
    const value = onFormat ? onFormat(event.target.value) : event.target.value;

    onChange(value);
    onUpdate && onUpdate(name, value);
  }

  return (
    <TextField
      {...props}
      id={id}
      error={error ? !!error.message : false}
      label={label}
      helperText={error ? error.message : ""}
      value={value}
      className={className}
      onChange={onChangeValue}
      onBlur={onBlur}
    />
  );
}
