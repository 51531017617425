import React, { useEffect, useRef } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Tooltip,
  styled,
} from "@mui/material";
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";

import moment from "moment";

import { InputForm } from "@components/molecules/input-form";
import { LocationSearchForm } from "@components/molecules/location-search-form";

import { CardForm, usePaymentCardFormState } from "./payment-card-form.state";
import { PaymentCardProvider } from "../payment-card-provider";
import { IPaymentCardResponseItem } from "../../payment-card.typings";

const StyledCheckbox = styled(Checkbox)`
  svg {
    color: #fd4e3b;
    font-size: 22px;
  }
`;

interface IPaymentCardForm {
  isOpen: boolean;
  activeItem: IPaymentCardResponseItem | null;
  onSubmit(form: CardForm): void;
  onCancel(): void;
}

export function PaymentCardForm({ isOpen, activeItem, onCancel, onSubmit }: IPaymentCardForm) {
  if (!isOpen) {
    return null;
  }

  const {
    isAdditionalEmail,
    onToggleAdditionalEmail,
    cardProvider,
    onValidateCardProvider,
    control,
    handleSubmit,
    onSelectLocationOptions,
    onSubmitForm,
    isEdit,
  } = usePaymentCardFormState(onSubmit, activeItem);

  const divRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      divRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="add-new-card" id="card-view">
      <hr className="sepration-line" />
      {!isEdit && <h6 className="mb-0"> Add New Card </h6>}
      <div className="create-step-form">
        <div className="row">
          <div className="col-md-6 textBox">
            <InputForm
              control={control}
              name="cardName"
              rules={
                isEdit
                  ? {}
                  : {
                      required: "Card Name is required",
                    }
              }
              label="NAME ON CARD*"
              variant="outlined"
              className="textField allFeild"
              disabled={isEdit}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="col-md-6 textBox">
            <InputForm
              control={control}
              name="nickName"
              rules={{}}
              label="CARD NICKNAME"
              variant="outlined"
              className="textField allFeild"
              disabled={isEdit}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="col-md-6 textBox">
            <InputForm
              control={control}
              name="cardLastDigits"
              rules={
                isEdit
                  ? {}
                  : {
                      required: "Card number is required",
                      pattern: {
                        value: /^[0-9]{15,16}$/,
                        message: "Please enter a valid card number",
                      },
                    }
              }
              label="CARD NUMBER*"
              variant="outlined"
              className="textField allFeild"
              onFormat={(value) => value.replace(/\D/, "")}
              onUpdate={(name, value) => {
                onValidateCardProvider(value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PaymentCardProvider provider={cardProvider} />
                  </InputAdornment>
                ),
              }}
              disabled={isEdit}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="col-md-3 col-6 textBox">
            <InputForm
              control={control}
              name="cardExpr"
              rules={
                isEdit
                  ? {}
                  : {
                      required: "Expiration date is required",
                      validate: (value) => {
                        if (value.length < 5) {
                          return "Please enter correct expiration date";
                        }
                        const [month, year] = value.split("/");

                        if (+month > 12) {
                          return "Month is incorrect";
                        }

                        const expirationDate = moment()
                          .date(1)
                          .month(month - 1)
                          .year(+`20${year}`);
                        if (
                          !expirationDate.isValid() ||
                          expirationDate.isBefore(moment(), "date")
                        ) {
                          return "The card is expired";
                        }

                        return true;
                      },
                    }
              }
              label="EXPIRATION DATE*"
              placeholder="MM/YY"
              variant="outlined"
              className="textField allFeild"
              onFormat={(value) => {
                const pureValue = value.replace("/", "").replace(/\D/, "");
                return pureValue.length > 2
                  ? `${pureValue.slice(0, 2)}/${pureValue.slice(2, 4)}`
                  : pureValue;
              }}
              disabled={isEdit}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="col-md-3 col-6 textBox">
            <InputForm
              control={control}
              name="cvv"
              rules={
                isEdit
                  ? {}
                  : {
                      required: "CVV is required",
                      pattern: {
                        value: /^[0-9]{3,4}$/,
                        message: "Please enter a valid 3 or 4-digits CVV code",
                      },
                    }
              }
              label="CVV*"
              variant="outlined"
              className="textField allFeild"
              placeholder="***"
              onFormat={(value) => value.replace(/\D/, "")}
              type={"password"}
              disabled={isEdit}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="col-md-12 textBox">
            <div>
              <LocationSearchForm
                control={control}
                name="locationName"
                rules={
                  isEdit
                    ? {}
                    : {
                        required: "Billing address is required",
                      }
                }
                label="BILLING ADDRESS*"
                className="textField allFeild"
                onChangeLocationOptions={onSelectLocationOptions}
                disabled={isEdit}
                inputProps={{
                  InputLabelProps: { shrink: true },
                }}
              />
            </div>
          </div>
          <div className="col-md-6 textBox">
            <div className="d-flex">
              <InputForm
                control={control}
                name="billingZipCode"
                onFormat={(value) => value.replace(/\D/, "")}
                rules={
                  isEdit
                    ? {}
                    : {
                        required: "Zip Code is required",
                        validate: (value) => {
                          if (`${value}`.length !== 5) {
                            return "Zip code should be 5 digits";
                          }
                          return true;
                        },
                      }
                }
                label="ZIP CODE*"
                variant="outlined"
                className="textField allFeild"
                disabled={isEdit}
                InputLabelProps={{ shrink: true }}
              />
              <span>
                <Tooltip title="Zip code associated with your billing address" placement="top">
                  <IconButton>
                    <img
                      src="../assets/images/icon-info.svg"
                      className="img-fluid"
                      alt="Zip Info"
                    />
                  </IconButton>
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="col-md-12 ">
            <FormGroup>
              <FormControlLabel
                ref={divRef}
                control={
                  <StyledCheckbox
                    checked={isAdditionalEmail}
                    onChange={onToggleAdditionalEmail}
                    color={"primary"}
                    icon={<CheckCircleOutline style={{ color: "#9F9E9E" }} />}
                    checkedIcon={<CheckCircle />}
                  />
                }
                className=""
                style={{ fontSize: "12px" }}
                label="Click here to add additional email addresses for order updates and receipts"
              />
            </FormGroup>
          </div>
          {isAdditionalEmail && (
            <>
              <div className="col-md-12 textBox">
                <InputForm
                  control={control}
                  name="additionalEmail"
                  rules={
                    isAdditionalEmail
                      ? {
                          required: "Please enter email address",
                          pattern: {
                            value:
                              /^([\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(\s*,\s*)?)*([\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4})$/,
                            message: "Please enter valid comma-separated email addresses",
                          },
                        }
                      : {}
                  }
                  label="ADDITIONAL EMAIL FOR ORDER UPDATES & RECEIPTS"
                  variant="outlined"
                  className="textField allFeild"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <p className="ms-2" style={{ color: "rgba(0, 0, 0, 0.38)" }}>
                You can add multiple email addresses separated by comma.
              </p>
            </>
          )}
        </div>
      </div>
      <div className="cancel-save-btn">
        <Button className="cancelBtn" variant="contained" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmitForm)}
          className="saveBtn"
          variant="contained"
          color="primary">
          {activeItem ? "Update" : "Add"}
        </Button>
      </div>
    </div>
  );
}
