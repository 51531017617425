import React, { useState } from "react";
import LoginHeader from "../../Login/LoginHeader";
import Footer from "../../Common/footer";
import { Button, TextField, CircularProgress } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, Link } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { CONTACT_US } from "../../../Graphql/mutations";
import { toggleSnackbar, userDeatils } from "../../../ReactiveVariables/index";
import { phoneNumberFormat } from "../../Common/commonUtils";
import ReCAPTCHA from "react-google-recaptcha";
import {Helmet} from "react-helmet";
export default function Contact() {
  const [contactNo, setContactNo] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    phoneNumber: yup
      .string()
      .required("Phone is required")
      .min(14, "The phone number must be 10 digits."),
    email: yup.string().required("Email is required").email("Please enter a valid email address"),
    //message: yup.string().required("Message is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { locationName:    props?.selectedItem?.locationName?  props?.selectedItem?.locationName :"" }
  });
  const [createContactUs, { loading }] = useMutation(CONTACT_US, {
    //refetchQueries: ["ListAddress"],
    onCompleted: (res) => {},
  });

  function onChange(value) {

    setCaptchaValue(value);
    //if (value === null) setCaptchaValue(false);
  }

  function onSubmit(params) {
    if(captchaValue){
    try {
      createContactUs({ variables: { data: params } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Your email has been sent",
            variant: "success",
          });
          setContactNo("");
          setCaptchaValue(null);
          reset();
         
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
    }else{
      toggleSnackbar({
        status: true,
        message: "Invalid",
        variant: "error",
      });
    }
  }
  return (
    <div>
      <Helmet>
        <title>CaterPlace - Corporate Catering</title>
        <meta name="Keywords" />
        <meta name="description" content="Email Support@caterplace.com" />
      </Helmet>
      <LoginHeader />
      <section className="contact">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-img">
                <h2>Reach out to us!</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form-wrap">
                <div className="contact-form">
                  <p>
                    For Help/Inquiries please check our{" "}
                    <Link to={"/faq"}>FAQ's</Link>.
                  </p>
                  <p>
                    You can also reach us at{" "}
                    <a href="mailto:support@caterplace.com">
                      support@caterplace.com
                    </a>{" "}
                    or to text or call please contact :{" "}
                    <a href="tel:646-535-4875">646-535-4875.</a>
                  </p>
                  <h5 className="h5">Contact Us</h5>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    className="login-box create-step-form w-100 border-0 p-0"
                  >
                    <div className="textBox">
                      <TextField
                        id="contactName"
                        label="Full Name"
                        autoFocus
                        variant="outlined"
                        className="textField allFeild"
                        placeholder="Enter the full name"
                        {...register("name")}
                      />

                      {errors.name && (
                        <span className="error-msg">{errors.name.message}</span>
                      )}
                    </div>
                    <div className="textBox">
                      <TextField
                        id="contactPhone"
                        label="Phone"
                        variant="outlined"
                        className="textField allFeild"
                        placeholder="Enter the phone number"
                        // {...register("phoneNumber")}
                        value={contactNo}
                        onChange={(e) => {
                          if (
                            !isNaN(e.target.value.replace(/[-,(,), ]+/g, "")) &&
                            e.target.value.replace(/[-,(,), ]+/g, "").length <=
                              10
                          ) {
                            register("phoneNumber").onChange({
                              target: {
                                value: e.target.value,
                                name: "phoneNumber",
                              },
                            });
                            setContactNo(phoneNumberFormat(e.target.value));
                          }
                        }}
                      />

                      {errors.phoneNumber && (
                        <span className="error-msg">
                          {errors.phoneNumber.message}
                        </span>
                      )}
                    </div>
                    <div className="textBox">
                      <TextField
                        id="contactEmail"
                        label="Email"
                        variant="outlined"
                        className="textField allFeild"
                        placeholder="Enter the email address"
                        {...register("email")}
                      />

                      {errors.email && (
                        <span className="error-msg">
                          {errors.email.message}
                        </span>
                      )}
                    </div>

                    <div className="textBox">
                      <TextField
                        id="contactHelp"
                        label="How can we help you ?"
                        variant="outlined"
                        className="textField allFeild"
                        placeholder="Please type here"
                        multiline
                        rows={4}
                        {...register("message")}
                      />

                      {errors.message && (
                        <span className="error-msg">
                          {errors.message.message}
                        </span>
                      )}
                    </div>
                    <ReCAPTCHA
                       sitekey = {process.env.REACT_APP_SITE_KEY}
                       onChange={onChange}
                    />

                    {/* <Button type="submit" variant="contained" className="invite-btn">
                    {loading ? <CircularProgress color="inherit" size={20} /> : "Invite Now"}
                  </Button> */}
                    <Button
                      type="submit"
                      variant="contained"
                      className="submit-btn mt-2 mt-lg-2"
                    >
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        "Request Info"
                      )}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
