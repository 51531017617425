//this is the index page for the customer details 
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoggedHeader from '../../Common/loggedHeader';
import SideMenu from '../../../components/organisms/sidebar';
import UserProfile from './userProfile';
import { Link, useParams } from 'react-router-dom';
import { GET_ONE_CUSTOMER } from '../../../Graphql/queries';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import CaterCash from '../../CaterCash';
import {Orders} from '../../../screens/orders'
import Note from '../../Note';
import { ADMIN_NOTE_TYPES } from '../../Note/note.constants';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CustomerDetails() {
  const { id } = useParams()
  const location = useLocation();
  const locationDetails = location?.state;
  const [value, setValue] = useState(locationDetails==="customers"?1:0);
  const { data } = useQuery(GET_ONE_CUSTOMER, { variables: { getOneUserId: id } })
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <LoggedHeader title={`${data?.getOneUser?.firstName +" " + data?.getOneUser?.lastName}`} user="admin" />
      <SideMenu selected="Customers" user="admin" />
      <div className="contentWrap">
        <div>
        <div className='cater-tab'>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="User Profile" {...a11yProps(0)} />
              <Tab label="Orders" {...a11yProps(1)} />
              <Tab label="CaterCash" {...a11yProps(2)} />
              <Tab label="Note" {...a11yProps(3)} />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
          <Link to={"/customers"} className="main-admin-back-link" style={{ cursor: "pointer" }}><span>Back to Customers</span></Link>
            <UserProfile data={data} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Link to={"/customers"} className="main-admin-back-link" style={{ cursor: "pointer" }}><span>Back to Customers</span></Link>
            <Orders userType="Admin" tile="customers" customerId = {id}/>
          </TabPanel>
          <TabPanel style={{padding:"0 20px"}} value={value} index={2}>
          <Link to={"/customers"} className="main-admin-back-link ms-0" style={{ cursor: "pointer",marginBottom: "30px" }}><span>Back to Customers</span></Link>
            <CaterCash 
            customerTab
            id={id}
            username={`${data?.getOneUser?.firstName +" " + data?.getOneUser?.lastName}`}
            userFirstName={data?.getOneUser?.firstName}
            email={data?.getOneUser?.email}/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Link to={"/customers"} className="main-admin-back-link" style={{ cursor: "pointer",marginBottom: "30px" }}><span>Back to Customers</span></Link>
            <Note type={ADMIN_NOTE_TYPES.customer}/>
          </TabPanel>
        </div>

      </div>
    </div>
  );
}
