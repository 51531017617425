// this page shows the login header before creating account and after creating the account
import React from "react";

import { useState } from "react";
import Button from "@mui/material/Button";
import { authVar, userDeatils } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import Avatar from "@mui/material/Avatar";
import { Link, useParams } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DialogBox from "../Common/dialogBox";
import { IconButton } from "@mui/material";
import LoginDrawer from "./loginMenu";
import { useQuery } from "@apollo/client";
import { GET_ONE_USER } from "../../Graphql/queries";
import { useLogout } from "../../utils/hooks/use-logout";
import { HeaderMenu } from "@components/atoms/header-menu";
import { getUserType } from "@utils/user-format.utils";

export default function LoginHeader(props) {
  const user = useReactiveVar(userDeatils);
  const { id } = useParams();
  const [catererImage, setCatererImage] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const auth = useReactiveVar(authVar);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userType = getUserType(user)

  const { onLogout } = useLogout({
    userType,
    logoutCallback: handleClose,
  });

  var currentLocation = window.location.pathname;
  const urlData = { faqReferFriend: "faqReferFriend" };

  const { data: catererData } = useQuery(GET_ONE_USER, {
    fetchPolicy: "network-only",
    variables: {
      getOneCatererId:
        user?.data?.login?.catererId ||
        user?.data?.currentUserDetails?.catererId ||
        user?.data?.createUserIdentity?.catererId,
    },
    skip: userType !== "Caterer",
    onCompleted: (res) => {
      setCatererImage(res?.getOneCaterer?.imageUrl);
    },
  });
  
  return (
    <header className="fixed-top">
      {openDialog && (
        <DialogBox
          setOpenDialog={setOpenDialog}
          handleDelete={onLogout}
          openDialog={openDialog}
          title="Logout"
          discription="Are you sure you want to Logout?"
        />
      )}
      <LoginDrawer openSideMenu={openSideMenu} setOpenSideMenu={setOpenSideMenu} user={user} />

      <div className="container-fluid">
        <IconButton onClick={() => setOpenSideMenu(true)} className="d-block d-lg-none">
          <img src="../assets/images/hamburger-menu.svg" className="img-fluid" alt="Menu Icon" />
        </IconButton>

        <Link to={"/"} className="logo">
          <img src="../assets/images/logo.svg" className="img-fluid" alt="Logo" />
        </Link>

        {/* checking if the user has created the initial credentials */}
        {auth?.auth === false ? (
          <div className="header-menu">
            <ul>
              <li>
                <Link
                  to={auth?.auth === false ? "/login" : "/referFriend"}
                  state={urlData}
                  style={{
                    color: currentLocation === "/referFriend" ? "#FD4E3B" : "",
                  }}>
                  Refer A Friend
                </Link>
              </li>
              <li>
                <Link
                  to={"/resources"}
                  style={{
                    color: currentLocation === "/resources" ? "#FD4E3B" : "",
                  }}>
                  Resources
                </Link>
              </li>
              <li>
                <Link
                  to={"/caterer"}
                  style={{
                    color: currentLocation === "/caterer" ? "#FD4E3B" : "",
                  }}>
                  Caterers
                </Link>
              </li>
              <li>
                <Link
                  to={"/about"}
                  style={{
                    color: currentLocation === "/about" ? "#FD4E3B" : "",
                  }}>
                  About
                </Link>
              </li>
              <li>
                <Link
                  to={"/faq"}
                  style={{
                    color: currentLocation === "/faq" ? "#FD4E3B" : "",
                  }}>
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  to={"/contact"}
                  style={{
                    color: currentLocation === "/contact" ? "#FD4E3B" : "",
                  }}>
                  Contact
                </Link>
              </li>
            </ul>
            {currentLocation === "/login" ? (
              <Button
                onClick={() => props.setForgetPass(false)}
                variant="contained"
                className="signin-btn">
                Sign In
              </Button>
            ) : (
              <Link to="/login">
                <Button variant="contained" className="signin-btn">
                  Sign In
                </Button>
              </Link>
            )}
            {/* {currentLocation === "/login" ? null : <Link to="/login" state={customerSignIn}>
              <Button variant="contained" className="signin-btn ms-1  md-ms-2">
                Customer Sign In
              </Button>
            </Link>} */}
            {/* add shopping cart icon only for customers */}
            {props?.userType === "Customer" && (
              <IconButton>
                <ShoppingCartIcon />
              </IconButton>
            )}
          </div>
        ) : (
          <div className="header-menu">
            {(currentLocation === "/" ||
              currentLocation === "/caterers" ||
              currentLocation === `/caterers/${id}` ||
              currentLocation === "/about" ||
              currentLocation === "/faq" ||
              currentLocation === "/contact" ||
              currentLocation === "/caterer" ||
              currentLocation === "/referFriend" ||
              currentLocation === "/resources" ||
              currentLocation === "/terms-and-conditions" ||
              currentLocation === "/privacy-policy") && (
              <ul>
                {userType === "Customer" && (
                  <li>
                    <Link
                      to={"/referFriend"}
                      style={{
                        color: currentLocation === "/referFriend" ? "#FD4E3B" : "",
                      }}>
                      Refer A Friend
                    </Link>
                  </li>
                )}
                <li>
                  <a
                    href={"/resources"}
                    style={{
                      color: currentLocation === "/resources" ? "#FD4E3B" : "",
                    }}>
                    Resources
                  </a>
                </li>
                <li>
                  <Link
                    to={"/caterer"}
                    style={{
                      color: currentLocation === "/caterer" ? "#FD4E3B" : "",
                    }}>
                    Caterers
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/about"}
                    style={{
                      color: currentLocation === "/about" ? "#FD4E3B" : "",
                    }}>
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/faq"}
                    style={{
                      color: currentLocation === "/faq" ? "#FD4E3B" : "",
                    }}>
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/contact"}
                    style={{
                      color: currentLocation === "/contact" ? "#FD4E3B" : "",
                    }}>
                    Contact
                  </Link>
                </li>
              </ul>
            )}
            <Avatar
              className="profile-avatar"
              alt="Remy Sharp"
              src={
                catererImage && userType === "Caterer"
                  ? catererImage
                  : user?.data?.currentUserDetails?.imageUrl
                  ? user?.data?.currentUserDetails?.imageUrl
                  : user?.data?.login?.imageUrl
                  ? user?.data?.login?.imageUrl
                  : "/static/images/avatar/1.jpg"
              }
              style={{ cursor: "pointer" }}
              onClick={handleClick}>
              {user?.data?.login?.fullName.charAt(0).toUpperCase() ||
                user?.data?.currentUserDetails?.name?.charAt(0).toUpperCase() ||
                user?.data?.createCaterer?.fullName?.charAt(0).toUpperCase() ||
                user?.data?.createUserIdentity?.fullName?.charAt(0).toUpperCase()}
            </Avatar>
            <h3 style={{ cursor: "pointer" }} className="header-user-name" onClick={handleClick}>
              {user?.data?.login?.fullName ||
                user?.data?.currentUserDetails?.name ||
                user?.data?.createCaterer?.fullName ||
                user?.data?.createUserIdentity?.fullName}
            </h3>
          </div>
        )}
        <HeaderMenu
          element={anchorEl}
          isOpen={open}
          userType={userType}
          onClose={handleClose}
          onLogout={() => setOpenDialog(true)}
        />
      </div>
    </header>
  );
}
