import { IconButton } from "@mui/material";
import React from "react";

interface ICartItemQuantityProps {
  quantity: number;
  minQuantity: number;
  onChangeQuantity(e: any): void;
  onIncrement(): void;
  onDecrement(): void;
}

export function CartItemQuantity({
  quantity,
  minQuantity,
  onChangeQuantity,
  onDecrement,
  onIncrement,
}: ICartItemQuantityProps) {
  return (
    <div className="menu-qty">
      <h6>Quantity</h6>
      <div className="menu-qty-edit">
        <IconButton disabled={quantity < minQuantity} onClick={onDecrement}>
          <img
            src={
              quantity > minQuantity
                ? "../assets/images/qty-minus-dark.svg"
                : "../assets/images/qty-minus.svg"
            }
            className="img-fluid"
            alt="Decrement"
          />
        </IconButton>
        <input
          className="form-control"
          name="Cart"
          type="number"
          min={minQuantity}
          value={quantity}
          onChange={onChangeQuantity}
        />
        <IconButton onClick={onIncrement}>
          <img src="../assets/images/qty-plus.svg" className="img-fluid" alt="Increment" />
        </IconButton>
      </div>
    </div>
  );
}
