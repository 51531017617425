import React from "react";
import { useController } from "react-hook-form";

import { LocationInput, ILocationInputProps } from "../atoms/location-search";

interface ILocationFormProps extends Partial<ILocationInputProps> {
  control: any;
  name: string;
  rules: object;
  onUpdate?(name: string, value: string): void;
}

export function LocationSearchForm({
  control,
  name,
  rules,
  onUpdate,
  ...props
}: ILocationFormProps) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });

  function onChangeValue(location) {
    onChange(location);
    onUpdate && onUpdate(name, location);
  }

  return (
    <LocationInput
      {...props}
      value={value}
      onChange={onChangeValue}
      onBlur={onBlur}
      helperText={error ? error.message : ""}
    />
  );
}
