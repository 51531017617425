import React from "react";
import { IDeliveryHours } from "typings/caterer.api";
import { v4 as uuidv4 } from "uuid";

interface IDeliveryHoursProps {
  status: string;
  deliveryHours: IDeliveryHours[];
  setOpenHours(v: boolean): void;
}

export function DeliveryHours({ status, deliveryHours, setOpenHours }: IDeliveryHoursProps) {
  return (
    <div className="location-details-tile h-100">
      <div className="ld-titles">
        <h4 className="h4">
          <img
            src="../assets/images/icon-delivery-hours.svg"
            className="img-fluid"
            alt="CaterCash & Commission Icon"
          />
          Delivery Hours
          <span
            className={status === "Closed" ? "ld-delivery-status ld-close" : "ld-delivery-status"}>
            <label></label>
            {status}
          </span>
        </h4>
        <a className="ld-edit" onClick={() => setOpenHours(true)}>
          <img src="../assets/images/icon-edit.svg" className="img-fluid" alt="Edit Icon" />
          Edit
        </a>
      </div>
      <div className="ld-delivery-hrs">
        <ul>
          {deliveryHours &&
            deliveryHours.map((item) => (
              <li key={uuidv4()}>
                {item?.day} :{" "}
                {item?.openHours.map((time) => (
                  <React.Fragment key={uuidv4()}>
                    {item?.flag === false ? (
                      <label className="me-2">Closed</label>
                    ) : (
                      <label className="me-2">
                        {time.startTime === "" ? "-" : time.startTime} -{" "}
                        {time.closeTime === "" ? "-" : time.closeTime}
                      </label>
                    )}
                  </React.Fragment>
                ))}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
