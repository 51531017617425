// this is the index page for add new business
import * as React from "react";
import Drawer from '@mui/material/Drawer';
import Box from "@mui/material/Box";
import { userDeatils } from "../../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import NewBusiness from "./newBusiness";
import NewCuisines from "./newCuisines";
import NewHours from "./newHours";
import NewBankInfo from "./newBankInfo";

const steps = [
  "Business",
  "Cuisines",
  "Hours",
  "Banking Information",
];

export default function AddBusiness(props) {
  const userDetails = useReactiveVar(userDeatils);
  const [activeStep, setActiveStep] = React.useState(0);
  const [userValues,setUserValues] = React.useState()
  const [skipped, setSkipped] = React.useState(new Set());
  const [createRes,setCreateRes] = React.useState("")
  const [title,setTitle] = React.useState("")

  
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

//   const handleNext = () => {
//     let newSkipped = skipped;
//     if (isStepSkipped(activeStep)) {
//       newSkipped = new Set(newSkipped.values());
//       newSkipped.delete(activeStep);
//     }
//  // handling stepper
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     setSkipped(newSkipped);
//   };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  return (
    <div key={"right"}>
      <Drawer
      className='common-drawer business-drawer'
        anchor={"right"}
        open={props?.openNewBusiness}
        onClose={()=>{props?.setOpenNewBusiness(false);setUserValues(null)}}
      >
          <div>
          <div className='drawer-head'>
        <h4 className="h4">
          <img
            src="../assets/images/icon-business.svg"
            className="img-fluid"
            alt="Business Details Icon"
          />
         {title}
        </h4>
        <span style={{cursor:'pointer'}} onClick={()=>{props?.setOpenNewBusiness(false);setUserValues(null)}}>
          <img
            src="../assets/images/x-icon.svg"
            className="img-fluid"
            alt="close icon"
          />
        </span>
      </div>
            <Box  className="create-step-wrap add-business-step-wrap p-0" sx={{ width: "100%" }}>
              {
                <>
                
                <Stepper className="create-stepper" activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
               {/* Business details component */}
              {activeStep === 0 &&  (
                <NewBusiness
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  openNewBusiness={props?.openNewBusiness}
                  setOpenNewBusiness={props?.setOpenNewBusiness}
                  setUserValues={setUserValues}
                  userValues={userValues}
                  setCreateRes={setCreateRes}
                  createRes={createRes}
                  userDetails={userDetails}
                  setTitle={setTitle}
                />
              )}
              {/* Cuisines component */}
              {activeStep === 1 && (
                <NewCuisines
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  setUserValues={setUserValues}
                  userValues={userValues}
                  setCreateRes={setCreateRes}
                  createRes={createRes}
                  userDetails={userDetails}
                  setTitle={setTitle}
                />
              )}
              {/* Hours component */}
              {activeStep === 2 && 
              <NewHours
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              setUserValues={setUserValues}
              userValues={userValues}
              setCreateRes={setCreateRes}
              createRes={createRes}
              userDetails={userDetails}
              setTitle={setTitle}
              />
              }
              {/* banking info component */}
              {activeStep === 3 && 
              <NewBankInfo 
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              setUserValues={setUserValues}
              userValues={userValues}
              setCreateRes={setCreateRes}
              createRes={createRes}
              userDetails={userDetails}
              setTitle={setTitle}
              />
              }
              </>
              }
              
            </Box>
          </div>
      </Drawer>
    </div>
        
  );
}


