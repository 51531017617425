//this page is for showing all the promocodes
import React, { useState } from "react";
import SideMenu from "../../../components/organisms/sidebar";
import LoggedHeader from "../../Common/loggedHeader";
import { Table, Select, MenuItem,Button,Menu} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatter } from "../../Common/commonUtils";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_PROMO_CODE } from "../../../Graphql/queries";
import { DELETE_PROMOCODE } from "../../../Graphql/mutations";
import Pagination from "@mui/material/Pagination";
import { toggleSnackbar,authVar } from "../../../ReactiveVariables/index";
import Stack from "@mui/material/Stack";
import AddPromoCode from "./addPromoCode";
import DialogBox from "../../Common/dialogBox";
import moment from "moment";
import {useNavigate } from "react-router-dom";
export default function PromoCodes(){
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [openPromo,setOpenPromo] =useState(false)
  const open = Boolean(anchorEl);
  const promoVariable = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
  }
  const {data,loading}= useQuery(LIST_PROMO_CODE,{variables:{data:promoVariable}})
  const [deletePromoCode] = useMutation(DELETE_PROMOCODE,{refetchQueries:['ListPromoCodes'],
  onCompleted:(res)=>{
    setSelectedItem(null)
  }})
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };
  //pagination
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  // for showing anchor
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // close menu
  const handleClose = () => {
    setAnchorEl(null);
  };
  //delete functionality for promo code
  function handleDelete(){
    try { deletePromoCode({ variables: {promoCodeId: selectedItem?._id } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Promo Code deleted successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
  }
  return (
    <div>
      <LoggedHeader title="Promo code" user="admin" />
      <SideMenu selected="Promo Codes" user="admin" />
      {openPromo&&<AddPromoCode 
      selectedItem ={selectedItem}
      setSelectedItem ={setSelectedItem}
      openPromo={openPromo}
      setOpenPromo={setOpenPromo}/>}

      {openDialog && (<DialogBox
        setOpenDialog={setOpenDialog}
        handleDelete={handleDelete}
        openDialog={openDialog}
        title="Delete"
        discription="Are you sure you want to delete this Promo Code?"
      />
      )}
      <div className="contentWrap">
        <div className="contentWrapInner promocode-table">
          <div className="location-head">
            <div className="location-head-title justify-content-between">
              {data?.listPromoCodes?.count!== undefined?<h5>{`${data?.listPromoCodes?.count} Promo codes`}</h5>: <p> Loading...</p>}
              <div className="d-flex align-items-center">
              <Button className="fw-bold" color="primary" variant="contained" onClick={()=>{setOpenPromo(true); setSelectedItem(null)}} >
                  <img src="../assets/images/icon-add-menu.svg" className="img-fluid me-1" alt="Icon" /> Add Promo code
              </Button>
              </div>
            </div>
          </div>
          <TableContainer>
            <Table
              className="commonTabel"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Promo Code</TableCell>
                  <TableCell>Expiry Date</TableCell>
                  <TableCell align="center">Discount Amount</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Total Usage</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : data && data?.listPromoCodes?.promoCodes?.length > 0 ? (
                  data &&
                  data?.listPromoCodes?.promoCodes?.map((item) => (
                    <TableRow >
                      <TableCell component="th" scope="row">
                        {item?.coupenCode}
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {item?.expDate?moment(item?.expDate).format('L'):"--"}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                      {formatter.format(item?.discountAmt)}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                      <Button
                            className="status-btn"
                            style={{
                              color: "#fff",
                              backgroundColor:item?.codeStatus==="expired"||item?.codeStatus==="Inactive"? "#646777":"#00C643",
                            }}
                            variant="contained"
                          >
                            {item?.codeStatus==="expired"?"Expired":item?.codeStatus==="Inactive"?"Inactive":"Active"}
                          </Button>
                        
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {item?.totalUsage}
                      </TableCell>
                      <TableCell align="center">
                        <img
                          style={{ cursor: "pointer" }}
                            onClick={(e) => { setSelectedItem(item); handleClick(e) }}
                          src="../assets/images/more-horizontal.svg"
                          alt="img"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )  : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No list added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select
              // variant="outlined"
              onChange={handlePageInputChange}
              value={rowsPerPage}
            >
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listPromoCodes?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="menu-list-more"
        >
          <MenuItem
            onClick={() => {
              setOpenPromo(true);
              handleClose();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem onClick={() => { setOpenDialog(true); handleClose() }}>Delete</MenuItem>
        </Menu>
      </div>
    </div>
  )
}