import React from "react";

interface IBankDetailsProps {
  accountName: string;
  bankName: string;
  setOpenBank(v: boolean): void;
}

export function BankDetails({ accountName, bankName, setOpenBank }: IBankDetailsProps) {
  return (
    <div className="location-details-tile">
      <div className="ld-titles">
        <h4 className="h4">
          <img src="../assets/images/icon-bank.svg" className="img-fluid" alt="Bank Details Icon" />
          Bank Details
        </h4>
        <a className="ld-edit" onClick={() => setOpenBank(true)}>
          <img src="../assets/images/icon-edit.svg" className="img-fluid" alt="Edit Icon" />
          Edit
        </a>
      </div>
      <div className="ld-business-detail">
        <div className="row">
          <div className="col-md-6 ld-business-detail-item">
            <label>Legal Business Name</label>
            <b>{accountName}</b>
          </div>
          <div className="col-md-6 ld-business-detail-item">
            <label>Bank</label>
            <b>{bankName}</b>
          </div>
        </div>
      </div>
    </div>
  );
}
