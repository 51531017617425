//this page is for showing all the categories
import React, { useState } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  Table,
  Select,
  MenuItem,
  IconButton,
  TableRow,
  Stack,
  Pagination,
  TableContainer,
  TableCell,
} from "@mui/material";

import SideMenu from "../../../components/organisms/sidebar";
import LoggedHeader from "../../Common/loggedHeader";
import { SORT_CATEGORY } from "../../../Graphql/mutations";
import { LIST_CATEGORY_SORT } from "../../../Graphql/queries";
import { toggleSnackbar, authVar } from "../../../ReactiveVariables/index";

export default function SortCategory(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const locationData = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const categoryVariable = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    catererId: id,
    sortField: "CategoryOrder",
  };
  const { data, loading } = useQuery(LIST_CATEGORY_SORT, {
    variables: categoryVariable,
    skip: !id,
  });
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  const [sortCategory] = useMutation(SORT_CATEGORY, {
    refetchQueries: ["ListCategoryOrder"],
  });

  function handleSort(itemValue, sortValue, sortOrder) {
    const changeCategory = structuredClone(itemValue);
    let upwardSortValue = sortOrder - 1;
    let downwardSortValue = sortOrder + 1;
    let sortPreVal = [];
    let updatedData = [];
    changeCategory.map((item) => {
      delete item?.categoryDtls;
      sortPreVal.push(item);
    });

    if (sortValue === "upward") {
      let id;
      if (
        sortPreVal[0].order !== sortOrder &&
        sortOrder <= sortPreVal[sortPreVal.length - 1].order
      ) {
        updatedData = sortPreVal?.map((sort) => {
          if (sort?.order === sortOrder) {
            id = sort?._id;
            sort.order = upwardSortValue;
          }
          if (sort?.order === upwardSortValue && sort?._id !== id) {
            sort.order = sortOrder;
          }
          delete sort?.__typename;
          return sort;
        });
      } else return;
    } else if (sortValue === "downword") {
      let id;
      if (sortOrder < sortPreVal[sortPreVal.length - 1].order) {
        updatedData = sortPreVal?.map((sort) => {
          if (sort?.order === sortOrder) {
            id = sort?._id;
            sort.order = downwardSortValue;
          }
          if (sort?.order === downwardSortValue && sort?._id !== id) {
            sort.order = sortOrder;
          }
          delete sort?.__typename;
          return sort;
        });
      } else return;
    }

    try {
      sortCategory({ variables: { catererId: id, data: updatedData } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Category sorted successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if (message === "Not authenticated") {
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/login", { state: "noAuth" });
          } else {
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <div>
      <LoggedHeader />
      <SideMenu selected="Menu" user="caterer" />
      <div className="contentWrap">
        <div className="contentWrapInner">
          <Link to={"/menu"} className="menu-back" style={{ cursor: "pointer" }}>
            {" "}
            <img src="../assets/images/back.svg" className="img-fluid" alt="Icon" />
            <span>Back to Menu</span>
          </Link>
          <div className="sort-category">
            <div className="location-head">
              <div className="location-head-title justify-content-between">
                <h5 style={{ color: "#202428", fontSize: "16px" }}>{`Sort ${
                  data && data?.listCategoryOrder?.count
                } Categories for ${locationData?.state?.name}, ${
                  locationData?.state?.location
                }`}</h5>
              </div>
            </div>
            <TableContainer>
              <Table
                //className="commonTabel"
                //   sx={{ minWidth: 650 }}
                aria-label="simple table">
                {/* <TableHead>
                  <TableRow>
                    <TableCell width="20%">Sort</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead> */}
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <p> Loading...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  data &&
                  data?.listCategoryOrder?.data?.map((item) => (
                    <TableRow style={{ cursor: "pointer" }}>
                      <TableCell component="th" scope="row">
                        <div className="d-flex sub-addon-sort align-items-center">
                          <div className="d-flex flex-column">
                            <IconButton
                              className={
                                data?.listCategoryOrder?.data[0].order === item?.order
                                  ? "sort-disabled"
                                  : ""
                              }
                              onClick={() =>
                                handleSort(data?.listCategoryOrder?.data, "upward", item?.order)
                              }>
                              <img
                                src="../assets/images/sort-up.svg"
                                className="img-fluid"
                                alt="Arrow Drop Up Icon"
                              />
                              {/* <ArrowDropUpIcon  /> */}
                            </IconButton>
                            <IconButton
                              className={
                                data?.listCategoryOrder?.data[
                                  data?.listCategoryOrder?.data.length - 1
                                ].order === item?.order
                                  ? // item?.order ===
                                    //   data?.listCategoryOrder?.data.length
                                    "sort-disabled"
                                  : ""
                              }
                              onClick={() =>
                                handleSort(data?.listCategoryOrder?.data, "downword", item?.order)
                              }>
                              <img
                                src="../assets/images/sort-down.svg"
                                className="img-fluid"
                                alt="Arrow Drop Down Icon"
                              />
                              {/* <ArrowDropDownIcon /> */}
                            </IconButton>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ textTransform: "capitalize" }}>
                          {item?.categoryDtls?.categoryTitle}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select
              // variant="outlined"
              onChange={handlePageInputChange}
              value={rowsPerPage}>
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listCategoryOrder?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
}
