//  this page is for adding the cusines for new business
import React from 'react';

import { useState,useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_CUISINE_TYPE } from "../../../Graphql/queries";
import { UPDATE_CATERE } from "../../../Graphql/mutations";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormGroup from "@mui/material/FormGroup";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import {
  toggleSnackbar,
} from "../../../ReactiveVariables/index";
const StyledCheckbox = styled(Checkbox)`
  svg {
    color: #fd4e3b;
    font-size: 22px;
  }
`;
export default function NewCuisines(props) {
  const currentData =props?.userValues?.updateCaterer;
  const [checkedValue, setCheckedValue] = useState(currentData?.cuisineType ?? []);
  const { data } = useQuery(LIST_CUISINE_TYPE)
  const [updateCaterer, { loading }] = useMutation(UPDATE_CATERE, {
    onCompleted: (res) => {
      props.setUserValues(res);
      props.setActiveStep(2);
    },
  });
    //adding cuisines to the array
  function addElement(item) {
    let newElement = [...checkedValue];
    newElement.push(item);
    setCheckedValue(newElement);
  }
    //removing cuisines from the array
  function removeElement(item) {
    let index = checkedValue.indexOf(item);
    let array = [...checkedValue]
    array.splice(index, 1);
    setCheckedValue(array);
  }
    // function for mutation of the cusines
  function UpdateCuisine(params) {
    let cuisineData = {
      cuisineType: checkedValue,
      tabValue: "1"
    }
    try {
      currentData.adminFlg?updateCaterer({ variables: { id: props?.createRes?.createCaterer?._id, catererId: props?.createRes?.createCaterer?.catererIdNew, data: cuisineData } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Cuisines saved successfully",
          variant: "success",
        });
      }):
      updateCaterer({ variables: { id: props.createRes?.createCaterer?.newUserId || props.createRes?.updateUserIdentity?._id, catererId: props.createRes?.createCaterer?.catererIdNew || props.createRes?.updateUserIdentity?.catererId, data: cuisineData } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Cuisines saved successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  useEffect(()=>{
    props?.setTitle("Add Cuisines")
  })
  return (
    <div>
      <div className="add-business-step-head">
        <h5 className="h5">Please select all the cuisine types offered</h5>
        <h6 className="subhead">You can select multiple options as offered.</h6>
      </div>
      <div className='create-step-form'>
        <div className='container-fluid'>
          <div className="row">
            {data?.listCuisineType?.data?.map((item) => (
              <FormGroup className="col-md-4">
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      checked={checkedValue.includes(item?._id)}
                      onChange={(e) =>
                        e.target.checked === true
                          ? addElement(item?._id)
                          : removeElement(item?._id)
                      }
                      color={"primary"}
                      icon={<CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />}
                      checkedIcon={<CheckCircleIcon />}
                    // {...others}
                    />
                  }
                  label={item?.cuisineName}
                />
              </FormGroup>
            ))}



          </div>
        </div>
      </div>
      <div className="createStepBtn">
        <Button
          className="cancelBtn"
          variant="contained"
          color="secondary"
          onClick={() => props.setActiveStep(0)}
        >
          Previous
        </Button>
        <Button
          className="saveBtn"
          variant="contained"
          color="primary"
          onClick={() => UpdateCuisine()}
        >
          {loading ? <CircularProgress color="inherit" size={20} className="m-auto"/> : "Save and Proceed "}<ArrowForwardIcon className="btnArrow" />
        </Button>

      </div>

    </div>
  );
}
