//This page is for showing all the Admin Users
import React, { useState } from "react";
import SideMenu from "../../../components/organisms/sidebar";
import LoggedHeader from "../../Common/loggedHeader";
import { Table, Select, MenuItem, Menu, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_USERS } from "../../../Graphql/queries";
import { DELETE_CUSTOMER } from "../../../Graphql/mutations";
import Pagination from "@mui/material/Pagination";
import DialogBox from "../../Common/dialogBox";
import { toggleSnackbar,authVar } from "../../../ReactiveVariables/index";
import Stack from "@mui/material/Stack";
import AddAdminUser from "./addAdminUser";
import AdminProfileInfo from "../../Location/locationEdits/adminProfileEdit";
export default function AdminUserList() {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddAdminUser, setOpenAddAdminUser] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const adminUserVariables = {
    userType: "Admin",
    limit: rowsPerPage,
    skip: (page - 1) * rowsPerPage,
  };
  const { data, loading } = useQuery(LIST_USERS, {
    variables: adminUserVariables,
  });
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    refetchQueries: ["ListUser"],
  });
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  // function for deleting the Admin user
  function handleDelete() {
    try {
      deleteCustomer({ variables: { customerId: selectedItem?._id } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Admin User deleted successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <div>
      {openAddAdminUser && (
        <AddAdminUser
          openAddAdminUser={openAddAdminUser}
          setOpenAddAdminUser={setOpenAddAdminUser}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      )}
       {openProfile && (
        <AdminProfileInfo
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          title={"Edit User"}
          fieldName={"ADMIN NAME"}
        />
      )}
      {openDialog && (
        <DialogBox
          setOpenDialog={setOpenDialog}
          handleDelete={handleDelete}
          openDialog={openDialog}
          title="Delete"
          discription="Are you sure you want to delete this Admin User?"
        />
      )}
      <LoggedHeader title="Admin Users" user="admin" />
      <SideMenu selected="Admin User" user="admin" />
      <div className="contentWrap">
        <div className="contentWrapInner">
          <div className="location-head">
            <div className="location-head-title justify-content-between">
             {data?.listUser?.count? <h5>{data?.listUser?.count===1?`${data?.listUser?.count} Admin User`:`${data?.listUser?.count} Admin Users`}</h5>:<p>Loading...</p>}
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenAddAdminUser(true)}
              >
                <img
                  src="../assets/images/icon-add-menu.svg"
                  className="img-fluid"
                  alt="Icon"
                />{" "}
                Add an User
              </Button>
            </div>
          </div>
          <TableContainer>
            <Table
              className="commonTabel"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="center">Phone</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : data && data?.listUser?.data?.length > 0 ? (
                  data &&
                  data?.listUser?.data?.map((item) => (
                    <TableRow style={{ cursor: "pointer" }}>
                      <TableCell component="th" scope="row">
                        {item?.name}
                      </TableCell>
                      <TableCell align="left">{item?.email}</TableCell>
                      <TableCell align="center">{item?.phoneNumber}</TableCell>
                      <TableCell align="center">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            setSelectedItem(item);
                            handleClick(e);
                          }}
                          src="../assets/images/more-horizontal.svg"
                          alt="img"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) :  
                (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No list added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select
              // variant="outlined"
              onChange={handlePageInputChange}
              value={rowsPerPage}
            >
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listUser?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="menu-list-more"
        >
          <MenuItem
            onClick={() => {
              setOpenProfile(true);
              handleClose();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenDialog(true);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
