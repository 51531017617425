//this page is for editing the customer user profile from admin
import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { TextField, Button, CircularProgress } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { UPDATE_USER } from '../../../Graphql/mutations';
import { toggleSnackbar, authVar } from "../../../ReactiveVariables/index";
import { IMAGE_UPLOAD } from "../../../Graphql/queries";
import { useMutation, useLazyQuery } from '@apollo/client';
import imageCompression from "browser-image-compression";
import CropImage from "../../Common/cropImage";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const StyledCheckbox = styled(Checkbox)`
  svg {
    color: #fd4e3b;
    font-size: 22px;
  }
`;

export default function EditCustomerProfile(props) {
  const navigate = useNavigate()
  const [imageDeleteCheck, setImageDeleteCheck] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(props?.props?.data?.getOneUser?.phoneNumber || props?.selectedItem?.phoneNumber)
  const [taxExempt, setTaxExempt] = useState(props?.props?.data?.getOneUser?.taxExempt || false);
  const [disableSignUpPromo, setDisableSignUpPromo] = useState(props?.props?.data?.getOneUser?.disableSignUpPromo || false); 
  const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().required("Email is required").email("Please enter a valid email address"),
    phoneNumber: yup.string().required("Phone is required").min(14, "The phone number must be 10 digits."),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { phoneNumber: props?.props?.data?.getOneUser?.phoneNumber || props?.selectedItem?.phoneNumber }
  });
  const [updateCustomer, { loading }] = useMutation(UPDATE_USER, {
    refetchQueries: ['GetOneUser'],
    onCompleted: (res) => {
      props.setOpenProfile(false);
    },
  })
  //formatting the phone number
  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x =
        "(" +
        newNum.slice(0, 3) +
        ") " +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  };
  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/userprofile",
  };
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });
  // this function is for uploading the image
  function handleUploadClick(imagefile) {
    let obj = {};
    obj["fileName"] = imagefile.name;
    obj["url"] = URL.createObjectURL(imagefile);
    obj["file"] = imagefile;
    setSelectedImage(obj);
    upload();
  }
  //for chacking the image with certain criteria that we matches
  function checkImage(e) {
    let file = e.target.files[0];
    if (
      !(
        file?.type == "image/jpeg" ||
        file?.type == "image/png" ||
        file?.type == "image/jpg"
      )
    ) {
      toggleSnackbar({
        status: true,
        message:
          "This file format is not supported. Please upload only .png or .jpg files",
        variant: "error",
      });
      return false;
    } else if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    } else {
      var options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 300,
        useWebWorker: true,
      };
      imageCompression(file, options)
        .then((compressedFile) => {
          setCropImage(compressedFile);
          setCropOpen(true);
        })
        .catch((err) => {
          //console.log("err: ",err)
        });
    }
  }
  // this allows the us to call the upload image function after the crop is done.
  function handelCropDone(result) {
    setCropImage(null);
    handleUploadClick(result);
  }
  // function for getting the url  after the upload
  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    setImageUploadLoading(false);
    // props?.data?.getOneCaterer?.catererUsers?.length === 0 &&
    // props.userType !== "Admin"
    //   ? oncreateSubmit(params)
    //   : onSubmit(params);
    onSubmit(params)
  }
  function onSubmit(params) {
    const data = {
      ...params,
      imageUrl: uploadImageData?.imageUpload?.publicUrl ? uploadImageData?.imageUpload?.publicUrl : "#",
      _id: props?.props?.data?.getOneUser?._id || props?.selectedItem?._id,
      name: params.firstName + ' ' + params.lastName || props?.selectedItem?.firstName + ' ' + props?.selectedItem?.lastName,
      taxExempt,
      disableSignUpPromo
    }

    console.log(data)

    try {
      updateCustomer({ variables: { id: props?.props?.data?.getOneUser?._id || props?.selectedItem?._id, data } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Customer Profile updated successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message
          if (message === "Not authenticated") {
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          } else {
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  function handleDeleteImage() {
    setImageDeleteCheck(true)
    // setIsImageDelete(true)
    setSelectedImage(null)
  }

  function toggleTaxExempt() {
    setTaxExempt(!taxExempt);
  }

  function toggleSignUpPromo() {
    setDisableSignUpPromo(!disableSignUpPromo);
  }

  return (
    <Drawer className='common-drawer'
      anchor={"right"}
      open={props.openProfile}
      onClose={() => props.setOpenProfile(false)}
    >  {/* this component helps in croping the image */}
      <CropImage
        open={cropOpen}
        setOpen={setCropOpen}
        image={cropImage}
        handelCropDone={handelCropDone}
        aspectRatio={1}
      />
      {/* for opening the file to upload the image */}
      <input
        type={"file"}
        className="d-none"
        id="add-user-input"
        accept='.jpg, .png, .jpeg'
        onChange={checkImage}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <div className='drawer-head'>
        <h4 className="h4"><img src="../assets/images/icon-contact-info.svg" className="img-fluid" alt="Contact Information Icon" />Edit Customer Profile</h4><span style={{ cursor: 'pointer' }} onClick={() => props.setOpenProfile(false)}><img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" /></span>
      </div>
      <form
        onSubmit={handleSubmit(uploadImage)}
        noValidate
      >
        <div className='create-step-form'>
          <div className='container-fluid'>
            <div className="text-center">
              <div className="upload-img-wrap">
                <Avatar
                  alt="Remy Sharp"
                  src={selectedImage
                    ? selectedImage.url : props?.selectedItem?.imageUrl ? props?.selectedItem?.imageUrl : props?.props?.data?.getOneUser?.imageUrl === "#" ? "../assets/images/upoadHead.jpg" : props?.props?.data?.getOneUser?.imageUrl && !imageDeleteCheck ? props?.props?.data?.getOneUser?.imageUrl :
                      "../assets/images/upoadHead.jpg"
                  }
                  className="upload-img upload-img-square"
                  onClick={(e) =>
                    document.getElementById("add-user-input").click()
                  }
                />
                {(selectedImage === null || (props?.selectedItem?.imageUrl === undefined) || (props?.props?.data?.getOneUser?.imageUrl === "#" && imageDeleteCheck === true)) && <img
                  onClick={(e) =>
                    document.getElementById("add-user-input").click()
                  }
                  src="../assets/images/icon-upload-plus.svg"
                  alt="uploadImage"
                  className="upload-icon img-fluid"
                />}
                {(selectedImage || (props?.selectedItem?.imageUrl) || (props?.props?.data?.getOneUser?.imageUrl !== "#" && imageDeleteCheck === false)) && <img
                  alt="closeIconImage"
                  onClick={() => handleDeleteImage()}
                  src="../assets/images/close-black.svg"
                  className="upload-icon img-fluid"
                />}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 textBox'>
                <TextField
                  id="createAccountAdminName"
                  defaultValue={props?.props?.data?.getOneUser?.firstName || props?.selectedItem?.firstName}
                  label="FIRST NAME*"
                  autoFocus
                  variant="outlined"
                  className="textField allFeild"
                  {...register("firstName")}
                />
                {errors.firstName && <span className="error-msg">{errors.firstName.message}</span>}
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountAdminName"
                  defaultValue={props?.props?.data?.getOneUser?.lastName || props?.selectedItem?.lastName}
                  label="LAST NAME*"
                  autoFocus
                  variant="outlined"
                  className="textField allFeild"
                  {...register("lastName")}
                />
                {errors.lastName && <span className="error-msg">{errors.lastName.message}</span>}
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountAdminName"
                  defaultValue={props?.props?.data?.getOneUser?.email || props?.selectedItem?.email}
                  label="EMAIL*"
                  autoFocus
                  variant="outlined"
                  className="textField allFeild"
                  {...register("email")}
                />
                {errors.email && <span className="error-msg">{errors.email.message}</span>}
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountPhoneNumber"
                  label="PHONE NUMBER*"
                  variant="outlined"
                  className="textField allFeild"
                  value={phoneNumber}
                  onChange={(e) => {
                    // phone number validation
                    if (!isNaN(e.target.value.replace(/[-,(,), ]+/g, "")) && (e.target.value.replace(/[-,(,), ]+/g, "").length <= 10)) {
                      register("phoneNumber").onChange({ target: { value: e.target.value, name: "phoneNumber" } })
                      setPhoneNumber(phoneNumberFormat(e.target.value))
                    }
                  }}
                />
                {errors.phoneNumber && <span className="error-msg">{errors.phoneNumber.message}</span>}
              </div>
              <div className="col-12">
                <hr />
              </div>
              <div className="col-6">
                <h6 className="h6 pt-4">Tax exempt</h6>
                <div className="row">
                  <div className="col-4">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            checked={taxExempt}
                            onChange={() => { toggleTaxExempt() }}
                            color={"primary"}
                            icon={
                              <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                            }
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label="Yes"
                      />
                    </FormGroup>
                  </div>
                  <div className="col-4">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            checked={!taxExempt}
                            onChange={() => { toggleTaxExempt() }}
                            color={"primary"}
                            icon={
                              <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                            }
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label="No"
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <h6 className="h6 pt-4">Sign up promo</h6>
                <div className="row">
                  <div className="col-4">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            checked={!disableSignUpPromo}
                            onChange={() => { toggleSignUpPromo() }}
                            color={"primary"}
                            icon={
                              <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                            }
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label="Yes"
                      />
                    </FormGroup>
                  </div>
                  <div className="col-4">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            checked={disableSignUpPromo}
                            onChange={() => { toggleSignUpPromo() }}
                            color={"primary"}
                            icon={
                              <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                            }
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label="No"
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => { props.setOpenProfile(false); setImageDeleteCheck(false) }}
          >
            Cancel
          </Button>
          <Button
            className="saveBtn"
            variant="contained"
            type="submit"
            color="primary"
          >
            {loading ? <CircularProgress color="inherit" size={20} /> : "Update"}
          </Button>
        </div>
      </form>

    </Drawer>
  )
}