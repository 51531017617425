import { DATE_FORMATS } from "@constants/date-formats.constants";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

interface IBusinessDetailsProps {
  businessName: string;
  imageUrl: string;
  caterCash: number;
  createdDate: string;
  phone: string;
  isAdmin: boolean;
  additionalPhone?: string | null;
  deliveryEmails: string[];
  tagLine: string;
  locationName: string;
  zipCode: string;
  allCuisines: any[];
  cuisines: string[];
  deliveryInstructions: string;
  onReadMore(): void;
  setOpenBusiness(v: boolean): void;
}

export function BusinessDetailsOptions({
  businessName,
  imageUrl,
  caterCash,
  createdDate,
  phone,
  deliveryEmails,
  tagLine,
  locationName,
  isAdmin,
  additionalPhone,
  zipCode,
  allCuisines,
  cuisines,
  deliveryInstructions,
  onReadMore,
  setOpenBusiness,
}: IBusinessDetailsProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedCuisines, setSelectedCuisines] = useState("");

  console.log(additionalPhone)

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    setSelectedCuisines(
      allCuisines
        .filter((cuisine) => cuisines.includes(cuisine._id))
        .map((item) => item.cuisineName)
        .join(", "),
    );
  }, [cuisines, allCuisines]);

  return (
    <div className="location-details-tile ld-business-detail h-100">
      <div className="ld-titles">
        <h4 className="h4">
          <img
            src="../assets/images/icon-business.svg"
            className="img-fluid"
            alt="Business Details Icon"
          />
          {businessName}
        </h4>

        <a className="ld-edit" onClick={() => setOpenBusiness(true)}>
          <img src="../assets/images/icon-edit.svg" className="img-fluid" alt="Edit Icon" />
          Edit
        </a>
      </div>
      <div className="ld-contact">
        <div className="ld-contact-img">
          {!isLoaded && (
            <Skeleton variant="rectangular" width="100%">
              <div style={{ paddingTop: "70%" }} />
            </Skeleton>
          )}
          {
            <img
              onLoad={handleImageLoad}
              style={{ height: !isLoaded && "0" }}
              src={
                imageUrl === "#" || !imageUrl ? "../assets/images/default-login-img.png" : imageUrl
              }
              className="img-fluid"
              alt="Cater Image"
            />
          }
          <div className="cater-cash-tag">
            <b>{caterCash || 5}%</b>
            <label>CaterCash</label>
          </div>
        </div>
        <div className="ld-business-primary ld-business-detail">
          <div className="ld-business-detail-item">
            <label>
              Date Vendor Created
              <Tooltip
                title={
                  !createdDate
                    ? "Your Caterer cannot have a created date because it was created before the update."
                    : "MM/DD/YYYY"
                }
                placement="top">
                <IconButton sx={{ padding: 0, marginLeft: "5px", bottom: "1px" }}>
                  <img
                    src="../assets/images/icon-info.svg"
                    className="img-fluid"
                    alt="Delete Item"
                  />
                </IconButton>
              </Tooltip>
            </label>
            <b>{createdDate ? moment(createdDate).format(DATE_FORMATS.catererDate) : ""}</b>
          </div>
          <div className="ld-business-detail-item">
            <label>Phone Number (For SMS Updates)</label>
            <b>{phone}</b>
          </div>
          {isAdmin && (
            <div className="ld-business-detail-item">
              <label>Additional Phone</label>
              <b>{additionalPhone || '-'}</b>
            </div>
          )}
          <div className="ld-business-detail-item">
            <label>Additional Delivery Email Addresses</label>
            {deliveryEmails.map((item, index) => (
              <b style={{ textTransform: "lowercase" }} key={index}>
                {item}
              </b>
            ))}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 ld-business-detail-item">
          <label>Tagline</label>
          <b>{tagLine}</b>
        </div>

        <div className="col-md-12 ld-business-detail-item">
          <label>Location</label>
          <b>{`${locationName || ""}${zipCode ? `, ${zipCode}` : ""}`}</b>
        </div>
        <div className="col-md-12 ld-business-detail-item">
          <label>Cuisines</label>
          <b>{selectedCuisines}</b>
        </div>
        <div className="col-md-12 ld-business-detail-item">
          <label>
            Any special delivery instructions or any other information to share with the customer?
          </label>
          <b>
            {deliveryInstructions.slice(0, 140)}
            {deliveryInstructions.length > 140 && (
              <a style={{ color: "#FAA095" }} onClick={onReadMore}>
                Read more...
              </a>
            )}
          </b>
        </div>
      </div>
    </div>
  );
}
