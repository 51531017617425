import React from "react";
import { Link, useParams } from "react-router-dom";

import { TextField, InputAdornment, Box } from "@mui/material";
import { DatePicker } from "../../atoms/datepicker/datepicker";

import { ROUTES } from "../../../constants/routes.constants";
import { LocationInput } from "../../atoms/location-search";
import { MAX_ORDER_HEAD_COUNT } from "@constants/order.constants";

interface ICatererFilterProps {
  address: string;
  zip?: string | number;
  date: any;
  headCount: number;
  isEdit: boolean;
  coordinates: [number, number];
  savedAddressId?: string;
  addressId?: string;
  isShowZip?: boolean;
  addressRef?: any;
  datepickerRef?: any;
  setHeadCount(value: number): void;
  setAddress(value: string): void;
  setDate(value: Date): void;
  onLocationOptions({ zip, coordinated, city }: any): void;
}

export const CatererFilter = (props: ICatererFilterProps) => {
  const { id } = useParams();

  async function onSelectLocation({ zip, coordinates, city }) {
    props.onLocationOptions &&
      props.onLocationOptions({
        zip,
        coordinates,
        city,
      });
  }

  // TODO: replace with persist storage
  let urlData = {
    address: props?.address,
    date: props?.date,
    count: props?.headCount,
    cordinates: props.coordinates,
    savedAddressId: props?.savedAddressId,
    addressId: props?.addressId,
  };

  function onChangeHeadCount(event) {
    const isValidValue = /^$|^\d*[1-9]\d*$/.test(event.target.value);
    if (!isValidValue) {
      return props.setHeadCount(props.headCount)
    }
    const isBigValue = +event.target.value <= MAX_ORDER_HEAD_COUNT;
    props.setHeadCount(isBigValue ? event.target.value : props.headCount);
  }

  return (
    <div>
      <div className="container-fluid ">
        {props.isEdit ? (
          <div />
        ) : (
          <Link
            to={
              window.location.pathname === ROUTES.caterers
                ? ROUTES.root
                : props.coordinates
                ? ROUTES.caterers
                : ROUTES.root
            }
            state={urlData}>
            <a className="menu-back" style={{ cursor: "pointer" }}>
              <img src="../assets/images/back.svg" className="img-fluid" alt="Icon" />
              <span>
                Back to{" "}
                {props.coordinates && window.location.pathname === `/caterers/${id}`
                  ? "Results"
                  : "Home"}
              </span>
            </a>
          </Link>
        )}

        <div className="listing-filter-primary-field d-md-flex align-items-center justify-content-center">
          <Box className="lfp-delivery-address d-flex align-items-center">
            <LocationInput
              noOptionsText={"Enter your location"}
              label={"Enter Delivery Address"}
              value={props.address}
              onChange={props.setAddress}
              onChangeLocationOptions={onSelectLocation}
              className="location-input"
              inputProps={{
                InputProps: {
                  startAdornment: (
                    <>
                      <img src="../assets/images/icon-delivery-truck.svg" />
                    </>
                  ),
                },
                inputRef: props.addressRef,
              }}
            />

            {props.isShowZip ? (
              <div className="lfp-zip">
                <TextField placeholder="Zip" value={props.zip} variant="outlined" disabled={true} />
              </div>
            ) : null}
          </Box>

          <div className="d-flex align-items-center">
            <Box className="lfp-date">
              <DatePicker
                value={props.date}
                onChange={props.setDate}
                label={"Select a date and time"}
                ref={props.datepickerRef}
              />
            </Box>
            <Box className="lfp-head-count">
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={props.headCount}
                placeholder="Head Count"
                onChange={onChangeHeadCount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="../assets/images/head.svg" className="img-fluid" alt="Head Count" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};
