//this page is for showing all the Reviews from the customers
import React, { useState } from "react";
import SideMenu from "../../../components/organisms/sidebar";
import LoggedHeader from "../../Common/loggedHeader";
import { Table, Select, MenuItem,Button,Menu} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatter } from "../../Common/commonUtils";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_REVIEW_ADMIN } from "../../../Graphql/queries";
import { APPROVE_REVIEW } from "../../../Graphql/mutations";
import Pagination from "@mui/material/Pagination";
import { toggleSnackbar,authVar } from "../../../ReactiveVariables/index";
import Stack from "@mui/material/Stack";
import DialogBox from "../../Common/dialogBox";
import Switch from '@mui/material/Switch';
import moment from "moment";
import { styled } from '@mui/material/styles';
import {useNavigate } from "react-router-dom";

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ?  '#DC3545': 'green',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#DC3545',
      boxSizing: 'border-box',
    },
  }));

export default function Reviews(){
  const navigate = useNavigate()
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDialog, setOpenDialog] = useState(false);
  const [checkedValue,setCheckedValue] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const reviewVariable = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    adminFlag: true,
    search:search
  }
  const {data,loading}= useQuery(LIST_REVIEW_ADMIN,{variables:reviewVariable})
  const [approveReview] = useMutation(APPROVE_REVIEW,{refetchQueries: ["ListReviews"],
  onCompleted:(res)=>{
    setSelectedItem(null)
  }})
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };
  //pagination
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  //for activating the switch to approve and disapprove reviews
  function toggleSwitch(){
    let requestData={
        reviewId: selectedItem?._id,
        approvalStatus: checkedValue, 
        customerId: selectedItem?.customerId,
        catererId: selectedItem?.catererId
    }
    try { approveReview({ variables: requestData })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: checkedValue?"Review activated successfully":"Review deactivated successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
  }
  return (
    <div>
      <LoggedHeader title="Review" user="admin" />
      <SideMenu selected="Reviews" user="admin" />

      {openDialog && (<DialogBox
        setOpenDialog={setOpenDialog}
        handleDelete={toggleSwitch}
        openDialog={openDialog}
        title="Update Review"
        discription={checkedValue?"Are you sure you want to activate this review?":"Are you sure you want to deactivate this review?"}
      />
      )}
      <div className="contentWrap">
        <div className="contentWrapInner promocode-table">
          <div className="location-head">
            <div className="location-head-title justify-content-between">
              {data?.listReviews?.count!== undefined?<h5>{`${data?.listReviews?.count} Reviews`}</h5>: <p> Loading...</p>}
              <div className="d-flex align-items-center">
              <input
                placeholder="Search by Caterer Name / Customer Name"
                className="form-control"
                value={search}
                onChange={(e) => {
                  setPage(1);
                  setSearch(e.target.value);
                }}
              />
              </div>
            </div>
          </div>
          <TableContainer>
            <Table
              className="commonTabel"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Date & Time</TableCell>
                  <TableCell align="center">Rating</TableCell>
                  <TableCell width="30%">Review</TableCell>
                  <TableCell >Customer</TableCell>
                  <TableCell >Caterer</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : data && data?.listReviews?.reviewsList?.length > 0 ? (
                  data &&
                  data?.listReviews?.reviewsList?.map((item) => (
                    <TableRow >
                      <TableCell component="th" scope="row">
                        {moment(item?.reviewDate).format('llll')}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                      {item?.star}
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {item?.comment}
                      </TableCell>
                      <TableCell style={{cursor:"pointer"}} onClick={()=>{navigate(`/customers/${item?.customerId}`)}} component="th" scope="row">
                        {item?.customerName}
                      </TableCell>
                      <TableCell  component="th" scope="row">
                        {item?.catererName}
                      </TableCell>
                      <TableCell align="center">
                        <Button>
                            <AntSwitch onChange={(e)=>{setOpenDialog(true);setCheckedValue(e.target.checked);setSelectedItem(item)}} 
                            size={'70'} checked={item?.approvalStatus} inputProps={{ 'aria-label': 'ant design' }} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : search ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No data Found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No list added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select
              // variant="outlined"
              onChange={handlePageInputChange}
              value={rowsPerPage}
            >
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listReviews?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
      </div>
    </div>
  )
}