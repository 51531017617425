import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import { formatter } from "../../../Modules/Common/commonUtils";
import { ICartItem } from "@services/redux/order";
import { ROUTES } from "@constants/routes.constants";

interface IReviewOrderCart {
  cartDetails: ICartItem[];
  catererSlug: string;
  isEdit: boolean;
}

export function ReviewOrderCart({ cartDetails, catererSlug, isEdit }: IReviewOrderCart) {
  const navigate = useNavigate();

  function onAddEditItems() {
    navigate(`${ROUTES.caterers}/${catererSlug}`);
  }

  return (
    <div className="review-order-item">
      <h6>Your Order</h6>

      {cartDetails.map((cartItem, cartItemKey) => {
        return (
          <div className="roi-single" key={cartItemKey}>
            <div className="d-flex justify-content-between">
              <div className="col-md-9 roi-details">
                <h6>{cartItem.itemName}</h6>
                {cartItem.addOns.length > 0 && (
                  <div className="roi-subaddon-wrap">
                    {cartItem.addOns.map((addon, addonKey) => (
                      <p className="roi-subaddon" key={addonKey}>
                        {addon.addOnName}
                        {addon.subAddOns.length > 0 && <b>: </b>}
                        {addon.subAddOns.map((subAddon, subAddonKey) => (
                          <span key={subAddonKey}>
                            {subAddon.subAddOnName}
                            <b> / </b>
                          </span>
                        ))}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-md-3 roi-count-price">
                <div className="d-flex align-items-center justify-content-end">
                  <span style={{ color: "#FD4E3B", flexShrink: 0 }}>
                    {cartItem.quantity} X&nbsp;
                  </span>
                  {formatter.format(cartItem.cartTotalPrice)}
                </div>
              </div>
            </div>
            {cartItem.cartInstruction && (
              <div className="roi-sp-instr">
                <h6>Special Instructions</h6>
                <p>{cartItem.cartInstruction}</p>
              </div>
            )}
          </div>
        );
      })}
      {!isEdit && (
        <Button className="add-card-btn review-add-update-btn" onClick={onAddEditItems}>
          <img src="../assets/images/red_plus.svg" className="img-fluid" alt="Icon" />
          Add or Update Items
        </Button>
      )}
    </div>
  );
}
