import React from "react";

import "./cart-header.style.css";

interface ICartHeaderProps {
  cartItemsQuantity: number;
  onClose(): void;
}

export const CartHeader = ({ cartItemsQuantity, onClose }: ICartHeaderProps) => {
  return (
    <div>
      <h6 className="cart-view-title">
        {`${cartItemsQuantity} Items in cart`}
        <img
          src="../assets/images/shopping-cart.svg"
          className="img-fluid d-none d-xl-block"
          alt="Shopping Cart"
        />
        <img
          onClick={onClose}
          src="../assets/images/modal-close.svg"
          className="img-fluid d-block d-xl-none"
          alt="Shopping Cart"
        />
      </h6>
    </div>
  );
};