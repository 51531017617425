import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { ICustomItemForm } from "./custom-item-form/custom-item-form";
import { GET_ONE_ORDER } from "Graphql/queries";
import { useParams } from "react-router-dom";
import { toggleSnackbar, userDeatils } from "ReactiveVariables";
import {
  calculateCaterCash,
  driverTipCalculator,
  pricePerHead,
  salesTaxCalculator,
} from "Modules/Common/commonUtils";
import { IGetOrderResponse } from "typings/order.api";
import { CREATE_ORDER } from "Graphql/mutations";

export function useViewOrderState() {
  const { id } = useParams();
  const user: any = useReactiveVar(userDeatils);

  const { data: orderValues, loading: isOrderLoading } = useQuery<IGetOrderResponse>(GET_ONE_ORDER, {
    fetchPolicy: "network-only",
    variables: {
      customerId:
        user?.data?.currentUserDetails?._id ||
        user?.data?.createUserIdentity?._id ||
        user?.data?.login?._id,
      orderId: id,
    },
  });

  const [createCart, { loading: isCartSubmitting }] = useMutation(CREATE_ORDER, {
    refetchQueries: [
      {
        query: GET_ONE_ORDER,
        variables: {
          customerId:
            user?.data?.currentUserDetails?._id ||
            user?.data?.createUserIdentity?._id ||
            user?.data?.login?._id,
          orderId: id,
        },
      },
    ],
  });

  async function onCreateCustomOrder(params: ICustomItemForm) {
    const customItemTotalPrice = +params.pricePerUnit * +params.quantity;
    let totalFoodAndBeverage = orderValues?.getOneOrder?.totalPrice + customItemTotalPrice;

    let driverTipTypeFlag;
    if (orderValues?.getOneOrder?.driverTipType === "Percentage") {
      driverTipTypeFlag = false;
    } else {
      driverTipTypeFlag = true;
    }

    let salesTax = salesTaxCalculator(
      totalFoodAndBeverage +
        orderValues.getOneOrder.deliveryFee +
        orderValues.getOneOrder.tablewareTotal,
      orderValues?.getOneOrder?.salesTaxPercentage,
    );
    let caterCashTotal = calculateCaterCash(
      totalFoodAndBeverage,
      orderValues?.getOneOrder?.catererDetails,
    );

    let drivertipValue = parseFloat(
      driverTipCalculator(
        orderValues?.getOneOrder,
        orderValues?.getOneOrder?.catererDetails,
        totalFoodAndBeverage +
          orderValues.getOneOrder.deliveryFee +
          orderValues.getOneOrder.tablewareTotal,
      ),
    );
    let grandTotalValue =
      totalFoodAndBeverage + drivertipValue + orderValues.getOneOrder.deliveryFee + salesTax;

    try {
      return await createCart({
        variables: {
          data: {
            cartType: "Custom",
            catererId: orderValues?.getOneOrder?.catererId,
            cartInstruction: params.description,
            itemName: params.itemName,
            orderId: id,
            orderStatus: orderValues?.getOneOrder?.orderStatus,
            orderPlaced: true,
            cartPricePerPerson: parseFloat(params.pricePerUnit),
            quantity: +params.quantity,
            cartTotalPrice: customItemTotalPrice,
            totalPricePerUnit: parseFloat(params?.pricePerUnit),
            totalPrice: totalFoodAndBeverage,
            driverTip: drivertipValue,
            grandTotal: grandTotalValue,
            deliveryFee: orderValues.getOneOrder.deliveryFee,
            salesTaxAmount: salesTax,
            totalPerHead: pricePerHead(grandTotalValue, orderValues?.getOneOrder?.headCount),
            caterCashTotal: caterCashTotal,
          },
        },
      }).then(() => {
        toggleSnackbar({
          status: true,
          message: "Added to cart",
          variant: "success",
        });
      });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  return {
    orderValues,
    isOrderLoading,
    isCartSubmitting,
    onCreateCustomOrder,
  };
}
