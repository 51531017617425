import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client/react/hooks";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import debounce from "lodash/debounce";

import { toggleSnackbar, userDeatils } from "../../ReactiveVariables/index";
import { CHECK_AVL_DATE, GET_ONE_USER_FOR_CUSTOMER } from "../../Graphql/queries";
import { calcDistanceNew, convertTimeFormat } from "../../Modules/Common/commonUtils";
import { RootState, useAppDispatch, useAppSelector } from "../../services/redux";
import {
  ICartItem,
  IOrderPayload,
  clearDraftOrder,
  clearUnavailableCartItems,
  updateDraftOrder,
  updateEditOrder,
} from "../../services/redux/order";
import { ICatererDetailsResponse } from "typings/caterer.api";

const DESKTOP_SCREEN_W = 1200;

interface ISearchParams {
  street: string;
  coordinates: [number, number];
  zip: string | null;
  location: string;
  city: string;
  suit: string;
  instruction: string;
  addressId: string;
  deliveryDate: Date | null;
  headCount: number;
  contactPerson: string;
  contactNo: string;
}

export function useCatererDetailsState() {
  const [isAvailableAlert, setIsAvailableAlert] = useState(false);
  const [isAvailable, setIsAvailable] = useState<"date" | "address" | true>(true);
  const [catererId, setCatererId] = useState("");
  const [isMobileCartOpen, setIsMobileCartOpen] = useState(false);
  const [catererConflictData, setCatererConflictData] = useState<Partial<IOrderPayload> | null>(
    null,
  );
  const [alertStatus, setAlertStatus] = useState<"date" | "zip" | "conflict" | null>(null);
  const [search, setSearch] = useState<ISearchParams>({
    street: "",
    coordinates: [0, 0],
    zip: null,
    location: "",
    city: "",
    suit: "",
    instruction: "",
    addressId: "",
    deliveryDate: null,
    headCount: 1,
    contactPerson: "",
    contactNo: "",
  });

  const dispatch = useAppDispatch();

  const { id: slug } = useParams();

  const [searchParams] = useSearchParams();

  const isEdit = useMemo(() => {
    return !!searchParams.get("edit");
  }, [searchParams]);

  const { state: locationData } = useLocation();

  const order = useAppSelector((state: RootState) =>
    isEdit ? state.order.edit : state.order.draft,
  );

  const unavailableCartItems = useAppSelector((state) => state.order.unavailableCartItems);

  const updateAction = useMemo(() => {
    return isEdit ? updateEditOrder : updateDraftOrder;
  }, [isEdit]);

  // TODO: polish this later
  const user = useReactiveVar(userDeatils);

  // TODO: refactor later
  const customerId = useMemo(() => {
    return (
      locationData?.customerId ||
      user?.data?.currentUserDetails?._id ||
      user?.data?.createUserIdentity?._id ||
      user?.data?.login?._id
    );
  }, [locationData, user]);

  useEffect(() => {
    if (slug === order.catererSlug) {
      return setSearch((s) => ({
        street: order.street,
        coordinates: [order.lng, order.lat],
        city: order.city,
        location: order.location,
        suit: order.suit,
        instruction: order.instruction,
        zip: order.zip,
        addressId: order.addressId,
        deliveryDate: order.deliveryDate ? new Date(order.deliveryDate) : null,
        headCount: order.headCount,
        contactPerson: order.contactPerson,
        contactNo: order.contactNo,
      }));
    }

    const payload: Partial<IOrderPayload> = {};
    const searchPayload: Partial<ISearchParams> = {};

    if (!locationData) {
      return;
    }

    if (locationData.address) {
      payload.street = locationData.address.description
        ? locationData?.address.description
        : locationData?.address;
      searchPayload.street = payload.street;
    }
    if (locationData.date) {
      payload.deliveryDate = locationData.date;
      searchPayload.deliveryDate = new Date(locationData.date);
    }
    if (locationData.count) {
      payload.headCount = locationData.count;
      searchPayload.headCount = payload.headCount;
    }
    if (locationData.cordinates) {
      payload.lng = locationData.cordinates[0];
      payload.lat = locationData.cordinates[1];
      searchPayload.coordinates = locationData.cordinates;
    }
    if (locationData.zipCode) {
      payload.zip = locationData.zipCode;
      searchPayload.zip = locationData.zipCode;
    }
    if (locationData.contactPerson) {
      searchPayload.contactPerson = locationData.contactPerson;
    }
    if (locationData.contactNo) {
      searchPayload.contactNo = locationData.contactNo;
    }
    if (locationData.locationName) {
      searchPayload.location = locationData.locationName;
    }

    if (locationData.suit) {
      searchPayload.suit = locationData.suit;
    }

    if (locationData.city) {
      searchPayload.city = locationData.city;
    }
    if (locationData.savedAddressId) {
      searchPayload.addressId = locationData.savedAddressId;
    }
    if (locationData.instructions) {
      searchPayload.instruction = locationData.instructions;
    }

    setSearch((state) => ({
      ...state,
      ...searchPayload,
    }));
    onUpdateOrder(payload);
  }, []);

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data: catererData } = useQuery<ICatererDetailsResponse>(GET_ONE_USER_FOR_CUSTOMER, {
    fetchPolicy: "cache-and-network",
    variables: { getCatererDetailsId: slug, agsType: "slug" },
    onCompleted: (res) => {
      setCatererId(res?.getCatererDetails?._id);
    },
  });

  const [checkCatererAvailabilityRequest] = useLazyQuery(CHECK_AVL_DATE);

  function onCloseAvailabilityModal() {
    setIsAvailableAlert(false);
  }

  useEffect(() => {
    if (search.deliveryDate && search.street && catererData && catererId) {
      checkCatererAvailability({
        coordinates: search.coordinates,
        deliveryDate: search.deliveryDate,
      });
    }
  }, [search.deliveryDate, search.coordinates, catererData, catererId]);

  useLayoutEffect(() => {
    const resizeListener = debounce(() => {
      if (window.innerWidth >= DESKTOP_SCREEN_W && isMobileCartOpen) {
        onCloseMobileCart();
      }
    }, 100);

    window.addEventListener("resize", resizeListener);

    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  async function checkCatererAvailability({ deliveryDate, coordinates }) {
    const response = await checkCatererAvailabilityRequest({
      variables: {
        data: {
          catererId,
          deliveryDate,
          deliveryDay: moment(deliveryDate).format("dddd"),
          timeZone: timeZone,
          preprationTime: catererData?.getCatererDetails?.preprationTime,
          deliveryTime: convertTimeFormat(deliveryDate),
          locationRadius: parseFloat(
            calcDistanceNew(coordinates, catererData.getCatererDetails.location),
          ),
        },
      },
    });

    const message = response.data && response.data.chkCatererAvbl ? response.data.chkCatererAvbl.message : "";

    if (!message) {
      return;
    }

    toggleSnackbar({
      status: true,
      message,
      variant: message === "Caterer is available" ? "success" : "error",
    });

    switch (message) {
      case "Caterer is available":
        setIsAvailable(true);
        setIsAvailableAlert(false);
        break;
      case "Caterer is not available in this date/time":
        setIsAvailable("date");
        setIsAvailableAlert(true);
        break;
      case "Caterer is not available in this address you selected":
        setIsAvailable("address");
        setIsAvailableAlert(true);
        break;
      default:
        setIsAvailable(true);
        setIsAvailableAlert(false);
    }
  }

  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function onOpenMobileCart() {
    setIsMobileCartOpen(true);
    scrollTop();
  }

  function onCloseMobileCart() {
    setIsMobileCartOpen(false);
  }

  function onUpdateOrder(params: Partial<IOrderPayload>) {
    if (order.catererId && order.catererId === catererId) {
      dispatch(updateAction(params));
    }
  }

  function onSelectAddressOptions({ zip, coordinates, city }) {
    setSearch((s) => ({
      ...s,
      zip,
      lng: coordinates.length ? coordinates[0] : null,
      lat: coordinates.length ? coordinates[1] : null,
      city,
    }));
    onUpdateOrder({
      zip,
      lng: coordinates.length ? coordinates[0] : null,
      lat: coordinates.length ? coordinates[1] : null,
      city,
    });
  }

  function onUpdateAddress(value) {
    setSearch((s) => ({
      ...s,
      street: value,
    }));
    onUpdateOrder({
      street: value,
    });
  }

  function onUpdateDate(value: Date) {
    setSearch((s) => ({
      ...s,
      deliveryDate: value,
    }));
    onUpdateOrder({
      deliveryDate: value ? value.toISOString() : "",
    });
  }

  function onUpdateHeadCount(value) {
    setSearch((s) => ({
      ...s,
      headCount: +value,
    }));
    onUpdateOrder({
      headCount: +value,
    });
  }

  function onResolveCatererConflict() {
    dispatch(clearDraftOrder());
    dispatch(updateAction(catererConflictData));
    setCatererConflictData(null);
    setAlertStatus(null);
  }

  function onUpdateCart(cart: ICartItem[]) {
    const payload: Partial<IOrderPayload> = {
      customerId,
      catererId,
      catererSlug: slug,
      cartDetails: cart,
      catererName: catererData.getCatererDetails.businessName,
      tablewareCharged: catererData.getCatererDetails.tablewareCharged,
      street: search.street,
      zip: search.zip,
      city: search.city,
      location: search.location,
      addressId: search.addressId,
      lng: search.coordinates[0],
      lat: search.coordinates[1],
      suit: search.suit,
      instruction: search.instruction,
      headCount: search.headCount,
      deliveryDate: search.deliveryDate ? search.deliveryDate.toISOString() : "",
      contactPerson: search.contactPerson,
      contactNo: search.contactNo,
    };

    if (!isEdit && order.catererId && order.catererId !== catererId) {
      setAlertStatus("conflict");
      setCatererConflictData({
        ...payload,
        cartDetails: payload.cartDetails.filter((item) => item.catererId === catererId),
      });
      return;
    }

    dispatch(updateAction(payload));
  }

  function onCloseUnavailableItemsModel() {
    dispatch(clearUnavailableCartItems());
  }

  return {
    isEdit,
    customerId,
    mobileCartModal: {
      isOpen: isMobileCartOpen,
      onOpen: onOpenMobileCart,
      onClose: onCloseMobileCart,
    },
    search: {
      headCount: {
        value: search.headCount,
        onChange: onUpdateHeadCount,
      },
      deliveryDate: {
        value: search.deliveryDate,
        onChange: onUpdateDate,
      },
      street: {
        value: search.street,
        onChange: onUpdateAddress,
      },
      zip: {
        value: search.zip,
      },
      coordinates: {
        value: search.coordinates,
      },
      onSelectAddressOptions,
    },
    cart: {
      currentCaterer: order.catererName,
      newCaterer: catererData ? catererData.getCatererDetails.businessName : "",
      onResolveCatererConflict,
    },
    alert: {
      alertStatus,
      setAlertStatus,
    },
    availability: {
      isAvailable,
      isAvailableAlert,
      onCloseAvailabilityModal,
    },
    unavailableItems: {
      items: unavailableCartItems,
      onClose: onCloseUnavailableItemsModel,
    },
    order: order,
    catererId,
    catererData,
    onUpdateOrder,
    onUpdateCart,
  };
}
