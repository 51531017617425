import React from "react";
import { formatter } from "../../../Modules/Common/commonUtils";

interface ICatererMainInfoProps {
  name: string;
  cash: number;
  reviewRate: number;
  reviewCount: number;
  address: string;
  zip: string;
  phone: string;
  minOrder: number;
  deliveryFee: string;
}

export function CatererMainInfo({
  name,
  cash,
  reviewRate,
  reviewCount,
  address,
  zip,
  phone,
  minOrder,
  deliveryFee,
}: ICatererMainInfoProps) {
  return (
    <div className="container">
      <h2 className="h2">{name}</h2>
      <ul className="d-flex detail-main-points">
        <li className="dmp-bg">
          <img src="../assets/images/cashflow.svg" className="img-fluid" alt="Icon" />
          <b>{cash || 5}%</b>
          CaterCash
        </li>
        {reviewRate ? (
          <li className="dmp-bg">
            <img src="../assets/images/Star1.svg" className="img-fluid" alt="Icon" />
            <b>{reviewRate.toFixed(1)}</b>({reviewCount}Review{reviewCount === 1 ? "" : "s"})
          </li>
        ) : null}
        <li className="dmp-locations">
          <span className="dmp-bg">
            <img src="../assets/images/Icon-Location1.svg" className="img-fluid" alt="Icon" />
          </span>
          {address},{zip}
        </li>
        <li>
          <span className="dmp-bg">
            <img src="../assets/images/call.svg" className="img-fluid" alt="Icon" />
          </span>
          {phone}
        </li>
        <li>
          <span className="dmp-bg">
            <img src="../assets/images/dollar-sign.svg" className="img-fluid" alt="Icon" />
          </span>
          Min Order: {formatter.format(minOrder)}
        </li>
        <li>
          <span className="dmp-bg">
            <img src="../assets/images/truck.svg" className="img-fluid" alt="Icon" />
          </span>
          Delivery Fee: {deliveryFee}
        </li>
      </ul>
    </div>
  );
}
