import React from "react";

import { Skeleton } from "@mui/material";

export function CatererLoader() {
  return (
    <div className="cater-list">
      <div className="container">
        <div className="row">
          {[0, 1, 2, 3, 4, 5, 6].map((item) => (
            <div key={item} className="col-md-6 col-lg-4 cater-list-item-outer">
              <div className="cater-list-item">
                <div className="cater-list-item-img">
                  <Skeleton variant="rectangular" animation="wave" height={250} />
                </div>
                <div className="cater-list-item-cnt">
                  <h6>
                    <Skeleton variant="rectangular" animation="wave" height={22} />
                  </h6>
                  <h6>
                    <Skeleton variant="rectangular" animation="wave" height={22} />
                  </h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
