// this page is for adding cuisines
import React from 'react';
import Drawer from '@mui/material/Drawer';
import {TextField,Button,CircularProgress} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {toggleSnackbar,authVar } from "../../../ReactiveVariables/index";
import { useMutation } from '@apollo/client';
import { CREATE_CUISINES,UPDATE_CUISINES } from '../../../Graphql/mutations';
import {useNavigate } from "react-router-dom";
export default function AddCategories(props){
  const navigate = useNavigate()
    const schema = yup.object().shape({
        cuisineName: yup.string().required("Cuisine name is required"),
      });
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({ resolver: yupResolver(schema)});
      const [createCuisines,{loading}] = useMutation(CREATE_CUISINES,{refetchQueries:['ListCuisineType'],
    onCompleted:(res)=>{
        props?.setOpenCuisines(false)
    }})
    const [updateCuisines,{loading:updateLoading}] = useMutation(UPDATE_CUISINES,{refetchQueries:['ListCuisineType'],
    onCompleted:(res)=>{
        props?.setOpenCuisines(false)
        props?.setSelectedItem(null)
    }})

    // cuisines add functionality
      function onSubmit(params){
        params["_id"]= props?.selectedItem?._id
        try {
            props?.selectedItem?._id?updateCuisines({variables: {id:props?.selectedItem?._id,data:params }}) 
            .then((res) => {
                toggleSnackbar({
                  status: true,
                  message: "Cuisines updated successfully",
                  variant: 'success'
                });
              })
              .catch((err) => {
                const message = err && err.message;
                if(message==="Not authenticated"){
                  localStorage.clear();
                  authVar({
                    loaded: true,
                    auth: false,
                  });
                  navigate("/admin", { state: "noAuth" })
                }else{
                toggleSnackbar({
                  status: true,
                  message: message,
                  variant: "error",
                });
              }
              }):createCuisines({ variables: {data:params } })
              .then((res) => {
                toggleSnackbar({
                  status: true,
                  message: "Cuisines created successfully",
                  variant: 'success'
                });
              })
              .catch((err) => {
                const message = err && err.message
                if(message==="Not authenticated"){
                  localStorage.clear();
                  authVar({
                    loaded: true,
                    auth: false,
                  });
                  navigate("/admin", { state: "noAuth" })
                }else{
                toggleSnackbar({
                  status: true,
                  message: message,
                  variant: "error",
                });
              }
              });
            } catch (err) {
              const message = err && err.message;
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            }
      }
    return (
        <Drawer className='common-drawer'
      anchor={"right"}
      open={props.openCuisines}
      onClose={() => props.setOpenCuisines(false)}
    >
       <div className='drawer-head'>
            <h4 className="h4"><img src="../assets/images/icon-contact-info.svg" className="img-fluid" alt="Contact Information Icon" />{props?.selectedItem?._id?"Edit Cuisines":"Add Cuisines"}</h4><span style={{ cursor: 'pointer' }} onClick={() => {props.setOpenCuisines(false);props?.setSelectedItem(null)}}><img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" /></span>
          </div>
          <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
        >
             <div className='create-step-form'>
          <div className='container-fluid'>
            <div className='textBox'> 
          <TextField
                  id="createAccountAdminName"
                  defaultValue={props?.selectedItem?.cuisineName}
                  label="CUISINE NAME*"
                  autoFocus
                  variant="outlined"
                  className="textField allFeild"
                  {...register("cuisineName")}
                />
                {errors.cuisineName && <span className="error-msg">{errors.cuisineName.message}</span>}
          </div>
          </div>
          </div>
          <div className="createStepBtn">
            <Button
              className="cancelBtn"
              variant="contained"
              color="secondary"
              onClick={() =>{ props.setOpenCuisines(false);props?.setSelectedItem(null)}}
            >
              Cancel
            </Button>
            <Button
              className="saveBtn"
              variant="contained"
              type="submit"
              color="primary"
            >
              {loading||updateLoading ? <CircularProgress color="inherit" size={20} /> : props?.selectedItem?._id?"Update":"Add"}
            </Button>
          </div>
            </form>
        </Drawer>
    )
}