import React from "react";
import LoginHeader from "../../Modules/Login/LoginHeader";
import Footer from "../../Modules/Common/footer";
import { formatter } from "../../Modules/Common/commonUtils";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useCheckoutSuccessState } from "./checkout-success.state";

export function CheckoutSuccess() {
  const { date, time, timeZone, catererName, caterCash, onNewOrder, onViewOrder } =
    useCheckoutSuccessState();

  return (
    <>
      <LoginHeader />
      <div className="order-confirm-screen">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-lg-5 col-md-6 valign" style={{ background: "#F5F5F5" }}>
              <div className="ocs-img ">
                <img
                  style={{ cursor: "pointer" }}
                  src={"../assets/images/orderConfirmed.svg"}
                  className="img-fluid"
                  alt="img"
                />
                <h5 className="h5">Ready for Delivery...</h5>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 valign">
              <div className="ocs-text">
                <h1 className="h1">Thank you for Ordering</h1>
                <div className="mb-2">
                  <p>
                    Your Order has been placed with
                    <b className="ms-1">{catererName}</b> for{" "}
                    <b>
                      {date} at {time}, {timeZone}.
                    </b>
                  </p>
                  <p>You will receive an email once the order is confirmed by the Caterer. </p>
                </div>
                <div>
                  <p>
                    {`With the completion of this order you will earn ${formatter.format(
                      +caterCash,
                    )} Amazon Gift Card.`}
                    &nbsp;<Link to={"/CaterCash"}>Check CaterCash Balance</Link>
                  </p>
                </div>
                <Button
                  className="saveBtn me-3 mt-4"
                  variant="outlined"
                  color="primary"
                  onClick={onNewOrder}>
                  Place a New Order
                </Button>
                <Button
                  className="saveBtn mt-4"
                  variant="outlined"
                  color="primary"
                  onClick={onViewOrder}>
                  View order
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
