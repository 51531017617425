// this drawer is for editing the bank info
import React from 'react';

import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import TextField from "@mui/material/TextField";
import { Button, FormGroup, FormControlLabel, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useMutation } from '@apollo/client';
import { yupResolver } from "@hookform/resolvers/yup";
import { UPDATE_CATERE } from '../../../Graphql/mutations';
import * as yup from "yup";
import {
  toggleSnackbar,authVar,userDeatils
} from "../../../ReactiveVariables/index";
import { postiveValueConverter } from'../../Common/commonUtils';
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
const StyledCheckbox = styled(Checkbox)`
  svg {
    font-size: 22px;
  }
`;

export default function BankInfo(props) {
  const navigate= useNavigate()
  const user = useReactiveVar(userDeatils);
  const userType =
  user?.data?.login?.userType || user?.data?.currentUserDetails?.userType || user?.data?.createUserIdentity?.userType || user?.data?.createCaterer?.userType;
  const currentData =
    props?.data?.getOneCaterer
  const [newMail, setNewMail] = useState(currentData?.addEmailFlag ?? false)
  const schema = yup.object().shape({
    additionalEmail: yup.string().email("Please enter a valid email address"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [updateCaterer, { loading }] = useMutation(UPDATE_CATERE, { refetchQueries:['GetOneCaterer'],
    onCompleted: (res) => {
      props?.setOpenBank(false)
    }
  });

  // for allowing only positive values
  const handleInputChange = (event) => {
    event.target.value = postiveValueConverter(event, 'withoutDecimal');
  };

  // this function is for updating the banking info to the backend
  function onSubmit(params) {
    params["addEmailFlag"] = newMail
    try {
      updateCaterer({ variables: { id: props?.userData?._id, catererId: props?.data?.getOneCaterer?.catererUsers[0]?.catererId || props?.userData?.catererId, data: params } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Banking Info updated successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (

    <Drawer className='common-drawer'
      anchor={"right"}
      open={props.openBank}
      onClose={() => props.setOpenBank(false)}
    >

      <form onSubmit={handleSubmit(onSubmit)}
        noValidate>
        <div className='drawer-head'>
          <h4 className="h4"><img src="../assets/images/icon-bank.svg" className="img-fluid" alt="Bank Details Icon" />Bank Details</h4><span style={{ cursor: 'pointer' }} onClick={() => props?.setOpenBank(false)}><img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" /></span>
        </div>
        <div className='create-step-form'>
          <div className='container-fluid'>
            <div className='row'>
              <div className="col-md-6 textBox">
                <TextField
                  id="outlined-basic"
                  autoFocus
                  label="LEGAL BUSINESS NAME"
                  defaultValue={currentData?.accountName}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("accountName")}
                />
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="outlined-basic"
                  label="BANK NAME"
                  defaultValue={currentData?.bankName}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("bankName")}
                />
              </div>
              <div className="col-12">
                {newMail && <div className="col-md-12 textBox">
                  <TextField
                    id="outlined-basic"
                    autoFocus
                    label="EMAIL"
                    defaultValue={currentData?.additionalEmail}
                    variant="outlined"
                    className="textField allFeild"
                    {...register("additionalEmail")}
                  />
                  {errors.additionalEmail && <span className="error-msg">{errors.additionalEmail.message}</span>}
                </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => props.setOpenBank(false)}
          >
            Cancel
          </Button>
          <Button
            className="saveBtn"
            variant="contained"
            color="primary"
            type="submit"
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              "Update"
            )}
          </Button>
        </div>
      </form>
    </Drawer>
  )
}