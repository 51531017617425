import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { MAX_PRICE_PER_ITEM, MAX_QUANTITY, ORDER_STATUS } from "@constants/order.constants";
import { USER_TYPE } from "@constants/user.constants";
import { Button, CircularProgress, InputAdornment } from "@mui/material";
import { InputForm } from "@components/molecules/input-form";
import { PATTERNS } from "@constants/patterns.constants";

interface ICustomItemFormProps {
  userType: USER_TYPE;
  orderStatus: ORDER_STATUS;
  isSubmitting: boolean;
  onSubmit(form: ICustomItemForm): Promise<void>;
}

export interface ICustomItemForm {
  itemName: string;
  pricePerUnit: string;
  quantity: string;
  description: string;
}

export function CustomItemForm({
  userType,
  orderStatus,
  isSubmitting,
  onSubmit,
}: ICustomItemFormProps) {
  if (userType !== "Caterer" || ![ORDER_STATUS.scheduled, ORDER_STATUS.new].includes(orderStatus)) {
    return null;
  }

  const { control, handleSubmit } = useForm<ICustomItemForm>({
    mode: "all",
  });

  const [isCustomFormOpen, setCustomFormOpen] = useState(false);

  function onToggleCustomForm() {
    setCustomFormOpen(!isCustomFormOpen);
  }

  const onCancel = () => {
    setCustomFormOpen(false);
  };

  async function onSendForm(form) {
    await onSubmit(form);
    onCancel();
  }

  return (
    <div className="order-view-add-custom-item">
      <h6 className="h6" onClick={onToggleCustomForm}>
        <div className="order-view-add-custom-item-btn">
          <img
            src={
              isCustomFormOpen ? "../assets/images/red_minus.svg" : "../assets/images/red_plus.svg"
            }
            className="img-fluid"
            alt="Icon"
          />
          {isCustomFormOpen ? "Close" : "Add"} Custom Item
        </div>
      </h6>
      {isCustomFormOpen && (
        <form onSubmit={handleSubmit(onSendForm)} noValidate  autoComplete="off">
          <div className="create-step-form">
            <h6>Add Custom Item</h6>
            <div className="row">
              <div className="col-md-6 textBox">
                <InputForm
                  control={control}
                  name={"itemName"}
                  variant="outlined"
                  rules={{
                    required: "Item name is required",
                    maxLength: {
                      value: 50,
                      message: "Item name should be less than 50 characters",
                    },
                  }}
                  label="Item Name*"
                  className="textField allFeild"
                />
              </div>
              <div className="col-md-3 textBox">
                <InputForm
                  control={control}
                  name={"quantity"}
                  variant="outlined"
                  rules={{
                    required: "Quantity is required",
                    validate: (value) => {
                      const isValid = PATTERNS.number.test(value);
                      if (!isValid) {
                        return "Enter correct Quantity";
                      }

                      if (+value > MAX_QUANTITY) {
                        return `Quantity should be less than ${MAX_QUANTITY}`;
                      }
                      return isValid;
                    },
                  }}
                  inputProps={{ type: "number", min: "0" }}
                  label="Quantity*"
                  className="textField allFeild"
                />
              </div>
              <div className="col-md-3 textBox">
                <InputForm
                  control={control}
                  name={"pricePerUnit"}
                  variant="outlined"
                  rules={{
                    required: "Price per Unit is required",
                    validate: (value) => {
                      const isValid = PATTERNS.money.test(value);
                      if (!isValid) {
                        return "Enter correct Price per Uni";
                      }

                      if (+value > MAX_PRICE_PER_ITEM) {
                        return `Price per Unit should be less than ${MAX_QUANTITY}`;
                      }
                      return isValid;
                    },
                  }}
                  inputProps={{ type: "number", min: "0" }}
                  label="Price per Unit*"
                  className="textField allFeild"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </div>
              <div className="col-12 textBox">
                <InputForm
                  control={control}
                  name={"description"}
                  variant="outlined"
                  rules={{
                    maxLength: {
                      value: 200,
                      message: "Description should be less than 200 characters",
                    },
                  }}
                  label="Description"
                  multiline
                  rows={3}
                  className="textField allFeild"
                />
              </div>
            </div>
            <div className="add-custom-btn">
              <Button
                className="cancel-btn"
                variant="outlined"
                onClick={onCancel}
                disabled={isSubmitting}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress color="inherit" size={20} /> : "Add"}
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
