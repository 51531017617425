//  this page is for showing the showing the thank you
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { userDeatils, authVar } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import * as yup from "yup";
import { Button, TextField, CircularProgress } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { INVITE_FRIENDS } from "../../Graphql/mutations";
import { toggleSnackbar } from "../../ReactiveVariables/index";
import { useNavigate } from "react-router-dom";
export default function ReferFriendCustomerView(props) {
  const navigate = useNavigate()
  const schema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .matches(
        /^([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]{2,})+(,\s*[a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]{2,})*$/,
        "Please enter valid email address"
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });
  const user = useReactiveVar(userDeatils);
  const auth = useReactiveVar(authVar);
  const userId =
    user?.data?.login?._id ||
    user?.data?.currentUserDetails?._id ||
    user?.data?.createUserIdentity?._id;

  const [inviteFriends, { loading }] = useMutation(INVITE_FRIENDS);

  const onSubmit = (params) => {
    const referedEmail = params?.email.split(",");

    let request = {
      customerId: userId,
      referedEmail: referedEmail,
    };
    try {
      inviteFriends({
        variables: request,
      })
        .then((res) => {
          reset();
          if (res.data.inviteFriends.status) {
            toggleSnackbar({
              status: true,
              message: "Invitation has been successfully sent.",
              variant: "success",
            });
          } else {
            toggleSnackbar({
              status: true,
              message: "You Cannot Invite Yourself.",
              variant: "error",
            });
          }
        })
        .catch((err) => {
          const message = err && err.message;
          if (message === "Not authenticated") {
            localStorage.clear()
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/login", { state: "noAuth" })
          } else {
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  return (
    <div className="contentWrap refer-friend-contentWrap">
      {/* <div className="cater-tab">
                <Tabs
                    aria-label="basic tabs example"
                >
                    <Tab
                        className="w-50 refer-friend-tab fw-semibold"
                        label="Refer a Friend"
                        style={{fontWeight:'600',color:'#000000'}}
                    />
                </Tabs>
            </div> */}
      <div className="container-fluid h-100">
        <div className="refer-friend">
          <img
            src={"../assets/images/referFriendCustomer.svg"}
            className="img-fluid d-block m-auto"
            alt="Refer a Friend"
          />
          <div className="refer-friend-inner valign">
            <h1 className="h1">
              Tell your friends about CaterPlace! Our referral program
            </h1>

            <p>
              Enjoying your new lifestyle with all that CaterCash? Perhaps you
              have a friend or co-worker that would also like to elevate their
              catering game?{" "}
            </p>
            <p className="fw-bold">
              Each friend you refer will receive 50 CaterCash points for their
              first order over $150, And you'll also receive 50 CaterCash
              points.
            </p>

            <div>
              <h6 className="h6 fw-bold">
                <img src="../assets/images/mail.svg" />
                Email
              </h6>
              <b>You can add multiple email addresses seperated by comma.</b>
              <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                className="login-box create-step-form w-100"
              >
                <div className="textBox">
                  <TextField
                    id="loginEmail"
                    label="Email"
                    autoFocus
                    variant="outlined"
                    className="textField allFeild"
                    placeholder="Enter the Email Address"
                    {...register("email")}
                  />
                  {errors.email && (
                    <span className="error-msg">{errors.email.message}</span>
                  )}
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  className="invite-btn"
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Invite Now"
                  )}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
