import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import { formatter } from "../../Modules/Common/commonUtils";

interface ITablewareProps {
  cost: number;
  isCharged: boolean;
  isRemoved: boolean;
  onAdd?(): void;
  onRemove?(): void;
}

export function Tableware({ cost, isCharged, isRemoved, onAdd, onRemove }: ITablewareProps) {
  return (
    <div className="other-charges-item">
      <label>
        Tableware{" "}
        {isCharged && onAdd && onRemove ? (
          <a className="back-link" onClick={isRemoved ? onAdd : onRemove}>
            {isRemoved ? "Add" : "Remove"}
          </a>
        ) : null}
      </label>
      {isRemoved ? (
        <b>No Tableware</b>
      ) : (
        <b>
          <Tooltip title="Tableware prices are set by the caterer" placement="top">
            <IconButton sx={{ padding: 0, marginRight: "5px", bottom: "1px" }}>
              <img src="../assets/images/icon-info.svg" className="img-fluid" alt="Delete Item" />
            </IconButton>
          </Tooltip>
          {!isCharged ? "Included" : formatter.format(cost)}
        </b>
      )}
    </div>
  );
}
