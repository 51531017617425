import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, IconButton } from "@mui/material";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { Close, Redeem } from "@mui/icons-material";
import Modal from '@mui/material/Modal';

import { SEARCH_ADDRESS } from "../../Graphql/queries";
import { userDeatils, authVar, retainFilter } from "../../ReactiveVariables/index";
import LoginHeader from "Modules/Login/LoginHeader";
import Footer from "Modules/Common/footer";
import { Landing } from "./components/landing";
import { ISearchLocationPayload, LocationInput } from "@components/atoms/location-search";
import { IAddressListItemResponse } from "typings/address.api";

export function Home() {
  const user = useReactiveVar(userDeatils);
  const auth = useReactiveVar(authVar);
  const [address, setAddress] = useState("");
  const [contactPerson, setContactNames] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [locationName, setLocationNames] = useState("");
  const [suit, setSuite] = useState("");
  const [instructions, setInstructions] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [savedAddressId, setSavedAddressId] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const customerId =
    user?.data?.currentUserDetails?._id ||
    user?.data?.createUserIdentity?._id ||
    user?.data?.login?._id;
  const userType = user?.data?.login?.userType || user?.data?.currentUserDetails?.userType;

  const [listAddressData] = useLazyQuery(SEARCH_ADDRESS, {
    fetchPolicy: "network-only",
  });
  const currentUserPromoField = user?.data?.currentUserDetails?.disableSignUpPromo;
  const loginPromoField = user?.data?.login?.disableSignUpPromo;
  const disableSignUpPromo = !currentUserPromoField ? loginPromoField : currentUserPromoField;

  function handleClosePopUp() {
    setShowPopUp(false);
  }

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!disableSignUpPromo) {
      setShowPopUp(true);
    }
  }, [disableSignUpPromo]);

  useEffect(() => {
    retainFilter({ data: null });
  }, []);

  function onSelectAddress(address: string) {
    setAddress(address);
  }

  function onSelectAddressOption({ zip, city, coordinates }: ISearchLocationPayload) {
    setCoordinates(coordinates);
    setZipCode(zip);
    setCity(city);
  }

  function onSelectSavedAddress(savedAddress: IAddressListItemResponse) {
    setSavedAddressId(savedAddress._id);
    setAddress(savedAddress.street);
    setCoordinates([parseFloat(savedAddress.longitude), parseFloat(savedAddress.latitude)]);
    setLocationNames(savedAddress.locationName);
    setSuite(savedAddress.floor);
    setInstructions(savedAddress.instruction);
    setContactNames(savedAddress.contactName);
    setContactNo(savedAddress.Phone);
    setZipCode(savedAddress.customerZip);
    setCity(savedAddress.customerCity);
  }

  const urlDate = {
    address,
    cordinates: coordinates,
    zipCode,
    savedAddressId,
    suit,
    instructions,
    locationName,
    contactPerson,
    contactNo,
    city,
  };

  async function fetchSavedAddress(search: string) {
    if (!customerId) {
      return null;
    }
    return listAddressData({
      variables: {
        customerId: customerId,
        keyWord: search,
      },
    });
  }

  return (
    <div>
      {userType !== "Admin" && showPopUp && (
        <Alert
          iconMapping={{
            success: <Redeem fontSize="large" />,
          }}
          style={{ borderRadius: "0" }}
          color="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClosePopUp}
              className="h-100">
              <Close fontSize="inherit" />
            </IconButton>
          }>
          <span className="d-flex h-100 justify-content-center align-items-center font-weight-bold">
            <span>Get a $100 in Amazon Rewards by placing your first four orders at CaterPlace.com!&nbsp;</span>

            <a className="back-link ps-4" href="#" onClick={handleOpenModal}>Learn more by clicking here!</a>
          </span>
        </Alert>
      )}

      <Modal open={showModal} onClose={handleCloseModal}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title h4"><b>Get CaterCash!</b></h4>
              <button type="button" className="btn-close" onClick={handleCloseModal}></button>
            </div>
            <div className="modal-body">
              <p className="font-weight-bold text-center">
                As a new customer, you will receive $100 in CaterCash over your first four orders
              </p>
              <ul className="list-unstyled pt-4 text-center">
                <li>&bull; Order #1: $10 CaterCash</li>
                <li>&bull; Order #2: $20 CaterCash</li>
                <li>&bull; Order #3: $30 CaterCash</li>
                <li>&bull; Order #4: $40 CaterCash</li>
              </ul>
              <p className="font-weight-bold text-center pt-4">
                Get started by placing your first order today!
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
            </div>
          </div>
        </div>
      </Modal>


      <LoginHeader />
      <div className="pageWrap">
        <div className="banner">
          <div className="container p-2">
            <div className="banner-content row">
              <div className="col-md-12 banner-content-text">
                <h1 className="h1">Catering That Always Rewards!</h1>
              </div>

              <div className="col-md-12 banner-search-point">
                <h4 className="h4">Start Your Catering Order Now</h4>
                <div className="banner-search d-block d-md-flex">
                  <div className="bg-white w-100">
                    <LocationInput
                      label="Delivery Address"
                      noOptionsText="Enter your location"
                      value={address}
                      onChange={onSelectAddress}
                      onSelectSavedAddress={onSelectSavedAddress}
                      onChangeLocationOptions={onSelectAddressOption}
                      fetchSavedAddress={fetchSavedAddress}
                      inputProps={{
                        InputProps: {
                          endAdornment:
                            userType === "Customer" && auth?.auth ? (
                              <a className="" href="/address">
                                Saved Address
                              </a>
                            ) : null,
                        },
                      }}
                    />
                  </div>
                  <Link
                    className="banner-search-link d-none d-md-block"
                    to={address && "/caterers"}
                    state={urlDate}>
                    <IconButton className="">
                      <img
                        src="../assets/images/searchIcon.svg"
                        className="img-fluid"
                        alt="Search Icon"
                      />
                    </IconButton>
                  </Link>
                  <Link className="d-block d-md-none" to={address && "/caterers"} state={urlDate}>
                    <Button
                      className="mobile-search d-block d-md-none w-100"
                      variant="contained"
                      color="primary">
                      Search
                    </Button>
                  </Link>
                </div>
                <div className="banner-points pt-5">
                  <div className="banner-points-item">
                    <img
                      src="../assets/images/icon-banner-cater.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    <div className="banner-points-item-text">
                      <h5>
                        Search & Find <br />
                        Caterers Near You
                      </h5>
                    </div>
                  </div>
                  <div className="banner-points-item">
                    <img
                      src="../assets/images/icon-banner-order.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    <div className="banner-points-item-text">
                      <h5>
                        Order & Schedule <br />
                        Delivery Online
                      </h5>
                    </div>
                  </div>
                  <div className="banner-points-item">
                    <img
                      src="../assets/images/icon-banner-cash.svg"
                      className="img-fluid"
                      alt="Icon"
                    />
                    <div className="banner-points-item-text">
                      <h5>
                        Earn & Redeem
                        <br />
                        CaterCash
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Landing isAuth={!!auth?.auth} />
        <Footer />
      </div>
    </div>
  );
}
