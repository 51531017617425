export const ROUTES = {
  root: "/",
  caterers: "/caterers",
  checkout: "/revieworder",
  orders: "/orders",
  confirmedOrder: "/orderconfirmed",
  customerLogin: "/customerlogin",
  login: "/login",
  admin: "/admin",
};
