import React, { useState } from "react";

import { Button, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { ToggledInput } from "../molecules/toggled-input";
import { formatter } from "../../Modules/Common/commonUtils";
import { PATTERNS } from "../../constants/patterns.constants";

interface IDriverTipProps {
  isTipMandatory: boolean;
  activeDriverTip: number;
  percentageTip?: number;
  onChangePercentageTip(value: number | null): void;
  onChangeCustomTip(value: number | null): void;
}

export function DriverTip({
  isTipMandatory,
  activeDriverTip,
  percentageTip,
  onChangePercentageTip,
  onChangeCustomTip,
}: IDriverTipProps) {
  const [isOpenOtherAmount, setOpenOtherAmount] = useState(false);
  const [customTip, setCustomTip] = useState("");

  function onChangeCustomAmount(value) {
    if (!PATTERNS.money.test(value)) {
      return;
    }
    setCustomTip(value);
  }

  function onOpenCustomAmount() {
    setOpenOtherAmount(true);
    if (activeDriverTip && !percentageTip) {
      return setCustomTip(activeDriverTip.toString())
    } 
    setCustomTip("");
  }

  function onCloseCustomAmount() {
    setOpenOtherAmount(false);
    setCustomTip("");
  }

  function onApplyCustomAmount() {
    if (percentageTip) {
      onChangePercentageTip(null);
    }
    onChangeCustomTip(+customTip || 0);
    onCloseCustomAmount();
  }

  function onUpdatePercentageTip(value) {
    onChangePercentageTip(percentageTip === value ? 0 : value);
    if (isOpenOtherAmount) {
      onCloseCustomAmount();
    }
  }

  return (
    <div className="common-cart-viewbox common-cart-viewbox-tip d-block">
      <div className="d-flex align-items-center justify-content-between">
        <label>
          Tip for Driver
          {isTipMandatory && (
            <Tooltip title="This was calculated as per the vendor tipping policy" placement="top">
              <IconButton>
                <img src="../assets/images/icon-info.svg" className="img-fluid" alt="Delete Item" />
              </IconButton>
            </Tooltip>
          )}
        </label>
        {!isTipMandatory && (
          <div className="tip-btn align-items-center">
            {[10, 15, 20].map((value) => {
              const onClick = () => onUpdatePercentageTip(value);
              return (
                <Button
                  key={value}
                  className={percentageTip === value ? "tip-select" : ""}
                  variant="outlined"
                  onClick={onClick}>
                  {value}%
                </Button>
              );
            })}
          </div>
        )}
        <b>{formatter.format(activeDriverTip)}</b>
      </div>
      {!isTipMandatory && (
        <>
          <div className="other-amount text-end">
            <a onClick={onOpenCustomAmount}>Other amount</a>
          </div>
          {isOpenOtherAmount && (
            <ToggledInput
              value={customTip}
              onChange={onChangeCustomAmount}
              onApply={onApplyCustomAmount}
              onClose={onCloseCustomAmount}
              inputClassName="money-input"
              inputProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
