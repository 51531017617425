import React from "react";
import { FormGroup, FormControlLabel, Checkbox, styled } from "@mui/material";

import { CheckCircleOutline, CheckCircle } from "@mui/icons-material";

interface ICatererOptionsProps {
  setOpenOptions(v: boolean): void;
  activeOptions: string[];
}

const StyledCheckbox = styled(Checkbox)`
  svg {
    color: #fd4e3b;
    font-size: 22px;
  }
`;

const PACKAGE_OPTION_LIST = [
  { id: "0", value: "Allows same day orders" },
  { id: "1", value: "Individually wrapped meals" },
  { id: "2", value: "Offers packages" },
];

export function CatererOptions({ activeOptions, setOpenOptions }: ICatererOptionsProps) {
  return (
    <div className="location-details-tile h-100">
      <div className="ld-titles">
        <h4 className="h4">
          <img src="../assets/images/icon-option.svg" className="img-fluid" alt="Options Icon" />
          Options
        </h4>
        <a className="ld-edit" onClick={() => setOpenOptions(true)}>
          <img src="../assets/images/icon-edit.svg" className="img-fluid" alt="Edit Icon" />
          Edit
        </a>
      </div>
      <div className="ld-options-wrap">
        {PACKAGE_OPTION_LIST.map((field, index) => {
          return (
            <div className="ld-options" key={index}>
              <FormGroup key={field.id}>
                <FormControlLabel
                  control={
                    <StyledCheckbox
                      disabled
                      checked={activeOptions.includes(field.value)}
                      color={"primary"}
                      icon={<CheckCircleOutline style={{ color: "#9F9E9E" }} />}
                      checkedIcon={<CheckCircle />}
                    />
                  }
                  label={field.value}
                />
              </FormGroup>
            </div>
          );
        })}
      </div>
    </div>
  );
}
