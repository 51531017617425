// this page is for listing the payments
import React, { useState } from "react";
import { Table, Select, MenuItem, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { userDeatils, toggleSnackbar } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import TableRow from "@mui/material/TableRow";
import { LIST_ALL_ORDERS,LIST_PAYMENT_CATERER } from "../../Graphql/queries";
import SideMenu from "../../components/organisms/sidebar";
import LoggedHeader from "../Common/loggedHeader";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DialogBox from "../Common/dialogBox";
export default function PaymentAdmin(props) {
  const user = useReactiveVar(userDeatils);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }); // currency formater
  const open = Boolean(anchorEl);
  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;
  const orderVariable = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
     //   userId:  catererId ,
   // customerId: userType === "Customer" ? userId : "",
     search:search
  };

  const { data, loading } = useQuery(LIST_PAYMENT_CATERER, {
    fetchPolicy: "network-only",
    variables: orderVariable,
  });
  // handling pagination
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };
  // change number of lisiting in a page
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // close menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {/* header for changing location and getting the id */}
      <LoggedHeader title="Payments" />
      {/* keeping the side bar selected */}
      <SideMenu
        selected={props?.admin?"Caterers":"Payments"}
        user={
          userType === "Customer"
            ? "customer"
            : userType === "Caterer"
            ? "caterer"
            : "admin"
        }
      />
      {/* open drawer for adding and editing menu item */}

      <div className={props.userType !== "Admin" ? "contentWrap" : ""}>
        <div className="contentWrapInner">
        <div className="location-head">
          <div className="location-head-title justify-content-between align-items-center ">
          {/* <div className="menu-head-title "> */}
            <h5>{data?.listPaymentCaterers?.count} Caterers</h5>
            <input
              placeholder="Search by Store Name & Contact Person"
               className="form-control"
              //className="ms-3"
              value={search}
              onChange={(e) => {
                setPage(1);
                setSearch(e.target.value);
              }}
            />
          </div>
         </div>
          {/* <div className="location-head">
            <div className="location-head-title menu-search-by-item justify-content-between">
             
             </div>
             </div> */}

          {
            <TableContainer>
              <Table
                className="commonTabel"
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Store Name and Location</TableCell>
                    <TableCell align="left">Contact Person</TableCell>
                   {!props?.admin&& <TableCell align="center">Settled</TableCell>}
                    <TableCell align="center">Outstanding</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p> Loading...</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : data?.listPaymentCaterers?.data &&
                    data?.listPaymentCaterers?.data?.length > 0 ? (
                    data?.listPaymentCaterers?.data &&
                    data?.listPaymentCaterers?.data?.map((item) => {
                    return(
                      <TableRow>
                        
                        <TableCell
                          component="th"
                          scope="row"
                          style={{cursor:"pointer"}}
                          onClick={() => navigate(`${item?._id}`,  { state: { title: item?.businessName, location: item?.locationName ,catererId:item?._id } })}
                        
                        >
                          <div className="d-flex">
                            <div className="me-3">
                          <img
                               style={{ cursor: "pointer" }}
                              src={item.imageUrl ?? '../assets/images/dummy-cater-profile-img.png'}
                              className="img-fluid cater-profile-img"
                              alt={item.imageUrl}
                           />
                           </div>
                           <div className="mt-2">
                       <label><b>{item?.businessName}</b></label>
                          <small>{item?.locationName}</small>
                          </div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                        {/* {item?.catererUsers.map((items)=>(items?.userDtls?.firstName&&items?.userDtls?.lastName)&&items?.userDtls?.firstName+" "+items?.userDtls?.lastName)} */}
                        <label><b> {item?.catererUsers[0]?.userDtls?.name}</b></label>
                                       {/* {(item?.firstName||item?.lastName!==null)&&item?.firstName+" "+item?.lastName} */}
                                      <small> {item?.contactNumber}</small>
                        </TableCell>
                       {!props?.admin&& <TableCell align="center">
                       {formatter.format(item?.totalOutstanding?.settledTotal)}
                        </TableCell>}
                        <TableCell align="center" >
                        {formatter.format(item?.totalOutstanding?.completedTotal-item?.totalOutstanding?.settledTotal)}
                        </TableCell>
                        <TableCell align="center">
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={(e) => { handleClick(e);setSelectedItem(item) }}
                            src="../assets/images/more-horizontal.svg"
                            alt="img"
                          />
                        </TableCell>
                      </TableRow>
                      // </Link>
                    )})
                  ) : search ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>No data Found</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>No list added Yet!</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select onChange={handlePageInputChange} value={rowsPerPage}>
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listPaymentCaterers?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="menu-list-more"
        >
          <MenuItem onClick={() => { handleClose(); navigate(`/payments/${selectedItem?._id}`,  { state: { title: selectedItem?.businessName, location: selectedItem?.locationName, catererId:selectedItem?._id } }) }}>View</MenuItem>
         
        </Menu>
      </div>
    </div>
  );
}
