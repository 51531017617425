import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { USER_TYPE } from "@constants/user.constants";
import { useNavigate } from "react-router-dom";
import { SIDEBAR_TAB_PARAMS, USER_TAB_MAP } from "@constants/tab.constants";

interface IHeaderMenuProps {
  element: Element;
  isOpen: boolean;
  userType: USER_TYPE | null;
  onClose(): void;
  onLogout(): void;
}

export function HeaderMenu({ element, isOpen, userType, onClose, onLogout }: IHeaderMenuProps) {
  const navigate = useNavigate();

  const tabs = userType ? USER_TAB_MAP[userType] : [];

  return (
    <Menu
      id="basic-menu"
      className="nav-header-menu"
      anchorEl={element}
      open={isOpen}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}>
      {tabs.map((item, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            navigate(SIDEBAR_TAB_PARAMS[item].url);
          }}>
          {item}
        </MenuItem>
      ))}
      <MenuItem onClick={onLogout}>Logout</MenuItem>
    </Menu>
  );
}
