import React from "react";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { useController } from "react-hook-form";

export function SelectForm({ id, label, options, control, name, rules, onUpdate, ...props }) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });

  function onChangeValue(event) {
    onChange(event);
    onUpdate(event.target.value);
  }

  return (
    <FormControl fullWidth error={error ? !!error.message : false}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select {...props} value={value} label={label} onChange={onChangeValue} onBlur={onBlur}>
        {options.map(({ value, label }, key) => (
          <MenuItem value={value} key={key}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error ? error.message : ""}</FormHelperText>
    </FormControl>
  );
}
