import React from 'react'

import { formatter } from "../Common/commonUtils";
export default function GiftVoucher(props){
    return(
        <div className="gift-code">
            <img src="../assets/images/gift.svg" className="img-fluid" alt="Icon" />
            <label>Your Amazon Gift Code : <b>{props?.voucherData?.giftCard?.voucherCode}</b> 
            Gift Amount : <b>{formatter.format(props?.voucherData?.giftCard?.amount)}</b></label>
        </div>
    )
}