import React from "react";

import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  OutlinedInput,
  ListItemText,
  Checkbox,
  Button,
  IconButton,
  Drawer,
} from "@mui/material";
import { ICatererListFilter } from "../caterer-list.typings";
import { PACKAGE_OPTIONS_LIST } from "../caterer-list";

interface ICatererListFilterDrawerProps extends ICatererListFilter {
  isOpen: boolean;
  onCloseFilter(): void;
}

export function CatererListFilterDrawer({
  isOpen,
  search,
  sortBy,
  dietary,
  cuisine,
  cuisineList,
  setCuisine,
  packageOption,
  setPackageOption,
  setDietary,
  setSortBy,
  setSearch,
  onClear,
  onCloseFilter,
}: ICatererListFilterDrawerProps) {
  return (
    <div>
      <Drawer className="common-drawer" anchor={"right"} open={isOpen} onClose={onCloseFilter}>
        <div className="">
          <div className="filter-mobile-title">
            <h5>Filter</h5>
            <IconButton onClick={onCloseFilter}>
              <img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" />
            </IconButton>
          </div>
          <div className="listing-filter-secondary listing-filter-secondary-mob">
            <TextField
              className="form-control lfs-search lfs-item"
              placeholder="Search by Caterers Name"
              value={search}
              onChange={setSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src="../assets/images/search.svg" />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl className="lfs-deitary lfs-item">
              <Select
                labelId="Time"
                id="time"
                className="hours-dropdown"
                value={dietary}
                onChange={setDietary}>
                <MenuItem disabled className="lfs-menuitem" value={"Dietary"}>
                  <img src="../assets/images/diatry.svg" className="img-fluid" alt="Head Count" />
                  All Dietary
                </MenuItem>

                <MenuItem className="lfs-menuitem" value={"Vegetarian"}>
                  <img src="../assets/images/diatry.svg" className="img-fluid" alt="Dietry Icon" />
                  {"Vegetarian"}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl className="lfs-option lfs-item">
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={packageOption}
                onChange={setPackageOption}
                input={<OutlinedInput />}
                renderValue={(selected) =>
                  selected[0] === "options" && selected.length === 1
                    ? "All Options"
                    : selected.length - 1 + " Selected"
                }>
                {PACKAGE_OPTIONS_LIST.map((item) => (
                  <MenuItem className="lfs-select-item" key={item?.value} value={item?.value}>
                    <Checkbox className="p-0" checked={packageOption.indexOf(item?.value) > -1} />
                    <ListItemText primary={item?.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="lfs-cuisnes lfs-item">
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={cuisine}
                onChange={setCuisine}
                input={<OutlinedInput />}
                renderValue={(selected) =>
                  selected[0] === "All Cuisines" && selected.length === 1
                    ? "All Cuisines"
                    : selected.length - 1 + " Selected"
                }>
                {cuisineList
                ? cuisineList.map((item) => (
                  <MenuItem className="lfs-select-item" key={item?._id} value={item?._id}>
                    <Checkbox className="p-0" checked={cuisine.indexOf(item?._id) > -1} />
                    <ListItemText primary={item?.cuisineName} />
                  </MenuItem>
                )): null}
              </Select>
            </FormControl>
            <FormControl className="lfs-sort lfs-item">
              <Select
                labelId="Time"
                id="time"
                className="hours-dropdown"
                value={sortBy}
                onChange={setSortBy}>
                <MenuItem disabled className="lfs-menuitem" value={"CaterCash"}>
                  <p>Sort by&nbsp;:&nbsp;</p>CaterCash
                </MenuItem>
                <MenuItem className="lfs-menuitem" value={"1"}>
                  High to Low
                </MenuItem>
                <MenuItem className="lfs-menuitem" value={"-1"}>
                  Low to High
                </MenuItem>
              </Select>
            </FormControl>
            <div className="cancel-save-btn">
              <Button className="cancelBtn" variant="contained" color="primary" onClick={onClear}>
                Clear
              </Button>
              <Button
                className="saveBtn"
                variant="contained"
                color="primary"
                onClick={onCloseFilter}>
                Apply Filter
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
