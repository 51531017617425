import React from "react";

interface ICatererCategoryItemProps {
  category: any;
  isActive: boolean;
  onSelectCategory(value: string): void;
}

export function CatererCategoryItem({
  category,
  isActive,
  onSelectCategory,
}: ICatererCategoryItemProps) {
  function onClickCategory() {
    onSelectCategory(category.categoryId);
  }

  return (
    <div className="menu-category-item">
      <p
        style={{
          fontWeight: isActive && "bold",
          color: isActive ? "black" : "#838592",
          cursor: "pointer",
        }}
        onClick={onClickCategory}>
        {category.categoryDtls.categoryTitle}
      </p>
    </div>
  );
}
