import React from "react";

import LoginHeader from "../../Login/LoginHeader";
import Footer from "../../Common/footer";
import { Link, useNavigate } from "react-router-dom";
import { userDeatils, authVar } from "../../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLogout } from "../../../utils/hooks/use-logout";

export default function Faq(props) {
  const auth = useReactiveVar(authVar);
  const user = useReactiveVar(userDeatils);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;

  const urlData = { faqReferFriend: "faqReferFriend" };

  const { onLogout } = useLogout({
    userType,
    isRedirect: false,
  });

  return (
    <div>
      <Helmet>
        <title>CaterPlace - Corporate Catering</title>
        <meta name="Keywords" />
        <meta
          name="description"
          content="Find answers to most of the Frequently Asked Questions about CaterPlace"
        />
      </Helmet>
      <LoginHeader />
      <section className="about-banner faq-banner overlay">
        <div className="container">
          <h1 className="h1 text-center">Frequently Asked Questions</h1>
        </div>
      </section>
      <section className="faq">
        <div className="container">
          <div className="faq-item">
            <h4 className="h4">About</h4>
            <div className="faq-sub-item">
              {/* <label className="faq-sub-item-title">About CaterPlace</label> */}
              <div className="accordion accordion-flush" id="ac-1">
                <div className="accordion-item">
                  <h5 className="accordion-header" id="faq-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#ac-faq-1"
                      aria-expanded="false"
                      aria-controls="ac-faq-1">
                      What is CaterPlace?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="ac-faq-1"
                    className="accordion-collapse collapse"
                    aria-labelledby="faq-1"
                    data-bs-parent="#ac-1">
                    <div className="accordion-body">
                      <p>
                        CaterPlace is an online platform offering a variety of catering options
                        while rewarding loyal customers through a rewards based program called
                        “CaterCash”.
                      </p>

                      <p>
                        For more detailed information about our platform, please refer to our{" "}
                        <Link
                          to={"/terms-and-conditions"}
                          style={{ cursor: "pointer", color: "#FD4E3B" }}>
                          Terms of Service Agreement
                        </Link>{" "}
                        and{" "}
                        <Link
                          to={"/privacy-policy"}
                          style={{ cursor: "pointer", color: "#FD4E3B" }}>
                          Privacy Policy
                        </Link>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Customers */}
          <div className="faq-item">
            <h4 className="h4">Customers</h4>
            <div className="faq-sub-item">
              <label className="faq-sub-item-title">For Customers</label>
              <div className="accordion accordion-flush" id="cc-1">
                <div className="accordion-item">
                  <h5 className="accordion-header" id="faq-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cc-faq-1"
                      aria-expanded="false"
                      aria-controls="cc-faq-1">
                      What do I need to sign up for a CaterPlace account?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="cc-faq-1"
                    className="accordion-collapse collapse"
                    aria-labelledby="faq-1"
                    data-bs-parent="#cc-1">
                    <div className="accordion-body">
                      <p>
                        You will need an email address (this will be your username), password, first
                        and last name, phone number, and a credit card. We accept almost all major
                        credit cards.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="faq-sub-item">
              <label className="faq-sub-item-title">Orders</label>
              <div className="accordion accordion-flush" id="faq-acc">
                <div className="accordion-item">
                  <h5 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#co-faq-1"
                      aria-expanded="false"
                      aria-controls="co-faq-1">
                      When does my credit card get charged for an order I create?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="co-faq-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faq-acc">
                    <div className="accordion-body">
                      <p className="text">
                        Orders will be charged on the day that the order is completed and delivered.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="accordion-item">
                  <h5 className="accordion-header" id="faq-2">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#co-faq-2" aria-expanded="false" aria-controls="co-faq-2">
                      Can I place same day orders using CaterPlace?
                      <span className="accordion-plus accordion-action"><img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" /></span>
                      <span className="accordion-minus accordion-action"><img src="../assets/images/acc-minus.svg" className="img-fluid" alt="Close" /></span>
                    </button>
                  </h5>
                  <div id="co-faq-2" className="accordion-collapse collapse" aria-labelledby="co-123" data-bs-parent="#faq-acc">
                    <div className="accordion-body">
                      <p className="text">Same Day orders are allowed. However, PLEASE also contact the restaurant directly to ensure they have received the order AND are able to complete it within the timeline you require. REMEMBER, CaterPlace is not responsible for ensuring your order gets delivered – that is the responsibility of the caterer.</p>
                    </div>
                  </div>
                </div> */}
                <div className="accordion-item">
                  <h5 className="accordion-header" id="faq-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#co-faq-3"
                      aria-expanded="false"
                      aria-controls="co-faq-3">
                      How do I make a change to an existing order? Can I do that?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="co-faq-3"
                    className="accordion-collapse collapse"
                    aria-labelledby="co-faq-3"
                    data-bs-parent="#faq-acc">
                    <div className="accordion-body">
                      <p className="text">
                        All draft orders may be modified on the CaterPlace platform prior to sending
                        it to the chosen caterer. Once an order has been received and acknowledged
                        by that caterer, customers will need to contact the caterer directly to make
                        any changes outside of the CaterPlace platform. A refund may or may not be
                        possible based on the discrepancy of the Caterer. CaterCash rewards may or
                        may not be affected.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h5 className="accordion-header" id="faq-4">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#co-faq-4"
                      aria-expanded="false"
                      aria-controls="co-faq-4">
                      How can I check the status of my order?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="co-faq-4"
                    className="accordion-collapse collapse"
                    aria-labelledby="co-faq-4"
                    data-bs-parent="#faq-acc">
                    <div className="accordion-body">
                      <p className="text">
                        From the Order page, you will be able to see the status of your order.
                      </p>

                      <p>
                        New Order = “An order that has been created and submitted by a customer to a
                        caterer but the caterer has not confirmed receiving the order yet.”
                      </p>

                      <p>
                        Draft Order = “An order that has been created by a customer but has not been
                        finalized and sent to a caterer. Draft orders can be modified by the
                        customer.”
                      </p>

                      <p>
                        Scheduled Order = “An order that has been created by a customer and
                        confirmed by a caterer that still has not been delivered yet. Scheduled
                        orders can not be modified on the CaterPlace website.”
                      </p>

                      <p>
                        Completed = “An order that has been delivered by a caterer to the intended
                        address of the customer. ”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h5 className="accordion-header" id="faq-5">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#co-faq-5"
                      aria-expanded="false"
                      aria-controls="co-faq-5">
                      My catering order has not arrived – who do I contact?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="co-faq-5"
                    className="accordion-collapse collapse"
                    aria-labelledby="co-faq-5"
                    data-bs-parent="#faq-acc">
                    <div className="accordion-body">
                      <p className="text">
                        For immediate help with any order, simply text or call our customer service
                        number at{" "}
                        <a
                          style={{ cursor: "pointer", color: "#FD4E3B" }}
                          href="tel:(646) 535-4875">
                          (646) 535-4875.
                        </a>{" "}
                        One of our teams members will connect with you shortly. Please make sure to
                        include your name and order number.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h5 className="accordion-header" id="faq-6">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#co-faq-6"
                      aria-expanded="false"
                      aria-controls="co-faq-6">
                      Can I split a catering order?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="co-faq-6"
                    className="accordion-collapse collapse"
                    aria-labelledby="co-faq-6"
                    data-bs-parent="#faq-acc">
                    <div className="accordion-body">
                      <p className="text">
                        At this point, only one credit card can be used for each CaterPlace Order.
                        Therefore, only one customer can receive CaterCash rewards per order.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Customers */}

          {/* Caterers */}
          <div className="faq-item">
            <h4 className="h4">Caterers</h4>
            <div className="faq-sub-item">
              <label className="faq-sub-item-title">For Caterers</label>
              <div className="accordion accordion-flush" id="faq-acc-cat">
                <div className="accordion-item">
                  <h5 className="accordion-header" id="cater-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cater-faq-1"
                      aria-expanded="false"
                      aria-controls="cc-faq-1">
                      How do I sign up / register for CaterPlace?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="cater-faq-1"
                    className="accordion-collapse collapse"
                    aria-labelledby="faq-cater-1"
                    data-bs-parent="#faq-acc-cat">
                    <div className="accordion-body">
                      <p>
                        You can sign up for CaterPlace by clicking{" "}
                        <Link
                          to={"/register"}
                          style={{ cursor: "pointer", color: "#FD4E3B" }}
                          onClick={onLogout}>
                          here
                        </Link>
                        . You will need to provide your business name and address, contact phone and
                        email address for orders, and catering hours for taking orders. Providing a
                        bank name, account number, and routing number will allow faster delivery of
                        funds to your establishment.
                      </p>
                      {/* <p>You can sign up for CaterPlace by clicking <Link to={userType==="Admin"?'/admin':userType==="Caterer"&&auth.auth?'/summary':'/login'} style={{ cursor: "pointer",color:"#FD4E3B" }} onClick={()=>{userType!=="Caterer"&&handleLogout() }}>here</Link>. You will need to provide your business name and address, contact phone and email address for orders, and catering hours for taking orders. Providing a bank name, account number, and routing number will allow faster delivery of funds to your establishment.</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="faq-sub-item">
              <label className="faq-sub-item-title">Orders</label>
              <div className="accordion accordion-flush" id="faq-acc-cat-order">
                <div className="accordion-item">
                  <h5 className="accordion-header" id="cater-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cater-faq-2"
                      aria-expanded="false"
                      aria-controls="cater-faq-2">
                      How will I receive orders?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="cater-faq-2"
                    className="accordion-collapse collapse"
                    aria-labelledby="cater-faq-2"
                    data-bs-parent="#faq-acc-cat-order">
                    <div className="accordion-body">
                      <p>
                        Currently, orders will only be sent to caterers via email. Once an order is
                        received by a caterer, you will be able to confirm the order. If you need to
                        modify an order, please contact the customer directly.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h5 className="accordion-header" id="cater-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cater-faq-3"
                      aria-expanded="false"
                      aria-controls="cater-faq-3">
                      A customer wants to make a change to an existing order. Who do I need to
                      contact?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="cater-faq-3"
                    className="accordion-collapse collapse"
                    aria-labelledby="cater-faq-3"
                    data-bs-parent="#faq-acc-cat-order">
                    <div className="accordion-body">
                      <p>
                        Changes happen, we understand. After an order is placed and confirmed with a
                        caterer, any changes to that order will have to be made directly with the
                        customer who placed the order. A separate order may be necessary to correct
                        any last minute changes. It may even be easier to have a customer start an
                        order from scratch if need be. CaterPlace will try to make the appropriate
                        changes if possible.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="faq-sub-item">
              <label className="faq-sub-item-title">Payment</label>
              <div className="accordion accordion-flush" id="faq-acc-cat">
                <div className="accordion-item">
                  <h5 className="accordion-header" id="cater-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cater-faq-4"
                      aria-expanded="false"
                      aria-controls="cc-faq-4">
                      How do I get paid for orders made through CaterPlace?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="cater-faq-4"
                    className="accordion-collapse collapse"
                    aria-labelledby="faq-cater-4"
                    data-bs-parent="#faq-acc-cat">
                    <div className="accordion-body">
                      <p>
                        Payment for orders placed through CaterPlace will be paid once a month. You
                        will receive an invoice outlining the customers total order, minus
                        CaterCash, tip and tax.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="faq-sub-item">
              <label className="faq-sub-item-title">Menu</label>
              <div className="accordion accordion-flush" id="faq-acc-cat">
                <div className="accordion-item">
                  <h5 className="accordion-header" id="cater-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cater-faq-5"
                      aria-expanded="false"
                      aria-controls="faq-cater-5">
                      The menu listed on CaterPlace is incorrect or needs updating. How can that be
                      changed?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="cater-faq-5"
                    className="accordion-collapse collapse"
                    aria-labelledby="faq-cater-5"
                    data-bs-parent="#faq-acc-cat">
                    <div className="accordion-body">
                      <p>
                        We try our best to make sure all grammar, pricing, and item description is
                        correct. We are more than happy to help resolve any errors – Please email us
                        at{" "}
                        <Link
                          to={"mailto: support@caterplace.com"}
                          style={{ cursor: "pointer", color: "#FD4E3B" }}>
                          support@CaterPlace.com
                        </Link>{" "}
                        with your contact information and we will help resolve the issue. However,
                        it is up to the caterer to keep their menus accurate and up to date as
                        possible. You can change your menu by logging into the catering portal of{" "}
                        <Link to={"/login"} style={{ cursor: "pointer", color: "#FD4E3B" }}>
                          www.CaterPlace.com/caterer/signin
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Caterers */}

          {/* Catercash */}
          <div className="faq-item" id="faq-catercash">
            <h4 className="h4">CaterCash</h4>
            <div className="faq-sub-item">
              {/* <label className="faq-sub-item-title">For Caterers</label> */}
              <div className="accordion accordion-flush" id="faq-acc-ccash">
                <div className="accordion-item">
                  <h5 className="accordion-header" id="cater-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cash-faq-1"
                      aria-expanded="false"
                      aria-controls="cc-faq-1">
                      How do I use CaterCash points?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="cash-faq-1"
                    className="accordion-collapse collapse"
                    aria-labelledby="cash-cater-1"
                    data-bs-parent="#faq-acc-ccash">
                    <div className="accordion-body">
                      <p>
                        To use your CaterCash points earned, go to the{" "}
                        {auth?.auth && userType === "Customer" ? (
                          <Link to={"/CaterCash"} style={{ cursor: "pointer", color: "#FD4E3B" }}>
                            CaterCash section
                          </Link>
                        ) : (
                          "CaterCash section"
                        )}{" "}
                        in your user profile. From there, you will see a list of CaterPlace partners
                        you can use your CaterCash points with.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h5 className="accordion-header" id="cater-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cash-faq-2"
                      aria-expanded="false"
                      aria-controls="cash-faq-2">
                      How quickly will I see my CaterCash in my account?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="cash-faq-2"
                    className="accordion-collapse collapse"
                    aria-labelledby="cash-cater-2"
                    data-bs-parent="#faq-acc-ccash">
                    <div className="accordion-body">
                      <p>
                        Typically, CaterCash will be distributed to your account within 3 days after
                        the caterer marks your order as “Completed”.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h5 className="accordion-header" id="cater-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cash-faq-3"
                      aria-expanded="false"
                      aria-controls="cash-faq-3">
                      What is the value of CaterCash?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="cash-faq-3"
                    className="accordion-collapse collapse"
                    aria-labelledby="cash-cater-3"
                    data-bs-parent="#faq-acc-ccash">
                    <div className="accordion-body">
                      <p>
                        For most of our partners, CaterCash will have a 1 to 1 direct correlation.
                        For example, if you have earned 25 CaterCash points, you will be able to
                        purchase a 25 dollar Amazon Gift Card.
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="accordion-item">
                  <h5 className="accordion-header" id="cater-1">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cash-faq-4" aria-expanded="false" aria-controls="cash-faq-4">
                      Do CaterCash points ever expire?
                      <span className="accordion-plus accordion-action"><img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" /></span>
                      <span className="accordion-minus accordion-action"><img src="../assets/images/acc-minus.svg" className="img-fluid" alt="Close" /></span>
                    </button>
                  </h5>
                  <div id="cash-faq-4" className="accordion-collapse collapse" aria-labelledby="cash-cater-4" data-bs-parent="#faq-acc-ccash">
                    <div className="accordion-body">
                      <p>No, CaterCash points are earned by you and your loyalty. They never expire.</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* others */}

          {/* Catercash */}
          <div className="faq-item">
            <h4 className="h4">Others</h4>
            <div className="faq-sub-item">
              {/* <label className="faq-sub-item-title">For Caterers</label> */}
              <div className="accordion accordion-flush" id="faq-acc-other">
                <div className="accordion-item">
                  <h5 className="accordion-header" id="other-1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#other-faq-1"
                      aria-expanded="false"
                      aria-controls="cc-faq-1">
                      How do I refer my friends to CaterPlace?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="other-faq-1"
                    className="accordion-collapse collapse"
                    aria-labelledby="other-cater-1"
                    data-bs-parent="#faq-acc-other">
                    <div className="accordion-body">
                      <p>
                        Referring a friend is easy. Just click{" "}
                        <Link
                          to={auth?.auth && userType === "Customer" ? "/referFriend" : "/login"}
                          style={{ cursor: "pointer", color: "#FD4E3B" }}
                          state={urlData}>
                          here
                        </Link>{" "}
                        or go to the link to refer someone you think would like CaterCash points.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h5 className="accordion-header" id="other-2">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#other-faq-2"
                      aria-expanded="false"
                      aria-controls="other-faq-2">
                      I use a caterer but I don’t see them on the CaterPlace platform. How can I
                      help add them?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="other-faq-2"
                    className="accordion-collapse collapse"
                    aria-labelledby="other-cater-2"
                    data-bs-parent="#faq-acc-other">
                    <div className="accordion-body">
                      <p>
                        Feel free to email{" "}
                        <Link
                          to={"mailto: support@caterplace.com"}
                          style={{ cursor: "pointer", color: "#FD4E3B" }}>
                          support@CaterPlace.com
                        </Link>{" "}
                        directly with the name of the restaurant, contact name, and phone, and we
                        will contact the restaurant within 2-3 business days.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h5 className="accordion-header" id="other-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#other-faq-3"
                      aria-expanded="false"
                      aria-controls="other-faq-3">
                      Still have a question?
                      <span className="accordion-plus accordion-action">
                        <img src="../assets/images/acc-plus.svg" className="img-fluid" alt="Open" />
                      </span>
                      <span className="accordion-minus accordion-action">
                        <img
                          src="../assets/images/acc-minus.svg"
                          className="img-fluid"
                          alt="Close"
                        />
                      </span>
                    </button>
                  </h5>
                  <div
                    id="other-faq-3"
                    className="accordion-collapse collapse"
                    aria-labelledby="other-cater-3"
                    data-bs-parent="#faq-acc-other">
                    <div className="accordion-body">
                      <p>
                        Feel free to email{" "}
                        <Link
                          to={"mailto: support@caterplace.com"}
                          style={{ cursor: "pointer", color: "#FD4E3B" }}>
                          support@CaterPlace.com
                        </Link>{" "}
                        and we will respond as soon as possible.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Others */}
        </div>
      </section>
      <Footer />
    </div>
  );
}
