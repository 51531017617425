//this page is for the boxes on the top of summery page
import React from 'react'

import { formatter } from "../../Common/commonUtils";
export default function TotalSummeryBox(props) {
  const totalData= props?.totalData?.getTotalsForDashboard
  const earnedTotal = props?.caterCashTotal?.listCaterCashTotal
  return (
    <div className="data-overview">
      <div className="do-item">
        <img
          src="../assets/images/d-new-order.svg"
          className="img-fluid"
          alt="Icon"
        />
        <h6>Total Caterers</h6>
        {totalData&&<label>{totalData?.totalCaterers}</label>}
      </div>
      <div className="do-item">
        <img
          src="../assets/images/d-scheduled-order.svg"
          className="img-fluid"
          alt="Icon"
        />
        <h6>Total Customers</h6>
        {totalData&&<label>{totalData?.totalCustomers}</label>}
      </div>
      <div className="do-item">
        <img
          src="../assets/images/total-cmpltd-order.svg"
          className="img-fluid"
          alt="Icon"
        />
        <h6>Total Completed Orders</h6>
        {totalData&&<label>{totalData?.completedOrders}</label>}
      </div>

      <div className="do-item">
        <img
          src="../assets/images/d-completed-order.svg"
          className="img-fluid"
          alt="Icon"
        />
        <h6>CaterCash Balance</h6>
        {totalData&&<label>{formatter.format(totalData?.caterCashBalance)}</label>}
      </div>

      <div className="do-item">
        <img
          src="../assets/images/d-avg-order-amount.svg"
          className="img-fluid"
          alt="Icon"
        />
        <h6>CaterCash Earned</h6>
        {earnedTotal&&<label>{formatter.format(earnedTotal?.totEarned-earnedTotal?.totGift)}</label>}
      </div>
      <div className="do-item">
        <img
          src="../assets/images/commission-earned.svg"
          className="img-fluid"
          alt="Icon"
        />
        <h6>Commission Earned</h6>
       { totalData&&<label>{formatter.format(totalData?.commisionEarned)}</label>}
      </div>
      <div></div>
    </div>
  );
}
