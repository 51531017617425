import React from "react";
import { useQuery } from "@apollo/client/react/hooks";
import { Button, IconButton, Tooltip } from "@mui/material";

import { LIST_MENU } from "../../../../Graphql/queries";
import { CatererMenuLoader } from "./caterer-menu-loader";
import { formatter } from "../../../../Modules/Common/commonUtils";

interface ICatererMenuListProps {
  catererId: string;
  category: string;
  isCanEditOrder: boolean;
  onAddItem(id: string): void
}

export function CatererMenuList({ catererId, category, isCanEditOrder, onAddItem }: ICatererMenuListProps) {
  const { data: menuData, loading } = useQuery(LIST_MENU, {
    variables: {
      category,
      catererId,
    },
    skip: !catererId || !category,
    fetchPolicy: "cache-and-network",
  });

  return (
    <div className="row gx-md-5">
      {loading ? (
        <CatererMenuLoader />
      ) : (
        <>
          {menuData?.listMenu?.data?.map((item, idx) => {
            return (
              <div
                className="col-lg-6 menu-category-listing-col"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onAddItem(item?._id);
                }}
                key={`menu-data-category-listing-col-${idx}`}>
                <div className="menu-category-listing-item">
                  <div className="menu-category-listing-item-details">
                    <h3>{item?.itemName}</h3>
                    {item?.dietory !== "None" && (
                      <label className="dietry-label">
                        {(item?.dietory === "Vegetarian" ||
                          item?.dietory === "Veg and Gluten Free") && (
                          <img
                            src={
                              item?.dietory === "Vegetarian" ||
                              item?.dietory === "Veg and Gluten Free"
                                ? "../assets/images/veg.svg"
                                : ""
                            }
                            className="img-fluid"
                            alt="Icon"
                          />
                        )}
                        <span>
                          {item?.dietory === "Vegetarian"
                            ? "Vegetarian"
                            : item?.dietory === "Veg and Gluten Free"
                            ? "Veg and Gluten Free"
                            : ""}
                        </span>
                      </label>
                    )}

                    <div className="price-time">
                      <b style={{ color: "#FD4E3B" }}>{formatter.format(item?.price)}</b>
                      &nbsp;/&nbsp;
                      {item?.itemType === "Tray" ? "per tray" : "per order"} - min{" "}
                      {item?.minQuantity}
                      {item?.offerIndPkdFlg && (
                        <a>
                          &nbsp;/&nbsp;
                          <Tooltip title="Offers individually packaged" arrow placement="right-end">
                            <IconButton className="p-0">
                              {" "}
                              <img
                                src="../assets/images/package.svg"
                                className="img-fluid"
                                alt="Icon"
                              />
                            </IconButton>
                          </Tooltip>
                        </a>
                      )}
                    </div>
                    <p>{item?.itemDescription}</p>
                    {isCanEditOrder && (
                      <div className="">
                        <Button
                          onClick={() => {
                            onAddItem(item?._id);
                          }}
                          variant="outlined">
                          Add
                        </Button>
                      </div>
                    )}
                  </div>
                  {item?.itemImage && (
                    <div className="menu-category-listing-item-img">
                      <img src={item?.itemImage} className="img-fluid" alt={item?.itemName} />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}
