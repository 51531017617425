import React, { useEffect } from "react";
import LoginHeader from "../../Login/LoginHeader";
import Footer from "../../Common/footer";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { userDeatils, authVar } from "../../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import {Helmet} from "react-helmet";
export default function PrivacyPolicy() {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }, [])



  return (
    <div>
      <Helmet>
        <title>CaterPlace - Corporate Catering</title>
        <meta name="Keywords" />
        <meta name="description" content="Learn more about how CaterPlace handles your personal data by reading our privacy policy" />
      </Helmet>
      <LoginHeader />
      <section className="about-banner overlay">
        <div className="container">
          <div className="about-banner-cnt m-auto" style={{ maxWidth: 'fit-content' }}>
            <h2 className="h2 text-center">Privacy & Terms of Service</h2>
          </div>
        </div>
      </section>
      <section className="general-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 text-center m-auto">
              <h2 className="h2">CaterPlace Privacy Policy as of July 1, 2021</h2>
            </div>
            <div className="col-lg-9 m-auto">

              <p>The Privacy Policy stated below describes the types of information that CaterPlace, Inc. ( “CaterPlace,” “us,” “we,” and “our”) collect, use, and disclose from users and visitors of our website <a href="https://www.caterplace.com/" style={{ color: '#FD4E3B' }}>www.CaterPlace.com</a>, subdomains, related mobile websites, applications and other related services/websites (the “Platform”).</p>
              <p>By accessing or using the Platform, you agree that you have read, understood and agree to our collection, storage, use and disclosure of your personal information as described in this Privacy Policy and our <a href="/terms-and-conditions" style={{ color: '#FD4E3B' }}>Terms of Use</a>.</p>
              <h4 className="h4">1. Data we collect –</h4>
              <p><b>1.A Data You Provide to Us</b></p>
              <ul className="main_ul">
                <li><b>Registration and Profile Information.</b> When you create an account on the Platform, you provide us with information including, without limitation, your first name, last name, email address, phone number, and login information. We use this information to create and personalize your account and communicate with you directly.</li>
                <li><b>Ordering Information.</b> When you place a catering order through our Platform, you provide us details about your order – restaurant name, cuisine type, and amount of food you are ordering, dietary restrictions, delivery time and location, recipient’s name, and any other delivery instructions. You also provide us with payment information, such as your credit or debit card number and expiration date. All credit card information is stored by a third party processing provider and is inaccessible by us.</li>
                <li><b>Communications.</b> If you choose to communicate or contact CaterPlace for any reason, (including, without limitation, future/past ordering questions, receipts, CaterCash rewards, etc.) we receive information such as your name, phone number, email address, and any other content that may be included with or disclosed by said communication.</li>
                <li><b>Contact and Business Information.</b> If you are a food provider (“Vendor”) listed on or interested in being listed on the Platform, the information you provide may include your name, business name, bank information, business address, business website, contact information, and hours of operation.</li>
              </ul>

              <p><b>1.B The Data We Collect Through Automated Means</b></p>
              <ul className="main_ul">
                <li><b>	Device Data.</b> We collect and analyze information about the device(s) you use to access the Platform. This information might include your IP address, browser type, internet service provider, date and time stamp information about your device’s hardware, the operating system on your device and the version of that operating system, your language preferences, unique device identifiers, advertising identifiers, and information about the mobile network you use.</li>
                <li><b>Location Information.</b> When you use the Platform, we collect general location information from your computer or mobile device. “General” location information means information to identify the city and state in which your device is located based on its IP address. This information allows us to give you more relevant content (e.g. Vendors)</li>
              </ul>

              <p><b>1.C Information from Cookies and Similar Technologies.</b></p>
              <p>We also collect basic website usage analytics to optimize the experience for our visitors through the use of “cookies”.  Cookies are designed to store basic information and to help websites and apps recognize your browser. We may use different type of cookies like session cookies and persistent cookies. A session cookie ceases after you close your browser. A persistent cookie remains active after you close your browser and may be accessed each time you use the Platform (e.g. UserName,).</p>

              <p><b>1.D. Data We Collect from Others Services/Partners</b></p>
              <p>We may collect information about you from third parties, including the following:</p>
              <ul className="main_ul">
                <li><b>Our Catering Vendors</b> If you place an order with a Vendor, the Vendor may provide us information relating to your purchase or delivery, including, without limitation, your name, phone number, order details, and delivery information.</li>
              </ul>

              <p><b>2. How we use the data we collect,</b></p>
              <p>CaterPlace and our service providers may use the information we collect from and about you for the following purposes:</p>
              <ul className="main_ul">
                <li>Provide customer support, including responding to questions, requests, and issues;</li>
                <li>Provide, improve, enhance, maintain, promote and promote the Platform and Services;</li>
                <li>Communicate with you;</li>
                <li>Gain insights into prospective customers that might share an interest in our Services;</li>
                <li>Facilitate transactions, deliveries, and payments;</li>
                <li>Conduct research;</li>
                <li>Find and prevent fraud;</li>
                <li>Resolve disputes, protect ourselves and other Platform users and others, and enforce any legal terms that govern use of our website; and</li>
                <li>Comply with laws, regulations, and other legal process and procedures.</li>
              </ul>

              <p><b>3. What we do with the data</b></p>
              <p>We and our service providers share the information we collect from and about you for the following business and operational purposes:</p>
              <ul className="main_ul">
                <li><b>Vendors.</b> We may share your name, contact, and purchase information with the Vendors you have placed orders with, as well as with their partners. The information we share with our Vendors is not subject to this Privacy Policy, but instead is subject to the Vendor’s own privacy policy, which may allow the Vendor among other things, to send you marketing communications and other promotional content.</li>
                <li><b>Food Recipients.</b> If you are not the recipient of an order you place, we may share your information with the food recipient to the extent we communicate with them with respect to the order.</li>
                <li><b>Your Employer.</b> If you place an order for your employer, and your employer is a CaterPlace “corporate account” or “national account,” we may share your information with your employer.</li>
                <li><b>Service Providers.</b> We provide access to or share your information with third parties that perform services on our behalf, such as billing, payment processing, advertising, web and other analytics, data storage and processing, customer support, delivery services, security, fraud prevention, and other services.</li>
                <li><b>Affiliates.</b> We provide access to or share your information with our affiliates for the purposes described in this Privacy Policy.</li>
                <li><b>For Legal Reasons or the Protection of CaterPlace and Others.</b> We will disclose the information we collect about you if required by law or legal process or if we believe in good faith that disclosure is reasonably necessary to: (i) enforce our <a href="/terms-and-conditions" style={{ color: '#FD4E3B' }}>Terms of Use</a>, this Privacy Policy, or other contracts with you, including investigation of potential violations thereof; (ii) respond to claims that any content violates the rights of third parties; and/or (iii) protect the rights, property or personal safety of CaterPlace, our Platform users, and/or others. This includes exchanging information with other companies and organizations for fraud protection, spam/malware prevention, and similar purposes.</li>
                <li><b>Other Unaffiliated Third Parties.</b> We may also share your information with other unaffiliated third parties such as the following:
                  <ul>
                    <li>Third party parties we collaborate with in connection with integration, co-marketing and certain other coordinated efforts.</li>
                    <li>Third parties whose offers we believe you may value.</li>
                  </ul>
                </li>
                <li><b>Your Consent.</b> If you consent to our sharing your information, we will share your information consistent with your consent.</li>
              </ul>
              <p>We may share aggregate or de-identified information with any third party, including advertisers, promotional partners, and sponsors, at our discretion.</p>

              <p><b>4. Third party services and links</b></p>
              <p>The Platform may contain links to third-party websites and third-party plug-ins, such as Facebook, Instagram, and Twitter.  We do not have control over the third parties that operate these websites and services and are not responsible for their content or privacy practices. Please read their respective privacy policies before using their websites or services.</p>
              <p><b>5. Ensuring your data is safe</b></p>
              <p>We take measures to protect your data against accidental or unlawful destruction, loss, alteration, disclosure, or access. However, no method of transmission over the internet, and no means of electronic or physical storage, is absolutely secure. By using the Platform, you acknowledge and accept that we cannot guarantee the security of your information transmitted to, through, or on the Platform, and that any such transmission is at your own risk.</p>
              <p><b>6. Updates to our privacy policy</b></p>
              <p>We may update this Privacy Policy from time to time. If we make a change to this Privacy Policy, the revised Privacy Policy will be made accessible through the Platform. You can determine if the Privacy Policy has changed since the last time you reviewed it by checking the “Last Update” date at the top of this Privacy Policy. If we make a material change to this Privacy Policy, we will notify you through the Platform, by email, and/or by other legally permissible communication. By continuing to use the Platform, you are confirming that you have read and understood the latest version of this Privacy Policy.</p>
              <p><b>7. Contacting CaterPlace</b></p>
              <p>If you have any questions or comments about this Privacy Policy, please email us at <a style={{ color: '#FD4E3B' }} href="mailto:support@CaterPlace.com">support@CaterPlace.com</a>. </p>



            </div>
          </div>

        </div>
      </section>

      <Footer />
    </div>
  );
}
