import { GOOGLE_API_KEY } from "@constants/map.constants";
import { useJsApiLoader } from "@react-google-maps/api";

export function useGeolocation() {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });

  async function getDistance(origin: string, destination: string) {
    if (!isLoaded || loadError) {
      return;
    }

    const service = new google.maps.DistanceMatrixService();
    try {
      const response = await service.getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        travelMode: google.maps.TravelMode.DRIVING,
      });

      return response.rows[0].elements[0].distance
        ? response.rows[0].elements[0].distance.text.replace(" mi", "")
        : "";
    } catch (e) {
      console.error(e.message);
      return "";
    }
  }

  return {
    getDistance,
  };
}
