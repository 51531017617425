// this is for the banking information
import React from 'react';

import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client";
import { UPDATE_CATERE } from "../../Graphql/mutations";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  toggleSnackbar,
} from "../../ReactiveVariables/index";
import { postiveValueConverter } from '../Common/commonUtils';

const StyledCheckbox = styled(Checkbox)`
  svg {
    font-size: 22px;
  }
`;

export default function BankingInfo(props) {
  const navigate = useNavigate()
  const currentData =
    props?.currentDetails?.getOneCaterer || props?.userValues?.updateCaterer;
  const [newMail, setNewMail] = useState(currentData?.addEmailFlag ?? false)
  const schema = yup.object().shape({
    additionalEmail: yup.string().email("Please enter a valid email address"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [updateCaterer] = useMutation(UPDATE_CATERE, {
    onCompleted: (res) => {
      props.setUserValues(res);
      props.setActiveStep(5);
    },
  });

  // this function is used for updating the caterer details with the tab value only, since it calls on skip this.
  function skip(params) {
    let data = {
      tabValue: "4"
    }
    try {
      updateCaterer({ variables: { id: props?.id, catererId: currentData?.catererUsers[0]?.catererId || props.catererId, data: data } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Banking Info skipped successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  // for allowing only positive values
  const handleInputChange = (event) => {
    event.target.value = postiveValueConverter(event, 'withoutDecimal');
  };

  // this function is for updating the banking info to the backend
  function onSubmit(params) {
    params["addEmailFlag"] = newMail
    params["tabValue"] = "4";
    try {
      updateCaterer({ variables: { id: props?.id, catererId: currentData?.catererUsers[0]?.catererId || props.catererId, data: params } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Banking Info saved successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <div className="create-step-form">
      <h5 className="h5">Banking Information</h5>
      <p className="subhead">
      Add your business banking information
        {/* Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Donec
        viverra eleifend lacus */}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}
        noValidate>
        <div >
          <div className="row addBusinessSet">
            <div className="col-md-6 textBox">
              <TextField
                id="outlined-basic"
                label="LEGAL BUSINESS NAME"
                defaultValue={currentData?.accountName}
                variant="outlined"
                className="textField allFeild"
                {...register("accountName")}
              />
            </div>
            <div className="col-md-6 textBox">
              <TextField
                id="outlined-basic"
                label="BANK NAME"
                defaultValue={currentData?.bankName}
                variant="outlined"
                className="textField allFeild"
                {...register("bankName")}
              />
            </div>
            <div className="col-12">
              {newMail && <div className="col-md-12 textBox">
                <TextField
                  id="outlined-basic"
                  autoFocus
                  label="EMAIL"
                  defaultValue={currentData?.additionalEmail}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("additionalEmail")}
                />
                {errors.additionalEmail && <span className="error-msg">{errors.additionalEmail.message}</span>}
              </div>}
            </div>
            <div className="col-12">
              <div className="createStepBtn">
                <Button
                  className="cancelBtn"
                  variant="contained"
                  color="secondary"
                  onClick={() => props.setActiveStep(3)}
                >
                  Previous
                </Button>
                <Button
                  className="saveBtn"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save and Proceed <ArrowForwardIcon className="btnArrow" />
                </Button>
              </div>
              <div className="text-end mt-3">
                <Button className="mt-2" style={{ color: "#646777", fontWeight: '600' }} onClick={() => skip()}>Skip for now <ArrowForwardIcon className="ms-2" /></Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
