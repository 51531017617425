import React from "react";

import { Button, TextField, IconButton } from "@mui/material";

export function ToggledInput({ value, onChange, onApply, onClose, inputProps, inputClassName }) {
  function onChangeValue(event) {
    onChange(event.target.value);
  }
  return (
    <div className="d-flex align-items-center justify-content-between gap-1">
      <TextField
        color="primary"
        autoFocus
        variant="outlined"
        className={`textField allFeild other-amount-feild ${inputClassName || ""}`}
        type="search"
        {...inputProps}
        value={value}
        onChange={onChangeValue}
      />
      <div className="d-flex align-items-center">
        <Button variant="contained" color="primary" onClick={onApply}>
          Apply
        </Button>
        <IconButton className="ms-2" style={{ width: "24px", height: "24px" }} onClick={onClose}>
          <img src="../assets/images/icon-close.svg" className="img-fluid" alt="Cancel" />
        </IconButton>
      </div>
    </div>
  );
}
