// this is for adding and editing the menu
import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import {
  CREATE_MENU,
  UPDATE_MENU,
  CREATE_ADDON_TYPE,
  CREATE_UPDATE_ADDON,
} from "../../../Graphql/mutations";
import { IMAGE_UPLOAD, LIST_ADDON_TYPE } from "../../../Graphql/queries";
import {
  Button,
  CircularProgress,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { toggleSnackbar, authVar, userDeatils } from "../../../ReactiveVariables/index";
import Avatar from "@mui/material/Avatar";
import CropImage from "../../Common/cropImage";
import { generateDupeAddons, generateDupeAddOnsList } from "../../../lib/util";
import imageCompression from "browser-image-compression";
import axios from "axios";
import { postiveValueConverter } from "../../Common/commonUtils";
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

const Dietary = [
  "Vegetarian",
  "Non-Vegetarian",
  "Gluten Free",
  "Veg and Gluten Free",
  "Non-Veg and Gluten Free",
  "None",
];

export function AddMenu(props) {
  const navigate = useNavigate();
  const user = useReactiveVar(userDeatils);
  const [isImageDelete, setIsImageDelete] = useState(false);
  const [imageDeleteCheck, setImageDeleteCheck] = useState(false);
  const [checked, setChecked] = useState(
    props?.selectedItem?.offerIndPkdFlg
      ? props?.selectedItem?.offerIndPkdFlg
      : props?.data?.getOneMenu?.offerIndPkdFlg
      ? props?.data?.getOneMenu?.offerIndPkdFlg
      : false,
  );
  const [popular, setPopular] = useState(
    props?.selectedItem?.markasPopularFlg
      ? props?.selectedItem?.markasPopularFlg
      : props?.data?.getOneMenu?.markasPopularFlg
      ? props?.data?.getOneMenu?.markasPopularFlg
      : false,
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const modifyTitle = props?.isDupe ? "Duplicate" : props?.selectedItem ? "Edit" : "Add";
  const schema = yup.object().shape({
    itemName: yup.string().required("Item Name is required"),
    categoryId: yup.string().required("Category is required"),
    dietory: yup.string().required("Dietary is required"),
    minQuantity: yup.string().required("Min Quantity is required"),
    price: yup.string().required("Price is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [createMenu, { loading }] = useMutation(CREATE_MENU, {
    refetchQueries: ["ListMenu", "ListCategoryOrder", "ListAddOnType", "GetOneMenu"],
    onCompleted: (res) => {
      !imageDeleteCheck && props.setOpenAddMenu(false);
      !imageDeleteCheck &&
        props?.selectedItem === null &&
        props.setSelectedItem &&
        props.setSelectedItem(null);
    },
  });

  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;
  const catererId = props?.selectedItem?.catererId;

  const [updateMenu, { loading: updateLoading }] = useMutation(UPDATE_MENU, {
    refetchQueries: ["ListMenu", "ListCategoryOrder"],
    onCompleted: (res) => {
      !imageDeleteCheck && props.setOpenAddMenu(false);
      !imageDeleteCheck && props.setSelectedItem && props.setSelectedItem(null);
    },
  });

  const { data: addOns } = useQuery(LIST_ADDON_TYPE, {
    variables: { catererId, menuId: props?.selectedItem?._id },
  });

  const [createAddOnType] = useMutation(CREATE_ADDON_TYPE);

  const [createupdateAddon] = useMutation(CREATE_UPDATE_ADDON, {
    refetchQueries: ["ListAddOnType"],
  });

  const uploadImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/menu",
  };

  // uploading image
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uploadImageVariables,
  });

  function handleUploadClick(imagefile) {
    let obj = {};
    obj["fileName"] = imagefile.name;
    obj["url"] = URL.createObjectURL(imagefile);
    obj["file"] = imagefile;
    setSelectedImage(obj);
    upload();
  }

  function checkImage(e) {
    let file = e.target.files[0];
    if (!(file?.type == "image/jpeg" || file?.type == "image/png" || file?.type == "image/jpg")) {
      toggleSnackbar({
        status: true,
        message: "File does not support. You must use .png or .jpeg",
        variant: "error",
      });
      return false;
    } else if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    } else {
      var options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 300,
        useWebWorker: true,
      };
      imageCompression(file, options)
        .then((compressedFile) => {
          setCropImage(compressedFile);
          setCropOpen(true);
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    }
  }

  // call upload after crop
  function handelCropDone(result) {
    setCropImage(null);
    handleUploadClick(result);
  }

  //upload image
  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    setImageUploadLoading(false);
    onSubmit(params);
  }

  // for allowing only positive values
  const handleInputChange = (event) => {
    event.target.value = postiveValueConverter(event, "withDecimal");
  };
  const handleInputChangeWithoutDecimal = (event) => {
    event.target.value = postiveValueConverter(event, "withoutDecimal");
  };

  const publicUrl = uploadImageData?.imageUpload?.publicUrl;

  function onSubmit(params) {
    const createMenuData = {
      ...params,
      offerIndPkdFlg: checked,
      markasPopularFlg: popular,
      ...(params.additionalPkdPrice && parseFloat(params["additionalPkdPrice"])),
      price: parseFloat(params["price"]),
      catererId:
        props?.getLocId || props?.selectedItem?.catererId || props?.data?.getOneMenu?.catererId,
      // When duplicating, initilize itemImage as original.
      ...(props?.isDupe && {
        itemImage: props?.selectedItem?.itemImage ? props?.selectedItem?.itemImage : "",
      }),
      // If new image exists, ovewrite.
      ...((uploadImageData?.imageUpload?.publicUrl || isImageDelete === true) && {
        itemImage: publicUrl ? publicUrl : isImageDelete === true ? "" : "",
      }),
      // Do not pass `_id` if is duplicate.
      ...((props?.selectedItem !== null || props?.data?.getOneMenu?._id) &&
        !props?.isDupe && {
          _id: props?.selectedItem?._id || props?.data?.getOneMenu?._id,
        }),
    };

    try {
      // If selected item is null or isDupe then create new item.
      if (props?.selectedItem === null || props?.isDupe) {
        return createMenu({ variables: { data: createMenuData } })
          .then((res) => {
            const addOnsToDupe = addOns?.listAddOnType?.data || [];

            // If duplicating menu, also duplicate add-ons if they exist.
            if (props?.isDupe && addOnsToDupe.length > 0) {
              const menuId = res?.data?.createMenu?._id;
              const addOnsToCreate = generateDupeAddons(menuId, addOnsToDupe);

              // Iterate through all add-ons to duplicate
              return Promise.all(
                addOnsToCreate.map((data) => createAddOnType({ variables: { data } })),
              )
                .then((allRes) => {
                  // Duplicate sub-ons if they exist
                  return allRes.map((addOnCreateRes) => {
                    const data = addOnCreateRes?.data?.createAddOnType;
                    const newAddOnId = data._id;
                    const newMenuId = data.menuId;

                    // Create add-ons list and sub add-ons
                    const listToDupe = generateDupeAddOnsList(addOnsToDupe, newAddOnId, newMenuId);

                    // Loop over newly generated AddonsList and create them
                    listToDupe.forEach((listItem) => {
                      createupdateAddon({ variables: { addOnTypeId: newAddOnId, data: listItem } });
                    });

                    return toggleSnackbar({
                      status: true,
                      message: "Menu Duplicated Successfully",
                      variant: "success",
                    });
                  });
                })
                .catch((err) => {
                  const message = err && err.message;

                  return toggleSnackbar({
                    status: true,
                    message: message,
                    variant: "error",
                  });
                });
            }

            return toggleSnackbar({
              status: true,
              message: "Menu Created Successfully",
              variant: "success",
            });
          })
          .catch((err) => {
            const message = err && err.message;

            if (message === "Not authenticated") {
              localStorage.clear();

              authVar({
                loaded: true,
                auth: false,
              });

              return userType === "Admin"
                ? navigate("/admin", { state: "noAuth" })
                : navigate("/login", { state: "noAuth" });
            }

            return toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      }

      // Edit
      return updateMenu({
        variables: {
          id: props?.selectedItem?._id || props?.data?.getOneMenu?._id,
          data: createMenuData,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Menu Updated successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;

          if (message === "Not authenticated") {
            localStorage.clear();

            authVar({
              loaded: true,
              auth: false,
            });

            return userType === "Admin"
              ? navigate("/admin", { state: "noAuth" })
              : navigate("/login", { state: "noAuth" });
          }

          return toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;

      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  function handleDeleteImage() {
    setImageDeleteCheck(true);
    setIsImageDelete(true);
    setSelectedImage(null);
  }

  return (
    <Drawer
      className="common-drawer"
      anchor={"right"}
      open={props.openAddMenu}
      onClose={() => {
        props.setOpenAddMenu(false);
        props.setSelectedItem && props.setSelectedItem(null);
      }}>
      <div className="drawer-head">
        <h4 className="h4">
          {modifyTitle} an item on{" "}
          {props?.menuItems?.listMenu?.catererData?.businessName ||
            props?.data?.getOneMenu?.catererDtls?.businessName}
          ,{" "}
          {props?.selectedItem?.catererDtls?.locationName ||
            props?.data?.getOneMenu?.catererDtls?.locationName ||
            props?.menuItems?.listMenu?.catererData?.locationName}
        </h4>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.setOpenAddMenu(false);
            props.setSelectedItem && props.setSelectedItem(null);
          }}>
          <img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" />
        </span>
      </div>
      <form onSubmit={handleSubmit(uploadImage)} noValidate  autoComplete="off">
        <div className="create-step-form">
          <div className="container-fluid">
            {/*  crop image component */}
            <CropImage
              open={cropOpen}
              setOpen={setCropOpen}
              image={cropImage}
              handelCropDone={handelCropDone}
              aspectRatio={1}
            />
            {/* choose file */}
            <input
              type={"file"}
              className="d-none"
              id="add-user-input"
              accept=".jpg, .png, .jpeg"
              onChange={checkImage}
              onClick={(event) => {
                event.target.value = null;
              }}
            />

            <div className="text-center">
              <div className="upload-img-wrap">
                <Avatar
                  alt="Remy Sharp"
                  src={
                    selectedImage
                      ? selectedImage.url
                      : props?.selectedItem?.itemImage && !imageDeleteCheck
                      ? props?.selectedItem?.itemImage
                      : props?.data?.getOneMenu?.itemImage && !imageDeleteCheck
                      ? props?.data?.getOneMenu?.itemImage
                      : "../assets/images/uploadFood.jpg"
                  }
                  className="upload-img upload-img-square"
                  onClick={(e) => document.getElementById("add-user-input").click()}
                />
                {(selectedImage === null ||
                  props?.selectedItem?.itemImage === undefined ||
                  props?.data?.getOneMenu?.itemImage === null ||
                  (props?.data?.getOneMenu?.itemImage && imageDeleteCheck === true)) && (
                  <img
                    alt="uploadImage"
                    onClick={(e) => document.getElementById("add-user-input").click()}
                    src="../assets/images/icon-upload-plus.svg"
                    className="upload-icon img-fluid"
                  />
                )}
                {(selectedImage ||
                  (props?.selectedItem?.itemImage && imageDeleteCheck === false) ||
                  (props?.data?.getOneMenu?.itemImage && imageDeleteCheck === false)) && (
                  <img
                    alt="closeIconImage"
                    onClick={() => handleDeleteImage()}
                    src="../assets/images/close-black.svg"
                    className="upload-icon img-fluid"
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 textBox">
                <TextField
                  id="createAccountAdminName"
                  defaultValue={props?.selectedItem?.itemName || props?.data?.getOneMenu?.itemName}
                  label="Item Name*"
                  autoFocus
                  variant="outlined"
                  className="textField allFeild"
                  {...register("itemName")}
                />
                {errors.itemName && <span className="error-msg">{errors.itemName.message}</span>}
              </div>
              <div className="col-md-6 allFeild">
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Category*</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={
                        props?.selectedItem?.categoryId || props?.data?.getOneMenu?.categoryId || ""
                      }
                      label="Category"
                      {...register("categoryId")}>
                      {props?.categoryData?.listCategory?.data?.map((item) => (
                        <MenuItem value={item?._id}>{item?.categoryTitle}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {errors.categoryId && (
                  <span className="error-msg">{errors.categoryId.message}</span>
                )}
              </div>
              <div className="col-md-6 allFeild">
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Dietary*</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={
                        props?.selectedItem?.dietory || props?.data?.getOneMenu?.dietory || ""
                      }
                      {...register("dietory")}
                      label="Dietory">
                      {Dietary.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {errors.dietory && <span className="error-msg">{errors.dietory.message}</span>}
              </div>
              <div className="col-md-12 textBox">
                <TextField
                  id="createAccountAdminName"
                  defaultValue={
                    props?.selectedItem?.itemDescription || props?.data?.getOneMenu?.itemDescription
                  }
                  label="Description"
                  multiline
                  variant="outlined"
                  className="textField allFeild"
                  {...register("itemDescription")}
                />
              </div>
              <div className="col-md-4 textBox">
                <Box sx={{ minWidth: 150 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={
                        props?.selectedItem?.itemType || props?.data?.getOneMenu?.itemType || ""
                      }
                      {...register("itemType")}
                      label="Type">
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Tray"}>Tray</MenuItem>
                      <MenuItem value={"Packaged"}>Packaged</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="col-md-4 textBox">
                <TextField
                  id="createAccountAdminName"
                  defaultValue={
                    props?.selectedItem?.minQuantity || props?.data?.getOneMenu?.minQuantity
                  }
                  label="Min Quantity*"
                  // inputProps={{ type: "number" }}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("minQuantity")}
                  onChange={handleInputChangeWithoutDecimal}
                />
                {errors.minQuantity && (
                  <span className="error-msg">{errors.minQuantity.message}</span>
                )}
              </div>
              <div className="col-md-4 textBox">
                <TextField
                  id="outlined-basic"
                  label="Price*"
                  // type={"number"}
                  defaultValue={props?.selectedItem?.price || props?.data?.getOneMenu?.price}
                  variant="outlined"
                  className="textField allFeild"
                  // inputProps={{ type: "number" }}
                  {...register("price")}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
                {errors.price && <span className="error-msg">{errors.price.message}</span>}
              </div>
              {checked && (
                <div className="col-md-12 textBox">
                  <TextField
                    id="outlined-basic"
                    label="Additional Price for Individual Packaging"
                    // type={"number"}
                    inputProps={{ type: "number", min: "0" }}
                    defaultValue={
                      props?.selectedItem?.additionalPkdPrice ||
                      props?.data?.getOneMenu?.additionalPkdPrice
                    }
                    variant="outlined"
                    className="textField allFeild"
                    {...register("additionalPkdPrice")}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => {
              props.setOpenAddMenu(false);
              props.setSelectedItem && props.setSelectedItem(null);
            }}>
            Cancel
          </Button>
          <Button
            className="saveBtn"
            disabled={updateLoading || loading}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => setImageDeleteCheck(false)}>
            {loading || updateLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : props?.selectedItem === null ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
        </div>
      </form>
    </Drawer>
  );
}
