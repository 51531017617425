import React from "react";

import { useController } from "react-hook-form";
import { OutlinedTextFieldProps, TextField } from "@mui/material";
import { PATTERNS } from "../../constants/patterns.constants";

const PHONE_FORMAT_SYMBOLS = /[-,(,), ]+/g;

interface IPhoneFormProps extends OutlinedTextFieldProps {
  id?: string;
  label?: string;
  className?: string;
  control: any;
  name: string;
  rules: object;
  onUpdate?(name: string, value: string): void;
}

export function PhoneForm({
  id,
  label,
  className,
  control,
  name,
  rules,
  onUpdate,
  ...props
}: IPhoneFormProps) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      ...rules,
      validate: (value) => {
        const isValid = PATTERNS.phone.test(value);
        if (!isValid) {
          return "Please enter correct phone number";
        }
        return isValid;
      },
    },
  });

  const formatPhoneNumber = (num) => {
    const cleanedNum = num.replace(PHONE_FORMAT_SYMBOLS, "");

    const formattedNum =
      cleanedNum.length <= 3
        ? cleanedNum
        : cleanedNum.length <= 6
        ? `(${cleanedNum.slice(0, 3)}) ${cleanedNum.slice(3)}`
        : `(${cleanedNum.slice(0, 3)}) ${cleanedNum.slice(3, 6)}-${cleanedNum.slice(6, 10)}`;

    return formattedNum;
  };

  function onInput(event) {
    const inputValue = event.target.value.replace(PHONE_FORMAT_SYMBOLS, "");
    if (!isNaN(inputValue) && inputValue.length <= 10) {
      onChange(formatPhoneNumber(event.target.value));
      onUpdate && onUpdate(name, formatPhoneNumber(event.target.value));
    }
  }

  return (
    <TextField
      {...props}
      id={id}
      error={error ? !!error.message : false}
      label={label}
      helperText={error ? error.message : ""}
      value={value}
      className={className}
      onChange={onInput}
      onBlur={onBlur}
    />
  );
}
