import { Button } from "@mui/material";
import React, { useState } from "react";
import { formatter } from "../Common/commonUtils";
import VoucherBox from "./VoucherBox";
export default function CaterCashSummery(props) {
  const [openVoucherBox,setOpenVoucherBox] = useState(false)
  const [selectedAmount,setSelectedAmount]= useState('')
  return (
    <div>
      <VoucherBox 
      openVoucherBox={openVoucherBox}
      setOpenVoucherBox={setOpenVoucherBox}
      selectedAmount={selectedAmount}
      setSelectedAmount={setSelectedAmount}
      setVoucherData={props?.setVoucherData}
      />
      {props?.userType === "Customer" ? (
        <div className="catercash-overview">
          <div className="cc-item cc-total">
           { props?.caterCashTotal?.listCaterCashTotal&&<h2>{formatter.format(props?.caterCashTotal?.listCaterCashTotal?.totEarned)}</h2>}
            <div className="d-md-flex cc-item-inner align-items-center">
              <img
                src="../assets/images/catercashsum.svg"
                className="img-fluid"
                alt="Icon"
              /><label>Total CaterCash</label>
            </div>

          </div>
          <div className="cc-item cc-redeemed">
           {props?.caterCashTotal?.listCaterCashTotal&& <h2>{formatter.format(props?.caterCashTotal?.listCaterCashTotal?.totRedeemed)}</h2>}
            <div className="d-md-flex cc-item-inner align-items-center">
              <img
                src="../assets/images/redeemed.svg"
                className="img-fluid"
                alt="Icon"
              />
              <label>Redeemed CaterCash</label>
            </div>
          </div>

          <div className="cc-item cc-current">
            {props?.caterCashTotal?.listCaterCashTotal&&<h2>{formatter.format(props?.caterCashTotal?.listCaterCashTotal?.totEarned-props?.caterCashTotal?.listCaterCashTotal?.totRedeemed-props?.caterCashTotal?.listCaterCashTotal?.totRefunded)}</h2>}
            <div className="d-md-flex cc-item-inner align-items-center">
              <img
                src="../assets/images/current.svg"
                className="img-fluid"
                alt="Icon"
              />
              <label>Current Available CaterCash</label>
            </div>
          </div>

          <div className="cc-item cc-gift">
            <img src="../assets/images/amazon.svg" className="img-fluid" alt="Amazon Gift"/>
            <small>Choose a Value to Redeem Your CaterCash</small>
            <div className="amazon-btn">
              <Button variant={"contained"} onClick={()=>{setOpenVoucherBox(true);setSelectedAmount(10)}}>$10</Button>
              <Button variant={"contained"} onClick={()=>{setOpenVoucherBox(true);setSelectedAmount(25)}}>$25</Button>
              <Button variant={"contained"} onClick={()=>{setOpenVoucherBox(true);setSelectedAmount(50)}}>$50</Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="catercash-overview">
          <div className="cc-item cc-gift">
            {props?.totalData?.getCaterCashBalance&&<h2>{formatter.format(props?.totalData?.getCaterCashBalance?.caterCashBalance)}</h2>}
            <div className="d-flex  align-items-center">
              <img
                src="../assets/images/amazon-icon.svg"
                className="img-fluid"
                alt="Icon"
              />
              <label>Amazon Gift Card Balance</label>
            </div>
          </div>
          <div className="cc-item cc-total">
            {props?.caterCashTotal?.listCaterCashTotal&&<h2>{formatter.format(props?.caterCashTotal?.listCaterCashTotal?.totEarned-props?.caterCashTotal?.listCaterCashTotal?.totGift)}</h2>}
            <div className="d-flex align-items-center">
              <img
                src="../assets/images/earnedcash.svg"
                className="img-fluid"
                alt="Icon"
              />
              <label>CaterCash Earned</label>
            </div>
          </div>

          <div className="cc-item  cc-redeemed">
            {props?.caterCashTotal?.listCaterCashTotal&&<h2>{formatter.format(props?.caterCashTotal?.listCaterCashTotal?.totRedeemed)}</h2>}
            <div className="d-flex align-items-center">
              <img
                src="../assets/images/redeemedcash.svg"
                className="img-fluid"
                alt="Icon"
              />
              <label>CaterCash Redeemed</label>
            </div>
          </div>

          <div className="cc-item cc-current">
            {props?.caterCashTotal?.listCaterCashTotal&&<h2>{formatter.format(props?.caterCashTotal?.listCaterCashTotal?.totGift)}</h2>}
            <div className="d-flex align-items-center">
              <img
                src="../assets/images/giftedcash.svg"
                className="img-fluid"
                alt="Icon"
              />
              <label>CaterCash Gifted</label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
