import React from 'react';

import { useState, useEffect } from "react"
import { useForm, useFieldArray } from "react-hook-form";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Button from "@mui/material/Button"
import { CREATE_UPDATE_ADDON } from "../../../Graphql/mutations";
import {
  toggleSnackbar,authVar,userDeatils
} from "../../../ReactiveVariables/index";
import { useMutation } from "@apollo/client";
import { IconButton } from "@mui/material";
import DialogBox from "../../Common/dialogBox";
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#D34343',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'green',
    boxSizing: 'border-box',
  },
}));
const AddonList = [
  {
    addOnName: "",
    addOnType: "nonVeg",
    addOnPrice: "",
    menuId: null,
    addOnTypeId: null,
    catererId: null,
    subAddOns: [],
  },
];
const SubAddon = (props) => {
  const navigate= useNavigate()
  const user = useReactiveVar(userDeatils);
  const [openDrawer, setOpenDrawer] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [openSubAddonDialog, setOpenSubAddonDialog] = useState(false)
  const [indexState, setIndexState] = useState()
  const [index1State, setIndex1State] = useState(false)
  const [index2State, setIndex2State] = useState(false)
  const [deleteAlert,setDeleteAlert] = useState(null)
  const userType =
    user?.data?.login?.userType || user?.data?.currentUserDetails?.userType || user?.data?.createUserIdentity?.userType || user?.data?.createCaterer?.userType;
  const { control } = useForm({
  });
  const [createupdateAddon] = useMutation(CREATE_UPDATE_ADDON, { refetchQueries: ['ListAddOnType'] ,
  onCompleted:res=>{
    setDeleteAlert(null)
  }})
  useEffect(()=>{
    if(props?.newlyAddedAddOn){
      setOpenDrawer(props?.newlyAddedAddOn._id)
    }
  },[props?.newlyAddedAddOn])
//for adding new addon
  function addNewAddon(item, index) {
    let obj = {}
    obj.addOnName = "";
    obj.addOnType = "nonVeg";
    obj.addOnPrice = 0;
    obj.sortOrder = props?.mainAddonData?.listAddOnType?.data[index].addOnsList.length
    obj.menuId = item?.menuId;
    obj.catererId = item?.catererId;
    obj.addOnTypeId = item?._id;
    obj.subAddOns = []
    let test1 = JSON.stringify(props?.mainAddonData)
    let test2 = JSON.parse(test1)
    test2.listAddOnType?.data[index].addOnsList.push(obj)
    props?.setMainAddonData(test2)
  }
  //for adding sub addon
  function addNewsubAddon(item, index, index2) {
    let test1 = JSON.stringify(props?.mainAddonData)
    let test2 = JSON.parse(test1)
    let obj = {}
    obj.subAddOnName = "";
    obj.subAddOnPrice = 0;
    obj._id = null;
    obj.subSortOrder = props?.mainAddonData?.listAddOnType?.data[index2].addOnsList[index].subAddOns.length;
    test2.listAddOnType?.data[index2].addOnsList[index].subAddOns.push(obj)
    props?.setMainAddonData(test2)
  }

  //For sort order
  function sortorder(data, index) {
    for (let i = index; i < data.length; i++) {
      if (data[i].sortOrder) {
        data[i].sortOrder = data[i].sortOrder - 1
      }
      if (data[i].subSortOrder) {
        data[i].subSortOrder = data[i].subSortOrder - 1
      }
    }
    return data
  }
  // Delete add on
async  function delAddon(item, index, index2) {
    index = indexState;
    index2 = index2State;
    let test1 = JSON.stringify(props?.mainAddonData)
    let test2 = JSON.parse(test1)
    let newdata = sortorder(test2.listAddOnType?.data[index2].addOnsList, index)
    newdata.splice(index, 1);
    await props?.setMainAddonData(test2)
      document.getElementById("myButton").click(); 
  }
  //delete sub addon
 async function delsubAddon(item, index, index1, index2) {
    index = indexState;
    index1 = index1State;
    index2 = index2State;
    let test1 = JSON.stringify(props?.mainAddonData)
    let test2 = JSON.parse(test1)
    let newdata = sortorder(test2.listAddOnType?.data[index2].addOnsList[index].subAddOns, index1)
    newdata.splice(index1, 1);
    await props?.setMainAddonData(test2)
      document.getElementById("myButton").click(); 
  }
  const addValues = (item, index, index2, type, value) => {
    let data1 = JSON.stringify(props?.mainAddonData);
    let data2 = JSON.parse(data1);

    let obj = data2?.listAddOnType?.data[index2].addOnsList[index]
    if (type === "addOnPrice") {
      obj[type] = parseFloat(value)
    }
    else {
      obj[type] = value === true ? "noVeg" : value === false ? "veg" : value;
    }
    data2.listAddOnType.data[index2].addOnsList[index] = obj;
    props.setMainAddonData(data2);
  }
  const addSubValues = (item, index, index1, index2, type, value) => {
    let data1 = JSON.stringify(props?.mainAddonData)
    let data2 = JSON.parse(data1)
    let obj = data2?.listAddOnType?.data[index2].addOnsList[index].subAddOns[index1]
    if (type === "subAddOnPrice") {
      obj[type] = parseFloat(value)
    }
    else {
      obj[type] = value
    }
    data2.listAddOnType.data[index2].addOnsList[index].subAddOns[index1] = obj
    props.setMainAddonData(data2);
  }
// for disabling the sort button
  const buttonDisabledclass = (item,addonItem, value) => {
    let itemListClone = structuredClone(addonItem?.addOnsList)
    let addOnListLength = 0;
    itemListClone.map(list => {
      if (list?.addOnName) {
        addOnListLength++
      }
  })
    if (!item?.addOnName) {
      return 'disabled'
    }else if(value === 'upward' && item.sortOrder === 0){
      return 'disabled'
    }
    else if(value === 'downward' && item.sortOrder === addOnListLength-1){
      return 'disabled'
    }
  }
  // the addons fliping functionality
  const handleUpdateSortOrder = (item, itemList, data) => {
    props?.setCreateAddOnStatus(false)
    let itemListClone = structuredClone(itemList?.addOnsList)
let updatedItemList =[]
    let addOnListLength = 0;
    let itemListNew = []
    itemListClone.map((list) => {
      if (list?.addOnName) {
        addOnListLength++
        itemListNew.push(list)
      }
    })
//for upward arrow flaping
    if (data === 'upward') {
      let sortValue = item.sortOrder
      if (sortValue > 0 && sortValue < addOnListLength) {
        let newSortValue = sortValue - 1
         updatedItemList = itemListNew.map((list, index) => {
          let id;
          let indexValue; 
          if (list.sortOrder === sortValue) {
            indexValue = index 
            id = list._id
            list.sortOrder = newSortValue
          }
          if (list.sortOrder === newSortValue && (id !== list._id || indexValue !== index)) {
            list.sortOrder = sortValue
          }
          delete list._id
          delete list.__typename

          list?.subAddOns.map(sub => {
            delete sub._id
            delete sub.__typename
          })
          return list
        })
      } else {
        return
      }

    } 
    //downward arrow flaping
    else {
      let sortValue = item.sortOrder
      if(sortValue >= 0 && sortValue !== null){
      let newSortValue = sortValue + 1
      if (newSortValue < addOnListLength) {
        updatedItemList = itemListNew.map((list,index)=> {
          let id;
          let indexValue;
          if (list.sortOrder === sortValue) {
            indexValue = index 
            id = list._id
            list.sortOrder = newSortValue
          }
          if (list.sortOrder === newSortValue && (id !== list._id || indexValue !== index)) {
            list.sortOrder = sortValue
          }
          delete list._id
          delete list.__typename
          list?.subAddOns.map(sub => {
            delete sub._id
            delete sub.__typename
          })
          return list
        })
      } else {
        return
      }
    }else{
      return
    }
    }
    //update add on api
    try {
      createupdateAddon({ variables: { addOnTypeId: itemList?._id, data: updatedItemList } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Add on sorted successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }

  }
//for saving the addons and sub addons
  const handleSave = (addonItem, item, index, index2) => {
    props?.setCreateAddOnStatus(false)
    let addonItemClone = structuredClone(addonItem)

    if (addonItemClone && addonItemClone.addOnsList && addonItemClone.addOnsList.length > 0) {
      addonItemClone.addOnsList = addonItemClone.addOnsList.filter(addOn => (addOn.addOnName !== ""));
    }
    if (!deleteAlert&&addonItemClone.addOnsList.length === 0) {
      toggleSnackbar({
        status: true,
        message: "Add On is empty",
        variant: 'error'
      });
      return
    }

    let saveData = addonItemClone?.addOnsList
    let updatedData = []
    saveData.forEach(element => {
      let obj = {}
      obj.addOnName = element?.addOnName;
      obj.addOnPrice = (element?.addOnPrice===null||isNaN(element?.addOnPrice))?0:element?.addOnPrice;
      obj.addOnType = element?.addOnType;
      obj.addOnTypeId = element?.addOnTypeId;
      obj.catererId = element?.catererId;
      obj.menuId = element?.menuId;
      obj.sortOrder = element?.sortOrder;
      if(element?._id){
        obj._id = element?._id;
      }
      obj.subAddOns = [];

      let newSaveData = element?.subAddOns
      newSaveData.forEach(element1 => {
        let subObj = {}
        subObj.subAddOnName = element1?.subAddOnName
        subObj.subAddOnPrice = (element1?.subAddOnPrice===null||isNaN(element1?.subAddOnPrice))?0:element1?.subAddOnPrice
        subObj.subSortOrder = element1?.subSortOrder
        if (element1?._id !== null) {
          subObj._id = element1?._id
        }
        obj.subAddOns.push(subObj)
      });
      updatedData.push(obj)
    });

    try {
      createupdateAddon({ variables: { addOnTypeId: addonItem?._id, data: updatedData } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: deleteAlert??"Changes saved successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "AddOn"
  });
  return (
    <>
      {props?.mainAddonData?.listAddOnType?.data?.map((addonItem, index2) => {
        return (
          <div className="main-addon-wrap">
            <div className="main-addon">
              {openDialog && (<DialogBox
                setOpenDialog={setOpenDialog}
                handleDelete={delAddon}
                openDialog={openDialog}
                title="Delete"
                discription="Are you sure you want to delete this item?"
              />
              )}
              {openSubAddonDialog && (<DialogBox
                setOpenDialog={setOpenSubAddonDialog}
                handleDelete={delsubAddon}
                openDialog={openSubAddonDialog}
                title="Delete"
                discription="Are you sure you want to delete this item?"
              />
              )}
              <h3 className="addon-list" onClick={() => { setOpenDrawer(addonItem?._id); addonItem?.addOnsList.length === 0 && addNewAddon(addonItem, index2) }}>{`${addonItem?.addOnTypeName} (${addonItem?.type} Choice${addonItem?.type === "Multiple" ? ` , limit: ${addonItem.addOnTypeLimit}` : ''})`}</h3>
              <div>
                <div className="add-action">
                  <a className="ld-edit" onClick={() => { props?.setOpenAddon(true); props?.setSelectedAddOn(addonItem);props.setEditAddOnFlag(true) }}>
                    <img src="../assets/images/icon-menu-edit.svg" className="img-fluid" alt="Edit Icon" />
                    Edit
                  </a>
                  <IconButton onClick={() => { props?.setOpenDialog(true); props?.setSelectedAddOn(addonItem) }}>
                    <img src="../assets/images/Trash.svg" className="img-fluid" alt="Delete Icon" />
                  </IconButton>
                  {
                    openDrawer !== addonItem?._id &&
                    <IconButton onClick={() => { setOpenDrawer(addonItem?._id); addonItem?.addOnsList.length === 0 && addNewAddon(addonItem, index2) }}>
                      <img src="../assets/images/plus.svg" className="img-fluid" alt="expand Icon" />
                    </IconButton>
                  }
                  {
                    openDrawer === addonItem?._id &&
                    <IconButton onClick={() => setOpenDrawer(null)}>
                      <img src="../assets/images/minus.svg" className="img-fluid" alt="expand Icon" />
                    </IconButton>
                  }
                </div>
              </div>
            </div>
            {openDrawer === addonItem?._id &&
              <div>
                <div className="main-add-on-head d-flex">
                  <label className="flex-2 width-1">Item Name</label>
                  <label className="flex-1 width-2"><span style={{ color: '#008D3D' }}>Veg</span>/<span style={{ color: '#D34343' }}>Non-Veg</span></label>
                  <label className="flex-2 width-3">Additional price if any</label>
                </div>
                <div>
                  {addonItem?.addOnsList?.map((item, index) => {
                    return (
                      <div>
                        <div className="sub-add-on-wrap">
                          <div className="d-flex flex-column sub-addon-sort">
                            <IconButton className={buttonDisabledclass(item, addonItem,'upward')} onClick={() => handleUpdateSortOrder(item, addonItem, 'upward')}>
                              <img src="../assets/images/sort-up.svg" className="img-fluid" alt="Arrow Drop Up Icon" />
                              {/* <ArrowDropUpIcon  /> */}
                            </IconButton>
                            <IconButton className={buttonDisabledclass(item, addonItem,'downward')} onClick={() => handleUpdateSortOrder(item, addonItem, 'downward')}>
                              <img src="../assets/images/sort-down.svg" className="img-fluid" alt="Arrow Drop Down Icon" />
                              {/* <ArrowDropDownIcon /> */}
                            </IconButton>
                          </div>
                          <div className="sub-add-on-inner">
                            <div className="d-flex align-items-center">
                              <div className="flex-2 width-1">
                                <input className="form-control" type={"text"} value={item?.addOnName} onChange={(e) => { addValues(item, index, index2, "addOnName", e.target.value) }} />
                              </div>
                              <div className="flex-1 width-2">
                                <AntSwitch size={'50'} className="veg-nonveg-switch" checked={item?.addOnType === "veg" ? false : true} onChange={(e) => { addValues(item, index, index2, "addOnType", e.target.checked) }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                              </div>
                              <div className="flex-2 width-3 sub-add-on-right d-flex align-items-center">
                                <input className="form-control sub-add-on-price" type={"number"} min={0} value={item?.addOnPrice} onChange={(e) => {   
                                  if(e.target.value < 0) {
                                      e.target.value = -(e.target.value)
                                    }
                                    addValues(item, index, index2, "addOnPrice", e.target.value) }} />
                                <div>
                                  {index !== addonItem?.addOnsList.length - 1 &&
                                    <Button
                                      className="sub-add-on-btn"
                                      variant="contained"
                                      color="primary"
                                      onClick={() => addNewsubAddon(item, index, index2)}
                                    >
                                      <img src="../assets/images/icon-add-plus.svg" className="img-fluid me-1" alt="Icon" /> Sub
                                    </Button>
                                  }

                                  {index === addonItem?.addOnsList.length - 1 &&
                                    <Button
                                      className="sub-add-on-btn"
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {    
                                        props?.setCreateAddOnStatus(false);
                                        addNewAddon(addonItem, index2)}}
                                    >
                                      <img src="../assets/images/icon-add-plus.svg" className="img-fluid me-1" alt="Icon" /> Add
                                    </Button>
                                  }
                                  {index === addonItem?.addOnsList.length - 1 &&
                                    <Button
                                      className="sub-add-on-btn"
                                      variant="contained"
                                      color="primary"
                                      id="myButton"
                                      onClick={() => handleSave(addonItem, item, index, index2)}
                                    >
                                      Save
                                    </Button>
                                  }
                                  {index !== addonItem?.addOnsList.length - 1 && <IconButton onClick={() => {setIndexState(index); setIndex2State(index2); setOpenDialog(true);setDeleteAlert("Addon deleted successfully")}}>
                                    <img src="../assets/images/Trash.svg" className="img-fluid" alt="Delete Icon" />
                                  </IconButton>}
                                </div>
                              </div>
                            </div>
                            {item?.subAddOns?.length >= 1 &&
                              <div className="sub-add-on-type-wrap">
                                {item?.subAddOns.map((items, index1) => {
                                  return (
                                    <div className="sub-add-on-type d-flex align-items-center">
                                      <input className="form-control" type={"text"} value={items?.subAddOnName} onChange={(e) => { addSubValues(item, index, index1, index2, "subAddOnName", e.target.value) }} />
                                      <input className="form-control sub-add-on-type-price" type={"number"} min={0} value={items?.subAddOnPrice} 
                                      onChange={(e) => {
                                        if(e.target.value < 0) {
                                          e.target.value = -(e.target.value)
                                        }
                                        addSubValues(item, index, index1, index2, "subAddOnPrice", e.target.value)
                                         }} />
                                      <IconButton type="button" onClick={() =>{ setIndexState(index); setIndex1State(index1);setIndex2State(index2); setOpenSubAddonDialog(true);setDeleteAlert("Sub Addon deleted successfully")}}>
                                        <img src="../assets/images/Trash.svg" className="img-fluid" alt="Delete Icon" />
                                      </IconButton>
                                    </div>

                                  )
                                })}
                              </div>
                            }
                          </div>
                        </div>



                      </div>
                    )
                  }
                  )}
                </div>
              </div>}
          </div>
        )
      })
      }
    </>
  )
}
export default SubAddon