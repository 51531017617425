import React, { useState } from "react";
import moment from "moment";
import { Button, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";

import { checkBrowser, formatter } from "Modules/Common/commonUtils";
import { useAppDispatch } from "@services/redux";
import { GET_ONE_ORDER } from "Graphql/queries";
import { REORDER_EXIST } from "Graphql/mutations";
import {
  addUnavailableCartItems,
  formatServerOrder,
  updateDraftOrder,
} from "@services/redux/order";
import { toggleSnackbar } from "ReactiveVariables";
import { IOrderResponseItem } from "@screens/orders/order.typings";
import { IReOrderResponse } from "typings/order.api";
import { DATE_FORMATS } from "@constants/date-formats.constants";

interface IViewAddressOrderItemProps {
  item: IOrderResponseItem;
  customerId: string;
}

export function ViewAddressOrderItem({ item, customerId }: IViewAddressOrderItemProps) {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const [getOrderRequest] = useLazyQuery(GET_ONE_ORDER, {});
  const [reorderExist] = useMutation<IReOrderResponse>(REORDER_EXIST);

  async function handleReOrder() {
    const userAgent = navigator.userAgent;

    try {
      setLoading(true);
      const reorder = await reorderExist({
        variables: {
          orderId: item?._id,
          browserName: checkBrowser(userAgent),
          userAgent,
        },
      });

      const orderResponse = await getOrderRequest({
        variables: {
          customerId,
          orderId: reorder.data.reOrderExist._id,
        },
      });

      const newOrder = formatServerOrder(orderResponse.data.getOneOrder);
      dispatch(addUnavailableCartItems(reorder.data.reOrderExist.removedMenuName));
      dispatch(updateDraftOrder(newOrder));
      navigate(`/caterers/${newOrder.catererSlug}`);
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <TableRow style={{ cursor: "pointer" }}>
      <TableCell>{`#${item?.orderNo}`}</TableCell>
      <TableCell>
        {`${moment(item?.deliveryDate).format(DATE_FORMATS.orderCheckout)}, ${item?.deliveryTime}`}
      </TableCell>
      <TableCell>
        {`${item?.catererDetails?.businessName}, ${item?.catererDetails?.locationName}`}
      </TableCell>
      <TableCell>{formatter.format(item?.grandTotal)}</TableCell>
      <TableCell align="center">
        {item?.cartDetails?.map((order, index) =>
          index > 0 ? order?.itemName + ", " : order?.itemName,
        )}
      </TableCell>
      <TableCell align="center">
        <Button
          disabled={isLoading}
          className="status-btn"
          style={{ color: "#fff", backgroundColor: "#FFC806" }}
          variant="contained"
          onClick={handleReOrder}>
          Reorder
        </Button>
      </TableCell>
    </TableRow>
  );
}
