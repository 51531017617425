import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { getUserId } from "@utils/user-format.utils";
import { DELETE_ADDRESS } from "Graphql/mutations";
import { LIST_ADDRESS } from "Graphql/queries";
import { toggleSnackbar, userDeatils } from "ReactiveVariables";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAddressListItemResponse, IAddressListResponse } from "typings/address.api";

export function useAddressListState() {
  const navigate = useNavigate();
  const user = useReactiveVar(userDeatils);
  const [isLoading, setIsLoading] = useState(false);

  const [addressElement, setAddressElement] = useState(null);
  const [selectedItem, setSelectedItem] = useState<IAddressListItemResponse>(null);
  const [isAddressFormOpen, setAddressFormOpen] = useState(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(1);

  const { data: savedAddressList, loading } = useQuery<IAddressListResponse>(LIST_ADDRESS, {
    fetchPolicy: "network-only",
    variables: {
      customerId: getUserId(user),
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    },
  });

  const [deleteSavedAddress] = useMutation(DELETE_ADDRESS, {
    refetchQueries: ["ListAddress"],
  });

  async function onRemoveAddress() {
    try {
      setIsLoading(true);
      await deleteSavedAddress({ variables: { addressId: selectedItem?._id } });

      toggleSnackbar({
        status: true,
        message: "Saved address deleted successfully",
        variant: "success",
      });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function onAddAddress() {
    setAddressFormOpen(true);
    setSelectedItem(null);
  }

  function onOpenAddressMenu(event, item: IAddressListItemResponse) {
    if (isLoading) {
      return;
    }
    setSelectedItem(item);
    setAddressElement(event.currentTarget);
  }

  function onViewAddressItem() {
    if (isLoading) {
      return;
    }
    navigate(`${selectedItem?._id}`);
    onCloseAddressMenu();
  }

  function onEditAddressItem() {
    setAddressFormOpen(true);
    onCloseAddressMenu();
  }

  function onDeleteAddressItem() {
    setRemoveModalOpen(true);
    onCloseAddressMenu();
  }

  function onCloseAddressMenu() {
    setAddressElement(null);
  }

  function onCloseAddressForm() {
    setAddressFormOpen(false);
    setSelectedItem(null);
  }

  return {
    addressList: {
      loading,
      items: savedAddressList ? savedAddressList.listAddress.data : [],
      count: savedAddressList ? savedAddressList.listAddress.count : 0,
    },
    pagination: {
      page,
      rowsPerPage,
      setPage,
      setRowsPerPage,
    },
    addressModal: {
      isAddressFormOpen,
      onCloseAddressForm,
      selectedItem,
      setSelectedItem,
    },
    removeAddress: {
      isRemoveModalOpen,
      setRemoveModalOpen,
      onRemoveAddress,
    },
    isLoading: isLoading || loading,
    addressElement,
    onOpenAddressMenu,
    onCloseAddressMenu,
    onViewAddressItem,
    onEditAddressItem,
    onDeleteAddressItem,
    onAddAddress,
  };
}
