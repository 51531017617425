// this compenent is for change admin/ add admin/ edit profile for Admin users
import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  IconButton,
  Button,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { toggleSnackbar,authVar,userDeatils } from "../../../ReactiveVariables/index";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
  UPDATE_USER,
  CREATE_USER_IDENTITY,
  CREATE_CATERER_USER,
} from "../../../Graphql/mutations";
import Visibility from "@mui/icons-material/Visibility";
import {GET_ONE_USER_INFO, IMAGE_UPLOAD} from "../../../Graphql/queries";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import imageCompression from "browser-image-compression";
import CropImage from "../../Common/cropImage";
import axios from "axios";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useReactiveVar } from "@apollo/client";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useNavigate } from "react-router-dom";
export default function AdminProfileInfo(props) {
  const navigate = useNavigate()
  const auth = useReactiveVar(authVar);
  const user = useReactiveVar(userDeatils);
  const userType =
  user?.data?.login?.userType || user?.data?.currentUserDetails?.userType || user?.data?.createUserIdentity?.userType || user?.data?.createCaterer?.userType;
  const [phoneNumberUpdate, setPhoneNumberUpdate] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [resetPassword, setResetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [imageDeleteCheck, setImageDeleteCheck] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState(
  //   props?.data?.getOneCaterer?.catererUsers[0]?.userDtls?.phoneNumber ||
  //     props?.data?.data?.currentUserDetails?.phoneNumber ||
  //     props?.data?.data?.login?.phoneNumber ||
  //     props?.data?.data?.createCaterer?.phoneNumber ||
  //     props?.selectedItem?.phoneNumber ||
  //     props?.userData?.data?.currentUserDetails?.phoneNumber ||
  //     props?.userData?.data?.login?.phoneNumber ||
  //     props?.userData?.data?.createUserIdentity?.phoneNumber||props?.selectedItem?.catererUsers[0]?.userDtls?.phoneNumber

  // );
  const [value, setValue] = React.useState(0);
  const schema = yup.object().shape({
 
    firstName: !resetPassword && yup.string().required("First Name is required"),
    lastName: !resetPassword && yup.string().required("Last Name is required"),
   // name:  !props?.customerProfileEditName&&!resetPassword && yup.string().required("Admin Name is required"),
    email:
      !resetPassword &&
      yup
        .string()
        .required("Email is required")
        .email("Please enter a valid email address"),
    phoneNumber:
      !resetPassword &&
      yup.string().required("Phone is required").min(14, "The phone number must be 10 digits."),
    password: resetPassword
      ? props.title === "Profile"
        ? yup
            .string()
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,100}$/,
              "Password is Invalid"
            )
            // .test(
            //   "password",
            //   "Password should be 6 characters minimum ",
            //   (value) => {
            //     if (value && value.length < 6) return false;
            //     else return true;
            //   }
            // )
        : yup
            .string()
            .required("Password is required")
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,100}$/,
              "Password is Invalid"
            )
            // .min(6, "Password should be 6 characters minimum.")
            // .max(16, "Password should be 16 characters maximum.")
      : null,

    confirmPassword: resetPassword
      ? props.title === "Profile"
        ? yup
            .string()
            .oneOf(
              [yup.ref("password"), null],
              "Password and confirm password do not match"
            )
        : yup
            .string()
            .required("Confirm Password is required")
            .oneOf(
              [yup.ref("password"), null],
              "Password and confirm password do not match"
            )
      : null,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      smsEnabled: props?.data?.getOneCaterer?.smsEnabled || false,
      phoneNumber:
        props?.data?.getOneCaterer?.catererUsers[0]?.userDtls?.phoneNumber ||
        props?.data?.data?.currentUserDetails?.phoneNumber ||
        props?.data?.data?.login?.phoneNumber ||
        props?.data?.data?.createCaterer?.phoneNumber ||
        props?.selectedItem?.phoneNumber ||
        props?.userData?.data?.currentUserDetails?.phoneNumber ||
        props?.userData?.data?.login?.phoneNumber ||
        props?.userData?.data?.createUserIdentity?.phoneNumber||props?.selectedItem?.catererUsers[0]?.userDtls?.phoneNumber,
    },
  });

  const smsEnabledValue = watch("smsEnabled");

  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/userprofile",
  };
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    refetchQueries: ["GetOneCaterer", "CurrentUserDetails","ListUser"],
    onCompleted: (res) => {
      !imageDeleteCheck&& props.setOpenProfile(false);
    },
  });
  const [createUserIdentity] =
    useMutation(CREATE_USER_IDENTITY);

  const [createUser] = useMutation(
    CREATE_CATERER_USER,
    {
      refetchQueries: ["GetOneCaterer"],
      onCompleted: (res) => {
         props.setOpenProfile(false);
      },
    }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>
            <Typography>{children}</Typography>
          </div>
        )}
      </div>
    );
  }
  //toggle password / text
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // this function is for uploading the image
  function handleUploadClick(imagefile) {
    let obj = {};
    obj["fileName"] = imagefile?.name;
    obj["url"] = URL.createObjectURL(imagefile);
    obj["file"] = imagefile;
    setSelectedImage(obj);
    upload();
  }
  //for chacking the image with certain criteria that we matches
  function checkImage(e) {
    let file = e.target.files[0];
    if (
      !(
        file?.type == "image/jpeg" ||
        file?.type == "image/png" ||
        file?.type == "image/jpg"
      )
    ) {
      toggleSnackbar({
        status: true,
        message:
          "This file format is not supported. Please upload only .png or .jpg files",
        variant: "error",
      });
      return false;
    } else if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    } else {
      var options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 300,
        useWebWorker: true,
      };
      imageCompression(file, options)
        .then((compressedFile) => {
          setCropImage(compressedFile);
          setCropOpen(true);
        })
        .catch((err) => {
          //console.log("err: ",err)
        });
    }
  }
  // this allows the us to call the upload image function after the crop is done.
  function handelCropDone(result) {
    setCropImage(null);
    handleUploadClick(result);
  }
  // function for getting the url  after the upload
  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    setImageUploadLoading(false);
    props?.data?.getOneCaterer?.catererUsers?.length === 0 &&
    props.userType !== "Admin"
      ? oncreateSubmit(params)
      : onSubmit(params);
  }
  //onsubmit function
  function oncreateSubmit(params) {
    // delete params["confirmPassword"];
    delete params['confirmPassword']
    params["name"]=params?.firstName+" "+ params?.lastName
    params["imageUrl"] = uploadImageData?.imageUpload?.publicUrl;
    params["password"] =
      params["password"] === "" ? "123456789" : params["password"];
    params["userSrc"] = "AddBusiness";
    params["role"] = "Admin";
    try {
      createUserIdentity({ variables: { data: params } })
        .then((res) => {
          createUser({
            variables: {
              data: {
                catererId:
                  props?.data?.getOneCaterer?.catererOwnerUsers[0]?.catererId,
                  userId: res?.data?.createUserIdentity?._id,
                  role: res?.data?.createUserIdentity?.role,
                  status: res?.data?.createUserIdentity?.status,
              },
            },
          });
          toggleSnackbar({
            status: true,
            message: "Admin Created successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  //onsubmit function
  function onSubmit(params) {
    params["name"]= params?.firstName+ " " + params?.lastName
    params["_id"] =
      props?.data?.getOneCaterer?.catererUsers[0]?.userId ||
      props?.data?.getOneCaterer?.catererUsers[0]?.userDtls?._id ||
      props?.data?.data?.currentUserDetails?._id ||
      props?.data?.data?.login?._id ||
      props?.data?.data?.createCaterer?._id ||
      props?.selectedItem?._id ||
      props?.userData?.data?.currentUserDetails?._id ||
      props?.userData?.data?.login?._id ||
      props?.userData?.data?.createUserIdentity?._id|| props?.selectedItem?.catererUsers[0]?.userId;

    if (uploadImageData?.imageUpload?.publicUrl) {
      params['imageUrl'] = uploadImageData?.imageUpload?.publicUrl;
    }
    
    if (!resetPassword) {
      // edit profile submit
     
      delete params["password"];
      delete params["confirmPassword"];
    } else if (resetPassword) {
      //password submit
      delete params["imageUrl"];
      delete params["email"];
      delete params["name"];
      delete params["phoneNumber"];
    }

   let reqParams = {}

    if(resetPassword){
      reqParams._id = params._id;
      reqParams.password = params.password
    } else {
      reqParams = params
    }

    try {
      updateUser({
        variables: {
          id:
         
            props?.data?.getOneCaterer?.catererUsers[0]?.userId ||
            props?.data?.getOneCaterer?.catererUsers[0]?.userDtls?._id ||
            props?.data?.data?.currentUserDetails?._id ||
            props?.data?.data?.login?._id ||
            props?.data?.data?.createCaterer?._id ||
            props?.selectedItem?._id ||
            props?.userData?.data?.currentUserDetails?._id ||
            props?.userData?.data?.login?._id ||
            props?.userData?.data?.createUserIdentity?._id|| props?.selectedItem?.catererUsers[0]?.userId,
          data: reqParams,
        },
      })
        .then((res) => {
          if (res?.data?.updateUserIdentity?.firstName !== null||res?.data?.updateUserIdentity?.name !== null) {
            toggleSnackbar({
              status: true,
              message: "Contact information updated successfully",
              variant: "success",
            });
          } else {
            toggleSnackbar({
              status: true,
              message: "Password updated successfully",
              variant: "success",
            });
          }
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  function handleDelete(params) {
    setImageDeleteCheck(true)
    // setCropImage(null);
     setSelectedImage(null);
 
  }
  // for contact details edit
  const EditContactDetailsTab = () => {
    const [phoneNumberClone, setPhoneNumberClone]=useState()
    const [phoneNumber, setPhoneNumber] = useState(
   !phoneNumberUpdate? (props?.data?.getOneCaterer?.catererUsers[0]?.userDtls?.phoneNumber ||
          props?.data?.data?.currentUserDetails?.phoneNumber ||
          props?.data?.data?.login?.phoneNumber ||
          props?.data?.data?.createCaterer?.phoneNumber ||
          props?.selectedItem?.phoneNumber ||
          props?.userData?.data?.currentUserDetails?.phoneNumber ||
          props?.userData?.data?.login?.phoneNumber ||
          props?.userData?.data?.createUserIdentity?.phoneNumber||props?.selectedItem?.catererUsers[0]?.userDtls?.phoneNumber):phoneNumberClone
      );
    //  const nameFields= props?.catererNames?props?.selectedItem?.catererUsers[0]?.userDtls?.name:!props?.fieldNameCustomer
    //   ? props?.data?.getOneCaterer?.catererUsers[0]?.userDtls
    //       ?.name ||
    //     props?.data?.data?.currentUserDetails?.name ||
    //     props?.data?.data?.login?.fullName ||
    //     props?.data?.data?.createCaterer?.fullName ||
    //     props?.selectedItem?.firstName +
    //       " " +
    //       props?.selectedItem?.lastName

    //   :props?.userData?.data?.currentUserDetails?.name ||
    //     props?.userData?.data?.login?.fullName ||
    //     props?.userData?.data?.createUserIdentity?.fullName
    const firstNameFields= props?.catererNames?props?.selectedItem?.catererUsers[0]?.userDtls?.firstName:!props?.fieldNameCustomer
    ? props?.data?.getOneCaterer?.catererUsers[0]?.userDtls
        ?.firstName ||
      props?.data?.data?.currentUserDetails?.firstName ||
      props?.data?.data?.login?.firstName ||
      props?.data?.data?.createCaterer?.firstName ||
      props?.selectedItem?.firstName 

    :props?.userData?.data?.currentUserDetails?.firstName ||
      props?.userData?.data?.login?.firstName ||
      props?.userData?.data?.createUserIdentity?.firstName

      const lastNameFields= props?.catererNames?props?.selectedItem?.catererUsers[0]?.userDtls?.lastName:!props?.fieldNameCustomer
      ? props?.data?.getOneCaterer?.catererUsers[0]?.userDtls
          ?.lastName ||
        props?.data?.data?.currentUserDetails?.lastName ||
        props?.data?.data?.login?.lastName ||
        props?.data?.data?.createCaterer?.lastName ||
          props?.selectedItem?.lastName

      :props?.userData?.data?.currentUserDetails?.lastName ||
        props?.userData?.data?.login?.lastName ||
        props?.userData?.data?.createUserIdentity?.lastName

        // phone number formate
  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x =
        "(" +
        newNum.slice(0, 3) +
        ") " +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  };
    return (
      <div>
        {/* this component helps in croping the image */}
        <CropImage
          open={cropOpen}
          setOpen={setCropOpen}
          image={cropImage}
          handelCropDone={handelCropDone}
          aspectRatio={1}
        />
        {/* for opening the file to upload the image */}
        <input
          type={"file"}
          className="d-none"
          id="add-user-input"
          accept='.jpg, .png, .jpeg'
          onChange={checkImage}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <div className="create-step-form">
          <div className="container-fluid">
            {!props?.fieldName &&!props?.catererimageEdit&& (
              <div className="text-center">
                <div className="upload-img-wrap">
                  <Avatar
                    alt="Remy Sharp"
                    src={ selectedImage
                      ? selectedImage.url: props?.selectedItem?.imageUrl?  props?.selectedItem?.imageUrl:props?.userData?.data?.currentUserDetails?.imageUrl==="#"?"../assets/images/upoadHead.jpg":  props?.userData?.data?.currentUserDetails?.imageUrl&&!imageDeleteCheck  ? props?.userData?.data?.currentUserDetails?.imageUrl :
                      props?.userData?.data?.login?.imageUrl ?props?.userData?.data?.login?.imageUrl :
                      props?.userData?.data?.createUserIdentity?.imageUrl? props?.userData?.data?.createUserIdentity?.imageUrl:props?.selectedItem?.catererUsers[0]?.userDtls?.imageUrl ?props?.selectedItem?.catererUsers[0]?.userDtls?.imageUrl :
                      props?.data?.getOneCaterer?.catererUsers[0]?.userDtls?.imageUrl !== ""? props?.data?.getOneCaterer?.catererUsers[0]?.userDtls?.imageUrl: "../assets/images/upoadHead.jpg"}
                    className="upload-img upload-img-square"
                    onClick={(e) =>
                      document.getElementById("add-user-input").click()
                    }
                  
                  />
                { (selectedImage===null || props?.selectedItem?.imageUrl===undefined||(props?.userData?.data?.currentUserDetails?.imageUrl==="#"&&imageDeleteCheck===true)||(props?.userData?.data?.currentUserDetails?.imageUrl&&imageDeleteCheck===true)||
  props?.userData?.data?.login?.imageUrl===undefined ||
  props?.userData?.data?.createUserIdentity?.imageUrl===undefined||props?.selectedItem?.catererUsers[0]?.userDtls?.imageUrl===undefined || props?.data?.getOneCaterer?.catererUsers[0]?.userDtls?.imageUrl===undefined )&& <img
                    onClick={(e) =>
                      document.getElementById("add-user-input").click()
                    }
                    src="../assets/images/icon-upload-plus.svg"
                    alt="uploadImage"
                    className="upload-icon img-fluid"
                  />}
                   {(selectedImage || (props?.selectedItem?.imageUrl&&imageDeleteCheck===false)||(props?.userData?.data?.currentUserDetails?.imageUrl!=="#"&&imageDeleteCheck===false)||
  props?.userData?.data?.login?.imageUrl ||
  props?.userData?.data?.createUserIdentity?.imageUrl||props?.selectedItem?.catererUsers[0]?.userDtls?.imageUrl || props?.data?.getOneCaterer?.catererUsers[0]?.userDtls?.imageUrl )&&<img
                alt="closeIconImage"
                 onClick={()=>handleDelete()}
                  src="../assets/images/close-black.svg"
                  className="upload-icon img-fluid"
                />} 
                  {/* <HighlightOffIcon onClick={()=>handleDelete()} /> */}
                </div>
              </div>
            )}
            <div className="row">
          {/* { props?.customerProfileEditName&&  */}
          <div className="col-md-6 textBox">
                <TextField
                  id="createAccountAdminName"
                  defaultValue={firstNameFields }
                  label={"FIRST NAME*"}
                  //autoFocus
                  variant="outlined"
                  className="textField allFeild"
                  {...register("firstName")}
                />
                {errors.firstName && (
                  <span className="error-msg">{errors.firstName.message}</span>
                )}
              </div>
              {/* // } */}
             {/* {props?.customerProfileEditName&&  */}
             <div className="col-md-6 textBox">
                <TextField
                  id="createAccountAdminName"
                  defaultValue={lastNameFields }
                  label={"LAST NAME*"}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("lastName")}
                />
                {errors.lastName && (
                  <span className="error-msg">{errors.lastName.message}</span>
                )}
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountLoginEmail"
                  defaultValue={
                    props?.data?.getOneCaterer?.catererUsers[0]?.userDtls
                      ?.email ||
                    props?.data?.data?.currentUserDetails?.email ||
                    props?.data?.data?.login?.email ||
                    props?.data?.data?.createCaterer?.email ||
                    props?.selectedItem?.email ||
                    props?.userData?.data?.currentUserDetails?.email ||
                    props?.userData?.data?.login?.email ||
                    props?.userData?.data?.createUserIdentity?.email||props?.selectedItem?.catererUsers[0]?.userDtls?.email
                  }
                  label="LOGIN EMAIL*"
                  variant="outlined"
                  className="textField allFeild"
                  {...register("email")}
                />
                {errors.email && (
                  <span className="error-msg">{errors.email.message}</span>
                )}
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountPhoneNumber"
                  label={userType==="Customer"?"PHONE NUMBER*":"BUSINESS CONTACT NUMBER*"}
                  variant="outlined"
                  className="textField allFeild"
                  value={phoneNumber}
                 {...register('phoneNumber', {
                    onChange: (e) =>  { if (
                          !isNaN(e.target.value.replace(/[-,(,), ]+/g, "")) &&
                          e.target.value.replace(/[-,(,), ]+/g, "").length <= 10
                        ) {
                          
                          setPhoneNumberClone(phoneNumberFormat(e.target.value))
                          setPhoneNumber(phoneNumberFormat(e.target.value));
                         
                        }}
                  })}
                />
                {errors.phoneNumber && (
                  <span className="error-msg">
                    {errors.phoneNumber.message}
                  </span>
                )}
              </div>
              <div className="col-md-6 textBox">
                <FormControlLabel
                  required
                  control={<Checkbox
                    {...register("smsEnabled")}
                    checked={smsEnabledValue}
                  />}
                  label="SMS Enabled (Order Updates)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  // for resetting password
  const ResetPasswordTab = () => {
    return (
      <div className="admin-reset-password">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 textBox mt-4">
              <TextField
                id="createAccountPassword"
                label={props?.title ? "NEW PASSWORD" : "NEW PASSWORD*"}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                className="textField allFeild"
                {...register("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && (
                <span className="error-msg">{errors.password.message}</span>
              )}
            </div>
            <div className="col-md-12 textBox mt-4">
              <TextField
                id="filled-basic"
                label={
                  props?.title
                    ? "CONFIRM NEW PASSWORD"
                    : "CONFIRM NEW PASSWORD*"
                }
                variant="outlined"
                type={showConfirmPassword ? "text" : "password"}
                className="textField allFeild"
                {...register("confirmPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.confirmPassword && (
                <span className="error-msg">
                  {errors.confirmPassword.message}
                </span>
              )}
            </div>
           
          </div>
          {<div className="col-12">
            <span className="subhead">
            Password must contain atleast 8 characters, including letters, numbers and special characters (@$!%*#?&)
            </span>
          </div>}
        </div>
      </div>
    );
  };

  return (
    <Drawer
      className="common-drawer"
      anchor={"right"}
      open={props.openProfile}
      onClose={() => props.setOpenProfile(false)}
    >
      <form onSubmit={handleSubmit(uploadImage)} noValidate>
        <div className="drawer-head">
          <h4 className="h4">
            <img
              src="../assets/images/icon-manage-admin.svg"
              className="img-fluid"
              alt="Bank Details Icon"
            />
            {props?.fieldName
              ? "Edit User"
              : props?.fieldNameCustomer
              ? "Edit Profile"
              : "Admin Details"}
          </h4>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => props.setOpenProfile(false)}
          >
            <img
              src="../assets/images/x-icon.svg"
              className="img-fluid modal-close"
              alt="close icon"
            />
          </span>
        </div>

        <div className="cater-tab">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              className="w-50"
              label="Edit Contact Details"
              onClick={() => setResetPassword(false)}
            />
            <Tab
              className="w-50"
              label="Reset Password"
              onClick={() => setResetPassword(true)}
            />
          </Tabs>
        </div>
        <div className="create-step-form-admin-details">
          <TabPanel value={value} index={0}>
            <EditContactDetailsTab />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ResetPasswordTab />
          </TabPanel>
        </div>
        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => props.setOpenProfile(false)}
          >
            Cancel
          </Button>
          <Button
            className="saveBtn"
            variant="contained"
            type="submit"
            color="primary"
            onClick={()=>{setPhoneNumberUpdate(true);setImageDeleteCheck(false)}}
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              "Update"
            )}
          </Button>
        </div>
      </form>
    </Drawer>
  );
}

