import React, { useEffect } from 'react';

import Papa from 'papaparse';
import dayjs from 'dayjs';

import { useQuery } from '@apollo/client/react/hooks';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, TextField, List, ListItemButton, ListItemText } from '@mui/material';

import { LIST_ALL_ORDERS } from '../../Graphql/queries';
import { getLast6Months, downloadCSV } from '../Common/commonUtils';

export default function VendorStatements({ catererId }) {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const [rangeFirstMonth, setRangeFirstMonth] = React.useState(firstDay);
    const [rangeLastMonth, setRangeLastMonth] = React.useState(lastDay);
    const [ordersValues, setOrdersValues] = React.useState({
        catererId,
        limit: 1000,
        from: dayjs(firstDay).format('MM/DD/YYYY'),
        to: dayjs(lastDay).format('MM/DD/YYYY'),
        orderStatus: ["Completed"]
    });

    const formatter = new Intl.NumberFormat('en-US', {
        style: "currency",
        currency: 'USD',
    });

    const { data: ordersData, error } = useQuery(LIST_ALL_ORDERS, {
        variables: ordersValues
    });

    const handleRangeFirstChange = (newValue) => {
        setRangeFirstMonth(newValue);
    };

    const handleRangeSecondChange = (newValue) => {
        setRangeLastMonth(newValue);
    };

    useEffect(() => {
        if (rangeFirstMonth && rangeLastMonth) {
            setOrdersValues(prevValues => ({
                ...prevValues,
                from: dayjs(rangeFirstMonth).format('MM/DD/YYYY'),
                to: dayjs(rangeLastMonth).format('MM/DD/YYYY'),
            }));
        }
    }, [rangeFirstMonth, rangeLastMonth]);

    const handleMonthlyInvoice = async (m) => {
        const { firstDay, lastDay } = m;

        setRangeFirstMonth(dayjs(firstDay).toDate());
        setRangeLastMonth(dayjs(lastDay).toDate());
    };

    const submitCustomInvoice = () => {
        const orders = ordersData?.listOrders?.data;
        if (orders) {
            const statementValues = orders.map(field => {
                return {
                    "Order Number": field.orderNo,
                    "Date": dayjs(field.deliveryDate).format('MM/DD/YYYY'),
                    "Total Credit Charge": formatter.format(field.grandTotal),
                    "Credit Card Processing Charge (3%)": formatter.format(field.creditCardFee),
                    "Food Subtotal": formatter.format(field.totalPrice),
                    "Tax": formatter.format(field.salesTaxAmount),
                    "Delivery Fee": formatter.format(field.deliveryFee),
                    "Driver Tip": formatter.format(field.driverTip),
                    "Cater Cash Given": formatter.format(field.caterCashTotal),
                    "Platform Fee": formatter.format(field.commisionAmount),
                    "Refund/Other Fee": "",
                    "Total to be Paid to Vendor (Total Credit)": formatter.format(field.netAmount),
                    "Notes": ""
                };
            }).reverse();

            const csvData = Papa.unparse(statementValues);
            const now = new Date();
            const timestamp = now.toISOString().replace(/[:.T-]/g, '_');

            downloadCSV(csvData, `invoice_${timestamp}.csv`);
        }
    };

    return (
        <div className="location-details-tile h-100">
            <div className="ld-titles">
                <h4 className="h4"><img src="../assets/images/icon-option.svg" className="img-fluid" alt="Options Icon" />Statements</h4>
            </div>
            <div className="ld-business-primary ld-business-detail">
                <div className="row">
                    <div className="col-md-12 ld-business-detail-item">
                        <label className="pb-4">Custom Range</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs} className="row">
                            <DesktopDatePicker
                                label="Start"
                                inputFormat="MM/DD/YYYY"
                                value={rangeFirstMonth}
                                onChange={handleRangeFirstChange}
                                className="col-md-6 pe-2"
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                label="End"
                                inputFormat="MM/DD/YYYY"
                                value={rangeLastMonth}
                                onChange={handleRangeSecondChange}
                                className="col-md-6 ps-2"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <Button variant="contained" color="primary" className="w-100 my-3" onClick={() => submitCustomInvoice()}>Generate</Button>
                    </div>
                    <div className="col-md-12 ld-business-detail-item">
                        <label>Last Six Months</label>
                        <List component="nav">
                            {getLast6Months().reverse().map((m) =>
                                <ListItemButton key={m.monthString}>
                                    <ListItemText primary={m.monthString} onClick={() => handleMonthlyInvoice(m)} />
                                </ListItemButton>
                            )}
                        </List>
                    </div>
                </div>
            </div>
        </div>
    );
}