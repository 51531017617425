import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { LIST_ALL_ORDERS } from "Graphql/queries";
import { useLogout } from "@utils/hooks/use-logout";
import { IOrderListResponse } from "../order.typings";

interface IOrderListStateProps {
  userType: string;
  catererId: string;
  customerId: string;
  isCompleted: boolean;
}

export function useOrderListState({
  userType,
  catererId,
  customerId,
  isCompleted,
}: IOrderListStateProps) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState("");

  const {
    data: ordersData,
    loading: isLoading,
    error,
  } = useQuery<IOrderListResponse>(LIST_ALL_ORDERS, {
    fetchPolicy: "network-only",
    skip: userType === "Admin" ? false : !catererId && !customerId,
    variables: {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
      orderStatus: isCompleted
        ? ["Completed"]
        : userType === "Customer"
        ? ["New", "Cancelled", "Scheduled", "Draft", "Pending", "Approved"]
        : userType === "Admin"
        ? ["New", "Cancelled", "Scheduled", "Completed", "Pending", "Approved"]
        : ["New", "Cancelled", "Scheduled", "Pending", "Approved"],
      catererId,
      customerId,
      search: searchValue,
      sortField: userType === "Customer" ? "orderDate" : "deliveryDate",
    },
  });

  const { onLogout } = useLogout({
    userType,
  });

  useEffect(() => {
    if (error && error.message === "Not authenticated") {
      onLogout();
    }
  }, [error && error]);

  return {
    page,
    rowsPerPage,
    ordersData,
    isLoading,
    searchValue,
    orders: ordersData ? ordersData.listOrders.data : [],
    ordersCount: ordersData ? ordersData.listOrders.count : 0,
    setSearchValue,
    setPage,
    setRowsPerPage,
  };
}
