// This is for creating and updating the Admin Users
import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { CREATE_CUSTOMER } from "../../../Graphql/mutations";
import {
  Button,
  CircularProgress,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  toggleSnackbar,authVar
} from "../../../ReactiveVariables/index";
import {useNavigate } from "react-router-dom";
export default function AddAdminUser(props) {
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().required("Email is required").email(),
    phoneNumber: yup
      .string()
      .required("Phone Number is required")
      .min(14, "The phone number must be 10 digits."),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,100}$/,
        "Password is Invalid"
      ),
      // .min(6, "Password should be 6 characters minimum.")
      // .max(16, "Password should be 16 characters maximum."),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf(
        [yup.ref("password"), null],
        "Password and confirm password do not match"
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [createUserIdentity, { loading: createLoading }] = useMutation(
    CREATE_CUSTOMER,
    {
      refetchQueries: ["ListUser"],
      onCompleted: (res) => {
        props.setOpenAddAdminUser(false);
      },
    }
  );
  function onSubmit(params) {
    delete params["confirmPassword"];
    params["userType"] = "Admin";
    params["userSrc"] = "";
    params["name"] = params["firstName"].concat(" ", params["lastName"]);
    params["regFlag"] = "";
    params["role"] = "Admin";
    params["referralCode"] = "";
    params["level"]= 2;
    try {
      // creates the Admin User

      createUserIdentity({ variables: { data: params } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Admin User Created successfully",
            variant: "success",
          });
        })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Admin user added successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  //  function formats the phone number input
  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x =
        "(" +
        newNum.slice(0, 3) +
        ") " +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Drawer
      className="common-drawer"
      anchor={"right"}
      open={props.openAddAdminUser}
      onClose={() => {
        props.setOpenAddAdminUser(false);
        props.setSelectedItem(null);
      }}
    >
      <div className="drawer-head">
        <h4 className="h4">
          <img
            src="../assets/images/icon-contact-info.svg"
            className="img-fluid"
            alt="Contact Information Icon"
          />
          {"Add User"}
        </h4>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.setOpenAddAdminUser(false);
            props.setSelectedItem(null);
          }}
        >
          <img
            src="../assets/images/x-icon.svg"
            className="img-fluid"
            alt="close icon"
          />
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate  autoComplete="off">
        <div className="create-step-form">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountAdminName"
                  //defaultValue={props?.selectedItem?.firstName}
                  label="First Name*"
                  autoFocus
                  autoComplete="new-name"
                  InputProps={{
                    autoComplete: 'new-username',
                  }}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("firstName")}
                />
                {errors.firstName && (
                  <span className="error-msg">{errors.firstName.message}</span>
                )}
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountAdminName"
                  //defaultValue={props?.selectedItem?.lastName}
                  label="Last Name*"
                  autoComplete="new-lastname"
                  InputProps={{
                    autoComplete: 'new-lastname',
                  }}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("lastName")}
                />
                {errors.lastName && (
                  <span className="error-msg">{errors.lastName.message}</span>
                )}
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountAdminName"
                  label="Email*"
                  variant="outlined"
                  autoComplete="new-email"
                  InputProps={{
                    autoComplete: 'new-email',
                  }}
                  className="textField allFeild"
                  {...register("email")}
                />
                {errors.email && (
                  <span className="error-msg">{errors.email.message}</span>
                )}
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountAdminName"
                  label="Phone*"
                  autoComplete="new-phone"
                  InputProps={{
                    autoComplete: 'new-phone',
                  }}
                  variant="outlined"
                  className="textField allFeild"
                  // {...register("phoneNumber")}
                  value={phoneNumber}
                  onChange={(e) => {
                    if (
                      !isNaN(e.target.value.replace(/[-,(,), ]+/g, "")) &&
                      e.target.value.replace(/[-,(,), ]+/g, "").length <= 10
                    ) {
                      register("phoneNumber").onChange({
                        target: { value: e.target.value, name: "phoneNumber" },
                      });
                      setPhoneNumber(phoneNumberFormat(e.target.value));
                    }
                  }}
                />
                {errors.phoneNumber && (
                  <span className="error-msg">
                    {errors.phoneNumber.message}
                  </span>
                )}
              </div>
              <div className="col-md-12 textBox">
                <TextField
                  id="createAccountPassword"
                  label={props?.title ? "NEW PASSWORD" : "NEW PASSWORD*"}
                  variant="outlined"
                  autoComplete="new-pw"
                  type={showPassword ? "text" : "password"}
                  className="textField allFeild"
                  {...register("password")}
                  InputProps={{
                    autoComplete: 'new-pw',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && (
                  <span className="error-msg">{errors.password.message}</span>
                )}
              </div>
              <div className="col-md-12 textBox">
                <TextField
                  id="filled-basic"
                  label={
                    props?.title
                      ? "CONFIRM NEW PASSWORD"
                      : "CONFIRM NEW PASSWORD*"
                  }
                  variant="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  className="textField allFeild"
                  {...register("confirmPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.confirmPassword && (
                  <span className="error-msg">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </div>
            </div>
            {<div className="col-12">
            <span className="subhead">
            Password must contain atleast 8 characters, including letters, numbers and special characters (@$!%*#?&)
            </span>
          </div>}
          </div>
        </div>
        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => {
              props.setOpenAddAdminUser(false);
              props.setSelectedItem(null);
            }}
          >
            Cancel
          </Button>
          <Button
            className="saveBtn"
            disabled={createLoading}
            variant="contained"
            color="primary"
            type="submit"
          >
            {createLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              "Add"
            )}
          </Button>
        </div>
      </form>
    </Drawer>
  );
}
