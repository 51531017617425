// this page is for listing the reports
import React, { useState ,useEffect,useRef} from "react";
import { Table, Select, MenuItem, Button, InputAdornment } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { userDeatils, toggleSnackbar } from "../../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import TableRow from "@mui/material/TableRow";
import {
  LIST_ALL_ORDERS,
  LIST_PAYMENT_CATERER,
} from "../../../Graphql/queries";
import SideMenu from "../../../components/organisms/sidebar";
import LoggedHeader from "../../Common/loggedHeader";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DialogBox from "../../Common/dialogBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Reports(props) {
  const fromDatePickerRef = useRef(null);
  const toDatePickerRef = useRef(null);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState(null);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [fromDateError, setFromDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }); // currency formater
  
  const orderVariable = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    sortField:"orderDate",
    //   userId:  catererId ,
    // customerId: userType === "Customer" ? userId : "",
    orderStatus:["New", "Cancelled", "Scheduled", "Completed"],
    search: search,
    from:fromDate&&toDate&&new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)),
    to:toDate&&fromDate&&new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)),
    timeZone:timeZone
  };

  const { data, loading } = useQuery(LIST_ALL_ORDERS, {
    fetchPolicy: "network-only",
    variables: orderVariable,
  });
  // handling pagination
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };

  // change number of lisiting in a page
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  const onChangeRawFrom = (e) => {
    const value = e.target.value;
  
    if (!value) {
      setFromDateError(false); // Clear the error when the input is empty
      return;
    }
  
    const m = moment(value);
    if (!m.isValid()) {
      setFromDateError(true);
    } else {
      setFromDateError(false);
    }
  };
  const onChangeRawTo = (e) => {
    const value = e.target.value;
  
    if (!value) {
      setToDateError(false); // Clear the error when the input is empty
      return;
    }
  
    const m = moment(value);
    if (!m.isValid()) {
      setToDateError(true);
    } else {
      setToDateError(false);
    }
  };
  useEffect(() => {
    const handleTabKeyDown = (event, ref) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        ref.current.setOpen(false);
      }
    };

    const fromInputField = fromDatePickerRef.current.input;
    const toInputField = toDatePickerRef.current.input;

    fromInputField.addEventListener('keydown', (event) =>
      handleTabKeyDown(event, fromDatePickerRef)
    );

    toInputField.addEventListener('keydown', (event) =>
      handleTabKeyDown(event, toDatePickerRef)
    );

    return () => {
      fromInputField.removeEventListener('keydown', (event) =>
        handleTabKeyDown(event, fromDatePickerRef)
      );
      toInputField.removeEventListener('keydown', (event) =>
        handleTabKeyDown(event, toDatePickerRef)
      );
    };
  }, []);
  function handleKeyDown(e) {
    const value = e.target.value;
    const keyCode = e.keyCode || e.which;
    if (keyCode === 9) { // Tab key
      e.preventDefault();

      const targetName = e.target.name;
      const nextFieldName = targetName === 'from' ? 'to' : 'from';
      const nextField = document.querySelector(`input[name=${nextFieldName}]`);
      nextField.focus();
    }
    if (e.key === 'Backspace' || e.key === 'Delete') {
      return;
    }
    if (!/\d/.test(e.key)) {
      e.preventDefault();
      return;
    }
  
    if (value.length === 2) {
      if (value.charAt(2) !== '/') {
        e.preventDefault();
        e.target.value = `${value}/${e.key}`;
      }
    } else if (value.length === 5) {
      if (value.charAt(5) !== '/') {
        e.preventDefault();
        e.target.value = `${value.slice(0, 2)}${value.slice(2)}/${e.key}`;
      }
    }else if (value.length >= 10) {
      e.preventDefault();
    }
  }
  return (
    <div>
      {/* header for changing location and getting the id */}
      <LoggedHeader title="Reports" />
      {/* keeping the side bar selected */}
      <SideMenu selected={"Reports"} user={"admin"} />
      {/* open drawer for adding and editing menu item */}

      <div className={props.userType !== "Admin" ? "contentWrap" : ""}>
        <div className="contentWrapInner">
        <div className="location-head">
          <div className="location-head-title justify-content-between align-items-center report-head">
            <h5>Order Report</h5>
            
            <div className="d-flex review-order-datepicker">
            <input
              placeholder="Search by OrderID & Store Name"
               className="form-control"
              //className="ms-3"
              value={search}
              onChange={(e) => {
                setPage(1);
                setSearch(e.target.value);
              }}
            />
             {/* {fromDateError && <span className="">Invalid date format</span>} */}
              <DatePicker
              ref={fromDatePickerRef}
                selected={fromDate}
                onChange={(date) =>{ setFromDate(date);
                  !toDate&& toggleSnackbar({
                  status: true,
                  message: "Please select To date",
                  variant: "error",
                });
              }}
                placeholderText={"From"}
                maxDate={toDate}
                dateFormat="MM/dd/yyyy"
                name="from"
                onChangeRaw={onChangeRawFrom}
                onKeyDown={handleKeyDown}
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      src="../assets/images/head.svg"
                      className="img-fluid"
                      alt="Head Count"
                    />
                  </InputAdornment>
                }
              />
 {/* {toDateError && <span className="admin-error-msg error-msg">Invalid date format</span>} */}
              <DatePicker
              ref={toDatePickerRef}
                selected={toDate}
                onChange={(date) => {setToDate(date);
                  !fromDate&& toggleSnackbar({
                  status: true,
                  message: "Please select From date",
                  variant: "error",
                });
              }}
                placeholderText={"To"}
                // showTimeSelect
                minDate={fromDate}
                className="ms-3"
                // ref={props?.datepickerRef}
                dateFormat="MM/dd/yyyy"
                name="to"
                onChangeRaw={onChangeRawTo}
                onKeyDown={handleKeyDown}
                endAdornment={
                  <InputAdornment position="start">
                    <img
                      src="../assets/images/head.svg"
                      className="img-fluid"
                      alt="Head Count"
                    />
                  </InputAdornment>
                }
              />
            </div>
          </div></div>

          {/* <div className="location-head">
            <div className="location-head-title menu-search-by-item justify-content-between">
             
             </div>
             </div> */}

          {
            <TableContainer>
              <Table
                className="commonTabel"
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Order</TableCell>
                    <TableCell align="left">Order Date & Time </TableCell>
                    <TableCell align="left">Store Name and Location</TableCell>
                    <TableCell align="center">CaterCash </TableCell>
                    <TableCell align="center">Total</TableCell>
                    <TableCell align="left">Commission</TableCell>
                    <TableCell align="center">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p> Loading...</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : data?.listOrders?.data &&
                    data?.listOrders?.data?.length > 0 ? (
                    data?.listOrders?.data &&
                    data?.listOrders?.data?.map((item) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ cursor: "pointer" }}
                        >
                          {`#${item?.orderNo}`}
                        </TableCell>
                        <TableCell align="left">
                          {item?.orderDate
                            ? moment(item?.orderDate).format("llll")
                            : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {item?.catererDetails?.businessName}
                          {", "}
                          {item?.locationName}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.caterCashTotal)}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.grandTotal)}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.commisionAmount)}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.totalPrice)}
                        </TableCell>
                      </TableRow>
                      // </Link>
                    ))
                  ) : search ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>No data Found</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>No list added Yet!</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select onChange={handlePageInputChange} value={rowsPerPage}>
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listOrders?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
}
