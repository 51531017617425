import { USER_TYPE } from "./user.constants";

export enum SIDEBAR_TAB {
  orders = "Orders",
  caterCash = "CaterCash",
  payment = "Payments",
  referFriend = "Refer A Friend",
  summary = "Summary",
  menu = "Menu",
  profile = "Profile",
  address = "Address",
  taxes = "Taxes",
  dashboard = "Dashboard",
  caterers = "Caterers",
  customers = "Customers",
  reviews = "Reviews",
  reports = "Reports",
  settings = "Settings",
  promoCodes = "Promo Codes",
  cuisines = "Cuisines",
  category = "Category",
  adminUser = "Admin User",
}

interface ISideTabParam {
  icon: string;
  iconActive: string;
  url: string;
}

export const SIDEBAR_TAB_PARAMS: Record<SIDEBAR_TAB, ISideTabParam> = {
  [SIDEBAR_TAB.orders]: {
    icon: "../assets/images/Orders.svg",
    iconActive: "../assets/images/Orders.svg",
    url: "/orders",
  },
  [SIDEBAR_TAB.caterCash]: {
    icon: "../assets/images/caterCash.svg",
    iconActive: "../assets/images/caterCash.svg",
    url: "/CaterCash",
  },
  [SIDEBAR_TAB.payment]: {
    icon: "../assets/images/Payments.svg",
    iconActive: "../assets/images/payments2.svg",
    url: "/payments",
  },
  [SIDEBAR_TAB.referFriend]: {
    icon: "../assets/images/referFriend.svg",
    iconActive: "../assets/images/referFriend.svg",
    url: "/referFriend",
  },
  [SIDEBAR_TAB.summary]: {
    icon: "../assets/images/dashboard.svg",
    iconActive: "../assets/images/dashboard.svg",
    url: "/summary",
  },
  [SIDEBAR_TAB.menu]: {
    icon: "../assets/images/Menu.svg",
    iconActive: "../assets/images/Menu1.svg",
    url: "/menu",
  },
  [SIDEBAR_TAB.profile]: {
    icon: "../assets/images/icon-profile.svg",
    iconActive: "../assets/images/icon-profile.svg",
    url: "/profile",
  },
  [SIDEBAR_TAB.address]: {
    icon: "../assets/images/Payments.svg",
    iconActive: "../assets/images/Payments.svg",
    url: "/address",
  },
  [SIDEBAR_TAB.taxes]: {
    icon: "../assets/images/category.svg",
    iconActive: "../assets/images/category.svg",
    url: "/taxes",
  },
  [SIDEBAR_TAB.dashboard]: {
    icon: "../assets/images/dashboard.svg",
    iconActive: "../assets/images/dashboard.svg",
    url: "/dashboard",
  },
  [SIDEBAR_TAB.caterers]: {
    icon: "../assets/images/caterers.png",
    iconActive: "../assets/images/caterers.png",
    url: "/admincatererlist",
  },
  [SIDEBAR_TAB.customers]: {
    icon: "../assets/images/customers.svg",
    iconActive: "../assets/images/customers.svg",
    url: "/customers",
  },
  [SIDEBAR_TAB.reviews]: {
    icon: "../assets/images/reviews.svg",
    iconActive: "../assets/images/reviews.svg",
    url: "/reviews",
  },
  [SIDEBAR_TAB.settings]: {
    icon: "../assets/images/settings.svg",
    iconActive: "../assets/images/settings.svg",
    url: "/settings",
  },
  [SIDEBAR_TAB.reports]: {
    icon: "../assets/images/reports.svg",
    iconActive: "../assets/images/reports.svg",
    url: "/reports",
  },
  [SIDEBAR_TAB.promoCodes]: {
    icon: "../assets/images/promoCode.svg",
    iconActive: "../assets/images/promoCode.svg",
    url: "/promocodes",
  },
  [SIDEBAR_TAB.cuisines]: {
    icon: "../assets/images/cusines.svg",
    iconActive: "../assets/images/cusines.svg",
    url: "/cuisines",
  },
  [SIDEBAR_TAB.category]: {
    icon: "../assets/images/category.svg",
    iconActive: "../assets/images/category.svg",
    url: "/Category",
  },
  [SIDEBAR_TAB.adminUser]: {
    icon: "../assets/images/adminUser.svg",
    iconActive: "../assets/images/adminUser.svg",
    url: "/adminuser",
  },
};

export const USER_TAB_MAP: Record<USER_TYPE, SIDEBAR_TAB[]> = {
  [USER_TYPE.caterer]: [
    SIDEBAR_TAB.summary,
    SIDEBAR_TAB.orders,
    SIDEBAR_TAB.menu,
    SIDEBAR_TAB.profile,
    SIDEBAR_TAB.payment,
  ],
  [USER_TYPE.customer]: [
    SIDEBAR_TAB.orders,
    SIDEBAR_TAB.caterCash,
    SIDEBAR_TAB.referFriend,
    SIDEBAR_TAB.profile,
    SIDEBAR_TAB.address,
    SIDEBAR_TAB.taxes,
  ],
  [USER_TYPE.admin]: [
    SIDEBAR_TAB.dashboard,
    SIDEBAR_TAB.caterers,
    SIDEBAR_TAB.customers,
    SIDEBAR_TAB.orders,
    SIDEBAR_TAB.caterCash,
    SIDEBAR_TAB.payment,
    SIDEBAR_TAB.reviews,
    SIDEBAR_TAB.settings,
    SIDEBAR_TAB.reports,
    SIDEBAR_TAB.promoCodes,
    SIDEBAR_TAB.cuisines,
    SIDEBAR_TAB.category,
    SIDEBAR_TAB.adminUser,
    SIDEBAR_TAB.referFriend,
  ],
};
