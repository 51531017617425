import moment from 'moment';

// Create list of new addons based on items of original menu
export function generateDupeAddons (menuId, addOnsToDupe) {
  const addOnsToCreate = addOnsToDupe.map(addOn => {
    const {
      addOnTypeLimit,
      addOnTypeName,
      catererId,
      sortOrder,
      type
    } = addOn;

    return {
      menuId,
      addOnTypeLimit,
      addOnTypeName,
      catererId,
      sortOrder,
      type
    };
  });

  return addOnsToCreate;
}

// Provided add-ons, generate a addons list and sub items
export function generateDupeAddOnsList (addOns, addOnTypeId, menuId) {
  return addOns.map(addOn => {
    const { addOnsList } = addOn;

    return addOnsList.map(listItem => {
      const {
        addOnName,
        addOnPrice,
        addOnType,
        catererId,
        sortOrder,
        subAddOns
      } = listItem;

          // Clean subs
      const subs = subAddOns.map(sub => {
        const { subAddOnName, subAddOnPrice, subSortOrder } = sub;

        return {
          subAddOnName,
          subAddOnPrice,
          subSortOrder
        }
      });

      return {
        addOnName,
        addOnPrice,
        addOnType,
        addOnTypeId,
        menuId,
        catererId,
        sortOrder,
        subAddOns: subs
      }
    });
  });
};

export const isWithinFourHours = (deliveryDate) => {
  const currentTime = moment();
  const deliveryTime = moment(deliveryDate);
  return deliveryTime.diff(currentTime, 'hours') < 4;
}