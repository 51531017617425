import React, { useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { Link } from "react-router-dom";

interface ICatererAvailabilityModalProps {
  isOpen: boolean;
  type: "date" | "address" | true;
  urlInfo: {
    address: string;
    date: Date;
    coordinates: [number, number];
  };
  onClose(): void;
}

export function CatererAvailabilityModal({
  isOpen,
  type,
  urlInfo,
  onClose,
}: ICatererAvailabilityModalProps) {
  if (!isOpen) {
    return null;
  }

  const subject = useMemo(() => {
    return type === "date" ? "time" : type === "address" ? "address" : "";
  }, [type]);
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={"common-dialog-box headcount-dialog-box"}>
        <DialogTitle id="alert-dialog-title">
          <div className="dialog-box-title text-center">
            <h5 className="h5 w-100">Caterer Unavailable</h5>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="dialog-box-text">
              <h5 className="h5">
                Unfortunately, it looks like your caterer doesn’t deliver at the {subject} you have
                selected. Please select another caterer to see available caterers for your requested
                delivery {subject} or select a different delivery {subject}.
              </h5>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            variant="outlined"
            className="dailog-cancel-btn m-auto w-100 headcount-btn">
            Try a different {subject}
          </Button>

          <Button autoFocus variant="contained" color="primary" className="headcount-btn w-100">
            <Link to={`/caterers`} style={{ color: "#fff" }} state={urlInfo}>
              Try other caterers
            </Link>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
