import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useReactiveVar } from "@apollo/client";

import { ADD_ADDRESS, UPDATE_ADDRESS } from "Graphql/mutations";
import { toggleSnackbar, userDeatils } from "ReactiveVariables";
import { IAddressFormProps } from "./address-form";
import { ISearchLocationPayload } from "@components/atoms/location-search";
import { getUserId } from "@utils/user-format.utils";

interface ISaveAddressForm {
  street: string;
  locationName: string;
  customerZip: string;
  Phone: string;
  latitude: string;
  longitude: string;
  customerCity: string;
  instruction: string;
  floor: string;
  contactName: string;
}

export function useAddressFormState(props: IAddressFormProps) {
  const user = useReactiveVar(userDeatils);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<ISaveAddressForm>({});

  useEffect(() => {
    if (props.isOpen) {
      setValue("street", props.addressItem ? props.addressItem.street : "");
      setValue("locationName", props.addressItem ? props.addressItem.locationName : "");
      setValue("customerZip", props.addressItem ? props.addressItem.customerZip : "");
      setValue("customerCity", props.addressItem ? props.addressItem.customerCity : "");
      setValue("Phone", props.addressItem ? props.addressItem.Phone : "");
      setValue("latitude", props.addressItem ? props.addressItem.latitude : "");
      setValue("longitude", props.addressItem ? props.addressItem.longitude : "");
      setValue("floor", props.addressItem ? props.addressItem.floor : "");
      setValue("instruction", props.addressItem ? props.addressItem.instruction : "");
      setValue("contactName", props.addressItem ? props.addressItem.contactName : "");
    }
  }, [props.isOpen, props.addressItem]);

  function onCloseForm() {
    props.onClose();
    reset();
  }

  const [createAddAddress, { loading: createLoading }] = useMutation(ADD_ADDRESS, {
    refetchQueries: ["ListAddress"],
  });

  const [updateAddAddress, { loading: updateLoading }] = useMutation(UPDATE_ADDRESS, {
    refetchQueries: ["ListAddress", "GetOneAddress"],
  });

  function onUpdateLocation({ city, zip, coordinates }: ISearchLocationPayload) {
    setValue("customerCity", city);
    setValue("customerZip", zip);
    setValue("latitude", coordinates[1] ? coordinates[1].toString() : "");
    setValue("longitude", coordinates[0] ? coordinates[0].toString() : "");
  }

  async function onSubmit(form: ISaveAddressForm) {
    try {
      form["customerId"] = getUserId(user);

      const variables = props.addressItem
        ? {
            id: props.addressItem._id,
            data: {
              ...form,
              _id: props.addressItem._id,
            },
          }
        : { data: form };

      const method = props.addressItem ? updateAddAddress : createAddAddress;

      const response = await method({ variables });

      toggleSnackbar({
        status: true,
        message: props.addressItem
          ? "Saved address updated successfully"
          : "Saved address created successfully",
        variant: "success",
      });
      onCloseForm();
    } catch (e) {
      const message = e && e.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  return {
    loading: createLoading || updateLoading,
    control,
    onUpdateLocation,
    onCloseForm,
    handleSubmit,
    onSubmit,
  };
}
