import React from 'react'

import { IconButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { Link } from "react-router-dom";
import SideMenu from "../../components/organisms/sidebar";
import List from "@mui/material/List";
import { useReactiveVar } from "@apollo/client";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { userDeatils } from "../../ReactiveVariables/index";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";

const menuItemCustomer = [
  {
    image: "../assets/images/home.svg",
    image1: "../assets/images/home.svg",
    label: "Home",
    value: "Home",
    url: "/",
  },
  {
    image: "../assets/images/Orders.svg",
    image1: "../assets/images/Orders.svg",
    label: "Orders",
    value: "Orders",
    url: "/orders",
  },
  {
    image: "../assets/images/caterCash.svg",
    image1: "../assets/images/caterCash.svg",
    label: "CaterCash",
    value: "CaterCash",
    url: "/CaterCash",
  },
  {
    image: "../assets/images/referFriend.svg",
    image1: "../assets/images/referFriend.svg",
    label: "Refer A Friend",
    value: "Refer A Friend",
    url: "/referFriend",
  },
  {
    image: "../assets/images/icon-profile.svg",
    image1: "../assets/images/icon-profile.svg",
    label: "Profile",
    value: "Profile",
    url: "/profile",
  },
  {
    image: "../assets/images/Payments.svg",
    image1: "../assets/images/Payments.svg",
    label: "Address",
    value: "Address",
    url: "/address",
  },
];
export default function MobileSideMenu(props) {

  const navigate = useNavigate();
  return (
    <div>
      <Drawer
        className="m-menu-drawer m-menu-drawer-myaccount"
        anchor={"left"}
        open={props.openSideMenu}
        onClose={() => {
          props.setOpenSideMenu(false);
        }}
      >
        <div>
          <IconButton
            className="m-menu-close"
            onClick={() => props.setOpenSideMenu(false)}
          >
            <img
              src="../assets/images/x-icon.svg"
              className="img-fluid"
              alt="close icon"
            />
          </IconButton>
          <nav className="sideMenu">
            {menuItemCustomer.map((item, index) => (
              <List disablePadding key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    className="sideMenuItem"
                    selected={
                      item?.value && props?.sideMenu?.selected === item?.value
                    }
                    onClick={() => {
                      navigate(item?.url);
                      props.setOpenSideMenu(false);
                    }}
                  >
                    <img
                      src={
                        item?.value && props?.sideMenu?.selected === item?.value
                          ? item.image
                          : item.image1
                      }
                      alt="menu icon"
                    />
                    <ListItemText
                      className="sideMenuItemText"
                      primary={item.label}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            ))}
          </nav>
        </div>
      </Drawer>
    </div>
  );
}
