import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import client from "./graphqlConfig";
import CustomizedSnackbars from "./Modules/Common/customSnackbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CookiesProvider } from "react-cookie";
import { persistor, store } from "./services/redux";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import Hotjar from "@hotjar/browser";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://fd0667e7f110e1c9ab24996e87658db3@o4507130254786560.ingest.us.sentry.io/4507130261798912",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  const siteId = 5000368;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FD4E3B",
      light: "#f00",
      contrastText: "#fff",
      "&:hover": {
        background: "red",
      },
    },
    secondary: {
      main: "#F8F8F8",
      contrastText: "#646777",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <CustomizedSnackbars />
              <CookiesProvider>
                <App />
              </CookiesProvider>
            </ThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
