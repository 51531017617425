// this page is for viewing the details of the menu we created also for opening the addon component and deleting the addon
import React from 'react';

import { useState, useEffect} from "react";
import LoggedHeader from "../../Common/loggedHeader"
import SideMenu from "../../../components/organisms/sidebar"
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import { Button } from "@mui/material";
import { LIST_ONE_MENU ,LIST_CATEGORY,LIST_CATERER,LIST_ADDON_TYPE} from "../../../Graphql/queries";
import {DELETE_ADDON_TYPE} from "../../../Graphql/mutations";
import { useParams,Link } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import {
    userDeatils,
    toggleSnackbar,authVar
  } from "../../../ReactiveVariables/index";
import {AddMenu} from "./AddMenu";
import AddOn from "./AddOn";
import SubAddon from "./subAddon";
import DialogBox from "../../Common/dialogBox";
import { useNavigate } from "react-router-dom";
export default function ViewMenu() {
    const { id } = useParams();
    const user = useReactiveVar(userDeatils);
    const navigate= useNavigate()
    const [selectedAddOn,setSelectedAddOn] = useState('');
    const[mainAddonData,setMainAddonData] = useState(null);
    const [createAddOnStatus, setCreateAddOnStatus] = useState(false)
    const { data } = useQuery(LIST_ONE_MENU, { variables: { getOneMenuId: id } })
    const {data:addonData} = useQuery(LIST_ADDON_TYPE,{variables:{catererId:data?.getOneMenu?.catererId,menuId:data?.getOneMenu?._id},
    onCompleted:res=>{
      setMainAddonData(res);
      if (createAddOnStatus){
      newlyAddedFunction(res)}
    }})
    
    const { data: categoryData } = useQuery(LIST_CATEGORY);
    const[deleteAddOn]=useMutation(DELETE_ADDON_TYPE,{refetchQueries:['ListAddOnType'],
    onCompleted:(res)=>{
        setSelectedAddOn('')
    }})
    const [openAddMenu, setOpenAddMenu] = useState(false);
    const [openAddon,setOpenAddon] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [newlyAddedAddOn, setNewlyAddedAddOn] = useState('')
    const [editAddOnFlag, setEditAddOnFlag] = useState(false)
    const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;
    
    const newlyAddedFunction = (res) =>{
    if(newlyAddedAddOn){
      let addOnArray = res?.listAddOnType?.data;
      addOnArray?.map(addOn => {
        if(addOn?._id === newlyAddedAddOn?._id){
          if(addOn?.addOnsList.length === 0){
          addNewAddon(newlyAddedAddOn, 0, res)
          }
        }
      })
    }
    }
    //function to create empty addon
    function addNewAddon(item, index, mainAddonData) {
      let obj = {}
      obj.addOnName = "";
      obj.addOnType = "nonVeg";
      obj.addOnPrice = 0;
      obj.sortOrder = mainAddonData?.listAddOnType?.data[index].addOnsList.length
      obj.menuId = item?.menuId;
      obj.catererId = item?.catererId;
      obj.addOnTypeId = item?._id;
      obj.subAddOns = []
      let test1 = JSON.stringify(mainAddonData)
      let test2 = JSON.parse(test1)
      test2.listAddOnType?.data[index].addOnsList.push(obj)
      setMainAddonData(test2)
    }

// function for deleteing addon
      function handleDelete() {
        try {
            deleteAddOn({ variables: { addOnTypeId: selectedAddOn?._id } })
            .then((res) => {
              toggleSnackbar({
                status: true,
                message: "Addon deleted successfully",
                variant: 'success'
              });
            })
            .catch((err) => {
              const message = err && err.message;
              if(message==="Not authenticated"){
                localStorage.clear();
                authVar({
                  loaded: true,
                  auth: false,
                });
                userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
              }else{
              toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
              });
            }
            });
        } catch (err) {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      }
    const formatter= new Intl.NumberFormat('en-US',{style:"currency",currency:'USD',}) //currency formater
    const urlData= {
      getlocId: data?.getOneMenu?.catererId,
      menu: "menu"
    }

    return (
        <div>
          {/* logged header for showing the location */}
            {(userType==="Admin")?<LoggedHeader title={`${data?.getOneMenu?.catererDtls?.businessName}, ${data?.getOneMenu?.catererDtls?.locationName}`} user="admin" />:<LoggedHeader data={data} title={data?.getOneMenu?.itemName} />}
            {/*keeping sidebar menu selected*/}
            <SideMenu selected={(userType==="Admin")?"Caterers":"Menu"} user={(userType==="Admin")?"admin":''}/>
            {/* open drawer for edit */}
            {openAddMenu&&<AddMenu 
            openAddMenu= {openAddMenu}
            setOpenAddMenu= {setOpenAddMenu}
            data= {data}
            categoryData= {categoryData}
            />}
            {/* open modal for addon */}
            {openAddon&&
                <AddOn
                openAddon={openAddon}
                setOpenAddon={setOpenAddon}
                data={data}
                selectedAddOn={selectedAddOn}
                setSelectedAddOn={setSelectedAddOn}
                setNewlyAddedAddOn={setNewlyAddedAddOn}
                setCreateAddOnStatus={setCreateAddOnStatus}
                editAddOnFlag={editAddOnFlag}
                setEditAddOnFlag={setEditAddOnFlag}
                />
            }
            {/* open dialoge for deleting */}
            {openDialog && (<DialogBox
        setOpenDialog={setOpenDialog}
        handleDelete={handleDelete}
        openDialog={openDialog}
        title="Delete"
        discription="Are you sure you want to delete this item?"
      />
      )}
            <div className="contentWrap">
                <div className='contentWrapInner'>
                    <Link to={(userType==="Admin")?`/admincatererlist/${data?.getOneMenu?.catererId}`:'/menu'} state={urlData} className="menu-back" style={{ cursor: "pointer" }}> <img src="../assets/images/back.svg" className="img-fluid" alt="Icon" /><span>Back to Menu</span></Link>
                    <div className="menu-view">
                        <div className="menu-view-head">
                            <h4>Menu Item Detail</h4>
                            <a className="ld-edit" onClick={()=>setOpenAddMenu(true)}><img src="../assets/images/icon-menu-edit.svg" className="img-fluid" alt="Edit Icon" />Edit</a>
                        </div>
                        <div className="menu-details">
                            <div className="menu-details-img">
                                <img src={data?.getOneMenu?.itemImage === ""||data?.getOneMenu?.itemImage === null ? "../assets/images/uploadFood.jpg" : data?.getOneMenu?.itemImage} />
                            </div>
                            <div>
                                <h5 className="h5">{data?.getOneMenu?.itemName}</h5>
                                <ul className="menu-item-details">
                                    <li><img src="../assets/images/Food.svg" />{data?.getOneMenu?.categoryDtls?.categoryTitle}</li>
                                    <li><img src="../assets/images/Money.svg" />{formatter.format(data?.getOneMenu?.price)}</li>
                                    <li><img src={data?.getOneMenu?.dietory==="Non-Vegetarian"||data?.getOneMenu?.dietory==="Non-Veg and Gluten Free"?"../assets/images/non-veg-new.svg":data?.getOneMenu?.dietory==="Vegetarian"||data?.getOneMenu?.dietory==="Veg and Gluten Free"?"../assets/images/Frame.svg":""} /></li>
                                    <li>Type : {data?.getOneMenu?.itemType}</li>
                                    <li>Min Quantity : {data?.getOneMenu?.minQuantity}</li>
                                    {data?.getOneMenu?.offerIndPkdFlg&&<li>Individually Packaged : {formatter.format(data?.getOneMenu?.additionalPkdPrice)}</li>}
                                </ul>
                            </div>

                        </div>
                        <div className="menu-ingredient-list">
                            <p>{data?.getOneMenu?.itemDescription}</p>
                        </div>
                    </div>
                    <div className="menu-add-on">
                        <h5 className="h5">Add on</h5>
                        <Button color="primary" variant="contained" onClick={()=>setOpenAddon(true)}>
                            <img src="../assets/images/icon-add-plus.svg" className="img-fluid me-1" alt="Icon" /> Add
                        </Button>
                    </div>
                    <div>
                    <SubAddon 
                    addonData={addonData}
                    mainAddonData={mainAddonData}
                    setMainAddonData={setMainAddonData}
                    setOpenAddon={setOpenAddon}
                    setSelectedAddOn={setSelectedAddOn}
                    setOpenDialog={setOpenDialog}
                    newlyAddedAddOn={newlyAddedAddOn}
                    setCreateAddOnStatus={setCreateAddOnStatus}
                    setEditAddOnFlag={setEditAddOnFlag}

                    />
                    </div>
                </div>
            </div>
        </div>
    )
}