// dialog box for generating amazon voucher
import React from 'react'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';
import { CREATE_REDEEM } from '../../Graphql/mutations';
import { useMutation } from '@apollo/client';
import { toggleSnackbar } from '../../ReactiveVariables';
import { userDeatils } from '../../ReactiveVariables';
import { useReactiveVar } from '@apollo/client';
export default function VoucherBox(props) {
  const user = useReactiveVar(userDeatils);
const [createRedeem,{loading}]= useMutation(CREATE_REDEEM,{refetchQueries:['ListCaterCash','ListCaterCashTotal'],
  onCompleted:(res)=>{
    props?.setOpenVoucherBox(false)
    props?.setVoucherData(res)
  }
})
function GenerateCoupon(){
  let data= {
    amount: props?.selectedAmount,
    customerName: user?.data?.currentUserDetails?.name||user?.data?.login?.fullName||user?.data?.createUserIdentity?.fullName,
    customerId: user?.data?.currentUserDetails?._id || user?.data?.createUserIdentity?._id || user?.data?.login?._id,
    customerEmail: user?.data?.currentUserDetails?.email||user?.data?.login?.email||user?.data?.createUserIdentity?.email,
    paidOn: new Date(),
    customerFName:user?.data?.currentUserDetails?.firstName||user?.data?.login?.firstName||user?.data?.createUserIdentity?.firstName,
  }
  try {
    createRedeem({variables:{data:data}})
    .then((res)=>{
      toggleSnackbar({
        status: true,
        message: "Coupon Generated Successfully",
        variant: "success",
      });
    })
    .catch((err)=>{
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    })
    
  } catch (error) {
    //
  }
}
  return (
    <div>
      <Dialog
        open={props?.openVoucherBox}
        onClose={() => props?.setOpenVoucherBox(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='common-dialog-box vouceher-box'
      >
        <DialogTitle id="alert-dialog-title">
          <div className='dialog-box-title text-center'>
            <h5 className='h4 w-100'>Generate Coupon</h5>
            {/* <IconButton onClick={() => props?.setOpenDialog(false)}>
              <img src='../assets/images/modal-close.svg' className='img-fluid' alt='Close' />
            </IconButton> */}
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <div className='text-center'>
              <img src="../assets/images/amazon.svg" className="img-fluid amazon-gift-img" alt="Amazon Gift" />
              <h6 className='h6'>Redeem CaterCash</h6>
              <div className="amazon-btn">
                <Button style={{ backgroundColor: "#DEDEDE", color: "black",cursor:"default" }} variant={"contained"}>${props?.selectedAmount}</Button>
              </div>
            </div>
            <i>*Earn CaterCash points for every completed order.
              Each CaterCash point is equivalent to $1 on Amazon.com. Rewards typically take 3 days after your order is completed to fully transfer into your CaterPlace account.</i>
          </div>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button className={'dailog-cancel-btn m-auto'} variant='outlined' color="secondary" onClick={() => { props?.setOpenVoucherBox(false) }}>Cancel</Button>
          <Button className={'m-auto'} variant='contained' color="primary" onClick={() => { GenerateCoupon() }} autoFocus>
            {loading?<CircularProgress color="inherit" size={20} />:"Generate"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}