import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { authVar } from "../ReactiveVariables";

export default function RouteWrapper({ element, isPrivate, isAuthPage, admin, type, ...rest }) {
  const authStatus = useReactiveVar(authVar);
  const { loaded, auth } = authStatus;
  if (loaded && !auth) {
    return (
      <Navigate
        to={{
          pathname: "/",
          state: { url: window.location.pathname },
        }}
      />
    );
  }
  return <Outlet {...rest} element={element} />;
}
