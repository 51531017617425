import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrderPayload } from "./order.typings";
import { ORDER_DELIVERY_MODE, ORDER_STATUS } from "@constants/order.constants";

const orderSkeleton: IOrderPayload = {
  _id: "",
  cartDetails: [],
  uuid: "",
  orderStatus: ORDER_STATUS.draft,
  orderClient: "Web",
  cardId: "",
  orderId: "",
  headCount: 1,
  deliveryFee: 0,
  deliveryMode: ORDER_DELIVERY_MODE.delivery,
  deliveryDate: null,
  discountAmt: 0,
  customerId: "",
  catererId: "",
  catererSlug: "",
  catererName: "",
  street: "",
  locationName: "",
  city: "",
  location: "",
  suit: "",
  instruction: "",
  contactNo: "",
  contactPerson: "",
  tablewareAmount: 0,
  tablewareCharged: false,
  tablewareDeclined: false,
  tablewareTotal: 0,
  salesTaxAmount: 0,
  salesTaxPercentage: 0,
  driverTip: 0,
  driverTipType: null,
  tipPercentage: 0,
  grandTotal: 0,
  totalPrice: 0,
};

export interface IOrderState {
  draft: IOrderPayload;
  edit: IOrderPayload;
  unavailableCartItems: string[];
}

const initialState: IOrderState = {
  draft: JSON.parse(JSON.stringify(orderSkeleton)),
  edit: JSON.parse(JSON.stringify(orderSkeleton)),
  unavailableCartItems: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateDraftOrder: (state: IOrderState, action: PayloadAction<Partial<IOrderPayload>>) => {
      return {
        ...state,
        draft: {
          ...state.draft,
          ...action.payload,
        },
      };
    },
    updateEditOrder: (state: IOrderState, action: PayloadAction<Partial<IOrderPayload>>) => {
      return {
        ...state,
        edit: {
          ...state.edit,
          ...action.payload,
        },
      };
    },
    clearDraftOrder: (state: IOrderState) => {
      return {
        ...state,
        draft: JSON.parse(JSON.stringify(orderSkeleton)),
      };
    },
    clearEditOrder: (state: IOrderState) => {
      return {
        ...state,
        edit: JSON.parse(JSON.stringify(orderSkeleton)),
      };
    },
    addUnavailableCartItems: (state: IOrderState, action: PayloadAction<string[]>) => {
      return {
        ...state,
        unavailableCartItems: action.payload,
      };
    },
    clearUnavailableCartItems: (state: IOrderState) => {
      return {
        ...state,
        unavailableCartItems: [],
      };
    },
    clearOrder() {
      return initialState;
    },
  },
});

export const {
  updateDraftOrder,
  updateEditOrder,
  clearDraftOrder,
  clearEditOrder,
  clearOrder,
  addUnavailableCartItems,
  clearUnavailableCartItems,
} = orderSlice.actions;

export const orderReducer = orderSlice.reducer;
