//  this page is for showing the showing the thank you
import React from 'react';
import CustomerHeader from "../Common/customerHeader";
import LoggedHeader from "../Common/loggedHeader";
import Footer from "../Common/footer"
import SideMenu from "../../components/organisms/sidebar"
import { userDeatils } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import ReferFriendCustomerView from "./referFriendCustomerView"
import ReferFriendAdminView from "./referFriendAdminView"
import MobileHeader from "../Common/mobileHeader";
export default function ReferFriendCustomer(props) {

    const user = useReactiveVar(userDeatils);
    const userType =
        user?.data?.login?.userType ||
        user?.data?.currentUserDetails?.userType ||
        user?.data?.createUserIdentity?.userType ||
        user?.data?.createCaterer?.userType;

    return (
        <div>
            {userType === "Customer" ? (
                <>
                <CustomerHeader title="Refer A Friend" />
                <MobileHeader 
                    selected={"Refer A Friend"}
                />
                </>
            ) : (
                <LoggedHeader title="Refer a friend list" />
            )}
            <SideMenu
                selected={"Refer A Friend"}
                user="Customer"
            />
            {userType === 'Customer' ? <ReferFriendCustomerView/> : <ReferFriendAdminView/>}
            {/* <Footer /> */}
        </div>
    )
} 