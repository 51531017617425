import React from 'react'

import LoginHeader from "../Login/LoginHeader";
import Footer from "../Common/footer";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
export default function AccessDenied() {
  let currentLocation = window.location.pathname;
  useEffect(()=>{
    if(currentLocation==="/resources"){
      window.location.href= "https://www.caterplace.com/resources"
    }
  },[])
  return (
    <div>
     {currentLocation!="/resources"? <>
      <LoginHeader />
      <div className="container">
        <div className="access-page">
          <div className="access-page-inner">
          <img src="../assets/images/access-denied.svg" className="img-fluid" alt="Access Denied"/>
          <h2 className="h2">Access Denied</h2>
          <h4 className="h4">Sorry, you are not authorized to view this page</h4>
          </div>
          </div>
      </div>
      <Footer/>
      </>:<> <div className="d-flex justify-content-center align-items-center" style={{height:"calc(100vh - 70px)"}}><CircularProgress /></div></>}
    </div>
  );
}
