import { Pagination, MenuItem, Select, Stack } from "@mui/material";
import React from "react";

interface IOrderListPaginationProps {
  page: number;
  rowsPerPage: number;
  ordersCount: number;
  setPage(v: number): void;
  setRowsPerPage(v: number): void;
}

export function OrderListPagination({
  page,
  rowsPerPage,
  ordersCount,
  setRowsPerPage,
  setPage,
}: IOrderListPaginationProps) {
  function handlePageInputChange(event) {
    if (!event) {
      return;
    }
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }

  const handleChangePage = (e, pages) => {
    setPage(pages);
  };

  return (
    <div className="table-pagenation pb-5 pb-md-0">
      <div className="rowCount">
        <Select onChange={handlePageInputChange} value={rowsPerPage}>
          {[10, 25, 50, 100].map((pageNum) => (
            <MenuItem key={pageNum} value={pageNum}>
              {pageNum}
            </MenuItem>
          ))}
        </Select>
      </div>

      <Stack spacing={2}>
        <Pagination
          className="tableCount"
          count={Math.ceil(ordersCount / rowsPerPage)}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChangePage}
        />
      </Stack>
    </div>
  );
}
