import React from "react";
import { Button, TableCell, TableRow } from "@mui/material";
import moment from "moment";

import { formatter } from "../../../../Modules/Common/commonUtils";

import { IOrderResponseItem } from "../../order.typings";
import { OrderItemMenu } from "./order-item-menu";
import { useOrderItemState } from "./order-item.state";
import DialogBox from "Modules/Common/dialogBox";
import { getOrderStatusColor } from "@utils/order-format.utils";

interface IOrderItemProps {
  item: IOrderResponseItem;
  userType: string;
  userId: string;
  tile?: string;
  orderState: any;
  onInvoiceClick(id: string): void;
}

export function OrderItem({ item, userId, userType, orderState, onInvoiceClick }: IOrderItemProps) {
  const {
    isLoading,
    anchorEl,
    modalMode,
    onCloseModal,
    onCancelOrder,
    onDeleteOrder,
    onViewOrder,
    onEditOrder,
    onViewCustomer,
    onReOrder,
    onOpenMenuItem,
    onCloseMenu,
    onConfirmCancelOrder,
    onConfirmDeleteOrder,
    onContinueDraftOrder,
  } = useOrderItemState({
    orderState,
    userType,
    customerId: userId,
    order: item,
  });

  const onTableClick = () => {
    onViewOrder(item._id);
  };

  function onInvoice() {
    onInvoiceClick(item._id);
  }

  function onMenu(e: any) {
    if (isLoading) {
      return;
    }
    onOpenMenuItem(e);
  }

  return (
    <>
      <OrderItemMenu
        anchorEl={anchorEl}
        selectedItem={item}
        userType={userType}
        isLoading={isLoading}
        onClose={onCloseMenu}
        onViewOrder={onTableClick}
        onReOrder={onReOrder}
        onEditOrder={onEditOrder}
        onCancelOrder={onCancelOrder}
        onDeleteOrder={onDeleteOrder}
        onContinueOrder={onContinueDraftOrder}
      />
      {modalMode && (
        <DialogBox
          setOpenDialog={onCloseModal}
          handleDelete={modalMode === "delete" ? onConfirmDeleteOrder : onConfirmCancelOrder}
          openDialog={modalMode}
          title={modalMode === "delete" ? "Delete" : "Cancel"}
          discription={
            modalMode === "delete"
              ? "Are you sure you want to delete this order?"
              : "Are you sure you want to cancel this order?"
          }
        />
      )}
      <TableRow style={{ cursor: "pointer" }}>
        <TableCell onClick={onTableClick}>{`#${item?.orderNo}`}</TableCell>
        { userType === "Admin" &&
          <TableCell onClick={onTableClick}>
            {item?.hasDriver
              ? <Button className="status-btn" style={{ color: "#fff", backgroundColor: "#4CAF50" }} variant="contained">Yes</Button>
              : <Button className="status-btn" style={{ color: "#fff", backgroundColor: "#F44336" }} variant="contained">No</Button>
            }
          </TableCell>
        }
        <TableCell onClick={onTableClick}>
          {item?.deliveryDate
            ? `${moment(item?.deliveryDate).format("ddd, MMM DD YYYY")}, ${item?.deliveryTime}`
            : "---"}
        </TableCell>
        <TableCell onClick={onTableClick}>
          {userType !== "Caterer" ? `${item?.catererDetails?.businessName}, ` : ""}
          {item.location ? `${item.location}, ` : ""}
          {/* TODO: replace locWithStreet with street in future */}
          {item.locWithStreet}
          {item.zip ? `, ${item.zip}` : ""}
        </TableCell>
        <TableCell onClick={onViewCustomer}>
          {userType === "Customer"
            ? formatter.format(item?.grandTotal)
            : `${item?.customerDetails?.firstName} ${item?.customerDetails?.lastName}`}
        </TableCell>
        <TableCell align="center" onClick={onTableClick}>
          <Button
            className="status-btn"
            style={{
              color: "#fff",
              backgroundColor: getOrderStatusColor(item.orderStatus, item.isRefunded),
            }}
            variant="contained">
            {item?.isRefunded ? "Refunded" : item?.orderStatus}
          </Button>
        </TableCell>
        <TableCell align="center">
          {item?.orderStatus != "Draft" ? (
            <img
              onClick={onInvoice}
              src="../assets/images/pdf_icon.svg"
              className="img-fluid"
              alt="invoice img"
            />
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell align="center">
          <img
            style={{ cursor: "pointer" }}
            onClick={onMenu}
            src="../assets/images/more-horizontal.svg"
            alt="img"
          />
        </TableCell>
      </TableRow>
    </>
  );
}
