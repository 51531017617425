import React, { useState } from 'react';
import {
    TextField,
    Chip, Button
} from "@mui/material";

export default function Tags ({ tags = [], setTags, label, handleChange }) {
    const [inputValue, setInputValue] = useState('');

    const handleAddTag = () => {
        if (inputValue.trim() !== '') {
            const newTags = [...tags, inputValue.trim()];
            setTags(newTags);
            handleChange(newTags);
            setInputValue('');
        }
    };
    const handleDeleteTag = (tagToDelete) => {
        const newTags = tags.filter((tag) => tag !== tagToDelete);
        setTags(newTags);
        handleChange(newTags);
    };

    return (
        <div>
            <div className='d-flex'>
                <TextField
                    label={`${label ? label : 'Add Tag'}`}
                    variant="outlined"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />

                <Button
                    className='ms-3'
                    variant="contained"
                    onClick={(e) => {
                        e.preventDefault(); // Prevent form submission
                        handleAddTag();
                    }}>Add</Button>
            </div>

            <div style={{ marginTop: '10px' }}>
                {tags && tags.map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag}
                        onDelete={() => handleDeleteTag(tag)}
                        style={{ margin: '5px' }}
                    />
                ))}
            </div>
        </div>
    );
};