import { gql } from "@apollo/client";

export const CREATE_NEW_ORDER = gql`
  mutation CreateNewOrder($order: createOrderPayload, $cart: [cartPayload]) {
    createNewOrder(order: $order, cart: $cart) {
      _id
      addressId
      cardIcon
      cardId
      cardNo
      caterCashAfterRefund
      caterCashTotal
      catererId
      city
      commision
      contactNo
      contactPerson
      couponApplied
      createdBy
      createdOn
      cust_del
      customerId
      dateCancelled
      dateCompleted
      dateScheduled
      deliveryDate
      deliveryFee
      deliveryMode
      deliveryTime
      discountAmt
      driverTip
      driverTipType
      grandTotal
      headCount
      instruction
      isEditedOrder
      isCaterCashDeclained
      isPaid
      isPaidToCaterer
      isReOrder
      isReferred
      isRefunded
      lat
      lng
      location
      locationName
      orderDate
      orderNo
      orderStatus
      orderTax
      payProfId
      placeId
      pricePerPerson
      r_transaction_id
      reauthTransId
      refererReward
      refundAmount
      refundDate
      refundText
      refundTransactionId
      refundType
      settlementDate
      shipToId
      state
      street
      suit
      tax
      salesTaxAmount
      salesTaxPercentage
      tipPercentage
      totalAfterRefund
      totalPerHead
      totalPrice
      tablewareDeclined
      tablewareTotal
      transactionId
      updatedBy
      updatedOn
      zip
    }
  }
`;
