import React from "react";
import { IconButton } from "@mui/material";

import { formatter } from "../../../../../../Modules/Common/commonUtils";
import "./cart-list.style.css";
import { ICartItem } from "@services/redux/order";

interface ICartListItemProps {
  item: ICartItem;
  index: number;
  onItemRemove(id: string, index: number): void;
  onItemSelect(item: ICartItem, index: number): void;
}

export function CartListItem({ item, index, onItemSelect, onItemRemove }: ICartListItemProps) {
  function onRemoveItem() {
    onItemRemove(item?._id, index);
  }

  function onClickItem() {
    onItemSelect(item, index);
  }

  return (
    <div className="cart-view-list-item" data-testid="cart-item">
      <div className="cart-count-name">
        <div className="d-flex align-items-center" role="button" onClick={onClickItem}>
          <b className="item-count">
            <span>{item?.quantity}</span>
            <small>x</small>
          </b>
          <h6>{item?.itemName}</h6>
        </div>
        <div className="cart-price-delete">
          <b>{formatter.format(item?.cartTotalPrice || 0)}</b>
          <IconButton onClick={onRemoveItem}>
            <img
              src="../assets/images/cart-item-delete.svg"
              className="img-fluid"
              alt="Delete Item"
            />
          </IconButton>
        </div>
      </div>

      <div className="cart-item-name">
        {item?.addOns ? (
          <>
            {item.addOns.map((addOn, idx) => (
              <React.Fragment key={`add-on-${idx}`}>
                <span>{addOn?.addOnName}</span>
                {addOn?.subAddOns?.length > 0 && (
                  <ul>
                    {addOn?.subAddOns.map((sub, subIdx) => (
                      <li key={`sub-add-on-${subIdx}`}>{sub?.subAddOnName}</li>
                    ))}
                  </ul>
                )}
              </React.Fragment>
            ))}
          </>
        ) : null}
      </div>

      {item?.cartInstruction && (
        <p className="sp-instruction">
          <img src="../assets/images/instruction.svg" className="img-fluid" alt="instruction" />
          {item?.cartInstruction}
        </p>
      )}
    </div>
  );
}
