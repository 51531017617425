import React, { useState, useEffect } from "react";
import SideMenu from "../../components/organisms/sidebar";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { useParams, useNavigate } from "react-router-dom";

import CustomerHeader from "../../Modules/Common/customerHeader";
import { userDeatils } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { AddressForm } from "../address-form";
import MobileHeader from "../../Modules/Common/mobileHeader";

import { GET_ONE_ADDRESS } from "./view-address.query";
import { getUserId, getUserType } from "@utils/user-format.utils";
import { IGetOneAddressResponse } from "typings/address.api";
import { ViewAddressMap } from "./view-address-map";
import { TablePagination } from "@components/atoms/table-pagination";
import { ViewAddressOrderItem } from "./view-address-order-item";
import { LIST_ALL_ORDERS } from "Graphql/queries";

export function ViewAddress() {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useReactiveVar(userDeatils);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isAddressFormOpen, setAddressFormOpen] = useState(false);
  const [urlState, setUrlState] = useState(null);

  const userType = getUserType(user);
  const userId = getUserId(user);

  const catererId =
    user?.data?.login?.catererId ||
    user?.data?.currentUserDetails?.catererId ||
    user?.data?.createCaterer?.catererId;

  const { data: address, loading: isAddressLoading } = useQuery<IGetOneAddressResponse>(GET_ONE_ADDRESS, {
    fetchPolicy: "network-only",
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (!address) {
      return;
    }
    setUrlState({
      address: address ? address.getOneAddress.street : "",
      city: address ? address.getOneAddress.customerCity : "",
      zipCode: address ? address.getOneAddress.customerZip : "",
      addressId: address ? address.getOneAddress._id : "",
      suit: address ? address.getOneAddress.floor : "",
      locationName: address ? address.getOneAddress.locationName : "",
      instructions: address ? address.getOneAddress.instruction : "",
      cordinates: address
        ? [parseFloat(address.getOneAddress.longitude), parseFloat(address.getOneAddress.latitude)]
        : [],
      contactPerson: address ? address.getOneAddress.contactName : "",
      contactNo: address ? address.getOneAddress.Phone : "",
    });
  }, [address]);

  const orderVariable = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    orderStatus: ["Completed"],
    catererId: userType === "Caterer" ? catererId : "",
    customerId: userType === "Customer" ? userId : "",
    addressId: id,
  };

  const { data, loading: ordersLoading } = useQuery(LIST_ALL_ORDERS, {
    fetchPolicy: "network-only",
    variables: orderVariable,
  });

  function onCloseAddressForm() {
    setAddressFormOpen(false);
  }

  function onOpenAddressForm() {
    if (isAddressLoading || !address) {
      return;
    }
    setAddressFormOpen(true);
  }

  function onOrderToAddress() {
    if (!urlState) {
      return;
    }
    navigate("/caterers", { state: urlState });
  }

  return (
    <div>
      <CustomerHeader title={address ? address.getOneAddress.street : ""} />
      <MobileHeader selected={"Address"} />
      <SideMenu
        selected="Address"
        user={userType === "Customer" ? "customer" : userType === "Caterer" ? "caterer" : "admin"}
      />
      <div className={userType !== "Admin" ? "contentWrap" : ""}>
        <div className="contentWrapInner">
          <a onClick={() => window.history.back()} className="back-link-order">
            <img src="../assets/images/back-org.svg" className="img-fluid" alt="Back" />
            Back to Address
          </a>
          <div className="address-wrap">
            <div className="address-wt-box">
              {address && (
                <ViewAddressMap
                  latLng={{
                    lat: parseFloat(address.getOneAddress.latitude),
                    lng: parseFloat(address.getOneAddress.longitude),
                  }}
                  index={address.getOneAddress._id}
                />
              )}
              {isAddressFormOpen && (
                <AddressForm
                  isOpen={isAddressFormOpen}
                  onClose={onCloseAddressForm}
                  addressItem={address ? address.getOneAddress : null}
                />
              )}
            </div>
            <div className="address-wt-box">
              <div className="address-title-bar d-flex align-items-center justify-content-between">
                <h4 className="h4 d-flex align-items-center">
                  <img
                    src="../assets/images/icon-contact-info.svg"
                    className="img-fluid"
                    alt="Contact Information Icon"
                  />
                  Address Details
                </h4>
                <a className="ld-edit" onClick={onOpenAddressForm}>
                  <img
                    src="../assets/images/icon-edit-2.svg"
                    className="img-fluid"
                    alt="Edit Icon"
                  />
                  Edit
                </a>
              </div>

              <div className="row address-detail">
                <div className="col-md-4">
                  <label>Location Name</label>
                  <p>{address ? address.getOneAddress.locationName : ""}</p>
                </div>
                <div className="col-md-4">
                  <label>Unit/Floor</label>
                  <p>{address ? address.getOneAddress.floor : ""}</p>
                </div>
                <div className="col-md-4">
                  <label>Street Address</label>
                  <p>{address ? address.getOneAddress.street : ""}</p>
                </div>
                <div className="col-md-4">
                  <label>Contact Name</label>
                  <p>{address ? address.getOneAddress.contactName : ""}</p>
                </div>
                <div className="col-md-4">
                  <label>Phone</label>
                  <p>{address ? address.getOneAddress.Phone : ""}</p>
                </div>
                <div className="col-md-4">
                  <label>Zipcode</label>
                  <p>{address ? address.getOneAddress.customerZip : ""}</p>
                </div>
                <div className="col-md-8">
                  <label>Delivery Instructions</label>
                  <p>{address ? address.getOneAddress.instruction : ""}</p>
                </div>
              </div>
              <hr />
              <div className="d-md-flex align-items-center justify-content-end">
                <div className="banner-search-link d-block" onClick={onOrderToAddress}>
                  <Button
                    className="w-100 w-md-auto mb-2 mb-md-auto"
                    color="primary"
                    variant="contained">
                    Order to this address
                  </Button>
                </div>
              </div>

              <div className="address-title-bar d-flex align-items-center justify-content-between border-0 pb-0">
                <h4 className="h4 d-flex align-items-center ">
                  <img
                    src="../assets/images/order.svg"
                    className="img-fluid"
                    alt="Contact Information Icon"
                  />
                  Orders Placed to this Address
                </h4>
              </div>

              <TableContainer>
                <Table className="commonTabel" sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Orders</TableCell>
                      <TableCell align="left">Event Date & Time</TableCell>
                      <TableCell align="left">Caterer & Location</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Food</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ordersLoading || !data?.listOrders?.data.length ? (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <p> {ordersLoading ? "Loading..." : "No list added Yet!"}</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      data?.listOrders?.data.map((item) => (
                        <ViewAddressOrderItem item={item} customerId={userId} key={item._id} />
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <TablePagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          itemsLength={data?.listOrders?.count}
        />
      </div>
    </div>
  );
}
