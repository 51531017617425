import React from "react";

import {
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import SideMenu from "../../components/organisms/sidebar";
import CustomerHeader from "../../Modules/Common/customerHeader";
import { AddressListItem } from "./address-list-item";
import { AddressForm } from "../address-form";
import MobileHeader from "../../Modules/Common/mobileHeader";
import DialogBox from "../../Modules/Common/dialogBox";
import { TablePagination } from "@components/atoms/table-pagination";

import { AddressListMenuItem } from "./address-list-menu";
import { useAddressListState } from "./address-list.state";

export function AddressList() {
  const {
    isLoading,
    addressList,
    pagination,
    addressModal,
    removeAddress,
    addressElement,
    onOpenAddressMenu,
    onCloseAddressMenu,
    onViewAddressItem,
    onEditAddressItem,
    onDeleteAddressItem,
    onAddAddress,
  } = useAddressListState();

  return (
    <div>
      <AddressForm
        isOpen={addressModal.isAddressFormOpen}
        onClose={addressModal.onCloseAddressForm}
        addressItem={addressModal.selectedItem}
      />
      <DialogBox
        setOpenDialog={removeAddress.setRemoveModalOpen}
        handleDelete={removeAddress.onRemoveAddress}
        openDialog={removeAddress.isRemoveModalOpen}
        title="Delete"
        discription="Are you sure you want to delete this Saved Address?"
      />
      <CustomerHeader title="Address" />
      <MobileHeader selected={"Address"} />
      <SideMenu selected="Address" user="customer" />
      <div className="contentWrap">
        <div className="contentWrapInner">
          <div className="location-head">
            <div className="location-head-title justify-content-between">
              {addressList.loading ? (
                <p>Loading...</p>
              ) : (
                <h5
                  style={{
                    color: "#7DA3C1",
                  }}>{`${addressList.count} Saved Addresses`}</h5>
              )}
              <div className="d-flex align-items-center">
                <Button className="ms-3" color="primary" variant="contained" onClick={onAddAddress}>
                  <img
                    src="../assets/images/icon-add-menu.svg"
                    className="img-fluid me-1"
                    alt="Icon"
                  />
                  Add Address
                </Button>
              </div>
            </div>
          </div>
          <TableContainer>
            <Table className="commonTabel" sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Location Name</TableCell>
                  <TableCell align="left">Street Address</TableCell>
                  <TableCell align="left">Last Order</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addressList.loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <p> Loading...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : !addressList.items.length ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <p>No list added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  addressList.items.map((item, index) => (
                    <AddressListItem
                      key={index}
                      isLoading={isLoading}
                      item={item}
                      onSelect={onOpenAddressMenu}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <TablePagination
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          itemsLength={addressList.count}
          setRowsPerPage={pagination.setRowsPerPage}
          setPage={pagination.setPage}
        />

        <AddressListMenuItem
          addressElement={addressElement}
          onClose={onCloseAddressMenu}
          onView={onViewAddressItem}
          onEdit={onEditAddressItem}
          onDelete={onDeleteAddressItem}
        />
      </div>
    </div>
  );
}
