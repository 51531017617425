import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UpdateIcon from '@mui/icons-material/Update';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

import { UPLOAD_TAX_CERTIFICATE } from "../../Graphql/queries";
import CustomerHeader from "../Common/customerHeader";
import SideMenu from "../../components/organisms/sidebar";
import MobileHeader from "../Common/mobileHeader";
import { toggleSnackbar } from "../../ReactiveVariables/index";
import { useDropzone } from 'react-dropzone';
import { useReactiveVar } from "@apollo/client";
import { userDeatils } from "../../ReactiveVariables/index";

const dropzoneStyle = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default function TaxExemption(props) {
  const user = useReactiveVar(userDeatils);
  const userDetails = user?.data?.currentUserDetails || user?.data?.login || user?.data?.createUserIdentity;
  const customerId = userDetails?._id;
  const [file, setFile] = useState(null);
  const [fileRaw, setFileRaw] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const taxStatus = userDetails?.taxExempt;
  const pendingApproval = userDetails?.taxCert && !taxStatus;
  const profileTaxCert = userDetails?.taxCert;
  const isTaxExempt = taxStatus === true;
  const [taxCertURL, setTaxCertURL] = useState(null);

  const [uploadPdf, uploadData] = useLazyQuery(UPLOAD_TAX_CERTIFICATE, {
    fetchPolicy: "network-only",
    refetchQueries: ['GetOneUser']
  });

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const dataUrl = e.target.result;
        const fileData = dataUrl.split(',')[1]
        setFileRaw(selectedFile);
        setFile(fileData);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const upload = async () => {
    try {
      await uploadPdf({
        variables: {
          file,
          customerId,
          filename: fileRaw.name,
          mimetype: fileRaw.type
        }
      });

      setFileUploaded(true);
      toggleSnackbar({ status: "success", message: "Certificate successfully uploaded."});
    } catch (error) {
      setFileUploaded(false)
      toggleSnackbar({ status: "error", message: "Certificate upload failed."});
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (userDetails?.taxCert) {
      setTaxCertURL(userDetails?.taxCert);
    }
  }, [userDetails]);

  useEffect(() => {
    if (uploadData?.data?.uploadTaxCert?.url) {
      setTaxCertURL(uploadData?.data?.uploadTaxCert?.url);
    }
  }, [uploadData])

  return (
    <div>
      <CustomerHeader title="Tax Exemption" />
      <MobileHeader selected="Tax Exemption" />
      <SideMenu selected="Taxes" user="customer" />
      <div className="contentWrap contentWrap-customer-profile">
        <div className="customer-profile-wrap">
          <div className="customer-profile mt-0 mb-0">
            <div className="customer-profile-title">
              <h4 className="h4">
                <img
                  src="../assets/images/icon-contact-info.svg"
                  className="img-fluid"
                  alt="Contact Information Icon"
                />
                Tax Exemption
              </h4>
            </div>
            { isTaxExempt ?
              <div className="row customer-profile-details">
                <div className="col text-center pt-4">
                  <div className="pb-4"><CheckBoxIcon style={{ fontSize: '64px', color: "green" }}/></div>
                  <p>Your tax exemption certificate has been approved. All orders placed are now being processed without the addition of any taxes.</p>
                </div>
              </div>
             : (taxStatus === false && profileTaxCert) ?
                <div className="row customer-profile-details">
                  <div className="col text-center pt-4">
                    <div className="pb-4"><DoNotDisturbIcon style={{ fontSize: '64px', color: "red" }} /></div>
                    <p>Your tax exemption certificate was not approved, please contact us for more details.</p>
                  </div>
                </div>
             : <div className="row customer-profile-details">
              { ((fileUploaded || pendingApproval || taxCertURL)) ?
                <div className="col text-center">
                  <div className="py-4"><UpdateIcon fontSize="large"/></div>
                  <p>Your certificate has been successfully submitted to our team and is under review. Once a decision has been made this page will display your tax exemption status. You can view your uploaded certificate <a target="_blank" rel="noreferrer" href={taxCertURL}>here</a>.</p>
                  <p>Please feel free to reach out to us with any questions or concerns.</p>
                </div>
                : <div className="col">
                    <p>If you qualify for tax exemption, please upload your tax exemption certificate. Once we receive it, our team will promptly review your submission, and upon approval, we will apply the tax exemption to your profile.</p>

                    <div className="d-flex justify-content-center pt-4">
                      <div {...getRootProps()} style={dropzoneStyle}>
                        <input {...getInputProps()} />
                        {file ? (
                          <div>
                            <div>Selected file: {fileRaw.name}</div>
                          </div>
                        ) : (
                          <div>Drag and drop a PDF file here, <br/> or click to select one.</div>
                        )}
                      </div>
                    </div>
                    { file && (
                      <div className="d-flex justify-content-center pt-4">
                        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} onClick={() => upload(file)}>
                          Upload file
                        </Button>
                      </div>
                    ) }
                  </div>
              }
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
