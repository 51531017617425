import React from 'react';

import { Button } from "@mui/material";
import { useState } from "react";
import DialogBox from "../Common/dialogBox";

export default function RefundView(props) {
  const [openDialog, setOpenDialog] = useState(false)

  const InitiateRefundCheck = () => {
    const dateCompleted = props?.orderValues?.getOneOrder?.dateCompleted
    const currentTimeMilliseconds = new Date().getTime()
    const dateCompletedMilliseconds = new Date(dateCompleted).getTime()
    if (currentTimeMilliseconds - dateCompletedMilliseconds > 86400000) {
      props?.setInitatedRefund(true)
    } else {
      setOpenDialog(true)
    }
  }
  const closeDialog = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    setOpenDialog(false)
    return
  }

  return (
    <div className="order-status-wrap">
      {openDialog && (
        <DialogBox
          setOpenDialog={setOpenDialog}
          handleDelete={closeDialog}
          openDialog={openDialog}
          refund={'refund'}
          discription="Refund cannot be initiated for this order since the payment has not settled. Please wait for 24 hours before initiating refund."
        />
      )}
      <div className="order-view-title issue-refund">
        <h5 className="h5 mb-0">Issue Refund</h5>
        <Button variant="contained" onClick={() => InitiateRefundCheck()} className="status-update-btn">Initiate Refund</Button>
      </div>
    </div>
  );
}
