
import { ApolloClient, InMemoryCache,createHttpLink } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { Cookies } from 'react-cookie';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL + "/graphql",
  //uri: "https://api-stag.caterplace.com/graphql",
});

const authLink = setContext((_, { headers }) => {
  const cookies = new Cookies();
  const shortToken = cookies.get('@authToken');
  const token = localStorage.getItem("@authToken");
  return {
    headers: {
      ...headers,
      authorization: token ? token : shortToken ? shortToken : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  }),
});

export default client;
// Supported in React 18+
// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   <ApolloProvider client={client}>
//     <App />
//   </ApolloProvider>,
// );