import React from "react";
import { MenuItem, Menu } from "@mui/material";

interface IAddressListMenuItemProps {
  addressElement: Element;
  onClose(): void;
  onView(): void;
  onEdit(): void;
  onDelete(): void;
}

export function AddressListMenuItem({
  addressElement,
  onClose,
  onView,
  onEdit,
  onDelete,
}: IAddressListMenuItemProps) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={addressElement}
      open={!!addressElement}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      className="menu-list-more">
      <MenuItem onClick={onView}>View</MenuItem>
      <MenuItem onClick={onEdit}>Edit</MenuItem>
      <MenuItem onClick={onDelete}>Delete</MenuItem>
    </Menu>
  );
}
