import { makeVar } from "@apollo/client";
export const authVar = makeVar({
  loaded: false,
  auth: false,
  userType: null,
});
export const toggleSnackbar = makeVar({
  status: false,
  message: "",
  variant: "",
});
export const userDeatils = makeVar({
  data: null,
});
export const catererDeatils = makeVar({
  data: null,
});

export const setId = makeVar({
  data: null,
});

export const salesTaxPercentage = makeVar({
  percentage: null,
});

export const retainFilter = makeVar({
  data: null,
});
