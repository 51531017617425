// this page is for addin new bank info
import React from 'react';

import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button, FormGroup, FormControlLabel, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client";
import { UPDATE_CATERE } from "../../../Graphql/mutations";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  toggleSnackbar,
} from "../../../ReactiveVariables/index";
import { postiveValueConverter } from'../../Common/commonUtils';
const StyledCheckbox = styled(Checkbox)`
  svg {
    font-size: 22px;
  }
`;

export default function NewBankInfo(props) {
  const navigate = useNavigate()
  const currentData =
    props?.currentDetails?.getOneCaterer || props?.userValues?.updateCaterer;
  const [newMail, setNewMail] = useState(currentData?.addEmailFlag ?? false)
  const schema = yup.object().shape({
    additionalEmail: yup.string().email("Please enter a valid email address")
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [updateCaterer, { loading }] = useMutation(UPDATE_CATERE, {
    refetchQueries: ["ListCaterer"],
    onCompleted: (res) => {
      props.setUserValues(null);
      navigate("/location")
    },
  });

  // for alowing only positive values
  const handleInputChange = (event) => {
    event.target.value = postiveValueConverter(event,'withoutDecimal');
  };

  // this function is for updating the banking info to the backend
  function onSubmit(params) {
    params["addEmailFlag"] = newMail
    params["tabValue"] = "4";
    try {
      currentData.adminFlg ? updateCaterer({ variables: { id: props?.userDetails?.data?.currentUserDetails?._id || props?.createRes?.createCaterer?._id, catererId: props?.userDetails?.data?.currentUserDetails?.catererId || props?.createRes?.createCaterer?.catererIdNew, data: params } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Banking Info skipped successfully",
            variant: "success",
          });
        }) :
        updateCaterer({ variables: { id: props.createRes?.createCaterer?.newUserId || props.createRes?.updateUserIdentity?._id, catererId: props.createRes?.createCaterer?.catererIdNew || props.createRes?.updateUserIdentity?.catererId, data: params } })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Banking Info saved successfully",
              variant: "success",
            });
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  useEffect(() => {
    props?.setTitle("Add Banking information")
  })
  return (
    <div>
      <div className="add-business-step-head">
        <h5 className="h5">Banking Information</h5>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}
        noValidate>
        <div className="create-step-form">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 textBox">
                <TextField
                  id="outlined-basic"
                  label="LEGAL BUSINESS NAME"
                  defaultValue={currentData?.accountName}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("accountName")}
                />
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="outlined-basic"
                  label="BANK NAME"
                  defaultValue={currentData?.bankName}
                  variant="outlined"
                  className="textField"
                  {...register("bankName")}
                />
              </div>
              <div className="col-12">
                {newMail && <div className="col-md-12 textBox">
                  <TextField
                    id="outlined-basic"
                    autoFocus
                    label="EMAIL"
                    defaultValue={currentData?.additionalEmail}
                    variant="outlined"
                    className="textField allFeild"
                    {...register("additionalEmail")}
                  />
                  {errors.additionalEmail && <span className="error-msg">{errors.additionalEmail.message}</span>}
                </div>}
              </div>

            </div>
          </div>
        </div>
        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => props.setActiveStep(2)}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="justify-content-center saveBtn"
          >
            {loading ? <CircularProgress color="inherit" size={20} /> : "Save"}
          </Button>
        </div>
      </form>
    </div>
  );
}
