//listing the upcoming orders
import React from 'react'

import { Table, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
export default function UpcomingOrders(props) {
  return (
    <div className="cater-summary">
      <h4 className="h4">
        <img
          src="../assets/images/icon-delivery-hours.svg"
          className="img-fluid"
          alt="Upcoming Orders Icon"
        />
        Upcoming Orders
      </h4>
      <TableContainer>
        <Table
          className="commonTabel"
          // sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Orders</TableCell>
              <TableCell align="left" width="30%">Date & Time</TableCell>
              <TableCell align="left">Caterer</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.loading ? (
              <TableRow>
                <TableCell colSpan={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p> Loading...</p>
                  </div>
                </TableCell>
              </TableRow>
            ) : props?.orderData?.listOrders?.data.length > 0 ? (
              props?.orderData?.listOrders?.data.map((item) => (
                <TableRow style={{ cursor: "pointer" }}>
                  <TableCell>{`#${item?.orderNo}`}</TableCell>
                  <TableCell>
                    {moment(item?.deliveryDate).format("llll")}
                  </TableCell>
                  <TableCell>{item?.catererDetails?.businessName}</TableCell>
                  <TableCell align="center">
                    <Button
                      className="status-btn"
                      style={{
                        color: "#fff",
                        backgroundColor:
                          item?.orderStatus === "New"
                            ? "#00C2FF"
                            : item?.orderStatus === "Completed" &&
                              !item?.isRefunded
                            ? "#3A8CF8"
                            : item?.orderStatus === "Cancelled"
                            ? "#FF0000"
                            : item?.orderStatus === "Scheduled"
                            ? "#FD982F"
                            : item?.orderStatus === "Completed" &&
                              item?.isRefunded
                            ? "#219ebc"
                            : "",
                      }}
                      variant="contained"
                    >
                      {item?.isRefunded ? "Refunded" : item?.orderStatus}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>No list added Yet!</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
