// this page is for showing all the list of locations that are created by the caterer 
import React, { useState } from 'react';
import SideMenu from "../../components/organisms/sidebar"
import LoggedHeader from "../Common/loggedHeader"
import { Table, Select, MenuItem } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate} from "react-router-dom";
import { useQuery } from '@apollo/client';
import { LIST_CATERER } from '../../Graphql/queries';
import Pagination from '@mui/material/Pagination';
import { userDeatils } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import Stack from '@mui/material/Stack';

export default function Location() {
    const user = useReactiveVar(userDeatils);
    const navigate= useNavigate()
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const catererVariables= {
      skip:(page - 1) * rowsPerPage ,
      limit: rowsPerPage,
      userId:user?.data?.currentUserDetails?._id ||user?.data?.login?._id||user?.data?.createCaterer?._id,
      search: search,
    }
    const {data,loading}= useQuery(LIST_CATERER,{variables:catererVariables,fetchPolicy:"network-only"})
    const handleChangePage = (e, pages) => {
        setPage(pages);
    };
    // function for handling pagenation 
    function handlePageInputChange(event) {
        setRowsPerPage(Number(event.target.value));
        setPage(1);
    }
    return (
        <div>
          {/* header after login/ registering  */}
            <LoggedHeader title="Profile" />
            {/* side menu for different options for the caterer */}
            <SideMenu selected="Profile" />

            <div className="contentWrap">
                <div className='contentWrapInner'>
                    <div className='location-head'>
                        <div className='location-head-title'>
                            <h5>Search</h5>
                            <input 
                            placeholder="Search by Store Name" 
                            className="form-control" 
                            value={search}
                            onChange={(e) => {setPage(1);setSearch(e.target.value)}}/>
                        </div>
                    </div>
                    <TableContainer>
                        <Table className='commonTabel' sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Store Name</TableCell>
                                    <TableCell align="left">Address</TableCell>
                                    <TableCell align="center">Admin Name</TableCell>
                                    <TableCell align="center">Owner Contact Number</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading?(
                                  <TableRow>
                                    <TableCell colSpan={12}>
                                      <div
                                        style={{ display: "flex", justifyContent: "center" }}
                                      >
                                        <p> Loading...</p>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                  ):data&&data?.listCaterer?.data?.length>0?(
                                  data&&data?.listCaterer?.data?.map((item)=>(
                                  <TableRow style={{cursor:"pointer"}} onClick={()=>navigate(`${item?._id}`,{ state: "locationDetails" })}>
                                    <TableCell component="th" scope="row">
                                        {item?.businessName}
                                    </TableCell>
                                    <TableCell align="left">{item?.locationName}</TableCell>
                                    {/* <TableCell align="center">{item?.catererUsers[0]?.role==="Admin"?item?.catererUsers[0]?.userDtls?.name : item?.catererUsers[1]?.role==="Admin"?item?.catererUsers[1]?.userDtls?.name : "Not Assigned"}</TableCell> */}
                                    <TableCell align="center">{item?.catererUsers[0]?.userDtls?.name}</TableCell>
                                    <TableCell align="center">{item?.catererUsers[0]?.userDtls?.phoneNumber}</TableCell>
                                </TableRow>
                                ))):search ? (
                                  <TableRow>
                                    <TableCell colSpan={12}>
                                      <div
                                        style={{ display: "flex", justifyContent: "center" }}
                                      >
                                        <p>No data Found</p>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={12}>
                                      <div
                                        style={{ display: "flex", justifyContent: "center" }}
                                      >
                                        <p>No list added Yet!</p>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                )}
                            </TableBody>
                        </Table>

                    </TableContainer>
                </div>
                <div className='table-pagenation'>
                    <div className='rowCount'>
                        <Select
                            // variant="outlined"
                            onChange={handlePageInputChange}
                            value={rowsPerPage}
                        >
                            <MenuItem value={10}>{10}</MenuItem>
                            <MenuItem value={25}>{25}</MenuItem>
                            <MenuItem value={50}>{50}</MenuItem>
                            <MenuItem value={100}>{100}</MenuItem>
                            
                        </Select>
                    </div>


                    <Stack spacing={2}>
                        <Pagination
                            className='tableCount'
                            count={Math.ceil(data?.listCaterer?.count/ rowsPerPage)}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            onChange={handleChangePage}
                        />
                    </Stack>
                </div>
            </div>
        </div>
    )
}