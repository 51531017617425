import React, { useState } from "react";
import SideMenu from "../../components/organisms/sidebar";
import CustomerHeader from "../../Modules/Common/customerHeader";
import LoggedHeader from "../../Modules/Common/loggedHeader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useLocation, useParams } from "react-router-dom";
import { userDeatils } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import { OrderList } from "./order-list/order-list";
import MobileHeader from "../../Modules/Common/mobileHeader";
import { OrderTab } from "./order-tab";
import { getUserId, getUserType } from "@utils/user-format.utils";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface IOrderProps {
  tile?: string;
  catererId?: string;
  customerId?: string;
  title?: string;
}

export function Orders(props: IOrderProps) {
  const { id } = useParams();
  const user = useReactiveVar(userDeatils);
  const location = useLocation();

  const [tab, setTab] = useState(location?.state?.order === "completed" ? 1 : 0);

  const userType = getUserType(user);
  const userId = getUserId(user);

  const onChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const catererId =
    user?.data?.login?.catererId ||
    user?.data?.currentUserDetails?.catererId ||
    user?.data?.createCaterer?.catererId;

  const classSelection = () => {
    if (props?.tile === "caterer" || props?.tile === "customers") {
      return "";
    } else {
      return "contentWrap";
    }
  };
  let currentLocation = window.location.pathname;
  return (
    <div>
      {/* header for Customer login  */}
      {userType === "Caterer" ? (
        <LoggedHeader title="Orders" />
      ) : currentLocation === `/admincatererlist/${id}` && userType === "Admin" ? (
        <LoggedHeader title={props?.title} />
      ) : userType === "Admin" ? (
        <LoggedHeader title="Orders" />
      ) : (
        <>
          <CustomerHeader title="Orders" />
          <MobileHeader selected={"Orders"} />
        </>
      )}

      {/* Side menu for different options for the Customer */}
      {props?.tile === "caterer" ? (
        <SideMenu selected="Caterers" user={"admin"} />
      ) : props?.tile === "customers" ? (
        <SideMenu selected="Customers" user={"admin"} />
      ) : (
        <SideMenu
          selected="Orders"
          user={userType === "Customer" ? "customer" : userType === "Caterer" ? "caterer" : "admin"}
        />
      )}

      {/* if admin then no separate tab */}
      {userType === "Admin" ? (
        <div className={classSelection()}>
          <OrderList
            tile={props?.tile}
            catererId={
              userType === "Caterer" || (props?.tile === "caterer" && userType === "Admin")
                ? props?.catererId || catererId
                : ""
            }
            customerId={
              userType === "Customer" || (props?.tile === "customers" && userType === "Admin")
                ? props?.customerId || userId
                : ""
            }
          />
        </div>
      ) : (
        <div className="contentWrap">
          <div>
            <div className="cater-tab">
              <Tabs value={tab} onChange={onChangeTab} aria-label="basic tabs example">
                <Tab
                  label={userType === "Customer" ? "New/Draft/Pending Orders" : "Scheduled Orders"}
                  {...a11yProps(0)}
                />
                <Tab label="Completed Orders/ Receipts" {...a11yProps(1)} />
              </Tabs>
            </div>

            <OrderTab isActive={tab === 0} value={0}>
              <OrderList
                addNewOrder
                catererId={
                  userType === "Caterer" || (props?.tile === "caterer" && userType === "Admin")
                    ? props?.catererId || catererId
                    : ""
                }
                customerId={
                  userType === "Customer" || (props?.tile === "customers" && userType === "Admin")
                    ? props?.customerId || userId
                    : ""
                }
              />
            </OrderTab>
            <OrderTab isActive={tab === 1} value={1}>
              <OrderList
                completed
                catererId={userType === "Caterer" ? catererId : ""}
                customerId={userType === "Customer" ? userId : ""}
              />
            </OrderTab>
          </div>
        </div>
      )}
    </div>
  );
}
