// this page is for listing the payments
import React, { useState } from "react";
import { Table, Select, MenuItem, Button, InputAdornment } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { userDeatils, toggleSnackbar,authVar } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import TableRow from "@mui/material/TableRow";
import { LIST_ALL_ORDERS } from "../../Graphql/queries";
import SideMenu from "../../components/organisms/sidebar";
import LoggedHeader from "../Common/loggedHeader";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DialogBox from "../Common/dialogBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
export default function Payments(props) {
  const user = useReactiveVar(userDeatils);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const navigate = useNavigate()
  const [search, setSearch] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }); // currency formater
  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;
  const userId =
    user?.data?.login?._id ||
    user?.data?.currentUserDetails?._id ||
    user?.data?.createUserIdentity?._id;
  const catererId =
    user?.data?.login?.catererId ||
    user?.data?.currentUserDetails?.catererId ||
    user?.data?.createCaterer?.catererId;
  const orderVariable = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    orderStatus:
      userType === "Customer"
        ? ["New", "Cancelled", "Scheduled", "Draft"]
        : userType === "Admin" && props?.admin
        ? ["Completed"]
        : userType === "Admin"
        ? ["New", "Cancelled", "Scheduled", "Completed"]
        : ["Completed"],
    catererId:
      userType === "Caterer"
        ? catererId
        : userType === "Admin"
        ? props?.ids
        : "",
    customerId: userType === "Customer" ? userId : "",
    from: fromDate&&new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)),
    to: toDate&&new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)),
    search:search,
    sortField:"dateCompleted",
  };

  const { data, loading, error } = useQuery(LIST_ALL_ORDERS, {
    fetchPolicy: "network-only",
    variables: orderVariable,
  });

  useEffect(()=>{
    if(error&&error.message==="Not authenticated"){
        localStorage.clear();
        authVar({
          loaded: true,
          auth: false,
        });
        userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
    }
  },[error&&error])
  // handling pagination
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };

  // change number of lisiting in a page
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }

  return (
    <div>
      {/* header for changing location and getting the id */}
      <LoggedHeader
        title={
          props?.admin
            ? `${props?.title?.businessName}, ${props?.title?.locationName}`
            : "Payments"
        }
      />
      {/* keeping the side bar selected */}
      <SideMenu
        selected={props?.admin ? "Caterers" : "Payments"}
        user={
          userType === "Customer"
            ? "customer"
            : userType === "Caterer"
            ? "caterer"
            : "admin"
        }
      />
      {/* open drawer for adding and editing menu item */}

      <div className={props.userType !== "Admin" ? "contentWrap" : ""}>
        <div className="contentWrapInner">
        <div className="location-head">
          <div className="location-head-title justify-content-between align-items-center ">
          {/* <div className="menu-head-title d-flex justify-content-between align-items-center"> */}
         { userType === "Caterer"
            ? <h5>Total Net Amount: {data?.listOrders?.totalAmt&&formatter.format(data?.listOrders?.totalAmt)}</h5>:"Payments"}
           {props?.admin&& <input
              placeholder="Search by OrderID & Customer Name"
               className="form-control"
              //className="ms-3"
              value={search}
              onChange={(e) => {
                setPage(1);
                setSearch(e.target.value);
              }}
            />}
             {userType === "Caterer" && (
            <div className="d-flex review-order-datepicker">
          
              <DatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                placeholderText={"From"}
                 maxDate={toDate}
                dateFormat="MMM d, yyyy"
                startAdornment={
                  <InputAdornment position="end">
                    <img
                      src="../assets/images/head.svg"
                      className="img-fluid"
                      alt="Head Count"
                    />
                  </InputAdornment>
                }
              />
            {/* // )} */}
            {/* {userType === "Caterer" && ( */}
              <DatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
                placeholderText={"To"}
                // showTimeSelect
                minDate={fromDate}
                className="ms-3"
               // ref={props?.datepickerRef}
                dateFormat="MMM d, yyyy"
                endAdornment={
                  <InputAdornment position="start">
                    <img
                      src="../assets/images/head.svg"
                      className="img-fluid"
                      alt="Head Count"
                    />
                  </InputAdornment>
                }
              />
            
            </div>)}
          </div></div>
          {/* <div className="location-head">
            <div className="location-head-title menu-search-by-item justify-content-between">
             
             </div>
             </div> */}

          {
            <TableContainer>
              <Table
                className="commonTabel"
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Order#</TableCell>
                    <TableCell align="left">Completed Date & Time</TableCell>
                    {!props?.admin && (
                      <TableCell align="left">Store Name & Location</TableCell>
                    )}
                    <TableCell align="left">Customer</TableCell>
                    {props?.admin?<TableCell align="center">Net Amount</TableCell>:<TableCell align="center">Total Amount</TableCell>}
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p> Loading...</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : data?.listOrders?.data &&
                    data?.listOrders?.data?.length > 0 ? (
                    data?.listOrders?.data &&
                    data?.listOrders?.data?.map((item) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ color: !props?.admin && "#F96C5B" }}
                        >
                          {`#${item?.orderNo}`}
                        </TableCell>
                        <TableCell align="left">
                          {item?.dateCompleted?moment(item?.dateCompleted).format("llll"):"--"}
                        </TableCell>
                        {!props?.admin && (
                          <TableCell align="left">
                            {item?.catererDetails?.businessName}
                            {", "}
                            {item?.locationName}
                          </TableCell>
                        )}
                        <TableCell
                          align="left"
                          style={userType==="Admin"?{cursor:"pointer"}:{ color: !props?.admin && "#F96C5B" }}
                          onClick={()=> userType==="Admin"&& navigate(`/customers/${item?.customerId}`)} 
                        >
                          {item?.customerDetails?.firstName?`${item?.customerDetails?.firstName} ${item?.customerDetails?.lastName}`:"---"}
                        </TableCell>
                        <TableCell align="center">
                          {formatter.format(item?.netAmount)}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            className="status-btn"
                            style={{
                              color: "#fff",
                              backgroundColor: item?.isPaidToCaterer===true?"#00C643":"#E54566",
                            }}
                            variant="contained"
                          >
                         {item?.isPaidToCaterer===true? "Paid" : "Pending"}
                          </Button>
                        </TableCell>
                      </TableRow>
                      // </Link>
                    ))
                  ) : search ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>No data Found</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p>No list added Yet!</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select onChange={handlePageInputChange} value={rowsPerPage}>
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listOrders?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
}
