import { gql } from "@apollo/client";

export const LOGIN_ADMIN = gql`
  mutation login($email: String!, $password: String!, $rememberMe: Boolean, $userType: String,$uuid: String) {
    login(email: $email, password: $password,rememberMe: $rememberMe, userType: $userType, uuid: $uuid) {
      token
      fullName
      firstName
      lastName
      catererId
      email
      imageUrl
      regFlag
      phoneNumber
      userSrc
      _id
      userType
      taxCert
      taxExempt
      disableSignUpPromo
    }
  }
`;

export const FORGET_PASS = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordToken: String!, $newPassword: String!) {
    resetPassword(resetPasswordToken: $resetPasswordToken, newPassword: $newPassword) {
      status
      message
    }
  }
`;

export const CREATE_CATERER = gql`
  mutation CreateCaterer($data: createCatererInput!) {
    createCaterer(data: $data) {
      token
      fullName
      catererId
      newUserId
      email
      phoneNumber
      catererIdNew
      adminFlg
      _id
      userSrc
      userType
    }
  }
`;



export const CREATE_CUSTOMER = gql`
  mutation CreateUserIdentity($data: createUserIdentityInput!) {
    createUserIdentity(data: $data) {
      _id
      token
      regFlag
      fullName
      firstName
      lastName
      catererIdNew
      catererId
      newUserId
      email
      phoneNumber
      adminFlg
      imageUrl
      userSrc
      referralCode
      backupContact
      excludeCaterCashFlg
      userType
      taxExempt
    }
  }
`;

export const UPDATE_CATERE = gql`
  mutation UpdateCaterer($id: ID!, $data: updateCatererInput!,$catererId: ID!) {
    updateCaterer(_id: $id, data: $data,catererId: $catererId) {
      _id
      firstName
      lastName
      name
      email
      countryCode
      phoneNumber
      additionalPhone
      status
      role
      latitude
      longitude
      imageUrl
      dlvryShutDownFlag
      cutOfTimeMng
      cutOfTimeNxtOrder
      contactNumber
      businessPhone
      businessName
      tagLine
      locationName
      location {
        type
        coordinates
      }
      tabValue
      deliveryFee
      deliveryFeeType
      deliveryFeeTiers {
        price
        zipCodes
      }
      convenienceFee
      convenienceFeeType
      sameContact
      sameEmail
      customDeliveryFee
      customDeliveryFlag
      deliveryRadius
      driverTip
      driverTipFlag
      driverTipType
      preprationTime
      cancellationTime
      specialDeliveryInstn
      legalBusinessName
      emailForMonthlyStmt
      deliveryFeeCater
      mandatoryTipFlg
      deliveryEmail
      fax
      offersPackage
      cuisineType
      starRating
      imOrder
      desserts
      token
      registrationDate
      licence
      accountNo
      accountName
      bankName
      routingNo
      caterCash
      commission
      lastPaymentDate
      closure
      adminFlg
      slug
      minOrderAmt
      catererTax
      catererZipCode
      deliveryOption
      option
      iAgreeFlg
      timeZone
      addEmailFlag
      additionalEmail
      cancellationPolicy
      deliveryHrs {
        day
        flag
        openHours {
          startTime
          closeTime
        }
      }
      tablewareCharged
      tablewareAmount
      hasDrivers
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUserIdentity($id: ID!, $data: updateUserIdentityInput!) {
    updateUserIdentity(_id: $id, data: $data) {
      _id
      name
      email
      phoneNumber
      status
      firstName
      catererId
      role
      imageUrl
    }
  }
`;

export const CONTINUE_DRAFT = gql`
  mutation ContinueDraftOrder($orderId: String, $orderType: String,$browserName: String, $userAgent: String) {
    continueDraftOrder(orderId: $orderId, orderType: $orderType, browserName: $browserName, userAgent:$userAgent) {
      _id
      catererSlug
      catererDetails {
        slug
      }
    }
  }
`;


export const CREATE_MENU = gql`
mutation CreateMenu($data: createMenuInput!) {
  createMenu(data: $data) {
    _id
    itemName
    order
    categoryId
    dietory
    itemDescription
    markasPopularFlg
    additionalPkdPrice
    offerIndPkdFlg
    price
    minQuantity
    itemType
    itemPkgType
    itemImage
  }
}
`;

export const UPDATE_MENU = gql`
  mutation UpdateMenu($id: ID!, $data: updateMenuInput!) {
    updateMenu(_id: $id, data: $data) {
      _id
      itemName
      order
      categoryId
      catererId
      dietory
      itemPkgType
      itemType
      minQuantity
      price
      offerIndPkdFlg
      additionalPkdPrice
      markasPopularFlg
      itemDescription
      itemImage
    }
  }
`;

export const DELETE_MENU = gql`
  mutation DeleteMenu($menuId: String!) {
    deleteMenu(menuId: $menuId) {
      status
      message
    }
  }
`;

export const CREATE_USER_IDENTITY = gql`
  mutation CreateUserIdentity($data: createUserIdentityInput!) {
    createUserIdentity(data: $data) {
      _id
      name
      email
      phoneNumber
      status
      catererId
      role
      imageUrl
      adminFlg
    }
  }
`;

export const DELETE_USER_IDENTITY = gql`
mutation deleteUserIdentity($id: ID!) {
    deleteUserIdentity(_id: $id) {
      status
      message
    }
  }
`;

export const CREATE_CATERER_USER = gql`
  mutation CreateCatererUser($data: createCatererUserInput!) {
    createCatererUser(data: $data) {
      catererId
      userId
      role
      status
      id
    }
  }
`;

export const CREATE_ADDON_TYPE = gql`
  mutation CreateAddOnType($data: createAddOnTypeInput!) {
    createAddOnType(data: $data) {
      _id
      addOnTypeName
      menuId
      catererId
      addOnTypeLimit
      type
    }
  }
`;

export const UPDATE_ADDON_TYPE = gql`
  mutation UpdateAddOnType($data: updateAddOnTypeInput!, $id: ID!) {
    updateAddOnType(data: $data, _id: $id) {
      _id
      addOnTypeName
      sortOrder
      menuId
      catererId
      type
      addOnTypeLimit
    }
  }
`;

export const DELETE_ADDON_TYPE = gql`
  mutation DeleteAddOnType($addOnTypeId: String!) {
    deleteAddOnType(addOnTypeId: $addOnTypeId) {
      status
      message
    }
  }
`;

export const CREATE_UPDATE_ADDON = gql`
  mutation CreateUpdateAddOn($addOnTypeId: String, $data: [createAddOnInput]) {
    createUpdateAddOn(addOnTypeId: $addOnTypeId, data: $data) {
      status
      message
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($data: createCategoryInput!) {
    createCategory(data: $data) {
      _id
      categoryTitle
      status
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $data: updateCategoryInput!) {
    updateCategory(_id: $id, data: $data) {
      _id
      categoryTitle
      status
      order
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($categoryId: String!) {
    deleteCategory(categoryId: $categoryId) {
      status
      message
    }
  }
`;

export const CREATE_CUISINES = gql`
  mutation CreateCuisineType($data: createCuisineTypeInput!) {
    createCuisineType(data: $data) {
      _id
      cuisineName
      order
    }
  }
`;

export const UPDATE_CUISINES = gql`
  mutation UpdateCuisineType($id: ID!, $data: updateCuisineTypeInput!) {
    updateCuisineType(_id: $id, data: $data) {
      _id
      cuisineName
      order
    }
  }
`;

export const DELETE_CUISINES = gql`
  mutation DeleteCuisineType($cuisineTypeId: String!) {
    deleteCuisineType(cuisineTypeId: $cuisineTypeId) {
      status
      message
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($customerId: String!) {
    deleteCustomer(customerId: $customerId) {
      status
      message
    }
  }
`;



export const CREATE_ORDER = gql`
  mutation UpdateOrder($data: createOrderInput) {
    updateOrder(data: $data) {
      _id
      addressId
      cardIcon
      cardId
      cardNo
      caterCashAfterRefund
      caterCashTotal
      catererId
      city
      commision
      contactNo
      contactPerson
      couponApplied
      createdBy
      createdOn
      cust_del
      customerId
      dateCancelled
      dateCompleted
      dateScheduled
      deliveryDate
      deliveryFee
      deliveryMode
      deliveryTime
      discountAmt
      driverTip
      driverTipType
      grandTotal
      headCount
      instruction
      isEditedOrder
      isCaterCashDeclained
      isPaid
      isPaidToCaterer
      isReOrder
      isReferred
      isRefunded
      lat
      lng
      location
      locationName
      orderDate
      orderNo
      orderStatus
      orderTax
      payProfId
      placeId
      pricePerPerson
      r_transaction_id
      reauthTransId
      refererReward
      refundAmount
      refundDate
      refundText
      refundTransactionId
      refundType
      settlementDate
      shipToId
      state
      street
      suit
      tax
      salesTaxAmount
      salesTaxPercentage
      tipPercentage
      totalAfterRefund
      totalPerHead
      totalPrice
      tablewareDeclined
      tablewareTotal
      transactionId
      updatedBy
      updatedOn
      zip
    }
  }
`;

export const CREATE_CARD = gql`
  mutation CreateCard($data: createCardInput!) {
    createCard(data: $data) {
      authorizeId
      cardExpr
      cardImg
      cardLastDigits
      cardName
      locationName
      customerId
      defaultCard
      additionalEmail
      addEmailChk
      nickName
    }
  }
`;

export const DELETE_CARD = gql`
  mutation DeleteCard($cardId: String!) {
    deleteCard(cardId: $cardId) {
      message
      status
    }
  }
`;

export const UPDATE_CARD = gql`
  mutation UpdateCard($id: ID!, $data: updateCardInput!) {
    updateCard(_id: $id, data: $data) {
      _id
      authorizeId
      cardExpr
      cardImg
      cardLastDigits
      cardName
      addEmailChk
      additionalEmail
      customerId
      defaultCard
      nickName
    }
  }
`;

export const UPDATE_DEFAULT_CARD = gql`
  mutation UpdateDefaultCard($cardId: String!, $customerId: String!) {
    updateDefaultCard(cardId: $cardId, customerId: $customerId) {
      message
      status
    }
  }
`;

export const DELETE_CART = gql`
  mutation DeleteCart($cartId: String!) {
    deleteCart(cartId: $cartId) {
      status
      message
    }
  }
`;

export const DELETE_ALL_CART = gql`
  mutation DeleteOrder($orderId: String) {
    deleteOrder(orderId: $orderId) {
      _id
    }
  }
`;

export const CREATE_PROMOCODE = gql`
  mutation UpdatePromoCode($data: promoCodeInput) {
    updatePromoCode(data: $data) {
      _id
      catererId
      codeStatus
      coupenCode
      customerLimit
      discountAmt
      expDate
    }
  }
`;

export const ADD_NOTES = gql`
  mutation AddOrderNotes($orderId: String, $note: String) {
    addOrderNotes(orderId: $orderId, note: $note) {
      orderNotes {
        _id
        createdBy
        createdOn
        note
      }
    }
  }
`;

export const UPDATE_ORDER_STATUS = gql`
  mutation UpdateOrderStatus($data: orderStatusInput ) {
    updateOrderStatus(data: $data ) {
        _id
    }
  }
`;

export const UPDATE_ORDER_HAS_DRIVER = gql`
  mutation UpdateHasDriver($hasDriver: Boolean, $orderId: String) {
    updateHasDriver(orderId: $orderId, hasDriver: $hasDriver) {
      _id
      hasDriver
    }
  }
`;

export const DELETE_PROMOCODE = gql`
  mutation DeletePromoCode($promoCodeId: String!) {
    deletePromoCode(promoCodeId: $promoCodeId) {
      status
      message
    }
  }
`;

export const DELETE_CATERER = gql`
  mutation DeleteCaterer($catererId: String!) {
    deleteCaterer(catererId: $catererId) {
      status
      message
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($addressId: String!) {
    deleteAddress(addressId: $addressId) {
      status
      message
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation CreateAddress($data: createAddressInput!) {
    createAddress(data: $data) {
      Phone
      _id
      locationName
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($id: ID!, $data: updateAddressInput!) {
    updateAddress(_id: $id, data: $data) {
      Phone
      _id
      locationName
    }
  }
`;

export const ORDER_REFUND = gql`
  mutation OrderRefund($orderId: String!, $refundText: String!, $refundAmount: Float!, $newTotal: Float!, $caterCash: Float!, $refundType: String, $refundPercentage: Float) {
    orderRefund(orderId: $orderId, refundText: $refundText, refundAmount: $refundAmount, newTotal: $newTotal, caterCash: $caterCash, refundType: $refundType, refundPercentage: $refundPercentage) {
      message
      status
    }
  }
`;

export const REORDER_EXIST = gql`
  mutation ReOrderExist($orderId: String, $browserName: String, $userAgent: String) {
    reOrderExist(orderId: $orderId, browserName: $browserName, userAgent:$userAgent) {
      _id
      removedCatgryName
      removedMenuName
      deliveryDate
    }
  }
`;

export const GIFT_CATERCASH = gql`
  mutation CreateCaterCash($data: createCaterCashInput) {
    createCaterCash(data: $data) {
      _id
      caterCashType
      amount
      caterCashSrc
      caterCashStatus
      catererName
      customerId
      customerName
      paidOn
      voucherCode
      orderTotalAmt
    }
  }
`;

export const UPDATE_SETTLE_AMOUNT = gql`
  mutation UpdateSettleAmount($data: orderSettleAmountInput) {
    updateSettleAmount(data: $data) {
      _id
      isPaidToCaterer
    }
  }
`;

export const ADD_REVIEW = gql`
  mutation AddReview($data: reviewInput!) {
    addReview(data: $data) {
      _id
    }
  }
`;

export const APPROVE_REVIEW = gql`
  mutation ApproveReview($reviewId: ID!, $approvalStatus: Boolean!, $customerId: ID!, $catererId: ID!) {
    approveReview(reviewId: $reviewId, approvalStatus: $approvalStatus, customerId: $customerId, catererId: $catererId) {
      message
      status
    }
  }
`;

export const INVITE_FRIENDS = gql`
  mutation InviteFriends($customerId: ID!, $referedEmail: [String]!) {
    inviteFriends(customerId: $customerId, referedEmail: $referedEmail) {
      message
      status
    }
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings($id: ID!, $data: updateSettingsInput!) {
    updateSettings(_id: $id, data: $data) { 
      _id
    }
  }
`;

export const CREATE_REDEEM = gql`
  mutation GiftCard($data: createCaterCashInput) {
    giftCard(data: $data) {
      _id
      amount
      voucherCode
    }
  }
`;

export const UPDATE_INVITATION = gql`
  mutation UpdateInvitation($actionFrom: String, $actionTo: String, $referId: String) {
    updateInvitation(actionFrom: $actionFrom, actionTo: $actionTo, referId: $referId) {
      message
      status
    }
  }
`;

export const REMOVE_PROMOCODE = gql`
  mutation RemovePromoCode($promoCode: String!, $orderId: ID) {
    removePromoCode(promoCode: $promoCode, orderId: $orderId) {
      discountAmt
      grandTotal
      totalPerHead
    }
  }
`;

export const CONTACT_US = gql`
  mutation createContactUs($data: createContactUsInput!) {
    createContactUs(data: $data) {
    name
    }
  }
`;

export const SORT_CATEGORY = gql`
  mutation UpdateMultiCategoryOrder($catererId: ID!, $data: [updateCategoryOrderInput]) {
    updateMultiCategoryOrder(catererId: $catererId, data: $data) {
      message
      status
    }
  }
`;

export const DELETE_CUSTOMER_DRAFT_ORDER = gql`
  mutation DeleteCustomerDraftOrder($orderId: String!, $customerId: String!) {
    deleteCustomerDraftOrder(orderId: $orderId, customerId: $customerId) {
      message
      status
    }
  }
`;

export const UPDATE_ORDER_DATE = gql`
  mutation UpdateOrderDate($data: orderDelDateInput) {
    updateOrderDate(data: $data) {
      _id
    }
  }
`;

export const UPLOAD_TAX_CERTIFICATE = gql`
  mutation uploadTaxCert($file: String!, $customerId: String!, $filename: String!, $mimetype: String!) {
    uploadTaxCert(file: $file, customerId: $customerId, filename: $filename, mimetype: $mimetype) {
      url,
      customerId
    }
  }
`;

export const SEND_EMAIL_TO_SUPPORT = gql`
  mutation sendEmailToSupport($data: EmailToSupportInput!) {
    sendEmailToSupport(data: $data) {
        message,
        status
    }
  }  
`;

export const IMPORT_MENU = gql`
  mutation importMenu($fromCatererId: String!, $toCatererId: String!) {
    importMenu(fromCatererId: $fromCatererId, toCatererId: $toCatererId) {
        message,
        status
    }
  }
`;