import React, { useState, useEffect } from "react";
import {
    Button,
    Tooltip,
    IconButton,
    InputAdornment,
    TextField,
    Drawer,
    CircularProgress,
} from "@mui/material";
import { useReactiveVar } from "@apollo/client";
import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import { useForm, Controller } from "react-hook-form";

import { UPDATE_CATERE } from "../../../Graphql/mutations";
import { toggleSnackbar, authVar, userDeatils } from "../../../ReactiveVariables/index";
import Tags from '../../Common/tags';

const MAX_TIERS = 5;

const formatter = new Intl.NumberFormat('en-US', {
    style: "currency",
    currency: 'USD',
});

export default function FeeDetails(props) {
    const navigate = useNavigate();
    const user = useReactiveVar(userDeatils);
    const userType =
        user?.data?.login?.userType ||
        user?.data?.currentUserDetails?.userType ||
        user?.data?.createUserIdentity?.userType ||
        user?.data?.createCaterer?.userType;
    const currentData = props?.data?.getOneCaterer;
    const deliveryFee = currentData?.deliveryFee;

    const [deliveryFeeTiers, setDeliveryFeeTiers] = useState(
        currentData?.deliveryFeeTiers?.map(tier => ({
            ...tier,
            price: parseFloat(tier.price) || 0
        })) || []
    );

    const schema = yup.object().shape({
        deliveryFeeTiers: yup
          .array()
          .of(
            yup.object().shape({
              price: yup.number().required('Price is required'),
              zipCodes: yup
                .array()
                .of(yup.string())
                .min(1, 'At least one Zip Code is required')
                .required('At least one Zip Code is required'),
            })
          ),
      });

    const { control, handleSubmit, setValue, formState: { errors }, register } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            deliveryFeeTiers: currentData?.deliveryFeeTiers || [],
        },
    });

    const [updateCaterer, { loading }] = useMutation(UPDATE_CATERE, { refetchQueries:['GetOneCaterer'],
        onCompleted: (res) => {
            props.setOpenFees(false);
        },
    });

    useEffect(() => {
        setValue('deliveryFeeTiers', deliveryFeeTiers);
    }, [deliveryFeeTiers, setValue]);

    const addTier = () => {
        const newTier = {
            price: 0,
            zipCodes: []
        };

        const newTiers = [...deliveryFeeTiers, newTier];

        setDeliveryFeeTiers(newTiers);
    };

    const removeTier = (index) => {
        const newTiers = [...deliveryFeeTiers];

        newTiers.splice(index, 1);

        setDeliveryFeeTiers(newTiers);
    };

    const handlePriceChange = (index, price) => {
        const numericPrice = parseFloat(price);
        const newTiers = [...deliveryFeeTiers];
        newTiers[index].price = isNaN(numericPrice) ? 0 : numericPrice;
        setDeliveryFeeTiers(newTiers);
    };

    const handleZipCodeChange = (index, zipCode) => {
        const newTiers = [...deliveryFeeTiers];
        newTiers[index] = { ...newTiers[index], zipCodes: zipCode };
        setDeliveryFeeTiers(newTiers);
    };

    const onSubmit = (data) => {
        try {
            updateCaterer({
              variables: {
                id: props?.userData?._id,
                catererId: props?.data?.getOneCaterer?.catererUsers[0]?.catererId || props?.userData?.catererId,
                data
              },
            }).then((res) => {
              toggleSnackbar({
                status: true,
                message: "Other details updated successfully",
                variant: "success",
              });
            })
            .catch((err) => {
              const message = err && err.message;
      
              if (message === "Not authenticated"){
                localStorage.clear();
      
                authVar({
                  loaded: true,
                  auth: false,
                });
      
                userType==="Admin"
                    ? navigate("/admin", { state: "noAuth" })
                    : navigate("/login", { state: "noAuth" })
              } else {
                toggleSnackbar({
                  status: true,
                  message: message,
                  variant: "error",
                });
              }
            });
          } catch (err) {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
    }

    return (
        <Drawer className="common-drawer"
            anchor={"right"}
            open={props.openHours}
            onClose={() => props.setOpenFees(false)}
        >
            <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
                <div className='drawer-head'>
                    <h4 className="h4"><img src="../assets/images/Money.svg" className="img-fluid" alt="Delivery Hours Icon" />Delivery Fees</h4><span style={{ cursor: 'pointer' }} onClick={() => props.setOpenFees(false)}><img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" /></span>
                </div>
                <div>
                    <div className='container-fluid'>
                        <div className="col-12 d-flex">
                            <Tooltip title={deliveryFeeTiers.length >= MAX_TIERS && "You can not have more than three tiers."}>
                                <span>
                                    <Button className="mt-4" onClick={() => addTier()} disabled={deliveryFeeTiers.length >= MAX_TIERS}><AddIcon /> Add Tier</Button>
                                </span>
                            </Tooltip>
                        </div>
                        <ul className="list-group list-group-flush pt-2">
                            {deliveryFeeTiers.map((tier, i) =>
                                <li className="list-group-item" key={`tier-${i}`}>
                                    <div className="d-flex w-100 justify-content-between">
                                        <h6 className="my-3 h6">Tier {i + 1}</h6>
                                        <small>
                                            <IconButton aria-label="delete" onClick={() => removeTier(i)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </small>
                                    </div>
                                    <div className="mb-3">
                                        <Controller
                                            control={control}
                                            name={`deliveryFeeTiers[${i}].price`}
                                            render={({ field: { onChange, value } }) => (
                                                <>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Price"
                                                        value={value}
                                                        variant="outlined"
                                                        className="textField allFeild"
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            handlePriceChange(i, e.target.value);
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">$</InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <div className="invalid-feedback">
                                                        {errors.deliveryFeeTiers?.[i]?.price && (
                                                            <p>{errors.deliveryFeeTiers[i].price.message}</p>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-1">
                                        <Controller
                                            control={control}
                                            name={`deliveryFeeTiers[${i}].zipCodes`}
                                            render={({ field: { onChange, value } }) => (
                                                <Tags
                                                    tags={value}
                                                    label={'Add Zipcode'}
                                                    setTags={(newTags) => onChange(newTags)}
                                                    handleChange={(newZipCodes) => handleZipCodeChange(i, newZipCodes)}
                                                />
                                            )}
                                        />
                                        <div className="text-danger small">
                                            {errors.deliveryFeeTiers?.[i]?.zipCodes && (
                                                <p>{errors.deliveryFeeTiers[i].zipCodes.message}</p>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            )}

                            <li className="list-group-item">
                                <div className="d-flex w-100 justify-content-between">
                                    <h6 className="my-3 h6">Default Delivery Fee</h6>
                                </div>
                                <p className="mb-1">Price: {formatter.format(deliveryFee)}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="createStepBtn">
                    <Button
                        onClick={() => props.setOpenFees(false)}
                        className="cancelBtn"
                        variant="contained"
                        color="secondary">
                        Cancel
                    </Button>

                    <Button
                        className="saveBtn"
                        variant="contained"
                        type="submit"
                        color="primary">
                        {loading ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : (
                                "Update"
                        )}{" "}
                    </Button>
                </div>
            </form>
        </Drawer>
    );
}
