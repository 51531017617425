import React from "react";

interface IEmptyCatererListMessage {
  isDateSelected: boolean;
  isAddressSelected: boolean;
  isFilterSelected: boolean;
}

export function EmptyCatererListMessage({
  isDateSelected,
  isAddressSelected,
  isFilterSelected,
}: IEmptyCatererListMessage) {
  return (
    <div className="cater-list">
      <div className="container">
        <div className="row">
          <div className="col-md-6 m-auto text-center">
            <img
              src="../assets/images/no-order.svg"
              className="img-fluid mt-4"
              alt="No Caterers available"
            />
            <h5 className="h5 mt-3">
              {!isAddressSelected
                ? "Please select delivery address to search for caterers"
                : isFilterSelected
                ? "Unfortunately, it doesn’t look like there are any available caterers with selected filter options."
                : isDateSelected
                ? "Unfortunately, it doesn’t look like there are any available caterers to deliver to that location at the time you selected."
                : "Unfortunately, it doesn’t look like there are any available caterers to deliver to that location."}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
