import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Button, CircularProgress, TextField, InputLabel, InputAdornment } from "@mui/material";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { useParams } from "react-router";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useMutation } from "@apollo/client";
import { GIFT_CATERCASH } from "../../Graphql/mutations";
import {
  toggleSnackbar,authVar
} from "../../ReactiveVariables/index";
import { useNavigate } from "react-router-dom";
export default function GiftCaterCash(props) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState(null)
  const [giftAmount, setGiftAmount] = useState(null)
  const [editorValue, setEditorValue] = useState(EditorState.createEmpty())
  const [textEditorValue, setTextEditorValue] = useState(
    EditorState.createEmpty()
  );

  const [createGift, { loading }] = useMutation(GIFT_CATERCASH, {
    refetchQueries: ['ListCaterCash'],
    onCompleted: res => {
      props.setOpenGiftCash(false)
      setTextEditorValue(EditorState.createEmpty())
    }
  });

  function handleTextEditor(value) {
    setTextEditorValue(value);
    const newValue = draftToHtml(convertToRaw(value.getCurrentContent()));
    setEditorValue(newValue);
  }

  function createCaterCash() {
    if (giftAmount === null) {
      setErrorMessage('Please add an amount')
      return
    }
    let date = new Date()
    let data = {
      amount: parseFloat(giftAmount),
      customerId: id,
      caterCashSrc: "Gifted",
      caterCashStatus: true,
      caterCashType: "Earned",
      paidOn: date,
      message: editorValue,
      customerName: props?.username,
      customerEmail:props?.email,
      customerFName:props?.userFirstName
    };

    try {
      createGift({ variables: { data: data } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Gift send successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <Drawer
      className="common-drawer"
      anchor={"right"}
      open={props.openGiftCash}
      onClose={() => { props.setOpenGiftCash(false); setTextEditorValue(EditorState.createEmpty()) }}
    >
      <div className="drawer-head">
        <h4 className="h4"><img src="../assets/images/icon-bank.svg" className="img-fluid" alt="Bank Details Icon" />
          Give CaterCash
        </h4>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => { props.setOpenGiftCash(false); setTextEditorValue(EditorState.createEmpty()) }}
        >
          <img
            src="../assets/images/x-icon.svg"
            className="img-fluid"
            alt="close icon"
          />
        </span>
      </div>
      <div className='create-step-form'>
            <div className="textBox">
              <TextField
                id="outlined-basic"
                autoFocus
                label="CATERCASH*"
                variant="outlined"
                className="textField allFeild"
                onChange={(e) => {
                  setErrorMessage(null)
                  let value = e.target.value;
                  if (!/^\d+(\.\d{0,2})?$/.test(value)) {
                    e.target.value = value.slice(0, -1);
                  }
                  let inputValue = e.target.value;
                  setGiftAmount(inputValue)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              {errorMessage && <span className="error-msg">{errorMessage}</span>}
            </div>
            <div className="catercash-editor">
              <InputLabel>
                EMAIL CONTENT
              </InputLabel>
              <Editor
                editorState={textEditorValue}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(value) => handleTextEditor(value)}
                toolbar={{
                  options: ['inline', 'link', 'list', 'history'],
                  inline: {
                      options: ['bold', 'italic', 'underline'],
                  },
                  list: { inDropdown: true },
                  link: { inDropdown: false, defaultTargetOption: '_blank',showOpenOptionOnHover:false},
              }}
              />
            </div>
      </div>
      <div className="createStepBtn">
        <Button
          className="cancelBtn"
          variant="contained"
          color="secondary"
          onClick={() => { props.setOpenGiftCash(false); setTextEditorValue(EditorState.createEmpty()) }}
        >
          Cancel
        </Button>
        <Button
          className="saveBtn"
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => createCaterCash()}
        >
          {loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </Drawer>
  )
}