import React, { useEffect, useState } from 'react';
import Drawer from "@mui/material/Drawer";
import { useForm } from "react-hook-form";
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { LIST_MENU } from '../../../Graphql/queries';
import { IMPORT_MENU } from '../../../Graphql/mutations';
import { toggleSnackbar } from "../../../ReactiveVariables/index";

import {
  Button,
  TextField
} from "@mui/material";
import { userDeatils } from "../../../ReactiveVariables/index";
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from "@apollo/client";

export default function ImportMenu(props) {
  const navigate = useNavigate()
  const user = useReactiveVar(userDeatils);

  const [ menuToImportCatererId, setMenuToImportCatererId ] = useState(null);
  const [ enabled, setEnabled ] = useState(false);
  const [ menu, setMenu ] = useState(null);
  const [ fromCaterer, setFromCaterer ] = useState(null);

  const { data, refetch } = useQuery(LIST_MENU, {
    variables: { catererId: menuToImportCatererId },
    enabled,
    skip: !menuToImportCatererId
  });

  const [importMenu, { loading }] = useMutation(IMPORT_MENU, {
    onCompleted: () => {
      location.reload();
    },
    onError: (err) => {
      toggleSnackbar({
        status: true,
        message: err.message || "Error occurred while importing menu",
        variant: "error",
      });
    }
  });

  const catererId = props?.catererId;
  const catererData = props?.catererData;

  const fetchMenu = () => {
    setEnabled(true);
    refetch();
  };

  const importMenuFromCaterer = async (fromCatererId, toCatererId) => {
    try {
      await importMenu({ variables: { fromCatererId, toCatererId } });
    } catch (e) {
      toggleSnackbar({
        status: true,
        message: e.message || "Error occurred while importing menu",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (enabled && data?.listMenu) {
      setMenu(data?.listMenu);
      setFromCaterer(data?.listMenu?.catererData);
    }
  }, [enabled, data]);

  return (
    <Drawer
      className="common-drawer"
      anchor={"right"}
      open={props.openImportMenu}
      onClose={() => { props.setOpenImportMenu(false); }}
    >
      <div className="drawer-head">
        <h4 className="h4">
          Import Menu
        </h4>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => { props.setOpenImportMenu(false); }}
        >
          <img
            src="../assets/images/x-icon.svg"
            className="img-fluid"
            alt="close icon"
          />
        </span>
      </div>

      <form
        onSubmit={() => console.log('sup')}
        noValidate
      >
        <div className="container py-2">
          <div className="row">
            { !menu &&
              <div className="col-12">
                <input
                    placeholder="Input catererId"
                    className="form-control align-right"
                    onChange={(e) => {
                      setMenuToImportCatererId(e.target.value)
                    }}
                  />
                <Button
                  variant="contained"
                  color="primary"
                  className="w-100 mt-2"
                  onClick={fetchMenu}>Fetch Menu</Button>
              </div>
            }

            { menu &&
              <div className="col-12">
                <div>Menu found with item count: <strong>{menu?.count}</strong></div>
                <div>Do you want to import menu from <u>{fromCaterer?.businessName || fromCaterer?._id}</u> to <u>{catererData?.businessName || catererId}</u>?</div>
                <Button
                  variant="contained"
                  color="primary"
                  className="w-100 mt-2"
                  disabled={loading}
                  onClick={() => importMenuFromCaterer(fromCaterer?._id, catererId)}>Yes</Button>
              </div>
            }
          </div>
        </div>
        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => { props.setOpenImportMenu(false); setMenu(null); setMenuToImportCatererId(null); }}
          >
            Cancel
          </Button>
        </div>
      </form>

    </Drawer >
  );
}
