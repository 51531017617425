// dialog box for review caterer
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ReactStars from "react-rating-stars-component";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { ADD_REVIEW } from "../../../Graphql/mutations";
import React, { useState } from "react";
import { useReactiveVar } from "@apollo/client";
import {
  toggleSnackbar,
  userDeatils,
  authVar,
} from "../../../ReactiveVariables/index";
export default function CatererReview(props) {
  const [starRate, setStarRate] = useState(null);
  const [comment, setComment] = useState("");
  const user = useReactiveVar(userDeatils);
  const auth = useReactiveVar(authVar);
  const customerId =
    user?.data?.currentUserDetails?._id ||
    user?.data?.createUserIdentity?._id ||
    user?.data?.login?._id;
  const [addReview] = useMutation(ADD_REVIEW, {
    onCompleted: (res) => {
      props?.setOpenReview(false);
      setComment("");
      setStarRate(null);
    },
  });
  const ratingChanged = (newRating) => {
    setStarRate(newRating);
  };
  function handleAddReview() {
    let date = new Date();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!auth?.auth) {
      toggleSnackbar({
        status: true,
        message: "Please Sign Up before rating",
        variant: "error",
      });
      return;
    }
    if (!starRate) {
      toggleSnackbar({
        status: true,
        message: "Add your rating",
        variant: "error",
      });
      return;
    }
    let data = {
      comment: comment,
      star: starRate,
      catererId: props?.catererId,
      customerId: customerId,
      timeZone: timeZone,
      reviewDate: date,
    };
    try {
      addReview({ variables: { data: data } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Your review will be published after approval by the administrator",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <div>
      <Dialog
        open={props?.openReview}
        onClose={() => props?.setOpenReview(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="common-dialog-box review-box"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="dialog-box-title text-center">
            <h5 className="h5 w-100">Add a Review</h5>
            {/* <IconButton onClick={() => props?.setOpenDialog(false)}>
              <img src='../assets/images/modal-close.svg' className='img-fluid' alt='Close' />
            </IconButton> */}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="review-star">
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={24}
              activeColor="#ffd700"
              classNames="review-star-item"
            />
          </div>
          <DialogContentText className="review-textbox" id="alert-dialog-description">
            <TextField
              id="review-comment"
              label="Share details of your own experience"
              multiline
              rows={4}
              className="w-100"
              onChange={(e) => setComment(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={"dailog-cancel-btn m-auto"}
            variant="outlined"
            color="secondary"
            onClick={() => {
              props?.setOpenReview(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className={"m-auto"}
            variant="contained"
            color="primary"
            onClick={() => {
              handleAddReview();
            }}
            autoFocus
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
