// index for the caterer registration page.
import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import CreateAccount from "./CreateAccount";
import Business from "./Business";
import {useLazyQuery } from "@apollo/client/react/hooks";
import { useLocation } from "react-router-dom";
import Cuisines from "./Cuisines";
import {GET_ONE_USER} from "../../Graphql/queries"
import LoginHeader from "../Login/LoginHeader";
import Hours from "./Hours";
import BankingInfo from './BankingInfo';
import { userDeatils ,catererDeatils} from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { CircularProgress } from "@mui/material";
import Survey from "./Survey";

const steps = [
  "Create Account",
  "Business",
  "Cuisines",
  "Hours",
  "Banking Information",
  "Survey"
];

const customerSteps = [
  "Create Account",
  "Survey"
]
export default function Register(props) {
  const user = useReactiveVar(userDeatils);
  const userDetails = useReactiveVar(catererDeatils);
  const [activeStep, setActiveStep] = React.useState(0);
  const [userValues,setUserValues] = React.useState()
  const location = useLocation();
  const userType = location.state || props?.userType;
  const id = user?.data?.currentUserDetails?._id ||user?.data?.login?._id||user?.data?.createCaterer?._id 
  const [skipped, setSkipped] = React.useState(new Set());
  const catererId = user?.data?.currentUserDetails?.catererId ||user?.data?.login?.catererId||user?.data?.createCaterer?.catererId
  const [getOneCaterer, {data,loading}] = useLazyQuery(GET_ONE_USER, {fetchPolicy: "network-only",
  onCompleted:(res)=>{
    setActiveStep(parseInt(res?.getOneCaterer?.tabValue)+1)
  }})
  useEffect(()=>{
    if(catererId){
      getOneCaterer({variables: {getOneCatererId: catererId}})
    }
  },[catererId])

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  return (
    <div className="wrap wrap-height">
      <LoginHeader />
      <div className="container-fluid p-0 wrap-height">
        <div className="row m-0 wrap-height">
          <div className="col-xl-5 p-0 wrap-height create-form-left">
            <div className="login-img">
              <img
                src="../assets/images/Login-img1.png"
                className="img-fluid"
                alt="Login"
              />
              <h3 className="h3">
                You are only a few more clicks away from earning CaterCash!
              </h3>
            </div>
          </div>
          <div className="col-xl-7 p-0 wrap-height create-form-right">
            <Box className="create-step-wrap overflow" sx={{ width: "100%" }}>
              {
                loading ? 
                <div className="d-flex justify-content-center align-items-center" style={{height:"100%"}}><CircularProgress /></div>
                
                :
                <>
                {userType?.user === "Caterer" &&
                <Stepper className="create-stepper" activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>}
                {userType?.user === "Customer" &&
                    <Stepper className="create-stepper" activeStep={activeStep}>
                      {customerSteps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                      })}
                    </Stepper>
                }
              {/* Create account component */}
              {activeStep === 0 && !loading &&(
                <CreateAccount
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  userType ={userType}
                  setUserValues={setUserValues}
                />
              )}
              {/* Business details component only for caterer */}
              {activeStep === 1 && userType?.user === 'Caterer' &&  !loading &&(
                <Business
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  userDetails={userDetails}
                  currentDetails= {data}
                  setUserValues={setUserValues}
                  userValues={userValues}
                  id={id}
                  catererId={catererId}
                />
              )}
              {/* Cuisines component */}
              {activeStep === 2 && (
                <Cuisines
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  userDetails={userDetails}
                  currentDetails= {data}
                  setUserValues={setUserValues}
                  userValues={userValues}
                  id={id}
                  catererId={catererId}
                />
              )}
              {/* Hours component */}
              {activeStep === 3 && <Hours 
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              userDetails={userDetails}
              currentDetails= {data}
              setUserValues={setUserValues}
              userValues={userValues}
              id={id}
              catererId={catererId}
              />
              }
              {/* banking info component */}
              {activeStep === 4 && <BankingInfo 
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              userDetails={userDetails}
              currentDetails= {data}
              setUserValues={setUserValues}
              userValues={userValues}
              id={id}
              catererId={catererId}
              />
              }
              {((activeStep === 1 && userType?.user === 'Customer') || activeStep === 5) &&  <Survey
               setActiveStep={setActiveStep}
               activeStep={activeStep}
               userDetails={userDetails}
               currentDetails={data}
               setUserValues={setUserValues}
               userValues={userValues}
               userType={userType}
               id={id}
               catererId={catererId}
               user={user}
                />
                }
              </>
              }
              
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
