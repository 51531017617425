import React from "react";
import { MenuItem, Menu } from "@mui/material";

import { isWithinFourHours } from "lib/util";
import { IOrderResponseItem } from "@screens/orders/order.typings";
import { ORDER_STATUS } from "@constants/order.constants";
import { USER_TYPE } from "@constants/user.constants";

interface IOrderItemMenuProps {
  anchorEl: Element;
  selectedItem: IOrderResponseItem | null;
  userType: string;
  isLoading: boolean;
  onClose(): void;
  onViewOrder(): void;
  onEditOrder(): void;
  onCancelOrder(): void;
  onDeleteOrder(): void;
  onContinueOrder(): void;
  onReOrder(): void;
}

export function OrderItemMenu({
  anchorEl,
  selectedItem,
  userType,
  isLoading,
  onViewOrder,
  onEditOrder,
  onReOrder,
  onCancelOrder,
  onDeleteOrder,
  onContinueOrder,
  onClose,
}: IOrderItemMenuProps) {
  if (!selectedItem) {
    return;
  }

  const isCanEditOrder =
    userType !== USER_TYPE.caterer
      ? selectedItem.orderStatus === ORDER_STATUS.new ||
        selectedItem.orderStatus === ORDER_STATUS.scheduled ||
        selectedItem.orderStatus === ORDER_STATUS.pending
      : false;

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      className="menu-list-more">
      <MenuItem disabled={isLoading} onClick={onViewOrder}>
        View
      </MenuItem>

      {isCanEditOrder && (
        <MenuItem
          disabled={isLoading || isWithinFourHours(selectedItem.deliveryDate)}
          onClick={onEditOrder}>
          Edit
        </MenuItem>
      )}

      {selectedItem.orderStatus === ORDER_STATUS.completed && userType == USER_TYPE.customer ? (
        <MenuItem disabled={isLoading} onClick={onReOrder}>
          Reorder
        </MenuItem>
      ) : (
        (selectedItem.orderStatus === ORDER_STATUS.new ||
          selectedItem.orderStatus === ORDER_STATUS.scheduled ||
          selectedItem.orderStatus === ORDER_STATUS.approved) && (
          <MenuItem disabled={isLoading} onClick={onCancelOrder}>
            Cancel
          </MenuItem>
        )
      )}

      {selectedItem.orderStatus === ORDER_STATUS.draft && (
        <MenuItem disabled={isLoading} onClick={onContinueOrder}>
          Continue
        </MenuItem>
      )}

      {selectedItem.orderStatus !== ORDER_STATUS.completed && (
        <MenuItem disabled={isLoading} onClick={onDeleteOrder}>
          Delete
        </MenuItem>
      )}
    </Menu>
  );
}
