import React, { useState, useEffect } from "react";
import { Table, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useLazyQuery } from "@apollo/client/react/hooks";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useNavigate } from "react-router-dom";
import { userDeatils } from "../../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import TableRow from "@mui/material/TableRow";
import { GET_ONE_ORDER, PRINT_INVOICE } from "../../../Graphql/queries";
import { OrderInvoice } from "./order-invoice/order-invoice";
import { useAppDispatch } from "../../../services/redux";
import { clearEditOrder, clearOrder } from "../../../services/redux/order";
import { OrderItem } from "./order-item";
import { IOrderResponseItem } from "../order.typings";
import { useOrderListState } from "./order-list.state";
import { OrderListPagination } from "./order-list-pagination";
import { getUserId } from "@utils/user-format.utils";

interface IOrderListProps {
  tile?: string;
  addNewOrder?: boolean;
  completed?: boolean;
  catererId: string;
  customerId: string;
}

export function OrderList(props: IOrderListProps) {
  const userAgent = navigator.userAgent;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [openInvoice, setOpenInvoice] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IOrderResponseItem>(null);
  const [invoiceOrderId, setInvoiceOrderID] = useState("");
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const navigate = useNavigate();
  const user = useReactiveVar(userDeatils);

  const [printOrderInvoices, { data: printInvoice, loading }] = useLazyQuery(PRINT_INVOICE, {
    variables: { orderId: invoiceOrderId, timeZone: timeZone },
    onCompleted: (res) => {
      setInvoiceUrl(res.printOrderInvoice.pdfUrl);
    },
  });

  const dispatch = useAppDispatch();

  const [invoiceOrder, { data: orderValues, loading: dataLoading }] = useLazyQuery(GET_ONE_ORDER, {
    variables: {
      customerId:
        user?.data?.currentUserDetails?._id ||
        user?.data?.createUserIdentity?._id ||
        user?.data?.login?._id,
      orderId: invoiceOrderId,
    },
    onCompleted: (res) => {},
  });

  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;

  const {
    page,
    rowsPerPage,
    isLoading,
    searchValue,
    orders,
    ordersCount,
    setSearchValue,
    setPage,
    setRowsPerPage,
  } = useOrderListState({
    userType,
    catererId: props.catererId,
    customerId: props.customerId,
    isCompleted: props.completed,
  });

  const userId = getUserId(user);

  const status = {
    status: "completed",
    title: props?.tile,
  };

  useEffect(() => {
    dispatch(clearEditOrder());
  }, []);

  function onInvoiceClick(id: string) {
    invoiceOrder();
    printOrderInvoices();
    setInvoiceOrderID(id);
    setOpenInvoice(true);
  }

  const orderViewState = props?.completed ? status : props?.tile ?? null;

  return (
    <div>
      {openInvoice && (
        <OrderInvoice
          open={openInvoice}
          setOpen={setOpenInvoice}
          orderId={invoiceOrderId}
          order={orderValues?.getOneOrder}
          invoiceUrl={invoiceUrl}
          loading={loading}
          dataLoading={dataLoading}
        />
      )}

      <div className={userType === "Customer" || userType === "Caterer" ? "" : "contentWrap"}>
        <div className="contentWrapInner">
          <div className="location-head">
            <div className="location-head-title justify-content-between">
              {ordersCount >= 0 ? <h5>{`${ordersCount} Orders`}</h5> : <h5>Orders</h5>}
              {userType != "Customer" && (
                <input
                  placeholder="Search by Customer Name/OrderId"
                  className="form-control"
                  value={searchValue}
                  onChange={(e) => {
                    setPage(1);
                    setSearchValue(e.target.value);
                  }}
                />
              )}
              {userType === "Customer" && props?.addNewOrder && (
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/");
                  }}>
                  <img
                    src="../assets/images/icon-add-menu.svg"
                    className="img-fluid me-1"
                    alt="Icon"
                  />
                  Add new order
                </Button>
              )}
            </div>
          </div>
          <TableContainer>
            <Table
              className="commonTabel"
              //sx={{ minWidth: 650 }}
              aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Orders</TableCell>
                  {userType === "Admin" && <TableCell>Driver Set</TableCell>}
                  <TableCell className="width-175" align="left">
                    {userType === "Admin" && props?.tile
                      ? "Order Date & Time"
                      : "Event Date & Time"}
                  </TableCell>
                  <TableCell className="width-175" align="left">
                    {userType === "Customer"
                      ? "Caterer & Location"
                      : userType === "Admin" && props?.tile === "caterer"
                      ? "Delivery Address"
                      : userType === "Admin" && props?.tile === "customers"
                      ? "Store Name & Delivery Address"
                      : userType === "Admin"
                      ? "Store Name & Delivery Address"
                      : "Delivery Location"}
                  </TableCell>
                  <TableCell align="left">
                    {userType === "Customer" ? "Amount" : "Customer"}
                  </TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Invoice</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <p> Loading...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : orders.length > 0 ? (
                  orders.map((item) => (
                    <OrderItem
                      key={item?._id}
                      item={item}
                      tile={props.tile}
                      userType={userType}
                      userId={userId}
                      orderState={orderViewState}
                      onInvoiceClick={onInvoiceClick}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <p>{searchValue ? "No data Found" : "No list added Yet!"} </p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <OrderListPagination
          page={page}
          rowsPerPage={rowsPerPage}
          ordersCount={ordersCount}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </div>
  );
}
