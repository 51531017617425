import React, { useEffect, useState } from "react";

import { useGeolocation } from "@utils/hooks/use-location";

interface ICatererLocationInfo {
  name: string;
  address: string;
  tagLine: string;
  deliveryInstruction: string;
  catererLocation: string;
}

export function CatererLocationInfo({
  name,
  address,
  tagLine,
  deliveryInstruction,
  catererLocation,
}: ICatererLocationInfo) {
  const [distance, setDistance] = useState("");

  const { getDistance } = useGeolocation();

  useEffect(() => {
    if (!catererLocation || !address) {
      setDistance("")
      return;
    }

    fetchDistance();
  }, [address, catererLocation]);

  async function fetchDistance() {
    const value = await getDistance(catererLocation, address);
    setDistance(value);
  }

  return (
    <div className="detail-tagline cater-detail-box">
      <h4 className="h4">
        <img src="../assets/images/icon-business.svg" className="img-fluid" alt="Icon" />
        {name}
      </h4>
      <h4 className={`h6 ${distance ? "" : " d-none"}`}>
        Distance from {address}  ({distance} miles)
      </h4>
      <div className="detail-tagline-item">
        <h5>{tagLine}</h5>
        <p>{deliveryInstruction}</p>
      </div>
    </div>
  );
}
