import React from "react";
import { IDeliveryHours } from "typings/caterer.api";

interface ICatererScheduleProps {
  status: string;
  deliveryHours: IDeliveryHours[];
}

export function CatererSchedule({ status, deliveryHours }: ICatererScheduleProps) {
  return (
    <div className="cater-detail-box detail-ordering-hours">
      <div>
        <h4 className="h4">
          <img src="../assets/images/icon-delivery-hours.svg" className="img-fluid" alt="Icon" />{" "}
          Ordering Hours
          <span
            className={status === "Closed" ? "ld-delivery-status ld-close" : "ld-delivery-status"}>
            <label></label>
            {status}
          </span>
        </h4>
      </div>
      <ul className="cater-detail-timing">
        {deliveryHours.map((item, idx) => (
          <li key={`timing-detail-${idx}`}>
            <b>{item?.day?.substring(0, 3)}</b> :{" "}
            {item?.openHours.map((time, hrsIdx) => (
              <span key={`open-hours-${hrsIdx}`}>
                {!item?.flag ? (
                  <label className="working-schedule">Closed</label>
                ) : (
                  <label className="working-schedule me-2">
                    {time.startTime === "" ? "-" : `${time.startTime}`} -{" "}
                    {time.closeTime === "" ? "-" : `${time.closeTime}`}
                  </label>
                )}
              </span>
            ))}
          </li>
        ))}
        <li className="d-none d-md-block"></li>
      </ul>
    </div>
  );
}
