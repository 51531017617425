import React from "react";

import { Checkbox, FormControlLabel, FormGroup, styled } from "@mui/material";
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";

import {
  IAddonTypeItemResponse,
  IAddonTypeResponse,
  ISubAddonItemResponse,
} from "../../cart-item.typings";
import { formatter } from "Modules/Common/commonUtils";
import { CartItemSubAddonItem } from "./cart-item-subaddon-item";

interface ICartItemAddonItemProps {
  addon: IAddonTypeItemResponse;
  type: IAddonTypeResponse;
  onSelectAddon(isChecked: boolean, type: IAddonTypeResponse, addon: IAddonTypeItemResponse): void;
  isAddonCheckboxDisabled(type: IAddonTypeResponse, addon: IAddonTypeItemResponse): boolean;
  isAddonSelected(addon: IAddonTypeItemResponse): boolean;
  isSubAddonSelected(
    addon: IAddonTypeItemResponse,
    subAddon: ISubAddonItemResponse,
  ): boolean;
  onSelectSubAddon(
    isChecked: boolean,
    addon: IAddonTypeItemResponse,
    subAddon: ISubAddonItemResponse,
  ): void;
}

const StyledCheckbox = styled(Checkbox)`
  svg {
    font-size: 22px;
  }
`;

export function CartItemAddonItem({
   addon,
  type,
  isAddonCheckboxDisabled,
  onSelectAddon,
  isAddonSelected,
  isSubAddonSelected,
  onSelectSubAddon,
}: ICartItemAddonItemProps) {
  const onChange = (event) => {
    onSelectAddon(event.target.checked, type, addon);
  };

  return (
    <FormGroup
      className={
        isAddonSelected(addon) && addon.subAddOns.length ? "addon-active col-md-12" : "col-md-4"
      }>
      <FormControlLabel
        className="mainAddonCheck"
        control={
          <StyledCheckbox
            disabled={isAddonCheckboxDisabled(type, addon)}
            checked={isAddonSelected(addon)}
            onChange={onChange}
            color={"primary"}
            icon={<CheckCircleOutline style={{ color: "#9F9E9E" }} />}
            checkedIcon={<CheckCircle />}
          />
        }
        label={
          <p>
            {addon.addOnName}
            {addon.addOnPrice !== 0 && (
              <span className="extra-charges">{`(+${formatter.format(addon.addOnPrice)})`}</span>
            )}
          </p>
        }
      />

      <div
        className={isAddonSelected(addon) && addon.subAddOns.length ? "addon-active-row row" : "row"}>
        {isAddonSelected(addon)
          ? addon.subAddOns.map((subAddon, subAddonKey) => (
              <CartItemSubAddonItem
                key={subAddonKey}
                subAddon={subAddon}
                addon={addon}
                isSubAddonSelected={isSubAddonSelected}
                onSelectSubAddon={onSelectSubAddon}
              />
            ))
          : null}
      </div>
    </FormGroup>
  );
}
