// this componenrt is the header after loggin in
import React, { useState, useEffect } from "react";
import { userDeatils } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import Avatar from "@mui/material/Avatar";
import { Link, useParams, useLocation } from "react-router-dom";
import DialogBox from "./dialogBox";
import { useQuery } from "@apollo/client";
import { GET_ONE_USER } from "../../Graphql/queries";
import { useLogout } from "../../utils/hooks/use-logout";
import { getUserType } from "@utils/user-format.utils";
import { HeaderMenu } from "@components/atoms/header-menu";

export default function LoggedHeader(props) {
  const catererInfo = props?.catererInfo;
  const { id } = useParams();
  const user = useReactiveVar(userDeatils);
  var currentLocation = window.location.pathname;
  const locationData = useLocation();
  const data = locationData.state;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [catererImage, setCatererImage] = useState();
  const [location, setLocation] = useState(
    currentLocation === `/menu/${id}`
      ? props?.data?.getOneMenu?.catererId
      : id
      ? id
      : props?.location?.listCaterer?.data[0]?._id,
  );

  const userType = getUserType(user);

  const open = Boolean(anchorEl);
  // open menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //close menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { onLogout } = useLogout({
    userType: props.user,
    logoutCallback: handleClose,
  });

  const { data: catererData } = useQuery(GET_ONE_USER, {
    fetchPolicy: "network-only",
    variables: {
      getOneCatererId: user?.data?.login?.catererId || user?.data?.currentUserDetails?.catererId,
    },
    skip: userType !== "Caterer",
    onCompleted: (res) => {
      setCatererImage(res?.getOneCaterer?.imageUrl);
    },
  });

  useEffect(() => {
    if (currentLocation === "/menu") {
      props?.setGetLocId(data?.getlocId ?? props?.location?.listCaterer?.data[0]._id);
      setLocation(data?.getlocId ?? props?.location?.listCaterer?.data[0]._id);
    }
    if (currentLocation === `/menu/${id}`) {
      setLocation(props?.data?.getOneMenu?.catererId);
    }
  }, [props?.location?.listCaterer?.data[0]._id]);

  return (
    <header className="fixed-top LoggedInHeader">
      {/* open profile edit */}
      {openDialog && (
        <DialogBox
          setOpenDialog={setOpenDialog}
          handleDelete={onLogout}
          openDialog={openDialog}
          title="Logout"
          discription="Are you sure you want to Logout?"
        />
      )}
      <div className="container-fluid">
        <div className="d-flex align-items-center h-100">
          <Link to={"/"} className="loggedLogo">
            <img src="../assets/images/logo-white.svg" className="img-fluid" alt="Logo" />
          </Link>

          {userType !== "Admin" && props.locationDetails === "locationDetails" ? (
            <div className="header-loaction-field-wrap">
              {(user?.data?.login?.userSrc === "Registration" ||
                user?.data?.currentUserDetails?.userSrc === "Registration" ||
                user?.data?.createCaterer?.userSrc === "Registration") &&
                props.locationDetails === "locationDetails" &&
                null}
            </div>
          ) : (
            <h3>
              {catererInfo?.businessName && catererInfo?.locationName
                ? `${catererInfo?.businessName}, ${catererInfo?.locationName}`
                : ""}
            </h3>
          )}
        </div>

        <div className="header-menu" onClick={handleClick}>
          <Avatar
            alt="Remy Sharp"
            src={
              catererImage && userType === "Caterer"
                ? catererImage
                : user?.data?.currentUserDetails?.imageUrl && userType !== "Caterer"
                ? user?.data?.currentUserDetails?.imageUrl
                : user?.data?.login?.imageUrl && userType !== "Caterer"
                ? user?.data?.login?.imageUrl
                : "/static/images/avatar/1.jpg"
            }
            style={{ cursor: "pointer" }}>
            {user?.data?.login?.fullName.charAt(0).toUpperCase() ||
              user?.data?.currentUserDetails?.name?.charAt(0).toUpperCase() ||
              user?.data?.createCaterer?.fullName?.charAt(0).toUpperCase()}
          </Avatar>
          <h3 style={{ cursor: "pointer" }} className="header-user-name">
            {user?.data?.login?.fullName ||
              user?.data?.currentUserDetails?.name ||
              user?.data?.createCaterer?.fullName}
          </h3>
        </div>
        <HeaderMenu
          element={anchorEl}
          isOpen={open}
          userType={userType}
          onClose={handleClose}
          onLogout={() => setOpenDialog(true)}
        />
      </div>
    </header>
  );
}
