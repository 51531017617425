import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ICartAlertModalProps {
  type: "warning" | "error" | 'hidden';
  title: string;
  description: string;
  onClose(): void;
  onContinue?(): void;
}

export function CartAlertModal({
  type,
  title,
  description,
  onClose,
  onContinue,
}: ICartAlertModalProps) {
  return (
    <Dialog
      open={type !== "hidden"}
      onClose={onClose}
      aria-labelledby="warning-dialog-title"
      aria-describedby="warning-dialog-description"
      className="common-dialog-box"
    >
      <DialogTitle id="warning-dialog-title">
        <div className="dialog-box-title text-center">
          <h5 className="h5 w-100">{title}</h5>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="warning-dialog-description">
          <div className="dialog-box-text">
            <h5 className="h5">{description}</h5>
          </div>
        </DialogContentText>
      </DialogContent>
      {type === "warning" ? (
        <DialogActions>
          <Button
            className="w-auto m-auto dailog-cancel-btn m-auto"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            Back to menu
          </Button>
          <Button
            onClick={onContinue}
            color="primary"
            className="w-auto m-auto"
            variant="contained"
          >
            Look's good, I'm ready to checkout
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={onClose} color="primary" className="w-100 m-auto" variant="contained">
            Ok
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
