import { Skeleton } from "@mui/material";
import React from "react";

export function CatererMenuLoader() {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6].map((key) => (
        <div
          className="col-lg-6 menu-category-listing-col"
          key={`menu-category-listing-col-${key}`}>
          <div className="menu-category-listing-item skeleton">
            <div className="menu-category-listing-item-details skeleton">
              <h3>
                <Skeleton variant="rectangular" animation="wave" />
              </h3>
              <label className="dietry-label">
                <Skeleton variant="rectangular" animation="wave" />
              </label>
              <div className="price-time">
                <Skeleton variant="rectangular" animation="wave" className="" />
              </div>
              <p>
                <Skeleton variant="rectangular" animation="wave" className="" />
              </p>
              <p>
                <Skeleton variant="rectangular" animation="wave" className="mt-1" />
              </p>
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={70}
                height={30}
                style={{ marginTop: "10px" }}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
