import React from "react";

import { Button, CircularProgress, Drawer } from "@mui/material";

import { InputForm } from "@components/molecules/input-form";
import { PhoneForm } from "@components/molecules/phone-form";
import { LocationSearchForm } from "@components/molecules/location-search-form";
import { useAddressFormState } from "./address-form.state";
import { IAddressListItemResponse } from "typings/address.api";

export interface IAddressFormProps {
  isOpen: boolean;
  addressItem: Omit<
    IAddressListItemResponse,
    | "number"
    | "orderDate"
    | "customerState"
    | "zipCode"
    | "existingOrderDate"
    | "defaultAddress"
    | "city"
    | "customerId"
    | "location"
    | "zipcode"
  > | null;
  onClose(): void;
}

export function AddressForm(props: IAddressFormProps) {
  const { control, loading, handleSubmit, onSubmit, onUpdateLocation, onCloseForm } =
    useAddressFormState(props);

  return (
    <Drawer className="common-drawer" anchor={"right"} open={props.isOpen} onClose={onCloseForm}>
      <div className="drawer-head">
        <h4 className="h4">
          <img
            src="../assets/images/icon-contact-info.svg"
            className="img-fluid"
            alt="Contact Information Icon"
          />
          {props.addressItem ? "Edit Address" : "Add Address"}
        </h4>
        <span style={{ cursor: "pointer" }} onClick={onCloseForm}>
          <img
            src="../assets/images/x-icon.svg"
            className="img-fluid modal-close"
            alt="close icon"
          />
        </span>
      </div>
      <div className="create-step-form">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 textBox">
              <div className="allFeild">
                <LocationSearchForm
                  label="STREET ADDRESS*"
                  control={control}
                  name="street"
                  rules={{
                    required: "Street address is required",
                  }}
                  onChangeLocationOptions={onUpdateLocation}
                />
              </div>
            </div>
            <div className="col-md-3 textBox">
              <InputForm
                label="ZIP CODE*"
                className="textField allFeild"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                control={control}
                name="customerZip"
                rules={{
                  required: "Zip Code is required",
                }}
              />
            </div>
            <div className="col-md-6 textBox">
              <InputForm
                label="LOCATION NAME*"
                className="textField allFeild"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                control={control}
                name="locationName"
                rules={{
                  required: "Location name is required",
                  maxLength: {
                    value: 50,
                    message: "Location name should be less than 50 characters",
                  },
                }}
              />
            </div>
            <div className="col-md-6 textBox">
              <InputForm
                label="SUITE/FLOOR"
                className="textField allFeild"
                variant="outlined"
                control={control}
                name="floor"
                rules={{
                  maxLength: {
                    value: 30,
                    message: "Suite/Floor should be less than 30 characters",
                  },
                }}
              />
            </div>
            <div className="col-md-6 textBox">
              <InputForm
                label="CONTACT NAME"
                className="textField allFeild"
                variant="outlined"
                control={control}
                name="contactName"
                rules={{
                  maxLength: {
                    value: 30,
                    message: "Contact name should be less than 30 characters",
                  },
                }}
              />
            </div>
            <div className="col-md-6 textBox">
              <PhoneForm
                label="PHONE"
                variant="outlined"
                className="textField allFeild"
                control={control}
                name="Phone"
                rules={{}}
              />
            </div>
            <div className="col-md-12 textBox">
              <InputForm
                label="DELIVERY INSTRUCTIONS"
                className="textField allFeild"
                variant="outlined"
                control={control}
                name="instruction"
                rules={{
                  maxLength: {
                    value: 500,
                    message: "Delivery instructions should be less than 500 characters",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="createStepBtn">
        <Button className="cancelBtn" variant="contained" color="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          className="saveBtn"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          type="submit"
          color="primary">
          {loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : props.addressItem ? (
            "Update"
          ) : (
            "Add"
          )}
        </Button>
      </div>
    </Drawer>
  );
}
