//this page is for listing the orders summery
import React, { useState } from "react";
import { Table, MenuItem, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import TableContainer from "@mui/material/TableContainer";
import { formatter } from "../Common/commonUtils";
import TableHead from "@mui/material/TableHead";
import { useNavigate,Link } from "react-router-dom";
import moment from "moment";
import {
  userDeatils,authVar,
  toggleSnackbar,
} from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import TableRow from "@mui/material/TableRow";
import { LIST_ALL_ORDERS,ORDER_SUMMERY } from "../../Graphql/queries";
import { DELETE_ALL_CART,UPDATE_ORDER_STATUS } from "../../Graphql/mutations";
import SideMenu from "../../components/organisms/sidebar";
import LoggedHeader from "../Common/loggedHeader";
import Menu from "@mui/material/Menu";
import DialogBox from "../Common/dialogBox";
export default function Summary(props) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [selectedItem, setSelectedItem] = useState('')

  const open = Boolean(anchorEl);
  const navigate = useNavigate()
  const user = useReactiveVar(userDeatils);
  const userType = user?.data?.createUserIdentity?.userType || user?.data?.login?.userType || user?.data?.currentUserDetails?.userType||user?.data?.createCaterer?.userType;
  const [deleteCart] = useMutation(DELETE_ALL_CART, {
    refetchQueries: ['ListOrders'],
    onCompleted: res => {
      setSelectedItem('')
    }
  })
  const [updateOrderStatus]= useMutation(UPDATE_ORDER_STATUS,{refetchQueries:['ListOrders'],
  onCompleted:res=>{
  //setSelectedItem('')
  }
    })
  const catererId = user?.data?.login?.catererId || user?.data?.currentUserDetails?.catererId||user?.data?.createCaterer?.catererId
  const orderVariable = {
    skip: 0,
    limit: 10,
    orderStatus: ["New", "Cancelled", "Scheduled", "Completed"],
    catererId: userType === "Caterer" ? catererId : "",
  }
  const { data, loading } = useQuery(LIST_ALL_ORDERS, { fetchPolicy: "network-only", variables: orderVariable })
  const {data:orderSummery} = useQuery(ORDER_SUMMERY,{variables:{userId:catererId,userType:"Caterer",status:null}})
  const avgTotalAmt = orderSummery?.orderSummaryHeader?.avgTotalAmt;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function handleDelete() {
    try {
      deleteCart({ variables: { orderId: selectedItem?._id } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Order deleted successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/login", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  function handleCancel() {
    let request={
      orderId:  selectedItem?._id,
      oldStatus:selectedItem?.orderStatus,
      orderStatus:"Cancelled",
      statusChangeDate:moment(),
      statusTimeZone:timeZone
    }
    try {
      updateOrderStatus({ variables: { data:request
        }})
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Order cancelled successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/login", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  return (
    <div>
      {/* header for Customer login  */}
      <LoggedHeader title="Summary"/>
      {/* Side menu for different options for the Customer */}
      <SideMenu selected="Summary" user="caterer" />

      {openDialog && (<DialogBox
        setOpenDialog={setOpenDialog}
        handleDelete={handleDelete}
        openDialog={openDialog}
        title={"Delete"}
        discription={'Are you sure you want to delete this order?'}
      />
      )}
       {openDialogCancel && (<DialogBox
        setOpenDialog={setOpenDialogCancel}
        handleDelete={handleCancel}
        openDialog={openDialogCancel}
        title={"Cancel"}
        discription={'Are you sure you want to cancel this order?'}
      />
      )}
      {
      // data?.listOrders?.data?.length === 0 ? <div className="contentWrap">
      //   <img src="../assets/images/no-order.svg" />
      //   <h1>Oops you haven't created any orders!</h1>
      //   <p>
      //     For a limited time, we are giving a $100 to spend on Amazon for all first
      //     orders above $150</p>
      // </div> :
       <div className="contentWrap">

        <div className="contentWrapInner cater-summary">
          <h4 className="h4">{`Welcome ${user?.data?.currentUserDetails?.name || user?.data?.login?.fullName|| user?.data?.createUserIdentity?.fullName||user?.data?.createCaterer?.fullName}`}</h4>
          <div className="data-overview">
            <div className="do-item">
              <img src="../assets/images/d-new-order.svg" className="img-fluid" alt="Icon" />
              <h6>New Orders</h6>
              <label>{orderSummery?.orderSummaryHeader?.newOrders}</label>
            </div>
            <div className="do-item">
              <img src="../assets/images/d-scheduled-order.svg" className="img-fluid" alt="Icon" />
              <h6>Scheduled Orders</h6>
              <label>{orderSummery?.orderSummaryHeader?.scheduledOrders}</label>
            </div>

            <div className="do-item">
              <img src="../assets/images/d-completed-order.svg" className="img-fluid" alt="Icon" />
              <h6>Completed Orders</h6>
              <label>{orderSummery?.orderSummaryHeader?.completedOrders}</label>
            </div>

            <div className="do-item">
              <img src="../assets/images/d-avg-order-amount.svg" className="img-fluid" alt="Icon" />
              <h6>Average Order Amount</h6>
              <label>{ avgTotalAmt >= 0 ? formatter.format(avgTotalAmt) : ''}</label>
            </div>

          </div>
          <h4 className="h4">Recent Orders</h4>
          <div className="location-head">
            {data?.listOrders?.count!==0&&<div className="location-head-title justify-content-between">
           {data?.listOrders?.count>=0?<h5>{`${data?.listOrders?.count} Orders`}</h5>:<h5>Orders</h5>}
              <Link to={'/orders'}>
              <Button variant="contained">View All</Button>
              </Link>
            </div>}
          </div>
          <TableContainer>
            <Table
              className="commonTabel"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Order #</TableCell>
                  <TableCell align="left">Event Date & Time</TableCell>
                  <TableCell align="left">{userType === "Customer" ? "Caterer & Location" : userType === "Admin" ? "Store Name & Location" : "Delivery Location"}</TableCell>
                  <TableCell align="left">{userType === "Customer" ? "Amount" : "Customer"}</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  loading ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p> Loading...</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : data?.listOrders?.data.length> 0? (
                    data?.listOrders?.data.map((item) => (
                      <TableRow style={{ cursor: "pointer" }}>
                        <TableCell onClick={() => {navigate(`/orders/${item?._id}`)}}>
                          {`#${item?.orderNo}`}
                        </TableCell>
                        <TableCell  onClick={() => {navigate(`/orders/${item?._id}`)}}>
                          {moment(item?.deliveryDate).format('llll')}
                        </TableCell>
                        <TableCell  onClick={() => {navigate(`/orders/${item?._id}`)}}>
                          {userType === "Caterer" ? `${item?.locWithStreet}` : `${item?.catererDetails?.businessName}, ${item?.catererDetails?.locationName}`}
                        </TableCell>
                        <TableCell  onClick={() => {navigate(`/orders/${item?._id}`)}}>
                          {userType === "Customer" ? formatter.format(item?.grandTotal) : `${item?.customerDetails?.firstName} ${item?.customerDetails?.lastName}`}
                        </TableCell>
                        <TableCell align="center" onClick={() => {navigate(`/orders/${item?._id}`)}}>
                          <Button className="status-btn" style={{color:"#fff", backgroundColor: item?.orderStatus === 'Draft' ? '#707781' : item?.orderStatus === 'New' ? '#00C2FF' : (item?.orderStatus === 'Completed' && !item?.isRefunded) ?"#3A8CF8":item?.orderStatus === 'Cancelled'?"#FF0000":item?.orderStatus === 'Scheduled'?"#FD982F":(item?.orderStatus === 'Completed' && item?.isRefunded )?"#219ebc":'' }}
                           variant="contained">{ item?.isRefunded ? "Refunded":item?.orderStatus}</Button>
                        </TableCell>
                        <TableCell align="center">
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={(e) => { handleClick(e); setSelectedItem(item) }}
                            src="../assets/images/more-horizontal.svg"
                            alt="img"
                          />
                        </TableCell>
                      </TableRow>
                    ))): (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <div
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <p>No list added Yet!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="menu-list-more"
        >
          <MenuItem onClick={() => { handleClose(); navigate(`/orders/${selectedItem?._id}`) }}>View</MenuItem>
          {/* <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            Edit
          </MenuItem> */}
          {(selectedItem?.orderStatus === "New"||selectedItem?.orderStatus === "Scheduled") && <MenuItem onClick={() => { handleClose();setOpenDialogCancel(true); }}>Cancel</MenuItem>}
        </Menu>
      </div>}
    </div>
  );
}
