import React from 'react';

import { IconButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { Link } from "react-router-dom";
export default function LoginDrawer(props) {
  var currentLocation = window.location.pathname;
  const userType =
  props?.user?.data?.login?.userType ||
  props?.user?.data?.currentUserDetails?.userType ||
  props?.user?.data?.createUserIdentity?.userType ||
  props?.user?.data?.createCaterer?.userType;
  return (
    <div>
      <Drawer
        className="m-menu-drawer"
        anchor={"left"}
        open={props.openSideMenu}
        onClose={() => {
          props.setOpenSideMenu(false);
        }}
      >
        <div>
          <IconButton className="m-menu-close" onClick={() => props.setOpenSideMenu(false)}>
            <img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" />
          </IconButton>
          <ul>
            {userType === 'Customer' &&<li>
              <Link to={"/referFriend"} style={{color:currentLocation === "/referFriend"?"#FD4E3B":""}}>Refer A Friend</Link>
            </li>}
            <li>
              <a href={"/resources"} style={{color:currentLocation === "/resources"?"#FD4E3B":""}}>Resources</a>
            </li>
            <li>
              <Link to={"/caterer"} style={{color:currentLocation === "/caterer"?"#FD4E3B":""}}>Caterers</Link>
            </li>
            <li>
            <Link to={"/about"} style={{color:currentLocation === "/about"?"#FD4E3B":""}}>About</Link>
            </li>
            <li>
              <Link to={"/faq"} style={{color:currentLocation === "/faq"?"#FD4E3B":""}}>FAQ</Link>
            </li>
            <li>
              <Link to={"/contact"} style={{color:currentLocation === "/contact"?"#FD4E3B":""}}>Contact</Link>
            </li>
          </ul>
        </div>

      </Drawer>
    </div>
  )
}