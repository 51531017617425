//this page is for showing all the categories
import React, { useState } from "react";
import SideMenu from "../../../components/organisms/sidebar";
import LoggedHeader from "../../Common/loggedHeader";
import { Table, Select, MenuItem,Button,Menu} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_CATEGORY } from "../../../Graphql/queries";
import { DELETE_CATEGORY } from "../../../Graphql/mutations";
import Pagination from "@mui/material/Pagination";
import { toggleSnackbar,authVar } from "../../../ReactiveVariables/index";
import Stack from "@mui/material/Stack";
import AddCategories from "./addCategories";
import DialogBox from "../../Common/dialogBox";
import {useNavigate } from "react-router-dom";
export default function CategoryList(){
  const navigate = useNavigate()
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [openCategory,setOpenCategory] =useState(false)
  const open = Boolean(anchorEl);
  const categoryVariable = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search: search
  }
  const {data,loading}= useQuery(LIST_CATEGORY,{variables: categoryVariable})
  const [deleteCategory] = useMutation(DELETE_CATEGORY,{refetchQueries:['ListCategory'],
  onCompleted:(res)=>{
      // setOpenCategory(false)
  }})
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // close menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  //category deletion
  function handleDelete(){
    try { deleteCategory({ variables: {categoryId: selectedItem?._id } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Category deleted successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
  }
  return (
    <div>
      <LoggedHeader title="Category" user="admin" />
      <SideMenu selected="Category" user="admin" />
      {openCategory&&<AddCategories 
      selectedItem ={selectedItem}
      setSelectedItem ={setSelectedItem}
      openCategory={openCategory}
      setOpenCategory={setOpenCategory}/>}
      {openDialog && (<DialogBox
        setOpenDialog={setOpenDialog}
        handleDelete={handleDelete}
        openDialog={openDialog}
        title="Delete"
        discription="Are you sure you want to delete this category?"
      />
      )}
      <div className="contentWrap">
        <div className="contentWrapInner">
          <div className="location-head">
            <div className="location-head-title justify-content-between">
            {data&&data?.listCategory?.count?<h5 style={{color:'#202428',fontSize:'20px'}}>{`${data&&data?.listCategory?.count} Categories`}</h5>:<p>Loading...</p>}
              <div className="d-flex align-items-center">
              <input
                placeholder="Search by Category Name"
                className="form-control"
                value={search}
                onChange={(e) => {
                  setPage(1);
                  setSearch(e.target.value);
                }}
              />
              <Button className="ms-3" color="primary" variant="contained" onClick={()=>{setOpenCategory(true);setSelectedItem(null)}} >
                  <img src="../assets/images/icon-add-menu.svg" className="img-fluid" alt="Icon" /> Add Category
              </Button>
              </div>
            </div>
          </div>
          <TableContainer>
            <Table
              className="commonTabel"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell width="80%">Name</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : data && data?.listCategory?.data?.length > 0 ? (
                  data &&
                  data?.listCategory?.data?.map((item) => (
                    <TableRow style={{ cursor: "pointer" }}>
                      <TableCell component="th" scope="row">
                        {item?.categoryTitle}
                      </TableCell>
                      <TableCell align="center">
                        <img
                          style={{ cursor: "pointer" }}
                            onClick={(e) => { setSelectedItem(item); handleClick(e) }}
                          src="../assets/images/more-horizontal.svg"
                          alt="img"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : search ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No data Found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No list added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select
              // variant="outlined"
              onChange={handlePageInputChange}
              value={rowsPerPage}
            >
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listCategory?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="menu-list-more"
        >
          <MenuItem
            onClick={() => {
              setOpenCategory(true);
              handleClose();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem onClick={() => { setOpenDialog(true); handleClose() }}>Delete</MenuItem>
        </Menu>
      </div>
    </div>
  )
}