// this page is for adding promo code
import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import {
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toggleSnackbar,authVar } from "../../../ReactiveVariables/index";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_CATERER_PROMO } from "../../../Graphql/queries";
import { CREATE_PROMOCODE } from "../../../Graphql/mutations";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {useNavigate } from "react-router-dom";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "D34343" : "green",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "#FD4E3B",
    boxSizing: "border-box",
  },
}));
const all = [
  {
    value: "All",
    label: "All",
  },
];
export default function AddPromoCode(props) {
  const navigate = useNavigate()
  const [catererArray, setCatererArray] = useState([]);
  const [startDateTime, setStartDateTime] = useState(
    props?.selectedItem?.expDate ? new Date(props?.selectedItem?.expDate) : ""
  );
  const [selectedValue, setSelectedValue] = useState([]);
  const [dateError, setDateError] = useState(false);
  const [allOptionsSelected, setAllOptionsSelected] = useState(false);
  const [catererDropdownList, setCatererDropdownList] = useState([])
  const schema = yup.object().shape({
    coupenCode: yup.string().required("Coupon Code name is required"),
    expDate: yup.string().required("Expiry date is required"),
    discountAmt: yup.string().required("Discount Amount is required"),
    customerLimit: yup.string().required("Customer Limit is required"),
    // catererId: yup.string().required("Caterer name is required"),
    // codeStatus: yup.string().required("codeStatus name is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      expDate: props?.selectedItem?.expDate
        ? new Date(props?.selectedItem?.expDate)
        : "",
    },
  });
  const [createAddPromoCode, { loading }] = useMutation(CREATE_PROMOCODE, {
    refetchQueries: ["ListPromoCodes"],
    onCompleted: (res) => {
      props?.setOpenPromo(false);
    },
  });
  const caterlistVariabel = {
    skip: 0,
    limit: 900,
  };
  const { data: catererData } = useQuery(
    LIST_CATERER_PROMO,
    {
      variables: caterlistVariabel,
      onCompleted: (res) => {
        let selectedCatererData = [];
        res?.catererSearchList?.data?.map(element => {
          if (props?.selectedItem?.catererId.includes(element._id)) {
            selectedCatererData.push(element)
          }

        })
        setSelectedValue(selectedCatererData)
        let catererArrayselected = res?.catererSearchList?.data
        const allOption = { label: 'Select All', value: '', _id: '' };
        const updatedOptions = [allOption, ...catererArrayselected];
        setCatererDropdownList(updatedOptions)
      }
    }
  );

  function onSubmit(params) {
    let selectedCatererIdArray = []
    selectedValue?.map((data) => {
      if(data?._id){
      selectedCatererIdArray.push(data._id)
      }
    })
    params["catererId"] = selectedCatererIdArray
    params["discountAmt"] = parseFloat(params?.discountAmt);
    params["customerLimit"] = parseFloat(params?.customerLimit);
    params["codeStatus"] = params?.codeStatus === true ? "Active" : "Inactive";
    params["expDate"] = new Date(startDateTime.setTime(new Date(startDateTime).getTime()))
    params["_id"] = props?.selectedItem?._id;
    try {
      props?.selectedItem?._id
        ? createAddPromoCode({
          variables: { id: props?.selectedItem?._id, data: params },
        }).then((res) => {
          toggleSnackbar({
            status: true,
            message: "Promo Code updated successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        })
        : createAddPromoCode({ variables: { data: params } })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Promo Code created successfully",
              variant: "success",
            });
          })
          .catch((err) => {
            const message = err && err.message;
            if(message==="Not authenticated"){
              localStorage.clear();
              authVar({
                loaded: true,
                auth: false,
              });
              navigate("/admin", { state: "noAuth" })
            }else{
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
          });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  const handleKeyPress = (e) => {
    const allowedKeys = [8, 37, 39, 191]; // Backspace, left arrow, right arrow, slash
    if (!allowedKeys.includes(e.keyCode) && isNaN(Number(e.key))) {
      e.preventDefault();
    }
  };
  const onChangeRaw = (e) => {
    let m = moment(e.target.value);
    if (!m.isValid()) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };
  const registerDate = register("expDate");
  React.useEffect(() => {
    if (allOptionsSelected) {
      setCatererArray(catererData?.catererSearchList?.data);
    }
  }, [allOptionsSelected]);

  //handle only numbers without negative values
  const handleInputChange = (event) => {
    let positiveValue

    let inputValue = (event.target.value).replace(/[^0-9.]/g, ''); // Replace all non-numeric characters with an empty string
    const decimalCount = (inputValue.match(/\./g) || []).length;
    if (decimalCount > 1) {
      inputValue = inputValue.slice(0, inputValue.lastIndexOf('.'));
    }
    if (inputValue < 0) {
      positiveValue = -(inputValue)
    } else {
      positiveValue = inputValue
    }
    event.target.value = positiveValue;
  };

  //handle only numbers without decimal and negative values
  const handleInputChangeLimit = (event) => {
    let positiveValue
    const inputValue = parseInt(event.target.value);
    if ((/^-?\d+$/).test(inputValue)) {
      if (inputValue) {
        if (inputValue < 0) {
          positiveValue = -(inputValue)
        } else {
          positiveValue = inputValue
        }
      } else {
        positiveValue = event.target.value
      }
    } else {
      positiveValue = ''
    }
    event.target.value = positiveValue;
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <Drawer
      className="common-drawer"
      anchor={"right"}
      open={props.openPromo}
      onClose={() => props.setOpenPromo(false)}
    >
      <div className="drawer-head">
        <h4 className="h4">
          <img
            src="../assets/images/icon-contact-info.svg"
            className="img-fluid"
            alt="Contact Information Icon"
          />
          {props?.selectedItem?._id ? "Edit Promo code" : "Add Promo code"}
        </h4>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.setOpenPromo(false);
            props?.setSelectedItem(null);
          }}
        >
          <img
            src="../assets/images/x-icon.svg"
            className="img-fluid"
            alt="close icon"
          />
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate  autoComplete="off">
        <div className="create-step-form">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountAdminName"
                  defaultValue={props?.selectedItem?.coupenCode}
                  label="COUPON CODE*"
                  autoFocus
                  variant="outlined"
                  className="textField allFeild"
                  {...register("coupenCode")}
                />
                {errors.coupenCode && (
                  <span className="error-msg">{errors.coupenCode.message}</span>
                )}
              </div>
              <div className="col-md-6 textBox review-order-datepicker addpromo-datepicker">
                {/* {dateError && (
                  <span className="error-msg invalid-error-msg">Invalid date format</span>
                )} */}
                <DatePicker

                  minDate={new Date()}
                  selected={startDateTime}
                  showTimeSelect
                  {...register("registerDate")}
                  // onChange={(date) => { setStartDateTime(date) }}
                  onChange={(date) => {
                    setStartDateTime(date);
                    registerDate.onChange({
                      target: { value: date, name: "expDate" },
                    });
                  }}
                  placeholderText={"EXPIRATION DATE*"}
                  dateFormat="MM/dd/yyyy h:mm aa"
                 // onKeyDown={handleKeyPress}
                 // onChangeRaw={onChangeRaw}
                 onFocus={(event)=>event.target.blur()}
                  className=""
                  filterTime={filterPassedTime}

                />
                {errors.expDate && (
                  <span className="error-msg">{errors.expDate.message}</span>
                )}
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountAdminName"
                  defaultValue={props?.selectedItem?.discountAmt}
                  label="DISCOUNT AMOUNT*"
                  variant="outlined"
                  // inputProps={{ type: "number", min: "1" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  className="textField allFeild"
                  {...register("discountAmt", { required: true, pattern: /^-?\d+$/ })}
                  onChange={handleInputChange}
                />
                {errors.discountAmt && (
                  <span className="error-msg">
                    {errors.discountAmt.message}
                  </span>
                )}
              </div>
              <div className="col-md-6 textBox">
                <TextField
                  id="createAccountAdminName"
                  defaultValue={props?.selectedItem?.customerLimit}
                  label="LIMIT PER CUSTOMER*"
                  // inputProps={{ type: "number", min: "1" }}
                  type="text"
                  variant="outlined"
                  className="textField allFeild"
                  {...register("customerLimit", { pattern: /^[0-9]*$/ })}
                  onChange={handleInputChangeLimit}
                />
                {errors.customerLimit && (
                  <span className="error-msg">
                    {errors.customerLimit.message}
                  </span>
                )}
              </div>
              <div className="col-md-12 textBox">
                <Controller
                  control={control}
                  name="catererId"
                  defaultValue={selectedValue}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        disablePortal
                        id="combo-box-demo"
                        options={
                          catererDropdownList ?
                            [...catererDropdownList] : []
                        }
                        // onChange={(event, value, option) => {                         
                        //     setSelectedValue(value);
                        // }}
                        getOptionLabel={(option) =>
                          option === "All"
                            ? option
                            : option?.businessName || option?.label
                        }
                        getOptionSelected={(option, values) => {
                          if (option.value === values.value) {
                            return true
                          }
                        }
                        }
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  if (option?.label === "Select All") {
                                    if (isChecked) {
                                      // If "Select All" is checked, set selectedOptions to all options except the first ("Select All") option
                                      setSelectedValue(catererDropdownList);
                                    } else {
                                      // If "Select All" is unchecked, set selectedOptions to an empty array
                                      setSelectedValue([]);
                                    }
                                  }else{
                                    if(isChecked){
                                      let arr = [...selectedValue];
                                      arr.push(option)
                                      setSelectedValue(arr)
                                    }else{
                                      let arr = [...selectedValue];
                                      let pos = arr?.findIndex(
                                        (element) => option?._id === element._id
                                      );
                                      arr.splice(pos, 1);
                                    setSelectedValue(arr);

                                    }

                                  }
                                }
                                }
                              />
                              {option === "All"
                                ? option
                                : option?.businessName || option?.label}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="SELECT CATERERS" />
                        )}
                        renderTags = { (values, getTagProps) => {
                          let selectedDataCount = values.filter(data => data._id)
                          return <div>{`${selectedDataCount.length} selected`}</div>;
                        }}
                        value={selectedValue}
                      />
                    );
                  }}
                />
                {errors.catererId && (
                  <span className="error-msg">{errors.catererId.message}</span>
                )}
              </div>
            </div>
            <div className="d-flex align-item-center mt-4">
              <AntSwitch
                size={"70"}
                {...register("codeStatus")}
                className="veg-nonveg-switch"
                defaultChecked={
                  props?.selectedItem?.codeStatus === "expired" ||
                    props?.selectedItem?.codeStatus === "Inactive"
                    ? false
                    : true
                }
                inputProps={{ "aria-label": "ant design" }}
              />
              <span className="ms-2">Active</span>
            </div>
          </div>
        </div>
        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => {
              props.setOpenPromo(false);
              props?.setSelectedItem(null);
            }}
          >
            Cancel
          </Button>
          <Button
            className="saveBtn"
            variant="contained"
            type="submit"
            color="primary"
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : props?.selectedItem?._id ? (
              "Update"
            ) : (
              "Add"
            )}
          </Button>
        </div>
      </form>
    </Drawer>
  );
}
