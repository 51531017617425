export enum CartDietary {
  glutenFree = "Gluten Free",
  vegetarian = "Vegetarian",
  nonVegetarian = "Non-Vegetarian",
  vegetarianGlutenFree = "Veg and Gluten Free",
  nonVegetarianGlutenFree = "Non-Veg and Gluten Free",
  none = "None",
}

export interface IGetMenuResponse {
  getOneMenu: {
    itemDescription: string;
    offerIndPkdFlg: boolean;
    itemName: string;
    minQuantity: number;
    price: number;
    dietory: CartDietary;
    itemType: string; //TODO confirm
    addOnTypeList: IAddonTypeResponse[];
    catererId: string;
    categoryId: string;
    _id: string;
  };
}

export interface IAddonTypeResponse {
  addOnTypeLimit: null | number;
  addOnTypeName: string;
  addOnTypePrice: number;
  catererId: string;
  menuId: string;
  type: string;
  _id: string;
  addOnsList: IAddonTypeItemResponse[];
}

export interface IAddonTypeItemResponse {
  _id: string;
  addOnDescription: string;
  addOnLimit: null | number;
  addOnName: string;
  addOnPrice: number;
  addOnType: string;
  addOnTypeId: string;
  catererId: string;
  menuId: string;
  subAddOns: ISubAddonItemResponse[];
}

export interface ISubAddonItemResponse {
  subAddOnName: string;
  subAddOnPrice: number;
  _id: string;
}
