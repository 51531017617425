import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardProvider } from "../payment-card-provider";
import { ISearchLocationPayload } from "@components/atoms/location-search";
import { IPaymentCardResponseItem } from "../../payment-card.typings";

export interface CardForm {
  cardName: string;
  nickName: string;
  cardLastDigits: string;
  cardExpr: string;
  cvv: string;
  locationName: string;
  billingZipCode: string;
  additionalEmail: string;
  city: string;
  state: string;
  street: string;
  addEmailChk: boolean;
  cardType: string;
}

export function usePaymentCardFormState(onSubmit, paymentCard: IPaymentCardResponseItem | null) {
  const [cardProvider, setCardProvider] = useState<CardProvider>(CardProvider.empty);
  const [isAdditionalEmail, setIsAdditionalEmail] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<CardForm>({
    mode: "all",
    defaultValues: {
      addEmailChk: false,
      additionalEmail: "",
      cardType: "",
      nickName: "",
      locationName: "",
    },
  });

  useEffect(() => {
    if (!paymentCard) {
      reset();
      return;
    }
    reset();

    //setting the value on edit
    setValue("cardName", paymentCard.cardName);
    setValue("nickName", paymentCard.nickName);
    setValue(
      "cardLastDigits",
      paymentCard?.cardLastDigits.length === 4
        ? "XXXXXXXXXXXX" + paymentCard?.cardLastDigits
        : paymentCard?.cardLastDigits,
    );
    setValue("cardExpr", `${paymentCard.cardExpr.slice(0, 2)}/${paymentCard.cardExpr.slice(2, 4)}`);
    setValue("additionalEmail", paymentCard.additionalEmail);
    setValue("addEmailChk", paymentCard.addEmailChk);
    setValue("billingZipCode", paymentCard.billingZipCode);
    setValue("cvv", "");
    setIsAdditionalEmail(paymentCard.addEmailChk);
    setValue("locationName", paymentCard.locationName);
    return () => reset();
  }, [paymentCard]);

  function onToggleAdditionalEmail() {
    if (isAdditionalEmail) {
      setValue("additionalEmail", "");
    }
    setIsAdditionalEmail(!isAdditionalEmail);
    setValue("addEmailChk", !isAdditionalEmail);
  }

  function onSelectLocationOptions({ city, zip, state, street }: ISearchLocationPayload) {
    setValue("billingZipCode", zip);
    setValue("city", city);
    setValue("state", state);
    setValue("street", street);
  }

  function onValidateCardProvider(value) {
    if (!value) {
      setValue("cardType", "");
      return setCardProvider(CardProvider.empty);
    }
    const sanitizedValue = value.replace(/[-,),(, ]+/g, "");

    switch (true) {
      case /^4[0-9]{6,}$/.test(sanitizedValue):
        setValue("cardType", CardProvider.Visa);
        setCardProvider(CardProvider.Visa);
        break;
      case /^3[47][0-9]{5,}$/.test(sanitizedValue):
        setValue("cardType", CardProvider.Amex);
        setCardProvider(CardProvider.Amex);
        break;
      case /^6(?:011|5[0-9]{2})[0-9]{3,}$/.test(sanitizedValue):
        setValue("cardType", CardProvider.Discover);
        setCardProvider(CardProvider.Discover);
        break;
      case /^(5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,})$/.test(
        sanitizedValue,
      ):
        setValue("cardType", CardProvider.Master);
        setCardProvider(CardProvider.Master);
        break;
      default:
        setValue("cardType", "");
        setCardProvider(CardProvider.default);
        break;
    }
  }

  async function onSubmitForm(form: CardForm) {
    const isSuccess = await onSubmit(form);
    if (isSuccess) {
      reset();
    }
  }

  return {
    control,
    handleSubmit,
    cardProvider,
    onValidateCardProvider,
    isAdditionalEmail,
    onToggleAdditionalEmail,
    onSelectLocationOptions,
    onSubmitForm,
    isEdit: !!paymentCard,
  };
}
