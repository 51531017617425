export function getUserItem(user) {
  if (!user || !user.data) {
    return null;
  }

  // TODO: test all of this methods
  return user.data.currentUserDetails
    ? user.data.currentUserDetails
    : user.data.createUserIdentity
    ? user.data.createUserIdentity
    : user.data.login
    ? user.data.login
    : null;
}

export function getUserId(user) {
  if (!user || !user.data) {
    return null;
  }

  // TODO: test all of this methods
  return user.data.currentUserDetails
    ? user.data.currentUserDetails._id
    : user.data.createUserIdentity
    ? user.data.createUserIdentity._id
    : user.data.login
    ? user.data.login._id
    : null;
}

export function getUserCatererId(user) {
  if (!user || !user.data) {
    return null;
  }

  return user.data.currentUserDetails
    ? user.data.currentUserDetails.catererId
    : user.data.createCaterer
    ? user.data.createCaterer.catererId
    : user.data.login
    ? user.data.login.catererId
    : null;
}

export function getUserType(user) {
  if (!user || !user.data) {
    return null;
  }

  // TODO: test all of this methods
  return user.data.login
    ? user.data.login.userType
    : user.data.currentUserDetails
    ? user.data.currentUserDetails.userType
    : user.data.createUserIdentity
    ? user.data.createUserIdentity.userType
    : user.data.createCaterer
    ? user.data.createCaterer.userType
    : "";
}

export function getUserName(user) {
  if (!user || !user.data) {
    return null;
  }

  // TODO: test all of this methods
  return user.data.currentUserDetails
    ? user.data.currentUserDetails.name
    : user.data.login
    ? user.data.login.fullName
    : user.data.createUserIdentity
    ? user.data.createUserIdentity.fullName
    : "";
}

export function getUserTaxExempt(user) {
  if (!user || !user.data) {
    return null;
  }

  return user?.data?.currentUserDetails?.taxExempt || false;
}
