import React from "react";
import { Avatar, Button, Skeleton } from "@mui/material";

export function OrderInvoiceLoading() {
  return (
    <>
      <div className="caterer-menu-header">
        <div>
          <h5 className="h5">
            <Skeleton variant="rectangular" animation="wave" width={320} height={22} />
          </h5>
          <small>
            <Skeleton variant="rectangular" animation="wave" width={200} height={15} />
          </small>
        </div>
      </div>
      <div className="order-view caterer-menu-content">
        <div className="order-view-address-wrap">
          <div className="row">
            <div className="col-md-6">
              <h6>
                <Skeleton variant="rectangular" animation="wave" width={90} height={22} />
              </h6>
              <div className="order-view-address order-view-address-mob">
                <Skeleton variant="circular" animation="wave" width={60} height={60}>
                  <Avatar />
                </Skeleton>
                <div>
                  <h6>
                    <Skeleton variant="rectangular" animation="wave" width={100} height={22} />
                  </h6>
                  <p>
                    <Skeleton variant="rectangular" animation="wave" width={150} height={15} />
                  </p>
                  <p>
                    <Skeleton variant="rectangular" animation="wave" width={150} height={15} />
                  </p>
                  <p>
                    <Skeleton variant="rectangular" animation="wave" width={150} height={15} />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h6>
                <Skeleton variant="rectangular" animation="wave" width={100} height={22} />
              </h6>
              <div className="order-view-address">
                <Skeleton variant="circular" animation="wave" width={60} height={60}>
                  <Avatar />
                </Skeleton>
                <div>
                  <h6>
                    <Skeleton variant="rectangular" animation="wave" width={100} height={22} />
                  </h6>
                  <p>
                    <Skeleton variant="rectangular" animation="wave" width={150} height={15} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="od-common-box">
          <p>
            <Skeleton variant="rectangular" animation="wave" width={600} height={15} />
          </p>
        </div>
        <div className="review-order-item">
          <div className="roi-single">
            <h6>
              <Skeleton variant="rectangular" animation="wave" width={170} height={22} />
            </h6>
          </div>
        </div>
        <div className="review-order-price">
          <div className="common-cart-viewbox tableware">
            <label>
              <Skeleton variant="rectangular" animation="wave" width={100} height={22} />
            </label>
            <b>
              <Skeleton variant="rectangular" animation="wave" width={90} height={15} />
            </b>
          </div>
          <div className="other-charges">
            <div className="other-charges-item">
              <label>
                <Skeleton variant="rectangular" animation="wave" width={120} height={15} />
              </label>
              <b>
                <Skeleton variant="rectangular" animation="wave" width={90} height={20} />
              </b>
            </div>
            <div className="other-charges-item">
              <label>
                <Skeleton variant="rectangular" animation="wave" width={120} height={15} />
              </label>
              <b>
                <Skeleton variant="rectangular" animation="wave" width={90} height={20} />
              </b>
            </div>
            <div className="other-charges-item">
              <label>
                <Skeleton variant="rectangular" animation="wave" width={120} height={15} />
              </label>
              <b>
                <Skeleton variant="rectangular" animation="wave" width={90} height={20} />
              </b>
            </div>
          </div>
        </div>
      </div>
      <div className="caterer-menu-footer">
        <Button>
          <Skeleton variant="rectangular" animation="wave" width={400} height={50} />
        </Button>
        <Button>
          <Skeleton variant="rectangular" animation="wave" width={400} height={50} />
        </Button>
      </div>
    </>
  );
}
