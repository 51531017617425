// crop image , we use react crop package for croping
import React, { useState, useEffect } from "react";
import { Modal, Backdrop, Fade, Button } from "@mui/material";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import imageCompression from 'browser-image-compression';

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
    zIndex:0
  },
};

export default function CropImage(props) {
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: props.aspectRatio ? props.aspectRatio : 34 / 9,
  });
  const [croppedImage, setCroppedImage] = useState("");
  const [src, setSrc] = useState(null);
  const [imageRef, setImageRef] = useState();
  let fileUrl;
  useEffect(() => {
    onSelectFile();
  }, [props.image]);

  const compressImage = async (file, options) => {
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      return file;
    }
  }
  const handleCropResult = async (cropResult) => {
    // Get the cropped image as a File object
    // Compress the image before sending it to the server
    const options = {
      maxSizeMB: 1, // Set the maximum file size in MB
      // maxWidthOrHeight: 600, // Set the maximum width or height of the image
      maxWidth: 1024,
      maxHeight: 768,
      useWebWorker: true, // Use web workers to offload the compression task
      quality: 80,
    };
    const compressedImage = await compressImage(cropResult.file, options);
    props.handelCropDone(compressedImage);
    props.setOpen(false);
  }
  function onSelectFile() {
    if (props.image) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(props.image);
    }
  }
  function onImageLoaded(image) {
    setImageRef(image);
  }
  function onCropComplete(crop) {
    makeClientCrop(crop);
  }
  function onCropChange(crop, percentCrop) {
    setCrop(crop);
  }
  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const imageData = await getCroppedImg(imageRef, crop, props?.image?.name);
      setCroppedImage(imageData);
    }
  };
  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(fileUrl);
          fileUrl = window.URL.createObjectURL(blob);
          resolve({ fileUrl: fileUrl, file: new File([blob], fileName,{ type: 'image/jpeg' }) });
        },
        "image/jpeg",
        1
      );
    });
  }
  const handleClose = () => {
    props.setOpen(false);
  };
  function handleDone() {
    handleCropResult(croppedImage)
    // props.handelCropDone(croppedImage);
    // props.setOpen(false);
  }
  return (
    <div>
      <Modal
        style={styles.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div style={styles.paper}>
            <div className="crop-outer">
              {src && (
                <ReactCrop
                  className="img-fluid"
                  src={src}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                />
              )}
              
            </div>
            <div className="d-flex justify-content-end mt-2">
            <Button  onClick={handleClose}>Cancel</Button>
            <Button className=" ms-3" onClick={handleDone}>Done</Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
