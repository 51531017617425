import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { DATE_FORMATS } from "@constants/date-formats.constants";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="logo-social-link">
          <a href="#">
            <img src="../assets/images/logo.svg" className="footer-logo img-fluid" alt="Icon" />
          </a>
          <ul>
            <li>
              <Link to="https://www.facebook.com/CaterPlace" target="_blank">
                <img src="../assets/images/icon-fb.svg" className="img-fluid" alt="Icon" />
              </Link>
            </li>
            <li>
              <Link to="https://twitter.com/CaterPlace" target="_blank">
                <img src="../assets/images/icon-twitter.svg" className="img-fluid" alt="Icon" />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/Cater_Place/" target="_blank">
                <img src="../assets/images/icon-ig.svg" className="img-fluid" alt="Icon" />
              </Link>
            </li>
            <li>
              <a href="mailto:support@caterplace.com">
                <img src="../assets/images/icon-mail.svg" className="img-fluid" alt="Icon" />
              </a>
            </li>
          </ul>
        </div>
        <div className="copy-terms">
          <span>
            © {moment().format(DATE_FORMATS.year)} CaterPlace&nbsp;&nbsp;
            <a href="/terms-and-conditions">Terms & Conditions</a>
            <a href="/privacy-policy">Privacy Policy</a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
