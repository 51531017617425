import { formatter } from "../Modules/Common/commonUtils";
import { DATE_FORMATS } from "@constants/date-formats.constants";
import moment from "moment";

export function formatTableWare({ isCharged, isDeclined, total, amount, people }) {
  const value = !isCharged || isDeclined ? 0 : total ? total : amount * people;
  const label = isDeclined ? "No Tableware" : !isCharged ? "Included" : formatter.format(value);
  return {
    value,
    label,
  };
}

export function formatDriverTip({ isPercentage, tip, totalCost }) {
  if (isPercentage) {
    return parseFloat(((totalCost * tip) / 100).toFixed(2));
  }
  return parseFloat(tip);
}

export function getPercentValue(value) {
  return parseFloat((value / 100).toFixed(2));
}

export function deliveryWindow(deliveryTime) {
  const parsedTime = moment(deliveryTime, DATE_FORMATS.deliveryTime);
  const newTime = parsedTime.subtract(15, "minutes");
  const deliveryWindowStart = newTime.format(DATE_FORMATS.deliveryTime);

  return {
    start: deliveryWindowStart,
    end: deliveryTime
  };
}

export function getOrderStatusColor(orderStatus, isRefunded) {
  return orderStatus === "Draft"
    ? "#707781"
    : orderStatus === "New"
    ? "#00C2FF"
    : orderStatus === "Completed" && !isRefunded
    ? "#3A8CF8"
    : orderStatus === "Cancelled"
    ? "#FF0000"
    : orderStatus === "Scheduled"
    ? "#FD982F"
    : orderStatus === "Completed" && isRefunded
    ? "#219ebc"
    : orderStatus === "Approved"
    ? "#03a703"
    : orderStatus === "Pending"
    ? "#5c948d"
    : "";
}
