import { gql } from "@apollo/client";

export const GET_ONE_ADDRESS = gql`
  query GetOneAddress($id: String!) {
    getOneAddress(id: $id) {
      _id
      city
      latitude
      longitude
      street
      zipcode
      location
      locationName
      geolocation {
        lat
        long
      }
      floor
      defaultAddress
      customerState
      customerId
      existingOrderDate
      orderDate
      customerCity
      customerZip
      contactName
      number
      Phone
      createdOn
      instruction
    }
  }
`;
