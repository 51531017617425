import React from "react";
import { Box, Stack, Slider } from "@mui/material";

interface ICaterCashProps {
  isAdmin: boolean;
  caterCash: number;
  commission: number;
  setCaterCash(v: boolean): void;
}

export function CaterCash({ isAdmin, caterCash, commission, setCaterCash }: ICaterCashProps) {
  return (
    <div className="location-details-tile">
      <div className="ld-titles">
        <h4 className="h4">
          <img
            src="../assets/images/icon-cater-cash.svg"
            className="img-fluid"
            alt="CaterCash & Commission Icon"
          />
          CaterCash & Commission
        </h4>
        {isAdmin && (
          <a className="ld-edit" onClick={() => setCaterCash(true)}>
            <img src="../assets/images/icon-edit.svg" className="img-fluid" alt="Edit Icon" />
            Edit
          </a>
        )}
      </div>

      <div className="ld-slider">
        <h5>CaterCash</h5>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center"></Stack>
          <Slider
            size="medium"
            disabled
            defaultValue={caterCash ? caterCash : 5}
            value={caterCash ? caterCash : 5}
            aria-label="Disabled slider"
            color="primary"
          />
        </Box>
        <h6>{!caterCash ? "5%" : `${caterCash}%`}</h6>
      </div>

      <div className="ld-slider">
        <h5>Commission</h5>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center"></Stack>
          <Slider
            size="medium"
            disabled
            defaultValue={commission ? commission : 8}
            value={commission ? commission : 8}
            aria-label="Disabled slider"
            color="primary"
          />
        </Box>
        <h6>{!commission ? "8%" : `${commission}%`}</h6>
      </div>
      {!isAdmin && (
        <label style={{ fontSize: "12px", color: "#FD4E3B" }}>
          Please contact the admin to make changes to the CaterCash and Commission .
        </label>
      )}
    </div>
  );
}
