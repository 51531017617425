// this page is for adding the business details
import React from 'react';
import {
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  userDeatils,
  toggleSnackbar,
} from "../../ReactiveVariables/index";
import { useForm, Controller } from "react-hook-form";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormGroup from "@mui/material/FormGroup";
import { useMutation, useLazyQuery } from "@apollo/client/react/hooks";
import { UPDATE_CATERE } from "../../Graphql/mutations";
import { IMAGE_UPLOAD } from '../../Graphql/queries';
import CropImage from '../Common/cropImage';
import FormControlLabel from "@mui/material/FormControlLabel";
import { useReactiveVar } from "@apollo/client";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import axios from "axios";
import { postiveValueConverter } from'../Common/commonUtils';
import { useState, useRef, useMemo, useEffect } from "react";

const packageOptionsArray = [
  { id: "0", value: "Same Day Orders" },
  { id: "1", value: "Individually wrapped meals" },
  { id: "2", value: "Offers Packages" },
];

const StyledCheckbox = styled(Checkbox)`
  svg {
    color: #fd4e3b;
    font-size: 22px;
  }
`;
// we use material ui autocomplete location for fetching the location.
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function Business(props) {
  const currentData =
    props?.currentDetails?.getOneCaterer || props?.userValues?.updateCaterer;
  const user = useReactiveVar(userDeatils);
  const userPhone = (user?.data?.currentUserDetails?.phoneNumber || user?.data?.login?.phoneNumber || user?.data?.createCaterer?.phoneNumber);
  const [value, setValue] = useState(currentData?.locationName ?? null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cordinates, setCordinates] = useState(currentData?.location?.coordinates ?? '')
  const [zip, setZip] = useState(currentData?.catererZipCode ?? '')
  const [city, SetCity] = useState(currentData?.catererCity ?? '')
  const [stateShortName, setStateShortName] = useState(currentData?.catererStateShort ?? '')
  const [cropOpen, setCropOpen] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [cropImage, setCropImage] = useState(null);

  const [deliveryOptions, setDeliveryOptions] = useState(
    currentData?.deliveryOption ?? "Pickup and Delivery"
  );
  const [driverTip, setDriverTip] = useState(
    currentData?.driverTipFlag ?? "non-mandatory"
  );
  const [customfee, setCustomfee] = useState(
    currentData?.customDeliveryFlag ?? false
  );
  const [businessPhone, setBusinessPhone] = useState(
    currentData?.businessPhone || userPhone
  );
  const [sameEmail, setSameEmail] = useState(currentData?.sameEmail ?? false);

  const [selectedOptions, setSelectedOptions] = useState(
    currentData?.option ?? []
  );

  const [errorMsg,setErrorMsg] = useState("")
  const [emailArr, setEmailArr] = useState((currentData?.deliveryEmail && currentData?.deliveryEmail?.length > 0) ? currentData?.deliveryEmail : [""]);
  const [emailValidation, setEmailValidation] = useState(Array(emailArr.length).fill(""));

  const [tablewareCharged, setTablewareCharged] = useState(
    currentData?.tablewareCharged || false
  );

  const loaded = useRef(false);
  const schema = yup.object().shape({
    businessName: yup.string().required("Business Name is required"),
    locationName: yup.string().nullable().required("Location is required"),
    deliveryRadius: yup.number().required("Delivery Radius is required").min(1).typeError('Delivery Radius must be minimum 1'),
    businessPhone: yup.string().required("Phone is required").min(14, 'The phone number must be 10 digits.'),
     preprationTime: yup.string().required("Minimum preparation time is required")
     .test('isValidNumber', 'Please enter the valid time', function (value) {
      const regex = /^(?!0(\.0|\.00)?$)(\d*[0-9]\d*)?(\.25|\.50|\.5|\.75|\d+\.0|\d+\.00)?$/;
      return regex.test(value);
    }),
     tablewareAmount: yup.number().when('tablewareCharged', (_, schema) => {
      if (tablewareCharged) {
        return schema.transform(value => (isNaN(value) ? undefined : value))
        .required('Tableware price is required')
        .positive('Tableware price per person must be greater than 0')
      }

      return schema.transform(value => (isNaN(value) ? undefined : value)).notRequired();
    })
   });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue: newSetValue,
    clearErrors
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      businessPhone: currentData?.businessPhone || userPhone,
      deliveryFeeType: currentData?.deliveryFeeType ?? "Amount",
      convenienceFeeType: currentData?.convenienceFeeType ?? "Percentage",
      driverTipType: currentData?.driverTipType ?? "Percentage",
      locationName: currentData?.locationName,
      tablewareAmount: currentData?.tablewareAmount ?? "0",
      tablewareCharged: currentData?.tablewareCharged ?? false
    },
  });

  // Busines details mutation
  const [updateCaterer, { loading }] = useMutation(UPDATE_CATERE, {
    onCompleted: (res) => {
      props.setUserValues(res);
      props.setActiveStep(2);
    },
  });

  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/userprofile",
  };

  // image upload mutation
  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });

  // this function is for uploading the image
  function handleUploadClick(imagefile) {
    let obj = {};
    obj["fileName"] = imagefile.name;
    obj["url"] = URL.createObjectURL(imagefile);
    obj["file"] = imagefile;
    setSelectedImage(obj);
    upload();
  }

  //for chacking the image with certain criteria that we matches
  function checkImage(e) {
    let file = e.target.files[0];
    if (
      !(
        file?.type === "image/jpeg" ||
        file?.type === "image/png" ||
        file?.type === "image/jpg"
      )
    ) {
      toggleSnackbar({
        status: true,
        message: "This file format is not supported. Please upload only .png or .jpg files",
        variant: "error",
      });
      return false;
    } else if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    } else {
      setCropImage(file);
      setCropOpen(true);
    }
  }

  // this allows the us to call the upload image function after the crop is done.
  function handelCropDone(result) {
    setCropImage(null);
    handleUploadClick(result);
  }

  // function for getting the url  after the upload
  async function uploadImage(params) {
    setImageUploadLoading(true);
    if (selectedImage) {
      let url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }
    setImageUploadLoading(false);
    onSubmit(params)
  }

  // for autocomplete to use the key for genarating the location
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  // check whether the data enetered by the caterer is updated or not
  const businessfieldDataUpdated = (params) => {
    let imageUrlChanged = false
    if (currentData?.imageUrl || params.imageUrl) {
      if (!selectedImage) {
        imageUrlChanged = false
      }
      else if (currentData?.imageUrl !== params.imageUrl) {
        imageUrlChanged = true
      }
    }

    if (currentData?.option !== params.option || currentData?.businessName !== params.businessName
      || currentData?.tagLine !== params.tagLine || currentData?.businessPhone !== params.businessPhone
      || currentData?.contactNumber !== params.contactNumber || currentData?.sameEmail !== params.sameEmail
      || currentData?.locationName !== params.locationName || currentData?.catererZipCode !== params.catererZipCode
      || currentData?.tabValue !== params.tabValue || currentData?.minOrderAmt !== params.minOrderAmt
      || currentData?.driverTipFlag !== params.driverTipFlag || currentData?.driverTip !== params.driverTip
      || currentData?.driverTipType !== params.driverTipType || currentData?.preprationTime !== params.preprationTime
      || currentData?.deliveryFee !== params.deliveryFee || currentData?.deliveryFeeType !== params.deliveryFeeType
      || currentData?.deliveryRadius !== params.deliveryRadius || currentData?.deliveryOption !== params.deliveryOption
      || currentData?.customDeliveryFlag !== params.customDeliveryFlag || currentData?.deliveryEmail !== params.deliveryEmail
      || currentData?.tablewareCharged !== params.tablewareCharged || currentData?.tablewareAmount !== params.tablewareAmount
      || currentData?.tablewareAmount !== params.tablewareAmount
      || currentData?.specialDeliveryInstn !== params.specialDeliveryInstn || imageUrlChanged) {
      return true
    } else {
      return false
    }
  }

  // function for calling the update caterer mutation
  function onSubmit(params) {
    if (!params.driverTip && driverTip==="mandatory") {
      setErrorMsg("Driver tip is required")
      return
    }

    const location = {
      type: "Point",
      coordinates: cordinates
    };

    const { tablewareAmount, ...paramsRest } = params;

    const payload = {
      ...paramsRest,
      option: selectedOptions,
      location,
      minOrderAmt: isNaN(parseInt(params["minOrderAmt"])) ? 0 : parseInt(params["minOrderAmt"]),
      driverTip: isNaN(parseInt(params["driverTip"])) ? 0 : parseInt(params["driverTip"]),
      deliveryFee: isNaN(parseInt(params["deliveryFee"])) ? 0 : parseInt(params["deliveryFee"]),
      deliveryOption: deliveryOptions,
      customDeliveryFlag: customfee,
      deliveryEmail: emailArr,
      imageUrl: uploadImageData?.imageUpload?.publicUrl,
      catererZipCode: zip,
      tablewareCharged,
      ...(tablewareCharged && {
        tablewareAmount: params.tablewareAmount
      })
    };

    let dataUpdated = businessfieldDataUpdated(payload);

    if (dataUpdated) {
      try {
        updateCaterer({ variables: { id: props?.id, catererId: currentData?.catererUsers[0]?.catererId || props.catererId, data: payload } })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Business details saved successfully",
              variant: "success",
            });
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      props.setActiveStep(2);
    }
  }

  // function for the phone number formating
  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x =
        "(" +
        newNum.slice(0, 3) +
        ") " +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  };

  // for allowing only positive values
  const handleInputChangeTip = (event) => {
    setErrorMsg("")
    event.target.value = postiveValueConverter(event, 'withDecimal');
  };
  // for allowing only positive values
  const handleInputChange = (event) => {
    event.target.value = postiveValueConverter(event, 'withDecimal');
  };
  const handleInputChangeWithoutDecimal = (event) => {
    event.target.value = postiveValueConverter(event, 'withoutDecimal');
  };

  //for getting the zip from the location
  async function handleValueSelect(value) {
    SetCity(
        value?.terms?.length === 4 ?
        value?.terms[1]?.value :
        value?.terms[2]?.value === "FL" ?
            value?.terms[1]?.value :
            value?.terms[2]?.value
    )
    setStateShortName(
        value?.terms?.length === 4 ?
            value?.terms[2]?.value :
            value?.terms[2]?.value === "FL" ?
                value?.terms[2]?.value :
                value?.terms[3]?.value
    )
    setValue(value);
    var config = {
      method: "get",
      url: `https://maps.googleapis.com/maps/api/geocode/json?place_id=${value?.place_id}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        let cordArray = []
        let lat = response?.data?.results[0]?.geometry?.location?.lat
        let lng = response?.data?.results[0]?.geometry?.location?.lng
        let zip = ''
        cordArray.push(lng, lat)
        setCordinates(cordArray)
        let arr = response?.data?.results?.[0]?.address_components
        let position = arr.findIndex((element) => element.types && element.types[0] == "postal_code");
        if (position !== -1) {
          zip = response?.data?.results?.[0]?.address_components?.[position]?.["long_name"];
          setZip(zip)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const reglocation = register("locationName")

  return (
    <div className="create-step-form">
      <h5 className="h5">Business Details</h5>
      <p className="subhead">
      Add your business details
      </p>
      {/* this component helps in croping the image */}
      <CropImage
        open={cropOpen}
        setOpen={setCropOpen}
        image={cropImage}
        handelCropDone={handelCropDone}
        aspectRatio={16/9}
      />
      {/* for opening the file to upload the image */}
      <input type={"file"}
        className="d-none"
        id="add-user-input"
        accept='.jpg, .png, .jpeg'
        onChange={checkImage}
        onClick={(event) => {
          event.target.value = null;
        }}
      />

      <form onSubmit={handleSubmit(uploadImage)}>
        <div>
          <div className="row addBusinessSet">
            <div className='text-left'>
              <div className='upload-img-wrap'>
                <img
                  alt="Remy Sharp"
                  src={selectedImage ? selectedImage.url : currentData?.imageUrl ? currentData?.imageUrl :
                    "../assets/images/upload-image.svg"}
                  className={currentData?.imageUrl || selectedImage ? 'upload-img img-fluid' : 'upload-img img-fluid upload-no-image'}
                  onClick={(e) => document.getElementById("add-user-input").click()}
                />
                <img onClick={(e) => document.getElementById("add-user-input").click()} src='../assets/images/icon-upload-plus.svg' className='upload-icon img-fluid' />
              </div>
            </div>
            <div className="col-md-6 textBox">
              <TextField
                id="outlined-basic"
                autoFocus
                label="BUSINESS NAME*"
                defaultValue={currentData?.businessName}
                variant="outlined"
                className="textField allFeild"
                {...register("businessName")}
              />
              {errors.businessName && (
                <span className="error-msg">{errors.businessName.message}</span>
              )}
            </div>
            <div className="col-md-6 textBox">
              <TextField
                id="outlined-basic"
                label="TAGLINE"
                defaultValue={currentData?.tagLine}
                variant="outlined"
                className="textField allFeild"
                {...register("tagLine")}
                inputProps={{ maxLength: 40 }}
              />
              <p className="error-msg" style={{ color: "#9F9E9E" }}>Maximum 40 characters</p>
            </div>
            <div className="col-md-12 textBox">
              <Autocomplete
                className="textField allFeild"
                id="google-map-demo"
                sx={{ width: 300 }}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.description.replace(", USA", "")
                }
                noOptionsText={"Enter your location"}
                filterOptions={(x) => x}
                options={options ?? []}
                autoComplete
                includeInputInList
                filterSelectedOptions
                {...reglocation}
                value={value}
                onChange={(event, newValue) => {
                  setOptions(newValue && newValue != null ? [newValue, ...options] : options);
                  setValue(newValue?.description.replace(", USA", ""));
                  reglocation.onChange({ target: { value: newValue?.description.replace(", USA", ""), name: "locationName" } })
                  handleValueSelect(newValue)
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="LOCATION*" fullWidth />
                )}
                renderOption={(props, option) => {
                  const matches =
                    option?.structured_formatting?.main_text_matched_substrings;
                  if (!matches) return false
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [
                      match.offset,
                      match.offset + match.length,
                    ])
                  );

                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Box
                            component={LocationOnIcon}
                            sx={{ color: "text.secondary", mr: 2 }}
                          />
                        </Grid>
                        <Grid item xs>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}

                          <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
              {errors.locationName && (
                <span className="error-msg">{errors.locationName.message}</span>
              )}
            </div>
            <div className="col-md-12">
              <div className="row align-items-center">
                <div className="col-md-6 textBox ">
                  <TextField
                    id="outlined-basic"
                    label="DELIVERY CONTACT NUMBER*"
                    variant="outlined"
                    name="businessPhone"
                    className="textField allFeild"
                    value={businessPhone}
                    onChange={(e) => {
                      if (!isNaN(e.target.value.replace(/[-,(,), ]+/g, "")) && (e.target.value.replace(/[-,(,), ]+/g, "").length <= 10)) {
                        register("businessPhone").onChange({
                          target: { value: e.target.value, name: "businessPhone" },
                        });
                        setBusinessPhone(phoneNumberFormat(e.target.value));
                      }
                    }}
                  />
                  {errors.businessPhone && (
                    <span className="error-msg">{errors.businessPhone.message}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div>
            <hr />
          </div>
          <div className="addBusinessSet">
            {emailArr.map((item, index) => (
              <div className="row align-items-center">
                <div className="col-md-6 textBox">
                  <TextField
                    id="outlined-basic"
                    defaultValue={currentData?.deliveryEmail}
                    value={emailArr[index]}
                    type="email"
                    disabled={index === 0 && sameEmail}
                    label="DELIVERY EMAIL ADDRESS"
                    variant="outlined"
                    className="textField allFeild"
                    onChange={(e) => {
                      const email = e.target.value;
                      let arr = [...emailArr];
                      arr[index] = email;
                      setEmailArr(arr);
                      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                      const isValid = regex.test(email);

                      if (isValid) {
                        setEmailArr(arr);
                        setEmailValidation((prevState) => {
                          const newState = [...prevState];
                          newState[index] = "";
                          return newState;
                        });
                      } else {
                        setEmailValidation((prevState) => {
                          const newState = [...prevState];
                          newState[index] = "Please enter a valid email address";
                          return newState;
                        });
                      }

                    }}
                  />
                    {emailValidation[index] && (
                    <span className="error-msg">
                      {emailValidation[index]}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  {index !== 0 && (
                    <a
                      className="more-delivery-address-email remove"
                      onClick={() => {
                        let arr = [...emailArr];
                        arr.splice(index, 1);
                        setEmailArr(arr);
                      }}
                    >
                      Remove
                    </a>
                  )}
                </div>
              </div>
            ))}
            <div className="mb-3">
              <a
                className="more-delivery-address-email"
                onClick={() => {
                  let arr = [...emailArr];
                  arr.push("");
                  setEmailArr(arr);
                }}
              >
                Add more email addresses for delivery notifications
              </a>
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div className="row addBusinessSet">
            <h6 className="h6">Delivery Instructions</h6>
            <div className="col-md-12">
              <TextField
                id="outlined-basic"
                multiline
                defaultValue={currentData?.specialDeliveryInstn}
                label="Any special delivery instructions/any other information to share with the customer?"
                variant="outlined"
                className="textField allFeild"
                {...register("specialDeliveryInstn")}
              />
            </div>
            <h6 className="h6">Options</h6>
            {packageOptionsArray.map((field, index) => (
              <div className="col-md-4">
                <FormGroup key={field.id}>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={selectedOptions.includes(field.value)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            let arr = [...selectedOptions];
                            arr.push(field.value);
                            setSelectedOptions(arr);
                          } else {
                            let arr = [...selectedOptions];
                            let pos = arr.findIndex(
                              (element) => element === field.value
                            );
                            if (pos > -1) arr.splice(pos, 1);
                            setSelectedOptions(arr);
                          }
                        }}
                        color={"primary"}
                        icon={
                          <CheckCircleOutlineIcon
                            style={{ color: "#9F9E9E" }}
                          />
                        }
                        checkedIcon={<CheckCircleIcon />}
                      // {...others}
                      />
                    }
                    label={field.value}
                  />
                </FormGroup>
              </div>
            ))}
          </div>
          <div>
            <hr />
          </div>
          <div className="row addBusinessSet">
            <h6 className="h6">Minimum Order</h6>
            <div className="col-md-8">
              <TextField
                id="outlined-basic"
                label="MINIMUM ORDER AMOUNT"
                defaultValue={currentData?.minOrderAmt === 0 ? "" : currentData?.minOrderAmt}
                variant="outlined"
                className="textField allFeild"
                {...register("minOrderAmt")}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </div>
            <h6 className="h6">Delivery Fee</h6>
            <div className="col-md-4">
              <Controller
                control={control}
                name="deliveryFeeType"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    label="DELIVERY FEE"
                    // type={"number"}
                    variant="outlined"
                    defaultValue={currentData?.deliveryFee === 0 ? "" : currentData?.deliveryFee}
                    className="textField allFeild"
                    {...register("deliveryFee")}
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: value === "Percentage" && (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      startAdornment: value === "Amount" && (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="col-md-4">
              <FormControl fullWidth className="textField allFeild">
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Controller
                  control={control}
                  name="deliveryFeeType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={value}
                      label="Type"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    >
                      <MenuItem value={"Percentage"}>Percentage</MenuItem>
                      <MenuItem value={"Amount"}>Amount</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </div>
            <div>
              <hr />
            </div>
            <div className="addBusinessSet">
              <h6 className="h6">Delivery Radius</h6>
              <div className="col-md-8 textBox">
                <TextField
                  id="outlined-basic"
                  // type={"number"}
                  label="MILES*"
                  variant="outlined"
                  className="textField allFeild"
                  {...register("deliveryRadius")}
                  onChange={handleInputChangeWithoutDecimal}
                />
                {errors.deliveryRadius && (
                    <span className="error-msg">{errors.deliveryRadius.message}</span>
                )}
              </div>
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div className="row addBusinessSet">
            <h6 className="h6">Driver Tip</h6>
            <div className="row">
              <div className="col-md-3">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={driverTip === "non-mandatory" ? true : false}
                        onChange={() => setDriverTip("non-mandatory")}
                        color={"primary"}
                        icon={
                          <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                        }
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label="Non-mandatory"
                  />
                </FormGroup>
              </div>
              <div className="col-md-3">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={driverTip === "mandatory" ? true : false}
                        onChange={() => setDriverTip("mandatory")}
                        color={"primary"}
                        icon={
                          <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                        }
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label="Mandatory"
                  />
                </FormGroup>
              </div>
            </div>
            {driverTip === "mandatory" && (
              <div className="col-md-4 textBox">
                <Controller
                  control={control}
                  name="driverTipType"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      label="DRIVER TIP"
                      defaultValue={currentData?.driverTip === 0 ? "" : currentData?.driverTip}
                      variant="outlined"
                      className="textField allFeild"
                      {...register("driverTip")}
                      onChange={handleInputChangeTip}
                      InputProps={{
                        endAdornment: value === "Percentage" && (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        startAdornment: value === "Amount" && (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                {errorMsg && (
                  <span className="error-msg">{errorMsg}</span>
                )}
              </div>
            )}
            {driverTip === "mandatory" && (
              <div className="col-4">
                <FormControl fullWidth className="textField allFeild">
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Controller
                    control={control}
                    name="driverTipType"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Age"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <MenuItem value={"Percentage"}>Percentage</MenuItem>
                        <MenuItem value={"Amount"}>Amount</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            )}
          </div>
          <div>
            <hr />
          </div>
          <div className="row addBusinessSet">
            <h6 className="h6">Additional Charge for Tableware</h6>
            <div className="row">
              <div className="col-md-3">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={tablewareCharged}
                        onChange={() => setTablewareCharged(true)}
                        color={"primary"}
                        icon={
                          <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                        }
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label="Yes"
                  />
                </FormGroup>
              </div>
              <div className="col-md-3">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={!tablewareCharged}
                        onChange={() => setTablewareCharged(false)}
                        color={"primary"}
                        icon={
                          <CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />
                        }
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label="No"
                  />
                </FormGroup>
              </div>
            </div>

            { tablewareCharged &&
              <div className="row">
                <div className="col-md-4 textBox">
                  <Controller
                    control={control}
                    name="tablewareAmount"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="outlined-basic"
                        label="PRICE"
                        defaultValue={currentData?.tablewareAmount}
                        value={value}
                        variant="outlined"
                        className="textField allFeild"
                        {...register("tablewareAmount")}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          endAdornment: <InputAdornment position="end">Per Person</InputAdornment>
                        }}
                      />
                    )}
                  />
                  {errors.tablewareAmount && (
                    <span className="error-msg">{errors.tablewareAmount.message}</span>
                  )}
                </div>
              </div>
            }
          </div>
          <div>
            <hr />
          </div>
          <div className="row addBusinessSet">
            <h6 className="h6">Preparation Time</h6>
            <div className="col-md-6 textBox">
              <TextField
                id="outlined-basic"
                defaultValue={currentData?.preprationTime === "0" ? "" : currentData?.preprationTime}
                label="MINIMUM PREPARATION TIME*"
                variant="outlined"
                className="textField allFeild"
                {...register("preprationTime", {
                  onChange: handleInputChange,
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">HRS</InputAdornment>
                  )
                }}
              />
               {errors?.preprationTime && (
                <span className="error-msg" >{errors?.preprationTime?.message}</span>
              )}
              <p className="error-msg" style={{ color: "#9F9E9E",right: "12px" }}>1.5 hours = 90 minutes</p>
            </div>
            <div className="col-12">
              <div className="createStepBtn justify-content-end">
                <Button
                  className="saveBtn"
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  {loading || imageUploadLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Save and Proceed"
                  )}{" "}
                  <ArrowForwardIcon className="btnArrow" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
