export const CATERER_SLIDER_RESPONSIVENESS = [
  {
    breakpoint: 1090,
    settings: {
      slidesToShow: 7,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 1,
      infinite: false,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
];
