import { Skeleton } from "@mui/material";
import { deliveryFeeformatter, formatter } from "Modules/Common/commonUtils";
import React, { useState } from "react";
import { Link } from "react-router-dom";

interface ICatererItemProps {
  item: any;
  urlData: any;
  filterData: any;
  retainFilter(d: any): void;
}

export function CatererItem({ item, urlData, filterData, retainFilter }: ICatererItemProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  const onImageLoad = () => {
    setIsLoaded(true);
  };
  return (
    <div className="col-md-6 col-lg-4 cater-list-item-outer">
      <Link to={`${item?.slug}`} state={urlData}>
        <div className="cater-list-item" onClick={() => retainFilter(filterData)}>
          <div className="cater-list-item-img">
            {!isLoaded && (
              <Skeleton variant="rectangular" width="100%">
                <div style={{ paddingTop: "60%" }} />
              </Skeleton>
            )}
            <img
              onLoad={onImageLoad}
              style={{ height: !isLoaded && "0" }}
              src={
                item?.imageUrl === "#"
                  ? "../assets/images/home.jpg"
                  : item?.imageUrl
                  ? item?.imageUrl
                  : "../assets/images/home.jpg"
              }
              className="img-fluid"
              alt="Cater"
            />
            {item?.caterCash && (
              <div className="cater-cash-tag">
                <b>{`${item?.caterCash}%`}</b>
                <label>CaterCash</label>
              </div>
            )}
          </div>
          <div className="cater-list-item-cnt">
            <h4>
              {item?.businessName}
              {item?.totalRating?.starRate > 0 && (
                <label>
                  <img src="../assets/images/star-rating.svg" className="img-fluid" alt="Rating" />
                  <span>
                    {item?.totalRating?.starRate.toFixed(1)}{" "}
                    <small>({item?.totalRating?.count})</small>
                  </span>
                </label>
              )}
            </h4>
            <h6>{item?.tagLine}</h6>
            <div className="cater-list-order">
              <span>
                <img
                  src="../assets/images/icon-dollar-sign.svg"
                  className="img-fluid"
                  alt="Icon Dollar"
                />
                Min Ord: {formatter.format(item?.minOrderAmt)}
              </span>
              <span>
                <img src="../assets/images/icon-truck.svg" className="img-fluid" alt="Icon Truck" />
                Delivery Fee: {deliveryFeeformatter(item)}
              </span>
              <span>
                <img
                  src="../assets/images/icon-map-pin.svg"
                  className="img-fluid"
                  alt="Icon Truck"
                />
                Est. {item?.distance === 0 ? item?.distance : item?.distance?.toFixed(2)} mi
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
