import React from "react";
import {
  IAddonTypeItemResponse,
  IAddonTypeResponse,
  ISubAddonItemResponse,
} from "../../cart-item.typings";
import { CartItemAddonItem } from "./cart-item-addon-item";

interface ICartItemAddonsProps {
  addonType: IAddonTypeResponse;
  onSelectAddon(isChecked: boolean, type: IAddonTypeResponse, addon: IAddonTypeItemResponse): void;
  isAddonCheckboxDisabled(type: IAddonTypeResponse, addon: IAddonTypeItemResponse): boolean;
  isAddonSelected(addon: IAddonTypeItemResponse): boolean;
  isSubAddonSelected(
    addon: IAddonTypeItemResponse,
    subAddon: ISubAddonItemResponse,
  ): boolean;
  onSelectSubAddon(
    isChecked: boolean,
    addon: IAddonTypeItemResponse,
    subAddon: ISubAddonItemResponse,
  ): void;
}

export function CartItemAddon({
  addonType,
  onSelectAddon,
  isAddonCheckboxDisabled,
  isAddonSelected,
  isSubAddonSelected,
  onSelectSubAddon,
}: ICartItemAddonsProps) {
  if (!addonType.addOnsList.length) {
    return null;
  }
  return (
    <div>
      <h5 className="h5">{addonType.addOnTypeName}</h5>
      <small>
        Choose{" "}
        {addonType.type === "Single"
          ? 1
          : `Minimum of 1 and maximum of ${addonType.addOnTypeLimit}`}
      </small>
      <div className="row">
        {addonType.addOnsList.map((addon, addonKey) => (
          <CartItemAddonItem
            key={addonKey}
            addon={addon}
            type={addonType}
            onSelectAddon={onSelectAddon}
            isAddonCheckboxDisabled={isAddonCheckboxDisabled}
            isAddonSelected={isAddonSelected}
            isSubAddonSelected={isSubAddonSelected}
            onSelectSubAddon={onSelectSubAddon}
          />
        ))}
      </div>
    </div>
  );
}
