// this drawer is for editing the options
import React from 'react';

import Drawer from '@mui/material/Drawer';
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, FormGroup, FormControlLabel, CircularProgress } from "@mui/material";
import { UPDATE_CATERE } from '../../../Graphql/mutations';
import { useMutation } from '@apollo/client';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { styled } from "@mui/material/styles";
import { toggleSnackbar,authVar,userDeatils } from "../../../ReactiveVariables/index";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
const packageOptionsArray = [
  { id: "0", value: "Allows same day orders" },
  { id: "1", value: "Individually wrapped meals" },
  { id: "2", value: "Offers packages" },
  // { id: "3", value: "Disposable plates/utensils and napkins" },
];
const StyledCheckbox = styled(Checkbox)`
  svg {
    color: #fd4e3b;
    font-size: 22px;
  }
  `;
export default function OptionDetails(props) {
  const navigate= useNavigate()
  const user = useReactiveVar(userDeatils);
  const userType =
  user?.data?.login?.userType || user?.data?.currentUserDetails?.userType || user?.data?.createUserIdentity?.userType || user?.data?.createCaterer?.userType;
  const currentData = props?.data?.getOneCaterer
  // getting the checked options
  const [selectedOptions, setSelectedOptions] = useState(
    currentData?.option ?? []
  );
  const [updateCaterer, { loading }] = useMutation(UPDATE_CATERE, { refetchQueries:['GetOneCaterer'],
    onCompleted: (res) => {
      props?.setOpenOptions(false)
    }
  });
  function UpdateOptions(params) {
    let data = {
      option: selectedOptions
    }
    try {
      updateCaterer({
        variables: {
          id: props?.userData?._id,
          catererId: props?.data?.getOneCaterer?.catererUsers[0]?.catererId || props?.userData?.catererId,
          data: data,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Options updated successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <Drawer className='common-drawer'
      anchor={"right"}
      open={props.openOptions}
      onClose={() => props.setOpenOptions(false)}
    >

      <div className='drawer-head'>
        <h4 className="h4"><img src="../assets/images/icon-option.svg" className="img-fluid" alt="Options Icon" />Options</h4><span style={{cursor:'pointer'}} onClick={()=>props.setOpenOptions(false)}><img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" /></span>
      </div>
      <div className='create-step-form'>
        <div className='container-fluid'>
          {packageOptionsArray.map((field, index) => {
            return (
              <div className="ld-options ld-options-edit">
                <FormGroup key={field.id}>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={selectedOptions.includes(field.value)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            let arr = [...selectedOptions];
                            arr.push(field.value);
                            setSelectedOptions(arr);
                          } else {
                            let arr = [...selectedOptions];
                            let pos = arr.findIndex(
                              (element) => element === field.value
                            );
                            if (pos > -1) arr.splice(pos, 1);
                            setSelectedOptions(arr);
                          }
                        }}
                        color={"primary"}
                        icon={
                          <CheckCircleOutlineIcon
                            style={{ color: "#9F9E9E" }}
                          />
                        }
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label={field.value}
                  />
                </FormGroup>
              </div>
            )
          })}
        </div>
      </div>
      <div className="createStepBtn">
        <Button
          className="cancelBtn"
          variant="contained"
          color="secondary"
          onClick={() => props.setOpenOptions(false)}
        >
          Cancel
        </Button>
        <Button
          className="saveBtn"
          variant="contained"
          color="primary"
          onClick={() => UpdateOptions()}
        >
          {loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            "Update"
          )}
        </Button>
      </div>

    </Drawer>
  )
}