import React from "react";
import { CartDietary } from "../cart-item.typings";
import { formatter } from "Modules/Common/commonUtils";

interface ICartItemHeader {
  name: string;
  dietary: CartDietary;
  minQuantity: number;
  type: string;
  price: number;
  onClose(): void;
}

export function CartItemHeader({
  name,
  dietary,
  minQuantity,
  type,
  price,
  onClose,
}: ICartItemHeader) {
  return (
    <div className="caterer-menu-header">
      <div>
        <div className="d-md-flex align-items-center">
          <h5 className="h5">{name}</h5>
          <label className="dietry-label">
            {dietary !== CartDietary.glutenFree && dietary !== CartDietary.none && (
              <img
                src={
                  dietary === CartDietary.nonVegetarian ||
                  dietary === CartDietary.nonVegetarianGlutenFree
                    ? "../assets/images/nonveg.svg"
                    : dietary === CartDietary.vegetarian ||
                      dietary === CartDietary.vegetarianGlutenFree
                    ? "../assets/images/veg.svg"
                    : ""
                }
                className="img-fluid"
                alt="Icon"
              />
            )}
            {dietary !== CartDietary.none && (
              <span>
                {dietary === CartDietary.nonVegetarian
                  ? "Non-Veg"
                  : dietary === CartDietary.vegetarian
                  ? "Veg"
                  : dietary}
              </span>
            )}
          </label>
        </div>
        <small>
          {formatter.format(price)}/{type === "Tray" ? "Tray" : "Person"}
          {type === "Tray" ? "" : `, Minimum ${minQuantity} Person`}
        </small>
      </div>
      <span style={{ cursor: "pointer" }} onClick={onClose}>
        <img
          src="../assets/images/modal-close.svg"
          className="img-fluid caterer-menu-close d-block"
          alt="close icon"
        />
      </span>
    </div>
  );
}
