//listing the latest caterers
import React from 'react'

import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
export default function LatestCaterers(props) {
  return (
    <div className="cater-summary">
      <h4 className="h4">
        <img
          src="../assets/images/icon-delivery-hours.svg"
          className="img-fluid"
          alt="Latest Caterers Icon"
        />
        Latest Caterers
      </h4>
      <TableContainer>
        <Table
          className="commonTabel"
          // sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Profile Image</TableCell>
              <TableCell align="left">Contact Person</TableCell>
              <TableCell align="left">Business Name </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.loading ? (
              <TableRow>
                <TableCell colSpan={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p> Loading...</p>
                  </div>
                </TableCell>
              </TableRow>
            ) : props?.catererListData?.listCaterer?.data.length > 0 ? (
              props?.catererListData?.listCaterer?.data.map((item) => (
                <TableRow style={{ cursor: "pointer" }}>
                  <TableCell>
                    {
                      <img
                        src={item?.imageUrl===""?"../assets/images/default-login-img.png":
                          item?.imageUrl ??
                          "../assets/images/default-login-img.png"
                        }
                        className="img-fluid menu-list-img"
                        alt={item?.itemName}
                      />
                    }
                  </TableCell>
                  <TableCell style={{textTransform:'capitalize'}}>{item?.catererUsers[0]?.userDtls?.name}</TableCell>
                  <TableCell>{item?.businessName}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>No list added Yet!</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
