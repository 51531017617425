import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { authVar, toggleSnackbar, userDeatils } from "../../ReactiveVariables";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_CATERER_NOTE,
  GET_CUSTOMER_NOTE,
  UPDATE_CATERER_NOTE,
  UPDATE_CUSTOMER_NOTE,
} from "./notes.mutations";
import { ADMIN_NOTE_TYPES } from "./note.constants";

export default function Note({ type }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [adminNote, setAdminNote] = useState("");

  const query = type === ADMIN_NOTE_TYPES.customer ? GET_CUSTOMER_NOTE : GET_CATERER_NOTE;
  const mutation = type === ADMIN_NOTE_TYPES.customer ? UPDATE_CUSTOMER_NOTE : UPDATE_CATERER_NOTE;

  const [postAdminNote, { loading: isPostLoading }] = useMutation(mutation);

  const { id } = useParams();

  const { data, loading: isGetLoading } = useQuery(query, {
    variables: {
      id,
      agsType: "id",
    },
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      const note =
        type === ADMIN_NOTE_TYPES.customer ? res.getOneUser.adminNote : res.getOneCaterer.adminNote;
      if (!note) {
        return;
      }
      setAdminNote(note);
    },
  });

  const user = useReactiveVar(userDeatils);

  const userType = user?.data?.currentUserDetails?.userType;

  const navigate = useNavigate();

  const handleNotes = (event) => {
    setAdminNote(event.target.value);
    setErrorMessage("");
  };

  function postCatererNote() {
    return postAdminNote({
      variables: {
        data: {
          adminNote,
        },
        id,
        catererId: id,
      },
    });
  }

  function postCustomerNote() {
    return postAdminNote({
      variables: {
        data: {
          adminNote,
          _id: id,
        },
        id,
      },
    });
  }

  function onSubmit() {
    if (!adminNote) {
      setErrorMessage("Add a note");
      return;
    }
    try {
      const mutate = type === ADMIN_NOTE_TYPES.customer ? postCustomerNote : postCatererNote;
      mutate()
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Business details updated successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;

          if (message === "Not authenticated") {
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            userType === "Admin"
              ? navigate("/admin", { state: "noAuth" })
              : navigate("/login", { state: "noAuth" });
          } else {
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
        });
    } catch (err) {
      const message = err && err.message;

      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  return (
    <div className="contentWrapInner">
      <div className="d-flex flex-column order-status">
        <h4 className="h4">Note</h4>
        <div className="os-note">
          <TextField
            id="outlined-multiline-flexible"
            label={isGetLoading ? "Loading..." : "Admin Note"}
            multiline
            rows={4}
            value={adminNote}
            onChange={handleNotes}
            disabled={isGetLoading || isPostLoading}
          />
          {errorMessage && <span className="error-msg">{errorMessage}</span>}
          <div className="text-end">
            <Button
              onClick={onSubmit}
              disabled={isGetLoading || isPostLoading}
              variant="contained"
              className="os-add-note-btn"
            >
              {isPostLoading ? <CircularProgress color="inherit" size={20} /> : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
