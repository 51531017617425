import React from "react";
import Slider from "react-slick";
import { useQuery } from "@apollo/client";

import { CatererCategoryItem } from "./caterer-category-item";

import { CATERER_SLIDER_RESPONSIVENESS } from "./caterer-categories.constants";
import { LIST_CATEGORY_SORT } from "../../../../Graphql/queries";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PrevArrow({ onClick }: any) {
  return (
    <div className="slick-prev menu-category-arrow">
      <h1>PREV</h1>
      <img
        onClick={onClick}
        className="slick-next-img"
        src="../assets/images/category-item-arrow-left.svg"
      />
    </div>
  );
}

function NextArrow({ onClick }: any) {
  return (
    <div className="slick-next menu-category-arrow">
      <img
        onClick={onClick}
        className="slick-next-img"
        src="../assets/images/category-item-arrow-right.svg"
      />
    </div>
  );
}

interface ICatererCategoriesProps {
  catererId: string;
  selectedCategory: string;
  onSelectCategory(value: string): void;
}

export function CatererCategories({
  catererId,
  selectedCategory,
  onSelectCategory,
}: ICatererCategoriesProps) {
  const { data: categoryData } = useQuery(LIST_CATEGORY_SORT, {
    fetchPolicy: "network-only",
    variables: { catererId, sortField: "CategoryOrder" },
    skip: !catererId,
    onCompleted: (res) => {
      onSelectCategory(res?.listCategoryOrder?.data[0]?.categoryId);
    },
  });

  const settings = {
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Slider
      className="menu-category"
      dots={false}
      arrows={true}
      infinite={false}
      speed={500}
      slidesToShow={categoryData?.listCategoryOrder?.data.length >= 9 ? 12 : 9}
      slidesToScroll={1}
      cssEase="linear"
      variableWidth={true}
      responsive={CATERER_SLIDER_RESPONSIVENESS}
      {...settings}>
      {categoryData?.listCategoryOrder?.data?.map((item, idx) => (
        <CatererCategoryItem
          key={`menu-category-item-${idx}`}
          category={item}
          isActive={selectedCategory === item.categoryId}
          onSelectCategory={onSelectCategory}
        />
      ))}
    </Slider>
  );
}
