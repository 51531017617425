import React from "react";

import { Button, Modal, CircularProgress } from "@mui/material";

import { useCartModalState } from "./cart-item-modal.state";
import { CartItemQuantity } from "./components/cart-item-quantity";
import { CartItemHeader } from "./components/cart-item-header";
import { CartItemInstruction } from "./components/cart-item-instructions";
import { CartItemAddon } from "./components/cart-item-addon";

import { formatter } from "../../../../Modules/Common/commonUtils";

import { ICartItem } from "@services/redux/order";

interface ICartItemModalProps {
  menuId: string;
  cartItem: ICartItem;
  cartIndex: number;
  customerId: string;
  cart: ICartItem[];
  isOpen: boolean;
  onClose(): void;
  onUpdateCart(cart: ICartItem[]);
}

export function CartItemModal({
  isOpen,
  cartItem,
  cartIndex,
  customerId,
  menuId,
  cart,
  onUpdateCart,
  onClose,
}: ICartItemModalProps) {
  if (!isOpen) {
    return null;
  }
  
  const {
    name,
    dietary,
    type,
    price,
    itemDescription,
    isIndividualPackage,
    addOnTypeList,
    instructions,
    quantity,
    totalPrice,
    minQuantity,
    isLoading,
    onChangeQuantity,
    onDecrement,
    onIncrement,
    setInstruction,
    isAddonCheckboxDisabled,
    isAddonSelected,
    onSelectAddon,
    isSubAddonSelected,
    onSelectSubAddon,
    onSaveCart,
  } = useCartModalState({
    cart,
    cartItem,
    cartIndex,
    menuId,
    customerId: customerId,
    onUpdateCart,
    onClose,
  });

  return (
    <div>
      <Modal
        className="caterer-menu-modal"
        open={isOpen}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center circle-loader"
            style={{ height: "100vh" }}>
            <CircularProgress />
          </div>
        ) : (
          <div className="caterer-menu-details">
            <CartItemHeader
              name={name}
              dietary={dietary}
              minQuantity={minQuantity}
              type={type}
              price={price}
              onClose={onClose}
            />
            <div className="caterer-menu-content">
              <p className="caterer-menu-description">{itemDescription}</p>
              {isIndividualPackage && <p>individually packaged</p>}

              {addOnTypeList.map((addonType, addonTypeKey) => (
                <CartItemAddon
                  key={addonTypeKey}
                  addonType={addonType}
                  onSelectAddon={onSelectAddon}
                  isAddonCheckboxDisabled={isAddonCheckboxDisabled}
                  isAddonSelected={isAddonSelected}
                  isSubAddonSelected={isSubAddonSelected}
                  onSelectSubAddon={onSelectSubAddon}
                />
              ))}
              <CartItemInstruction instruction={instructions} setInstruction={setInstruction} />
            </div>
            <div className="caterer-menu-footer">
              <CartItemQuantity
                quantity={quantity}
                minQuantity={minQuantity}
                onChangeQuantity={onChangeQuantity}
                onDecrement={onDecrement}
                onIncrement={onIncrement}
              />
              <Button
                disabled={quantity <= 0 || isNaN(quantity)}
                className="cart-price-btn"
                variant="contained"
                color="primary"
                type="submit"
                onClick={onSaveCart}>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : !!cartItem ? (
                  "Update Cart"
                ) : (
                  "Add to Cart"
                )}
                {quantity >= 0 && (
                  <span className="cart-price">{formatter.format(totalPrice)}</span>
                )}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
