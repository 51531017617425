//listing the customers
import React from 'react'

import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
export default function CustomerLists(props) {
  return (
    <div className="cater-summary">
      <h4 className="h4">
        <img
          src="../assets/images/icon-delivery-hours.svg"
          className="img-fluid"
          alt="Customers Icon"
        />
        Customers
      </h4>
      <TableContainer>
        <Table
          className="commonTabel customer-dashboard"
          // sx={{ maxWidth: "100%" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Customer Name</TableCell>
              <TableCell align="right">Mobile Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.loading ? (
              <TableRow>
                <TableCell colSpan={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p> Loading...</p>
                  </div>
                </TableCell>
              </TableRow>
            ) : props?.customerData?.listUser?.data.length > 0 ? (
              props?.customerData?.listUser?.data.map((item) => (
                <TableRow style={{ cursor: "pointer" }}>
                  <TableCell style={{textTransform:'capitalize'}}>{item?.name}</TableCell>
                  <TableCell align="right">{item?.phoneNumber}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>No list added Yet!</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
