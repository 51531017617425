import { useLazyQuery, useMutation } from "@apollo/client";
import { ORDER_STATUS } from "@constants/order.constants";
import { ROUTES } from "@constants/routes.constants";
import { IOrderResponseItem } from "@screens/orders/order.typings";
import { useAppDispatch } from "@services/redux";
import { addUnavailableCartItems, updateDraftOrder, updateEditOrder } from "@services/redux/order";
import { formatServerOrder } from "@services/redux/order/order.utils";
import { useLogout } from "@utils/hooks/use-logout";
import {
  CONTINUE_DRAFT,
  DELETE_ALL_CART,
  REORDER_EXIST,
  UPDATE_ORDER_STATUS,
} from "Graphql/mutations";
import { GET_ONE_ORDER, LIST_ALL_ORDERS } from "Graphql/queries";
import { checkBrowser } from "Modules/Common/commonUtils";
import { toggleSnackbar } from "ReactiveVariables";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router";
import { IGetOrderResponse, IReOrderResponse } from "typings/order.api";

interface IOrderItemStateProps {
  orderState: any;
  customerId: string;
  userType: string;
  order: IOrderResponseItem;
}

export function useOrderItemState({
  customerId,
  orderState,
  userType,
  order,
}: IOrderItemStateProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalMode, setModalMode] = useState<"delete" | "cancel" | false>(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { onLogout } = useLogout({
    userType,
  });

  const [getOrderRequest] = useLazyQuery<IGetOrderResponse>(GET_ONE_ORDER);
  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS, {
    refetchQueries: [LIST_ALL_ORDERS],
  });
  const [deleteCart] = useMutation(DELETE_ALL_CART, {
    refetchQueries: [LIST_ALL_ORDERS],
  });

  const [continueDraftOrder] = useMutation(CONTINUE_DRAFT);

  const [reorderExist] = useMutation<IReOrderResponse>(REORDER_EXIST);

  function onOpenMenuItem(event) {
    setAnchorEl(event.currentTarget);
  }

  function onCloseMenu() {
    setAnchorEl(null);
  }

  function onViewOrder(id: string) {
    if (isLoading) {
      return;
    }
    navigate(`${ROUTES.orders}/${id}`, {
      state: orderState,
    });
  }

  function onDeleteOrder() {
    setModalMode("delete");
  }

  function onCancelOrder() {
    setModalMode("cancel");
  }

  function handleCatchError(e: any) {
    const message = e && e.message;
    if (message === "Not authenticated") {
      return onLogout();
    } else {
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  async function onReOrder() {
    const userAgent = navigator.userAgent;
    setIsLoading(true);
    try {
      const response = await reorderExist({
        variables: {
          orderId: order._id,
          browserName: checkBrowser(userAgent),
          userAgent,
        },
      });
      const orderResponse = await getOrderRequest({
        variables: {
          customerId,
          orderId: response.data.reOrderExist._id,
        },
      });

      const newOrder = formatServerOrder(orderResponse.data.getOneOrder);
      dispatch(addUnavailableCartItems(response.data.reOrderExist.removedMenuName));
      dispatch(updateDraftOrder(newOrder));
      navigate(`/caterers/${newOrder.catererSlug}`);
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function onEditOrder() {
    try {
      setIsLoading(true);
      const response = await getOrderRequest({
        variables: {
          customerId: order.customerId,
          orderId: order._id,
        },
      });
      const newOrder = formatServerOrder(response.data.getOneOrder);
      dispatch(updateEditOrder(newOrder));
      navigate(`/caterers/${order.catererSlug}?edit=true`);
    } catch (err) {
      handleCatchError(err);
    } finally {
      setAnchorEl(null);
      setIsLoading(false);
    }
  }

  async function onConfirmDeleteOrder() {
    try {
      setIsLoading(true);
      const response = await deleteCart({ variables: { orderId: order._id } });
      if (!response.data.deleteOrder) {
        return toggleSnackbar({
          status: true,
          message: "Cannot be deleted",
          variant: "error",
        });
      }
      toggleSnackbar({
        status: true,
        message: "Order deleted successfully",
        variant: "success",
      });
    } catch (err) {
      handleCatchError(err);
    } finally {
      setIsLoading(false);
      setAnchorEl(null);
    }
  }

  async function onConfirmCancelOrder() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      setIsLoading(true);
      await updateOrderStatus({
        variables: {
          data: {
            orderId: order._id,
            oldStatus: order.orderStatus,
            orderStatus: ORDER_STATUS.canceled,
            statusChangeDate: moment(),
            statusTimeZone: timeZone,
          },
        },
      });
      toggleSnackbar({
        status: true,
        message: "Order cancelled successfully",
        variant: "success",
      });
    } catch (err) {
      handleCatchError(err);
    } finally {
      setIsLoading(false);
      setAnchorEl(null);
    }
  }

  async function onContinueDraftOrder() {
    try {
      setIsLoading(true);
      const res = await continueDraftOrder({
        variables: {
          orderId: order._id,
          orderType: "continueOrder",
          browserName: checkBrowser(navigator.userAgent),
          userAgent: navigator.userAgent,
        },
      });
      const response = await getOrderRequest({
        variables: {
          customerId,
          orderId: res?.data?.continueDraftOrder?._id,
        },
      });
      const newOrder = formatServerOrder(response.data.getOneOrder);
      dispatch(updateDraftOrder(newOrder));
      navigate(`/caterers/${res?.data?.continueDraftOrder?.catererSlug}`);
    } catch (err) {
      handleCatchError(err);
    } finally {
      setIsLoading(false);
      setAnchorEl(null);
    }
  }

  function onCloseModal() {
    setModalMode(false);
    setAnchorEl(null);
  }

  function onViewCustomer() {
    if (isLoading) {
      return;
    }
    if (userType === "Admin") {
      return navigate(`/customers/${order.customerId}`);
    }
    onViewOrder(order._id);
  }

  return {
    isLoading,
    anchorEl,
    modalMode,
    onCloseModal,
    onOpenMenuItem,
    onCloseMenu,
    onViewOrder,
    onEditOrder,
    onReOrder,
    onDeleteOrder,
    onCancelOrder,
    onConfirmCancelOrder,
    onConfirmDeleteOrder,
    onContinueDraftOrder,
    onViewCustomer,
  };
}
