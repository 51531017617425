import React from "react";

import { useController } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  styled,
  Checkbox,
} from "@mui/material";
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";

const StyledCheckbox = styled(Checkbox)`
  svg {
    font-size: 22px;
  }
`;

export function CheckboxForm({ label, control, name, rules, ...props }) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });

  return (
    <FormGroup>
      <FormControl fullWidth error={error ? !!error.message : false}>
        <FormControlLabel
          control={
            <StyledCheckbox
              {...props}
              checked={value}
              onChange={onChange}
              color={"primary"}
              icon={<CheckCircleOutline style={{ color: "#9F9E9E" }} />}
              checkedIcon={<CheckCircle />}
            />
          }
          label={<p>{label}</p>}
        />
        <FormHelperText>{error ? error.message : ""}</FormHelperText>
      </FormControl>
    </FormGroup>
  );
}
