import React from "react";
import { Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { userDeatils } from "../../ReactiveVariables/index";
import { SIDEBAR_TAB_PARAMS, USER_TAB_MAP } from "@constants/tab.constants";
import { USER_TYPE } from "@constants/user.constants";
import { getUserType } from "@utils/user-format.utils";

interface ISidebarProps {
  selected?: string;
  user?: string;
}

export default function Sidebar(props: ISidebarProps) {
  const user = useReactiveVar(userDeatils);
  const navigate = useNavigate();

  const userType: USER_TYPE | null = getUserType(user);

  const tabs = userType ? USER_TAB_MAP[userType] : [];

  return (
    <Box>
      <nav className="sideMenu">
        {tabs.map((item, index) => {
          const params = SIDEBAR_TAB_PARAMS[item];
          return (
            <List disablePadding key={index}>
              <ListItem disablePadding>
                <ListItemButton
                  className="sideMenuItem"
                  selected={item && props.selected === item}
                  onClick={() => navigate(params.url)}>
                  <img
                    src={item && props.selected === item ? params.icon : params.iconActive}
                    alt="menu icon"
                  />
                  <ListItemText className="sideMenuItemText" primary={item} />
                </ListItemButton>
              </ListItem>
            </List>
          );
        })}
      </nav>
    </Box>
  );
}
