//this page is for showing all the promocodes
import React, { useState } from "react";
import SideMenu from "../../../components/organisms/sidebar";
import LoggedHeader from "../../Common/loggedHeader";
import { Table, Select, MenuItem, Button, Menu } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatter } from "../../Common/commonUtils";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_SETTINGS } from "../../../Graphql/queries";
import { DELETE_PROMOCODE } from "../../../Graphql/mutations";
import Pagination from "@mui/material/Pagination";
import { toggleSnackbar } from "../../../ReactiveVariables/index";
import Stack from "@mui/material/Stack";
import DialogBox from "../../Common/dialogBox";
import moment from "moment";
import EditSettings from "./editSettings";
export default function Settings() {
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [openSettings, setSettings] = useState(false);
  const open = Boolean(anchorEl);
  const settingVariable = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
  };
  const { data, loading } = useQuery(LIST_SETTINGS, {
    variables: { data:settingVariable },
  });

  const handleChangePage = (e, pages) => {
    setPage(pages);
  };
  //pagination
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  // for showing anchor
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // close menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <LoggedHeader title="Settings" user="admin" />
      <SideMenu selected="Settings" user="admin" />
      {openSettings &&<EditSettings 
      selectedItem ={selectedItem}
      setSelectedItem ={setSelectedItem}
      openSettings={openSettings}
      setSettings={setSettings}/>}

    
      <div className="contentWrap">
        <div className="contentWrapInner promocode-table">
          <div className="location-head">
         
          </div>
          <TableContainer>
            <Table
              className="commonTabel"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Key</TableCell>
                  <TableCell align="left">Value</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : data && data?.listSettings?.data?.length > 0 ? (
                  data &&
                  data?.listSettings?.data?.map((item) => (
                    <TableRow>
                      <TableCell align="left" component="th" scope="row">
                        {item?.title}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {item?.value}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {item?.description}
                      </TableCell>
                     
                      <TableCell align="center">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            setSelectedItem(item);
                            handleClick(e);
                          }}
                          src="../assets/images/more-horizontal.svg"
                          alt="img"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No list added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select
              // variant="outlined"
              onChange={handlePageInputChange}
              value={rowsPerPage}
            >
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listSettings?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="menu-list-more"
        >
          <MenuItem
            onClick={() => {
              setSettings(true);
              handleClose();
            }}
          >
            Edit
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
