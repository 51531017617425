import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import { ROUTES } from "@constants/routes.constants";
import { DATE_FORMATS } from "@constants/date-formats.constants";
import { useAppDispatch } from "@services/redux";
import { clearOrder } from "@services/redux/order";

interface ICheckoutSuccessNavState {
  catererName: string;
  deliveryDate: string;
  deliveryTime: string;
  caterCashTotal: number;
  id: string;
}

export function useCheckoutSuccessState() {
  const navigate = useNavigate();
  const location = useLocation() as { state: ICheckoutSuccessNavState | null };
  const dispatch = useAppDispatch();

  const [isBackButtonClicked, setBackButtonPress] = useState(false);
  const [orderState, setOrderState] = useState<ICheckoutSuccessNavState>({
    catererName: "",
    deliveryDate: "",
    deliveryTime: "",
    id: "",
    caterCashTotal: 0,
  });

  const timeZone = useMemo(() => {
    return new Date().toLocaleTimeString("default", { timeZoneName: "short" }).split(" ")[1];
  }, []);

  const deliveryDate = useMemo(() => {
    return orderState.deliveryDate
      ? moment(orderState.deliveryDate).format(DATE_FORMATS.orderCheckout)
      : "";
  }, [orderState.deliveryDate]);

  useEffect(() => {
    if (!location.state) {
      navigate(ROUTES.root);
    }

    setOrderState((s) => ({ ...s, ...location.state }));
  }, []);

  useEffect(() => {
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (!isBackButtonClicked) {
        window.history.pushState(null, null, window.location.pathname);
        setBackButtonPress(false);
      }
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  function onNewOrder() {
    dispatch(clearOrder())
    navigate(ROUTES.root);
  }

  function onViewOrder() {
    const link = orderState.id ? `/orders/${orderState.id}` : "/orders";
    navigate(link);
  }

  return {
    date: deliveryDate,
    time: orderState.deliveryTime,
    timeZone: timeZone === "EDT" ? "EST" : timeZone,
    catererName: orderState.catererName,
    caterCash: +orderState.caterCashTotal,
    onNewOrder,
    onViewOrder
  };
}
