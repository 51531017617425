import React from 'react';

import { useMutation } from "@apollo/client";
import {
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import { toggleSnackbar, authVar } from "../../ReactiveVariables/index";
import { ADD_NOTES, UPDATE_ORDER_STATUS, UPDATE_ORDER_HAS_DRIVER } from "../../Graphql/mutations";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import DialogBox from "../Common/dialogBox";
import { useNavigate } from "react-router-dom";
import { set } from 'date-fns';
export default function OrderStatus(props) {
  const orderStatus = props?.orderValues?.getOneOrder?.orderStatus;
  const driverStatus = props?.orderValues?.getOneOrder?.hasDriver;
  console.log(driverStatus)
  const [status, setStatus] = useState(orderStatus ?? "");
  const [hasDriver, setHasDriver] = useState(driverStatus ?? false);
  console.log('hasDriver', hasDriver, 'top')
  const [notes, SetNotes] = useState("");
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDate = new Date();
  const sortArray = structuredClone(props?.orderValues?.getOneOrder?.orderNotes)
  const sortLog = structuredClone(props?.orderValues?.getOneOrder?.orderLog)

  const [addNotes] = useMutation(ADD_NOTES, {
    refetchQueries: ['GetOneOrder'],
    onCompleted: (res) => {
      SetNotes('')
    }
  });

  const [updateStatus, { loading: updateloading }] = useMutation(UPDATE_ORDER_STATUS, { refetchQueries: ['GetOneOrder'] });

  const [updateOrderHasDriver] = useMutation(UPDATE_ORDER_HAS_DRIVER, {
    refetchQueries: ['GetOneOrder'],
    onError: (error) => {
      console.error('Error updating driver status:', error);
      toggleSnackbar({
        status: true,
        message: 'Error updating driver status',
        variant: 'error'
      });

      // reset to original value if fails
      setHasDriver(driverStatus);
    },
    onCompleted: () => {
      toggleSnackbar({
        status: true,
        message: 'Driver status updated!',
        variant: 'success'
      });
    }
  });

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleHasDriver = (event) => {
    const newValue = event.target.value === 'true'; // Convert string value to boolean
    setHasDriver(newValue);

    updateHasDriver(newValue);
  };

  const handleNotes = (event) => {
    SetNotes(event.target.value);
    setErrorMessage('')
  };

  useEffect(() => {
    if (orderStatus) {
      setStatus(orderStatus)
    }
  }, [orderStatus]);

  useEffect(() => {
    if (driverStatus) {
      setHasDriver(driverStatus)
    }
  }, [driverStatus]);

  function onSubmit() {
    if (!notes) {
      setErrorMessage("Add a note")
      return
    }

    try {
      addNotes({ variables: { orderId: props?.id, note: notes } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Note added successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message
          if (message === "Not authenticated") {
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/login", { state: "noAuth" })
          } else {
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  function statusUpdate() {
    let data = {
      oldStatus: orderStatus,
      orderStatus: status,
      statusChangeDate: currentDate,
      statusTimeZone: timeZone,
      orderId: props?.id,
      _id: props?.id
    };

    try {
      updateStatus({ variables: { data: data } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Status updated successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message
          if (message === "Not authenticated") {
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/login", { state: "noAuth" })
          } else {
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  function updateHasDriver (newValue) {
    try {
      updateOrderHasDriver({
        variables: {
          hasDriver: newValue,
          orderId: props?.id
        }
      });
    } catch (error) {
      // Handle any errors if necessary
      console.error('Error updating driver status:', error);
      toggleSnackbar({
        status: true,
        message: 'Error updating driver status',
        variant: 'error'
      });
    }
  }

  console.log('hasDriver', hasDriver)

  return (
    <div className="order-status-wrap order-status-wrap-bottom">
      {openDialog && (<DialogBox
        setOpenDialog={setOpenDialog}
        handleDelete={statusUpdate}
        openDialog={openDialog}
        title={`${status} Order`}
        discription={`Are you sure you want mark this order as ${status}?`}
        description2={status === 'Completed' ? '*Order amount would be settled to the bank account registered with us.' : null}
      />
      )}


      <div className="order-view-title">
        <h5 className="h5">Order Status</h5>
      </div>
      <div className="order-status">
        <div className="os-update">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="Choose Status"
              onChange={handleStatus}
            >
              {orderStatus !== "Pending" || orderStatus !== "Declined" &&
                <MenuItem disabled={
                  orderStatus === "Cancelled" ||
                  orderStatus === "Completed" ||
                  orderStatus === "Scheduled" ||
                  orderStatus === "Pending" ||
                  orderStatus === "Approved"
                } value={'New'}>New</MenuItem>
              }

              {orderStatus !== "Pending" &&
                <MenuItem disabled={orderStatus === "Cancelled" || orderStatus === "Completed"} value={'Scheduled'}>Scheduled</MenuItem>
              }
              {orderStatus !== "Pending" &&
                <MenuItem disabled={orderStatus === "Cancelled"} value={'Completed'}>Completed</MenuItem>
              }
              {orderStatus === "Pending" &&
                <MenuItem disabled={orderStatus === "Pending" || orderStatus === "Approved"} value={'Pending'}>Pending</MenuItem>
              }
              {(orderStatus === "Pending" || orderStatus === "Approved") &&
                <MenuItem disabled={orderStatus === "Approved"} value={'Approved'}>Approved</MenuItem>
              }
              {(orderStatus === "Pending") &&
                <MenuItem disabled={orderStatus === "Declined"} value={'Declined'}>Declined</MenuItem>
              }
              <MenuItem disabled={orderStatus === "Completed"} value={'Cancelled'}>Cancelled</MenuItem>
            </Select>
          </FormControl>

          <Button onClick={() => setOpenDialog(true)} variant="contained" disabled={status === orderStatus || updateloading} className="status-update-btn">{updateloading ? <CircularProgress color="inherit" size={20} /> : "Update Order Status"}</Button>

        </div>
        <div className="os-update">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Driver Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={hasDriver ? 'true' : 'false'}
              label="Choose Driver Status"
              onChange={handleHasDriver}
            >
              <MenuItem value={'true'}>Driver Set</MenuItem>
              <MenuItem value={'false'}>No Driver</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="os-note">
          <h6 className="h6">Our Notes</h6>
          <TextField
            id="outlined-multiline-flexible"
            label="Input Text"
            multiline
            rows={4}
            value={notes}
            onChange={handleNotes}
          />
          {errorMessage && <span className="error-msg">{errorMessage}</span>}
          <div className="text-end">
            <Button onClick={onSubmit} variant="contained" className="os-add-note-btn">Add</Button>
          </div>
        </div>

        <div className="os-notes-history">
          <h6 className="h6">Notes</h6>
          {sortArray && sortArray.sort(function (a, b) {
            return new Date(b.createdOn) - new Date(a.createdOn);
          }).map((item) => (
            <div key={item?._id} className="os-notes-history-cnt">
              <p>{item?.note}</p>
              <time>{moment(item?.createdOn).format('lll')}</time>
            </div>
          ))}
        </div>
        <div className="os-notes-history">
          <h6 className="h6">History</h6>
          {sortLog && sortLog.sort(function (a, b) {
            return new Date(b.createdOn) - new Date(a.createdOn);
          }).map((item) => (
            <div key={item?._id} className="os-notes-history-cnt">
              <p>{item?.message}</p>
              <time>{moment(item?.createdOn).format('lll')}</time>
            </div>))}
        </div>

      </div>
    </div>
  );
}
