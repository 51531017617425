import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  styled,
} from "@mui/material";
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";
import { IPaymentCardResponseItem } from "../payment-card.typings";

const StyledCheckbox = styled(Checkbox)`
  svg {
    color: #fd4e3b;
    font-size: 22px;
  }
`;

interface IPaymentCardItemProps {
  item: IPaymentCardResponseItem;
  isActive: boolean;
  onEdit(id: IPaymentCardResponseItem): void;
  onCheck(id: string): void;
  onDelete(id: string): void;
}

export function PaymentCardItem({
  item,
  isActive,
  onEdit,
  onDelete,
  onCheck,
}: IPaymentCardItemProps) {
  function checkForCardTypeImage(value) {
    if (value) {
      if (value === "Visa") return "../assets/images/cc-visa.svg";
      else if (value === "Amex") return "../assets/images/cc-amex.svg";
      else if (value === "Discover") return "../assets/images/cc-discover.svg";
      else if (value === "Master") return "../assets/images/cc-master.svg";
      else return "../assets/images/card-1.jpg";
    } else {
      return "../assets/images/card-2.jpg";
    }
  }

  function onRemoveCard() {
    onDelete(item._id);
  }

  function onEditCard() {
    onEdit(item);
  }

  function onCheckCard(e) {
    if (!!e.target.checked) {
      onCheck(item?._id);
    }
  }

  return (
    <div className="saved-card-item">
      <div className="d-flex align-items-center">
        <FormGroup>
          <FormControlLabel
            label=""
            control={
              <StyledCheckbox
                checked={isActive}
                onChange={onCheckCard}
                color={"primary"}
                icon={<CheckCircleOutline style={{ color: "#9F9E9E" }} />}
                checkedIcon={<CheckCircle />}
              />
            }
          />
        </FormGroup>
        <div className="card-image-number d-flex align-items-center">
          <div className="card-image">
            <img src={checkForCardTypeImage(item?.cardType)} className="img-fluid" alt="Card" />
          </div>
          <label>{item?.cardLastDigits && item?.cardLastDigits.slice(-4).padStart(16, "*")}</label>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <a className="ld-edit" onClick={onEditCard}>
          View
        </a>
        <IconButton className="card-delete" type="button" onClick={onRemoveCard}>
          <img
            src="../assets/images/icon-card-delete.svg"
            className="img-fluid"
            alt="Delete Icon"
          />
        </IconButton>
      </div>
    </div>
  );
}
