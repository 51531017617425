//listing the catercash history
import React from 'react'

import { Table, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { formatter } from "../../Common/commonUtils";
import TableRow from "@mui/material/TableRow";
import {useNavigate } from "react-router-dom";
export default function CaterCashHistory(props) {
  const navigate = useNavigate()
  return (
    <div className="cater-summary">
      <h4 className="h4">
        <img
          src="../assets/images/icon-other.svg"
          className="img-fluid"
          alt="CaterCash History Icon"
        />
        CaterCash History
      </h4>
      <TableContainer>
        <Table
          className="commonTabel"
          // sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Orders</TableCell>
              <TableCell width="30%" align="left">
                Customer{" "}
              </TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">CaterCash</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.loading ? (
              <TableRow>
                <TableCell colSpan={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p> Loading...</p>
                  </div>
                </TableCell>
              </TableRow>
            ) : props?.caterCashData?.listCaterCash?.data.length > 0 ? (
              props?.caterCashData?.listCaterCash?.data.map((item) => (
                <TableRow style={{ cursor: "pointer" }}>
                  <TableCell align="left">
                    {item?.orderNo ? `#${item?.orderNo}` : "---"}
                  </TableCell>
                  <TableCell style={{cursor:"pointer"}} onClick={()=>{navigate(`/customers/${item?.customerId}`)}}>{item?.customerName}</TableCell>
                  <TableCell align="center">
                    <Button
                      className="status-btn"
                      variant="contained"
                      style={{
                        backgroundColor:
                        item?.caterCashSrc==="Order"&&
                        item?.caterCashStatus===true ?"#FD4E3B":
                          item?.caterCashSrc === "Order"
                            ? "#23BF50"
                            : item?.caterCashSrc === "GiftVoucher"
                            ? "#7877ED"
                            : item?.caterCashSrc === "GiftCard"
                            ? "#7877ED"
                            : item?.caterCashSrc === "Gifted"
                            ? "#EDA977"
                            :item?.caterCashSrc === "Refund"?
                            "#219ebc"
                            : "#FD4E3B",
                      }}
                    >
                      {item?.caterCashSrc==="Order"&&
                      item?.isReferral===true ?"Referral":
                      item?.caterCashSrc === "Order" &&
                      item?.caterCashStatus === false
                        ? "Pending"
                        : item?.caterCashSrc === "Order" &&
                          item?.caterCashStatus === true
                        ? "Earned"
                        : item?.caterCashSrc === "GiftVoucher"
                        ? "Redeemed"
                        : item?.caterCashSrc === "GiftCard"
                        ? "Redeemed"
                        : item?.caterCashSrc === "Gifted"
                        ? "Gifted"
                        : item?.caterCashSrc === "Review"
                        ? "Review"
                        : item?.caterCashSrc === "Refund"
                        ? "Refunded"
                        : "Referral"}
                    </Button>
                  </TableCell>
                  <TableCell align="center"  style={{color:item?.caterCashSrc==="GiftCard"||item?.caterCashSrc==="Refund"?"red":""}}>
                    {item?.caterCashSrc === "GiftCard"||item?.caterCashSrc === "GiftVoucher"?`-${formatter.format(item?.amount)}`:formatter.format(item?.amount)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>No list added Yet!</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
