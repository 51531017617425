import React from "react";

import { formatter } from "../../../Modules/Common/commonUtils";

import { DriverTip } from "../../../components/organisms/driver-tip";

interface IPriceViewProps {
  cartCost: number;
  tablewareCost: string;
  deliveryFee: number;
  salesTaxPercent: number;
  salesTaxAmount: number;
  isDelivery: boolean;
  isMandatoryTip: boolean;
  driverTip: number;
  percentageTip: number;
  onChangeCustomTip(v: number): void;
  onChangePercentageTip(v: number): void;
  totalCost: number;
  headCount: number;
  caterCash: number;
  taxExempt: boolean;
}

export function PriceView({
  cartCost,
  tablewareCost,
  deliveryFee,
  salesTaxPercent,
  salesTaxAmount,
  isDelivery,
  isMandatoryTip,
  driverTip,
  percentageTip,
  onChangePercentageTip,
  onChangeCustomTip,
  headCount,
  totalCost,
  caterCash,
  taxExempt
}: IPriceViewProps) {
  return (
    <div className="review-order-price">
      <h6>Price Details</h6>

      <div className="other-charges">
        <div className="other-charges-item">
          <label>{`Subtotal`}</label>
          <b>{formatter.format(cartCost)}</b>
        </div>
        <div className="other-charges-item">
          <label>Tableware</label>
          <b>{tablewareCost}</b>
        </div>
        <div className="other-charges-item">
          <label>Delivery Fee</label>
          <b>{formatter.format(deliveryFee)}</b>
        </div>
        <div className="other-charges-item">
          <label>{ taxExempt ? 'Sales Tax' : `${salesTaxPercent}% Sales Tax`}</label>
          <b>{taxExempt ? 'Tax Free' : formatter.format(salesTaxAmount)}</b>
        </div>
      </div>

      {isDelivery ? (
        <DriverTip
          isTipMandatory={isMandatoryTip}
          activeDriverTip={driverTip}
          percentageTip={percentageTip}
          onChangeCustomTip={onChangeCustomTip}
          onChangePercentageTip={onChangePercentageTip}
        />
      ) : null}

      <div className="cart-total common-cart-viewbox">
        <label>TOTAL</label>
        <b>{formatter.format(totalCost)}</b>
      </div>

      <div className="cart-price-perhead common-cart-viewbox">
        <label>Price Per Head</label>
        <b> {formatter.format(totalCost / headCount)}/person</b>
      </div>

      <div className="cart-catercash">
        <img src="../assets/images/icon-catercash.svg" className="img-fluid" alt="Catercash" />
        CaterCash Earned &nbsp;
        <b>{formatter.format(caterCash)}</b>
      </div>
    </div>
  );
}
