import { Routes, Route, useNavigate, Navigate, useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client/react/hooks";
import { CircularProgress } from "@mui/material";
import "./App.css";
import Login from "./Modules/Login/Login";
import RouteWrapper from "./Route/index";
import { CatererDetails } from "./screens/caterer-details";
import MenuList from "./Modules/Menu";
import Location from "./Modules/Location/Location";
import CatererListingScreen from "./Modules/Admin/catererListingScreen";
import CatererDetailsScreen from "./Modules/Admin/catererDetailsScreen";
import Register from "./Modules/Registration";
import { CatererList } from "./screens/caterer-list/caterer-list";
import { ReviewOrder } from "./screens/checkout";
import { CheckoutSuccess } from "./screens/checkout-success";
import ReferFriend from "./Modules/ReferFriend/index";
import CustomerList from "./Modules/Admin/customerList";
import CategoryList from "./Modules/Admin/Categories/categoryList";
import ViewMenu from "./Modules/Menu/MenuAction/ViewMenu";
import CustomerDetails from "./Modules/Admin/CustomerListDetails";
import { Home } from "./screens/home";
import Summary from "./Modules/Summary";
import PromoCodes from "./Modules/Admin/Promocode/promocode";
import CuisinesList from "./Modules/Admin/Cuisines";
import { AdminLogin } from "./Modules/Login/AdminLogin";
import ResetPassword from "./Modules/Login/ResetPassword";
import LocationDetails from "./screens/caterer-profile/caterer-profile";
import { useReactiveVar } from "@apollo/client";
import { authVar, userDeatils, toggleSnackbar } from "./ReactiveVariables/index";
import "./styles/style.css";
import { CURRENT_USER_DETAILS } from "./Graphql/queries";
import { Orders } from "./screens/orders";
import CustomerProfile from "./Modules/CustomerProfile/customerProfile";
import TaxExemption from "./Modules/CustomerProfile/taxExemption";
import AdminUserList from "./Modules/Admin/AdminUser/adminUserList";
import { ViewOrder } from "./screens/view-order";
import ReviewOrderLogin from "./Modules/Login/reviewOrderLogin";
import CaterCash from "./Modules/CaterCash";
import Payments from "./Modules/Payments/payment";
import { AddressList } from "./screens/address-list";
import { ViewAddress } from "./screens/view-address";
import PaymentAdmin from "./Modules/Payments/paymentAdmin";
import PaymentAdminDetail from "./Modules/Payments/paymentAdminDetail";
import Reviews from "./Modules/Admin/Review/Reviews";
import Reports from "./Modules/Admin/Reports/reports";
import Settings from "./Modules/Admin/Settings/settings";
import AboutUs from "./Modules/Home/HeaderLinks/About";
import TermsCondition from "./Modules/Home/HeaderLinks/terms-condition";
import PrivacyPolicy from "./Modules/Home/HeaderLinks/privacy-policy";
import DashBoard from "./Modules/Admin/Dashboard/dashboard";
import Faq from "./Modules/Home/HeaderLinks/faq";
import Contact from "./Modules/Home/HeaderLinks/contact";
import Caterers from "./Modules/Home/HeaderLinks/caterers";
import AccessDenied from "./Modules/Common/accessDenied";
import SortCategory from "./Modules/Menu/MenuAction/categorySort";
import { useCookies } from "react-cookie";
import React, { useEffect } from "react";
import { useLogout } from "./utils/hooks/use-logout";
import { ROUTES } from "@constants/routes.constants";

function App() {
  const navigate = useNavigate();
  const user = useReactiveVar(userDeatils);
  const auth = useReactiveVar(authVar);
  const token = localStorage.getItem("@authToken");
  const [cookies, setCookie, removeCookie] = useCookies([
    "user",
    "image",
    "userType",
    "@authToken",
  ]);
  const location = useLocation();
  const path = location.pathname;
  let shortToken = cookies["@authToken"];
  const dynamicValue = path.split("/")[2]; // Assuming the dynamic value is always the third segment of the path

  const apiUrl = process.env.REACT_APP_API_URL + "/graphql";
  let currentLocation = window.location.pathname;
  const generateExcludedPaths = (id) => {
    return [
      "/",
      "/customerlogin",
      "/admin",
      "/login",
      "/register",
      `/register/${dynamicValue}`,
      "/terms-and-conditions",
      "/privacy-policy",
      "/faq",
      "/contact",
      "/caterer",
      "/caterers",
      `/caterers/${dynamicValue}`,
      "/resetPasswordToken",
      `/resetPasswordToken/${dynamicValue}`,
      "/resources",
      "/about",
    ];
  };

  const excludedPaths = generateExcludedPaths(dynamicValue);
  const shouldRedirect = !excludedPaths.includes(currentLocation);

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // If you have an authentication token, include it in the headers
      Authorization: `${token ?? shortToken}`,
    },
    body: JSON.stringify({
      query:
        "query GetUserStatus {\n  getUserStatus {\n    message\n    status\n    __typename\n  }\n}",
    }),
  };

  const { data, loading } = useQuery(CURRENT_USER_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.currentUserDetails?.catererId === "" || res.currentUserDetails?.catererId) {
        userDeatils({ data: res });
        setCookie("user", res?.currentUserDetails?.name, {
          path: "/",
          domain: ".caterplace.com",
        });
        setCookie("image", res?.currentUserDetails?.imageUrl, {
          path: "/",
          domain: ".caterplace.com",
        });
        setCookie("userType", res?.currentUserDetails?.userType, {
          path: "/",
          domain: ".caterplace.com",
        });
      }
      authVar({
        loaded: true,
        auth: true,
      });
    },
  });

  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;

  const { onLogout } = useLogout({
    userType,
    logoutCallback: () =>
      toggleSnackbar({
        status: true,
        message: "Your session has expired. Please log back in to continue.",
        variant: "error",
      }),
  });

  useEffect(() => {
    if (shouldRedirect) {
      fetch(apiUrl, options)
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data
          if (currentLocation.includes("home")) {
            navigate(ROUTES.root);
          } else if (data?.data?.getUserStatus === null) {
            onLogout();
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error("error", error);
        });
    }
  }, [shouldRedirect, location]);

  const customerUserType = {
    user: "Customer",
  };

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "calc(100vh - 70px)" }}>
        <CircularProgress />
      </div>
    );
  return (
    <>
      <Routes>
        <Route element={<RouteWrapper />}>
          {userType === "Caterer" && <Route path="menu" element={<MenuList />} />}
          {userType === "Caterer" && <Route path="sort-category/:id" element={<SortCategory />} />}
          {userType === "Caterer" && <Route path="location" element={<Location />} />}
          {userType === "Caterer" && <Route path="profile" element={<LocationDetails />} />}
          {userType !== "Customer" && <Route path="menu/:id" element={<ViewMenu />} />}
          {userType === "Admin" && <Route path="customers" element={<CustomerList />} />}
          {userType === "Admin" && <Route path="customers/:id" element={<CustomerDetails />} />}
          {userType === "Admin" && (
            <Route path="admincatererlist" element={<CatererListingScreen />} />
          )}
          {userType === "Admin" && (
            <Route path="admincatererlist/:id" element={<CatererDetailsScreen />} />
          )}
          {(userType === "Admin" || userType === "Customer") && (
            <Route path="revieworder/:id" element={<ReviewOrder />} />
          )}
          {userType === "Customer" && (
            <Route path={ROUTES.confirmedOrder} element={<CheckoutSuccess />} />
          )}
          {userType === "Admin" && <Route path="category" element={<CategoryList />} />}
          {userType === "Admin" && <Route path="cuisines" element={<CuisinesList />} />}
          {userType === "Admin" && <Route path="adminuser" element={<AdminUserList />} />}
          <Route path="orders" element={<Orders />} />
          {userType === "Caterer" && <Route path="summary" element={<Summary />} />}
          <Route path="orders/:id" element={<ViewOrder />} />
          {userType === "Customer" && <Route path="profile" element={<CustomerProfile />} />}
          {userType === "Customer" && <Route path="taxes" element={<TaxExemption />} />}
          {userType === "Admin" && <Route path="promocodes" element={<PromoCodes />} />}
          {userType !== "Caterer" && <Route path="referFriend" element={<ReferFriend />} />}
          {userType !== "Caterer" && <Route path="CaterCash" element={<CaterCash />} />}
          {userType !== "Customer" && (
            <Route
              path="payments"
              element={userType !== "Admin" ? <Payments /> : <PaymentAdmin />}
            />
          )}
          {userType === "Admin" && <Route path="payments/:id" element={<PaymentAdminDetail />} />}
          {userType === "Customer" && <Route path="address" element={<AddressList />} />}
          {userType === "Admin" && <Route path="reviews" element={<Reviews />} />}
          {userType === "Customer" && <Route path="address/:id" element={<ViewAddress />} />}
          {userType === "Admin" && <Route path="reports" element={<Reports />} />}
          {userType === "Admin" && <Route path="settings" element={<Settings />} />}
          {userType === "Admin" && <Route path="dashboard" element={<DashBoard />} />}
        </Route>
        <Route path="resetPasswordToken/:id" element={<ResetPassword />} />
        <Route path="caterers" element={<CatererList />} />
        <Route path="caterers/:id" element={<CatererDetails />} />
        <Route path="login" element={auth?.auth ? <Navigate to={ROUTES.root} /> : <Login />} />
        <Route path="admin" element={auth?.auth ? <Navigate to="/" /> : <AdminLogin />} />
        <Route path="customerlogin" element={<ReviewOrderLogin />} />
        <Route path="register" element={<Register />} />
        <Route
          path="register/:token"
          state={customerUserType}
          element={<Register userType={customerUserType} />}
        />
        <Route
          path="register/customer"
          element={<Register userType={{ user: 'Customer' }} />}
        />
        <Route
          path="register/caterer"
          element={<Register userType={{ user: 'Caterer' }} />}
        />
        <Route path="about" element={<AboutUs />} />
        <Route path="terms-and-conditions" element={<TermsCondition />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="faq" element={<Faq />} />
        <Route path="contact" element={<Contact />} />
        <Route path="caterer" element={<Caterers />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<AccessDenied />} />
      </Routes>
    </>
  );
}

export default App;
