import React, { useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { GOOGLE_API_KEY } from "@constants/map.constants";

export function ViewAddressMap(props) {
  useEffect(() => {
    const loader = new Loader({
      apiKey: GOOGLE_API_KEY,
      libraries: ["places"],
    });

    loader.load().then(() => {
      let map = new window.google.maps.Map(document.getElementById("map" + props.index), {
        center: props?.latLng,
        zoom: 12,
        draggable: true,
        zoomControl: true,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: true,
      });
      const marker = new window.google.maps.Marker({
        position: props?.latLng,
        map: map,
      });
    });
  }, [props?.latLng, props.index]);
  return (
    <div
      id={"map" + props.index}
      className="address-map"
      // style={{ width: "100%", height: "275px" }}
    ></div>
  );
}
