//This is for the admin login page
import React from 'react';

import { useState,useEffect } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client/react";
import {
  Button,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from '@mui/material';
import * as yup from "yup";
import {
  authVar,
  userDeatils,
  toggleSnackbar,
} from "../../ReactiveVariables/index";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LOGIN_ADMIN, FORGET_PASS } from "../../Graphql/mutations"
import { useCookies } from 'react-cookie';
export const AdminLogin = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const userType = location.state;
  const [showPassword, setShowPassword] = useState(false);
  const [forgetPass, setForgetPass] = useState(false);
  const [rememberMe, setRememberMe] = useState(true)
  const [cookies, setCookie,removeCookie] = useCookies(['user','image','userType','@authToken']);
  // yup for form validation
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Please enter a valid email address")
      .transform((value) => (value ? value.trim() : '')),
    password: forgetPass ? null : yup.string().required("Password is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const [Login, { loading }] = useMutation(LOGIN_ADMIN, {
    onCompleted: (res) => {
      const userDetails = {
        data:res
      };
      setCookie('user', res?.login?.fullName, {
        path: '/',
        domain: '.caterplace.com',
      });
      setCookie('image', res?.login?.imageUrl, {
        path: '/',
        domain: '.caterplace.com',
      });
      setCookie('userType', res?.login?.userType, {
        path: '/',
        domain: '.caterplace.com',
      });
      // if (rememberMe === true) {
      //   setCookie("@authToken", res.login.token, { maxAge: 2592000 })
      // } else {
      //   setCookie("@authToken", res.login.token)
      // }
    }
  })
  useEffect(()=>{
    if(userType==="noAuth"){
    toggleSnackbar({
      status: true,
      message: "Your session has expired. Please log back in to continue.",
      variant: "error",
    });
  }
  },[userType==="noAuth"])
  const [forgetPassword] = useMutation(FORGET_PASS);
  //for toggling text/password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  function onSubmit(params) {
    // switching between login / forget password mutation
    if (!forgetPass) {
      // login
      params["rememberMe"] = rememberMe
      params["userType"] = 'Admin'
      try {
        Login({ variables: params })
          .then((res) => {
            const responseData = res.data && res.data.login;
            const token = responseData.token;
            if(rememberMe){
              localStorage.setItem("@authToken", token);
            }
            else{
              setCookie("@authToken", token)
            }
            // setting the the response to reactive variables.
            userDeatils({ data: res?.data });
            authVar({
              loaded: true,
              auth: true,
            });
            toggleSnackbar({
              status: true,
              message: "Login successful",
              variant: "success",
            });
             // check the reg.flag for navigating to catererListingScreen of admin
             if(responseData.userType === "Admin"){
              navigate("/dashboard")
            }
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    } else {
      // forget password
      try {
        forgetPassword({ variables: { email: params.email } })
          .then((res) => {
            const message = res?.data?.forgotPassword?.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "success",
            });
          })
          .catch((err) => {
            const message = err && err.message;
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
    }


  }
  return (
    <div className="admin-login">
      <div className="container">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="login-box create-step-form"
        >
          {/* switching between login form and forgot password based on the forgetpass value*/}
          {!forgetPass ? <img
            src="../assets/images/logo.svg"
            className="img-fluid admin-login-logo"
            alt="Logo"
          /> : <h4 className="h4">Forgot Password</h4>}
          <div className="textBox">
            <TextField
              id="loginEmail"
              label="EMAIL"
              autoFocus
              variant="outlined"
              className="textField allFeild"
              {...register("email")}
            />
            {errors.email && (
              <span className="error-msg">{errors.email.message}</span>
            )}
          </div>
          {!forgetPass && <div className="textBox">
            <TextField
              id="loginPassword"
              label="PASSWORD"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              className="textField allFeild"
              {...register("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password && (
              <span className="error-msg">Password is required</span>
            )}
          </div>}

          {!forgetPass && <div className="login-check d-flex align-items-center justify-content-between">
            <FormGroup>
              <FormControlLabel
                checked= {rememberMe}
                control={<Checkbox size="small" />}
                label="Remember me"
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            </FormGroup>
            <Link style={{color:'#0066FF'}} to="#" onClick={() => setForgetPass(true)}>Forgot password ?</Link>
          </div>}

          <div className="createOneLink">
            {forgetPass && <p onClick={() => setForgetPass(false)} style={{ cursor: 'pointer', textAlign: 'right', textDecoration: 'underline' }}>Back to Login</p>}
          </div>

          <Button type="submit" variant="contained" className="login-btn">
          {loading ? <CircularProgress color="inherit" size={20} /> : forgetPass ? "Request" : "Log In"}
          </Button>
        </form>
      </div>
    </div>
  )
}