export enum CATERER_DRIVER_TIP_TYPE {
  mandatory = "mandatory",
  default = "non-mandatory",
}

export enum ORDER_TIP_TYPE {
  percentage = "Percentage",
  custom = "Custom",
}

export enum DELIVERY_FEE_TYPE {
  percentage = "Percentage",
  amount = "Amount",
}

export enum ORDER_DELIVERY_MODE {
  pickup = "Pickup Only",
  delivery = "Delivery Only",
}

export enum ORDER_STATUS {
  draft = "Draft",
  completed = "Completed",
  new = "New",
  scheduled = "Scheduled",
  approved = "Approved",
  pending = "Pending",
  canceled = "Cancelled",
}

export const MAX_ORDER_HEAD_COUNT = 10000;
export const MAX_QUANTITY = 9999;
export const MAX_PRICE_PER_ITEM = 100000;
