import React from "react";

import { formatter } from "Modules/Common/commonUtils";
import { ICatererDeliveryFee } from "typings/caterer.api";

interface IDeliveryFeesProps {
  deliveryFeeTiers: ICatererDeliveryFee[];
  deliveryFee: number;
  setOpenFees(v: boolean): void;
}

export function DeliveryFees({ deliveryFeeTiers,deliveryFee, setOpenFees }: IDeliveryFeesProps) {
  return (
    <div className="location-details-tile h-100">
      <div className="ld-titles">
        <h4 className="h4">
          <img
            src="../assets/images/Money.svg"
            className="img-fluid"
            alt="CaterCash & Commission Icon"
          />
          Additional Delivery Fees
        </h4>
        <a className="ld-edit" onClick={() => setOpenFees(true)}>
          <img src="../assets/images/icon-edit.svg" className="img-fluid" alt="Edit Icon" />
          Edit
        </a>
      </div>
      <div className="ld-delivery-hrs">
        <ul className="list-group list-group-flush">
          {deliveryFeeTiers &&
            deliveryFeeTiers.map((tier, i) => (
              <li className="list-group-item">
                <div className="d-flex w-100 justify-content-between">
                  <h6 className="h6">
                    Tier {i + 1} - {formatter.format(tier?.price)}
                  </h6>
                </div>
                <p className="mb-1">Zip Codes: {tier.zipCodes.join(", ")}</p>
              </li>
            ))}

          <li className="list-group-item">
            <div className="d-flex w-100 justify-content-between">
              <h6 className="h6">Default Delivery Fee - {formatter.format(deliveryFee)}</h6>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
