// this page shows all the detais of one busines and the catere/admin details associated 
import * as React from 'react';
import SideMenu from "../../components/organisms/sidebar"
import LoggedHeader from "../Common/loggedHeader"
import { useQuery, useLazyQuery } from "@apollo/client/react/hooks";
import { GET_ONE_USER } from "../../Graphql/queries"
import { userDeatils } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import Box from '@mui/material/Box';
import { LIST_CATERER } from "../../Graphql/queries";
import Typography from '@mui/material/Typography';
import MenuList from '../Menu';
import { useParams, useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CatererProfileScreen from "../../screens/caterer-profile/caterer-profile";
import Payments from '../Payments/payment';
import {Orders} from '../../screens/orders';
import Note from '../Note';
import { ADMIN_NOTE_TYPES } from '../Note/note.constants';

export default function CatererDetailsScreen() {
    const { id } = useParams();
    const location = useLocation();
    const locationDetails = location?.state;
    const user = useReactiveVar(userDeatils);
    const [newData, setNewData] = useState(null);
    const [newCuisine, setNewCuisine] = useState([]);
    const [getLocId, setGetLocId] = useState("");
    const [value, setValue] = React.useState(locationDetails?.menu?2:locationDetails==="caterer"?1:0);
    const catererVariables = {
        skip: 0,
        limit: 50,
        userId: user?.data?.currentUserDetails?._id || user?.data?.login?._id || user?.data?.createCaterer?._id,
    };

    const [listCaterer, { data: catererData }] = useLazyQuery(LIST_CATERER);

    const { data } = useQuery(GET_ONE_USER, {
        variables: { getOneCatererId: getLocId ? getLocId : id ,agsType: "id" }, fetchPolicy: "network-only",
        skip: !getLocId && !id,
        onCompleted: (res) => {
            // for getting the cusines that are being selected
            let cusineArray = []
            newData?.listCuisineType?.data.forEach(element1 => {
                let position = res?.getOneCaterer?.cuisineType.findIndex((element) => element === element1._id);
                if (position > -1) {
                    cusineArray.push(element1?.cuisineName)
                }
            });
            setNewCuisine(cusineArray)
        }
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, []);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography component={'span'}>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    useEffect(() => {
        listCaterer({ variables: catererVariables })
    }, [locationDetails]);

    const catererInfo = {
        businessName: data?.getOneCaterer?.businessName,
        locationName: data?.getOneCaterer?.locationName
    }

    return <div>
        <LoggedHeader catererInfo={catererInfo} user="admin" />
        <SideMenu selected="Caterers" user="admin" />
        <div className="contentWrap">
            <div className='cater-tab'>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Caterers Profile" />
                    <Tab label="Order" />
                    <Tab label="Menu" />
                    <Tab label="Payment" />
                    <Tab label="Note" />
                </Tabs>
            </div>
            <TabPanel value={value} index={0}>
                <Link to={'/admincatererlist'} className="main-admin-back-link" style={{ cursor: "pointer" }}><span>Back to Caterers</span></Link>
                <CatererProfileScreen userType="Admin" />
            </TabPanel>
            <TabPanel value={value} index={1}>
            <Link to={'/admincatererlist'} className="main-admin-back-link" style={{ cursor: "pointer" }}><span>Back to Caterers</span></Link>
                <Orders
                    userType="Admin"
                    tile="caterer"
                    catererId = {id}
                    title={data?.getOneCaterer?.businessName && `${data?.getOneCaterer?.businessName}, ${data?.getOneCaterer?.locationName}`}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
            <Link to={'/admincatererlist'} className="main-admin-back-link" style={{ cursor: "pointer" }}><span>Back to Caterers</span></Link>
                <MenuList userType="Admin" menuId={id}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
            <Link to={'/admincatererlist'} className="main-admin-back-link" style={{ cursor: "pointer" }}><span>Back to Caterers</span></Link>
                <Payments userType="Admin" ids={id} admin title={data?.getOneCaterer}/>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Link to={'/admincatererlist'} className="main-admin-back-link" style={{ cursor: "pointer" }}><span>Back to Caterers</span></Link>
                <Note type={ADMIN_NOTE_TYPES.caterer}/>
            </TabPanel>
        </div>
    </div>
}