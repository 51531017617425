import React, { forwardRef, useLayoutEffect } from "react";

import DatePickerComponent from "react-datepicker";

import { DATE_FORMATS } from "../../../constants/date-formats.constants";

import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.style.css";

const TIME_INTERVALS = 15;
const DEFAULT_TIME_HOUR = 12;

export const DatePicker = forwardRef(function DatePickerC(
  { label, value, onChange, ...props }: any,
  ref,
) {
  useLayoutEffect(() => {
    const scrollIntoViewIfNeeded = () => {
      const timeList: any = document.querySelector(".react-datepicker__time-list");
      if (!timeList) {
        return;
      }

      const timeOptions = timeList.querySelectorAll(".react-datepicker__time-list-item");
      const defaultTime = value.getHours() * 60 + value.getMinutes();
      const timeListHeight = timeList.offsetHeight;
      const timeOptionHeight = timeOptions[0].offsetHeight;

      let scrollPosition = 0;
      if (defaultTime > 0) {
        const defaultTimeIndex = Math.floor(defaultTime / TIME_INTERVALS);
        const scrollTopOffset = Math.floor(timeListHeight / 2 - timeOptionHeight / 2);
        scrollPosition = Math.max(0, (defaultTimeIndex - 1) * timeOptionHeight - scrollTopOffset);
      }

      timeList.scrollTop = scrollPosition;
    };

    const timeoutId = setTimeout(scrollIntoViewIfNeeded, 0);

    return () => clearTimeout(timeoutId);
  }, [value]);

  function onChangeDate(date) {
    if (date && !value) {
      date.setHours(DEFAULT_TIME_HOUR);
    }
    onChange(date);
  }
  return (
    <div className="cater-datepicker">
      <DatePickerComponent
        {...props}
        ref={ref}
        onChangeRaw={(e) => {
          e.preventDefault();
        }}
        minDate={new Date()}
        selected={value}
        onChange={onChangeDate}
        placeholderText={label}
        showTimeSelect={true}
        timeIntervals={TIME_INTERVALS}
        dateFormat={DATE_FORMATS.order}
      />
    </div>
  );
});
