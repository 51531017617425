import React from "react";

interface IManageAdminProps {
  isAdmin: boolean;
  userLog: string;
  name: string;
  phone: string;
  email: string;
  isSMSEnabled: boolean;
  setOpenProfile(value: boolean): void;
}

export function ManageAdmin({
  isAdmin,
  userLog,
  name,
  phone,
  email,
  isSMSEnabled,
  setOpenProfile,
}: IManageAdminProps) {
  return (
    <div className="location-details-tile d-flex flex-column">
      <div className="ld-titles">
        <h4 className="h4">
          <img
            src="../assets/images/icon-manage-admin.svg"
            className="img-fluid"
            alt="Contact Information Icon"
          />
          {isAdmin ? "Admin Details" : "Manage Admin"}
        </h4>
        {userLog !== "AddBusiness" && (
          <a className="ld-edit" onClick={() => setOpenProfile(true)}>
            <img src="../assets/images/icon-edit.svg" className="img-fluid" alt="Edit Icon" />
            Edit
          </a>
        )}
      </div>
      <div className="ld-contact-details">
        <div className="ld-contact-details-item">
          <label>Admin Name</label>
          <span>
            {name ?? (
              <p>
                Admin not assigned yet.{" "}
                {!isAdmin && (
                  <a className="ld-edit" onClick={() => setOpenProfile(true)}>
                    Add Admin
                  </a>
                )}
              </p>
            )}
          </span>
        </div>
        <div className="ld-contact-details-item">
          <label>Business Contact Number</label>
          <span>{phone ?? "-"}</span>
        </div>
        <div className="ld-contact-details-item">
          <label>Login Email</label>
          <span>{email ?? "-"}</span>
        </div>
        <div className="ld-contact-details-item">
          <label>SMS Enabled</label>
          <span>{isSMSEnabled ? "Yes" : "No"}</span>
        </div>
      </div>
    </div>
  );
}
