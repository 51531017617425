//this page is for adding and editing the credit cards
import React, { useEffect } from "react";
import { Button } from "@mui/material";

import DialogBox from "../../../Modules/Common/dialogBox";

import { PaymentCardForm } from "./components/payment-card-form/payment-card-form";
import { PaymentCardItem } from "./components/payment-card-item";
import { usePaymentCardState } from "./payment-card.state";

export function PaymentCard(props) {
  const {
    onDeleteRequest,
    onDelete,
    isOpenDeleteModal,
    setDeleteModal,
    onSubmit: onSubmit2,
    isOpenForm,
    onOpenForm,
    onCloseForm,
    onEditCard,
    selectedCard,
    onSetDefaultCard,
  } = usePaymentCardState();

  // Setting the default card
  useEffect(() => {
    const cards = props?.listCard?.listCard?.data;
    const defaultCard = cards ? cards.find(card => card?.defaultCard) : null;

    console.log(defaultCard)

    // Initial Load
    if (cards && !props?.checkedCard && defaultCard) {
      props?.setCheckedCard(defaultCard?._id);
    }

    if (props?.checkedCard) {
      if (defaultCard?._id !== props?.checkedCard) {
        onSetDefaultCard(props?.checkedCard);
      }
    }

  }, [props?.listCard?.listCard?.data, props?.checkedCard]);

  return (
    <div className="added-card">
      {isOpenDeleteModal && (
        <DialogBox
          setOpenDialog={setDeleteModal}
          handleDelete={onDeleteRequest}
          openDialog={isOpenDeleteModal}
          title="Delete"
          discription="Are you sure you want to delete this credit card?"
        />
      )}
      <div className="saved-card">
        <h6 className="h6">{props?.reviewOrderScreen ? "Select Payment" : "Saved Cards"}</h6>
        {props?.listCard?.listCard?.data?.map((item, key) => {
          return (
            <PaymentCardItem
              key={key}
              item={item}
              isActive={props?.checkedCard === item?._id}
              onCheck={props?.setCheckedCard}
              onEdit={onEditCard}
              onDelete={onDelete}
            />
          );
        })}
      </div>

      {!selectedCard && (
        <Button className="add-card-btn" onClick={onOpenForm}>
          <img src="../assets/images/red_plus.svg" className="img-fluid" alt="Icon" />
          Add new card
        </Button>
      )}

      <PaymentCardForm
        isOpen={isOpenForm}
        activeItem={selectedCard}
        onSubmit={onSubmit2}
        onCancel={onCloseForm}
      />
    </div>
  );
}
