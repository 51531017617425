import React from "react";

import { useController } from "react-hook-form";
import { DatePicker } from "./atoms/datepicker";

import { FormControl, FormHelperText } from "@mui/material";

export function DatePickerForm({ control, name, rules, onUpdate, ...props }) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });

  function onChangeValue(date) {
    onChange(date);
    onUpdate && onUpdate(name, date);
  }

  // TODO: make border red on error
  return (
    <FormControl fullWidth error={error ? !!error.message : false}>
      <DatePicker {...props} value={value} onChange={onChangeValue} />
      <FormHelperText>{error ? error.message : ""}</FormHelperText>
    </FormControl>
  );
}
