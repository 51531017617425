import { useMutation, useReactiveVar } from "@apollo/client";
import { CREATE_CARD, DELETE_CARD, UPDATE_CARD, UPDATE_DEFAULT_CARD } from "Graphql/mutations";
import { toggleSnackbar, userDeatils } from "ReactiveVariables";
import { useState, useEffect } from "react";
import { CardForm } from "./components/payment-card-form/payment-card-form.state";
import { getUserItem } from "@utils/user-format.utils";
import { IPaymentCardResponseItem } from "./payment-card.typings";

export function usePaymentCardState() {
  const [selectedCard, setSelectedItem] = useState<IPaymentCardResponseItem>(null);
  const [isOpenForm, setOpenForm] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  const [isOpenDeleteModal, setDeleteModal] = useState(false);
  const userObject = useReactiveVar(userDeatils);

  const user = getUserItem(userObject);

  const [updateCard] = useMutation(UPDATE_CARD, {
    refetchQueries: ["ListCard"],
  });

  const [createCard] = useMutation(CREATE_CARD, {
    refetchQueries: ["ListCard"],
  });

  const [deleteCard] = useMutation(DELETE_CARD, {
    refetchQueries: ["ListCard"],
  });

  const [updateDefaultCard] = useMutation(UPDATE_DEFAULT_CARD, {
    refetchQueries: ["ListCard"],
  })

  function onOpenForm() {
    setOpenForm(true);
    setSelectedItem(null);
  }

  function onCloseForm() {
    setOpenForm(false);
    setSelectedItem(null);
  }

  function onEditCard(item: IPaymentCardResponseItem) {
    setSelectedItem(item);
    setOpenForm(true);
  }

  function onSetDefaultCard(id: string) {
    updateDefaultCard({ variables: { cardId: id, customerId: user?._id }});
  }

  async function onCreateCard(form: CardForm) {}

  async function onSubmit(form: CardForm) {
    try {
      if (!selectedCard) {
        const payload = {
          ...form,
          cardNumber: form.cardLastDigits,
          cardLastDigits: form.cardLastDigits.slice(-4),
          cardExpr: form.cardExpr.replace("/", ""),
          country: "USA",
          customerId: user._id,
          Phone: user.phoneNumber,
        };

        await createCard({ variables: { data: payload } });

        toggleSnackbar({
          status: true,
          message: "Card added successfully",
          variant: "success",
        });
      } else {
        const payload = {
          additionalEmail: form.additionalEmail,
          _id: selectedCard._id,
          addEmailChk: form.addEmailChk,
        };
        await updateCard({ variables: { id: selectedCard._id, data: payload } });

        toggleSnackbar({
          status: true,
          message: "Card updated successfully",
          variant: "success",
        });
      }

      setOpenForm(false);
      setSelectedItem(null);
      return true;
    } catch (err) {
      toggleSnackbar({
        status: true,
        message: err.message,
        variant: "error",
      });
      return false;
    }
  }

  async function onDeleteRequest() {
    try {
      const response = await deleteCard({ variables: { cardId: deletedId } });

      toggleSnackbar({
        status: true,
        message: "Card deleted successfully",
        variant: "success",
      });
      if (selectedCard && deletedId === selectedCard._id) {
        setSelectedItem(null);
        setOpenForm(false);
      }
      setDeletedId("");
    } catch (e) {
      toggleSnackbar({
        status: true,
        message: e.message,
        variant: "error",
      });
    }
  }

  function onDelete(id: string) {
    setDeleteModal(true);
    setDeletedId(id);
  }

  return {
    isOpenForm,
    onOpenForm,
    onCloseForm,
    onEditCard,
    onDelete,
    isOpenDeleteModal,
    setDeleteModal,
    onDeleteRequest,
    onSubmit,
    selectedCard,
    onSetDefaultCard,
  };
}
