// this componenrt is the header after loggin in
import React, { useState } from "react";
import { userDeatils } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import DialogBox from "./dialogBox";
import { useLogout } from "../../utils/hooks/use-logout";
import { HeaderMenu } from "@components/atoms/header-menu";
import { getUserType } from "@utils/user-format.utils";

export default function CustomerHeader(props) {
  const user = useReactiveVar(userDeatils);
  var currentLocation = window.location.pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userType = getUserType(user);

  const { onLogout } = useLogout({ userType: props.user, logoutCallback: handleClose });

  const open = Boolean(anchorEl);
  // open menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <header className="fixed-top LoggedInHeader d-md-flex d-none">
      {/* open profile edit */}
      {openDialog && (
        <DialogBox
          setOpenDialog={setOpenDialog}
          handleDelete={onLogout}
          openDialog={openDialog}
          title="Logout"
          discription="Are you sure you want to Logout?"
        />
      )}
      <div className="container-fluid">
        <div className="d-flex align-items-center h-100">
          <Link to={"/"} className="loggedLogo">
            <img src="../assets/images/logo-white.svg" className="img-fluid" alt="Logo" />
          </Link>

          <h3>{props?.title}</h3>
        </div>

        <div className="header-menu">
          <ul>
            <li>
              <Link
                to={"/referFriend"}
                style={{ color: currentLocation === "/referFriend" ? "#FD4E3B" : "" }}>
                Refer A Friend
              </Link>
            </li>
            <li>
              <a
                href={"/resources"}
                style={{ color: currentLocation === "/resources" ? "#FD4E3B" : "" }}>
                Resources
              </a>
            </li>
            <li>
              <Link
                to={"/caterer"}
                style={{ color: currentLocation === "/caterer" ? "#FD4E3B" : "" }}>
                Caterers
              </Link>
            </li>
            <li>
              <Link to={"/about"} style={{ color: currentLocation === "/about" ? "#FD4E3B" : "" }}>
                About
              </Link>
            </li>
            <li>
              <Link to={"/faq"} style={{ color: currentLocation === "/faq" ? "#FD4E3B" : "" }}>
                FAQ
              </Link>
            </li>
            <li>
              <Link
                to={"/contact"}
                style={{ color: currentLocation === "/contact" ? "#FD4E3B" : "" }}>
                Contact
              </Link>
            </li>
          </ul>
          <Avatar
            onClick={handleClick}
            alt="Remy Sharp"
            src={
              user?.data?.currentUserDetails?.imageUrl
                ? user?.data?.currentUserDetails?.imageUrl
                : user?.data?.login?.imageUrl
                ? user?.data?.login?.imageUrl
                : "/static/images/avatar/1.jpg"
            }
            style={{ cursor: "pointer" }}>
            {user?.data?.login?.fullName.charAt(0).toUpperCase() ||
              user?.data?.currentUserDetails?.name?.charAt(0).toUpperCase() ||
              user?.data?.createCaterer?.fullName?.charAt(0).toUpperCase() ||
              user?.data?.createUserIdentity?.fullName?.charAt(0).toUpperCase()}
          </Avatar>
          <h3 style={{ cursor: "pointer" }} className="header-user-name" onClick={handleClick}>
            {user?.data?.login?.fullName ||
              user?.data?.currentUserDetails?.name ||
              user?.data?.createCaterer?.fullName ||
              user?.data?.createUserIdentity?.fullName}
          </h3>
        </div>

        <HeaderMenu
          element={anchorEl}
          isOpen={open}
          userType={userType}
          onClose={handleClose}
          onLogout={() => setOpenDialog(true)}
        />
      </div>
    </header>
  );
}
