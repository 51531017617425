import { Avatar } from "@mui/material";
import React, { useMemo } from "react";

interface IViewOrderCustomerAddressProps {
  avatarUrl?: string;
  contactPerson?: string;
  name: string;
  email: string;
  phone: string;
  suit: string;
  location: string;
  street: string;
  zip: string | number;
}

export function ViewOrderCustomerAddress({
  avatarUrl,
  contactPerson,
  name,
  email,
  phone,
  suit,
  location,
  street,
  zip,
}: IViewOrderCustomerAddressProps) {
  const [streetAddress, stateAddress] = useMemo(() => {
    if (!street) {
      return ["", ""];
    }
    const comma = street.indexOf(", ", street.indexOf(", "));
    return [street.slice(0, comma), street.slice(comma + 2)];
  }, []);
  return (
    <div className="order-view-address-wrap">
      <div className="row">
        <div className="col-md-6">
          <div className="order-view-address order-view-address-mob">
            <Avatar src={avatarUrl || "/static/images/avatar/1.jpg"}>
              {contactPerson && contactPerson.charAt(0).toUpperCase()}
            </Avatar>
            <div>
              {name && <h6>{name}</h6>}
              {contactPerson && (
                <p>
                  <label>Contact</label>: {contactPerson}
                </p>
              )}
              <p>
                <label>Email</label>: {email}
              </p>
              <p>
                <label>Phone</label>: {phone}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="order-view-address">
            <div>
              <h6>Delivery Address </h6>
              <p>{`${suit || ""}${suit && location ? ", " : " "}${location || ""}`}</p>
              <p>{streetAddress}</p>
              <p>
                {stateAddress}
                {zip ? `, ${zip}` : ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
