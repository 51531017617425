import React from "react";

import { Avatar, Skeleton, Button } from "@mui/material";

export function ViewOrderLoader() {
  return (
    <>
      <div className="order-view-title">
        <h5>
          <Skeleton variant="rectangular" animation="wave" width={320} height={22} />
        </h5>
        <Button>
          <Skeleton variant="rectangular" animation="wave" width={100} height={30} />
        </Button>
      </div>
      <div className="order-view">
        <div className="order-view-address-wrap">
          <div className="row">
            <div className="col-md-6">
              <div className="order-view-address order-view-address-mob">
                <Skeleton variant="circular" animation="wave" width={60} height={60}>
                  <Avatar />
                </Skeleton>
                <div>
                  <p>
                    <label>
                      <Skeleton variant="rectangular" animation="wave" width={200} height={15} />
                    </label>
                  </p>
                  <p>
                    <label>
                      <Skeleton variant="rectangular" animation="wave" width={200} height={15} />
                    </label>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="order-view-address">
                <div>
                  <h6>
                    <Skeleton variant="rectangular" animation="wave" width={200} height={22} />
                  </h6>
                  <p>
                    <Skeleton variant="rectangular" animation="wave" width={200} height={15} />
                  </p>
                  <p>
                    <Skeleton variant="rectangular" animation="wave" width={200} height={15} />
                  </p>
                  <p>
                    <Skeleton variant="rectangular" animation="wave" width={200} height={15} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="od-common-box">
          <h6>
            <Skeleton variant="rectangular" animation="wave" width={200} height={22} />
          </h6>
          <p>
            <Skeleton variant="rectangular" animation="wave" width={600} height={15} />
          </p>
        </div>

        <div className="review-order-item">
          <h6>
            <Skeleton variant="rectangular" animation="wave" width={170} height={22} />
          </h6>
          <div className="roi-single">
            <h6>
              <Skeleton variant="rectangular" animation="wave" width={170} height={22} />
            </h6>
          </div>
        </div>
        <div className="review-order-price">
          <h6>
            <Skeleton variant="rectangular" animation="wave" width={180} height={22} />
          </h6>
          <div className="other-charges">
            <div className="other-charges-item">
              <label>
                <Skeleton variant="rectangular" animation="wave" width={120} height={15} />
              </label>
              <b>
                <Skeleton variant="rectangular" animation="wave" width={90} height={20} />
              </b>
            </div>
            <div className="other-charges-item">
              <label>
                <Skeleton variant="rectangular" animation="wave" width={120} height={15} />
              </label>
              <b>
                <Skeleton variant="rectangular" animation="wave" width={90} height={20} />
              </b>
            </div>
            <div className="other-charges-item">
              <label>
                <Skeleton variant="rectangular" animation="wave" width={120} height={15} />
              </label>
              <b>
                <Skeleton variant="rectangular" animation="wave" width={90} height={20} />
              </b>
            </div>
          </div>
          <div className="common-cart-viewbox common-cart-viewbox-tip">
            <label>
              <Skeleton variant="rectangular" animation="wave" width={120} height={15} />
            </label>
            <b>
              <Skeleton variant="rectangular" animation="wave" width={90} height={20} />
            </b>
          </div>
          <div className="common-cart-viewbox common-cart-viewbox-tip">
            <label>
              <Skeleton variant="rectangular" animation="wave" width={120} height={15} />
            </label>
            <b>
              <Skeleton variant="rectangular" animation="wave" width={90} height={20} />
            </b>
          </div>
          <div className="common-cart-viewbox common-cart-viewbox-tip">
            <label>
              <Skeleton variant="rectangular" animation="wave" width={120} height={15} />
            </label>
            <b>
              <Skeleton variant="rectangular" animation="wave" width={90} height={20} />
            </b>
          </div>
        </div>
      </div>
    </>
  );
}
