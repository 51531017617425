//  this page is for adding the cusines for the caterer
import React from 'react';
import { useState } from "react";
import { Button,CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_CUISINE_TYPE } from "../../Graphql/queries";
import { UPDATE_CATERE } from "../../Graphql/mutations";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormGroup from "@mui/material/FormGroup";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import {
  toggleSnackbar,
} from "../../ReactiveVariables/index";
const StyledCheckbox = styled(Checkbox)`
  svg {
    color: #fd4e3b;
    font-size: 22px;
  }
`;
export default function Cuisines(props) {
  const currentData =
  props?.currentDetails?.getOneCaterer || props?.userValues?.updateCaterer;
  const [checkedValue, setCheckedValue] = useState(currentData?.cuisineType??[]);
  const {data} = useQuery(LIST_CUISINE_TYPE,{variables: { sortField: 'cuisineName'}})
  const [updateCaterer, { loading }] = useMutation(UPDATE_CATERE, {
    onCompleted: (res) => {
      props.setUserValues(res);
      props.setActiveStep(3);
    },
  });
  //adding cuisines to the array
  function addElement(item) {
    let newElement = [...checkedValue];
    newElement.push(item);
    setCheckedValue(newElement);
  }
  //removing cuisines from the array
  function removeElement(item) {
    let index = checkedValue.indexOf(item);
    let array = [...checkedValue]
    array.splice(index, 1);
    setCheckedValue(array);
  }
  // function for mutation of the cusines
  function UpdateCuisine(params) {
    let cuisineData= {
      cuisineType:checkedValue,
      tabValue: "2"
    }
    try {
      updateCaterer({ variables: { id: props?.id,catererId:currentData?.catererUsers[0]?.catererId||props.catererId, data: cuisineData } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Cuisines saved successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <div className="create-step-form">
      <h5 className="h5">Please select all the cuisine types offered</h5>
      <h6 className="subhead">You can select multiple options as offered.</h6>

      <div className="row addBusinessSet">
        {data?.listCuisineType?.data?.map((item) => (
          <FormGroup className="col-md-3 col-6">
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={checkedValue.includes(item?._id)}
                  onChange={(e) =>
                    e.target.checked === true
                      ? addElement(item?._id)
                      : removeElement(item?._id)
                  }
                  color={"primary"}
                  icon={<CheckCircleOutlineIcon style={{ color: "#9F9E9E" }} />}
                  checkedIcon={<CheckCircleIcon />}
                // {...others}
                />
              }
              label={item?.cuisineName}
            />
          </FormGroup>
        ))}
        <div>
          <div className="col-12">
            <div className="createStepBtn">
              <Button
                className="cancelBtn"
                variant="contained"
                color="secondary"
                onClick={() => props.setActiveStep(1)}
              >
                Previous
              </Button>
              <Button
                className="saveBtn"
                variant="contained"
                color="primary"
                onClick={()=>UpdateCuisine()}
              >
               {loading?<CircularProgress color="inherit" size={20} />:"Save and Proceed "}<ArrowForwardIcon className="btnArrow" />
              </Button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
