import { Table, Select, MenuItem, Button } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { formatter } from "../Common/commonUtils";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import GiftCaterCash from "./GiftCash";
import moment from "moment";
import {useNavigate } from "react-router-dom";
import React, { useState } from "react";
export default function CaterCashList(props){
  const navigate = useNavigate()
  const [openGiftCash,setOpenGiftCash] = useState(false)
    return(
        <div>
          <GiftCaterCash 
          openGiftCash={openGiftCash}
          setOpenGiftCash={setOpenGiftCash}
          username={props?.username}
          userFirstName={props?.userFirstName}
          email={props?.email}
          />
            <div className="location-head catercash-head">
            {<div className="location-head-title justify-content-between">
              { <h5> {props?.Earned?"Earned":props?.Redeemed?"Redeemed":props?.Gifted?"Gifted":"Earned History"}</h5>}
              {props?.customerTab&&<Button color="primary" variant="contained" onClick={()=>setOpenGiftCash(true)}>
                  <img src="../assets/images/icon-add-menu.svg" className="img-fluid me-1" alt="Icon" /> Give CaterCash
                </Button>}
              {(props?.Earned||props?.Redeemed||props?.Gifted)&&<input
                placeholder={props?.Earned?"Search by Customer name or Order Id":"Search by Customer name"}
                className="form-control"
                value={props?.search}
                onChange={(e) => {
                  props?.setPage(1);
                  props?.setSearch(e.target.value);
                }}
              />}
            </div>}
          </div>
          <TableContainer>
            <Table
              className="commonTabel"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  {!props?.Gifted&&!props?.Redeemed&&<TableCell align="left">{props?.Earned||props?.Redeemed?"Order#":"Order/Gift Card"}</TableCell>}
                  <TableCell align="center">{props?.Earned||props?.Redeemed||props?.Gifted?"Customer Name":"Status"}</TableCell>
                  {!props?.Gifted&&<TableCell align="center">{props?.Earned?"Caterer Name":props?.Redeemed?"Gift Card ID#":"Order Amount"}</TableCell>}
                  <TableCell align="center">{props?.Earned?"Earned":props?.Redeemed?"Redeemed":props?.Gifted?"Gifted":"CaterCash"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  props?.loading ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p> Loading...</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : props?.data?.listCaterCash?.data.length> 0? (
                    props?.data?.listCaterCash?.data.map((item) => (
                      <TableRow>
                        <TableCell>
                          {moment(item?.paidOn).format('L')}
                        </TableCell>

                        {!props?.Gifted && !props?.Redeemed && (
                          <TableCell>
                            {props?.Earned || props?.Redeemed
                              ? item?.orderNo ? `#${item?.orderNo}` : '---'
                              : item?.caterCashSrc === "GiftVoucher"
                              ? item?.voucherCode
                              : item?.caterCashSrc === "Gifted"
                              ? '---'
                              : item?.caterCashSrc === "GiftCard"
                              ? item?.voucherCode
                              : item?.caterCashSrc === "Review"
                              ? '---'
                              : item?.caterCashSrc === "Referred"
                              ? '---'
                              : `#${item?.orderNo}`}
                          </TableCell>
                        )}

                        <TableCell
                          style={(props?.Earned||props?.Redeemed||props?.Gifted)&&{cursor:"pointer"}}
                          align="center"
                          onClick={()=>{(props?.Earned||props?.Redeemed||props?.Gifted)&&navigate(`/customers/${item?.customerId}`)}}>

                          {
                            props?.Earned || props?.Redeemed || props?.Gifted
                              ? item?.customerName
                              : (
                                <Button
                                  className="status-btn"
                                  variant="contained"
                                  style={{
                                    backgroundColor:
                                      item?.caterCashSrc === "Order" && item?.isReferral === true ? "#FD4E3B"
                                      : item?.caterCashSrc === "Order" ? "#23BF50"
                                      : item?.caterCashSrc === "GiftVoucher" ? "#7877ED"
                                      : item?.caterCashSrc === "Gifted" ? "#EDA977"
                                      : item?.caterCashSrc === "GiftCard" ? "#7877ED"
                                      : item?.caterCashSrc === "SignUpPromo" ? "#7877ED"
                                      : "#FD4E3B",
                                  }}
                                >
                                  {
                                    item?.caterCashSrc === "Order" && item?.isReferral === true ? "Referral"
                                    : item?.caterCashSrc === "Order" && item?.caterCashStatus === false ? "Pending"
                                    : item?.caterCashSrc === "SignUpPromo" && item?.caterCashStatus === true ? "Promo"
                                    : item?.caterCashSrc === "Order" && item?.caterCashStatus === true ? "Earned"
                                    : item?.caterCashSrc === "GiftVoucher" ? "Redeemed"
                                    : item?.caterCashSrc === "Gifted" ? "Gifted"
                                    : item?.caterCashSrc === "Review" ? "Review"
                                    : item?.caterCashSrc === "Refund" ? "Deducted"
                                    : item?.caterCashSrc === "GiftCard" ? "Redeemed"
                                    : "Referral"
                                  }
                                </Button>
                              )
                          }
                        </TableCell>

                        {!props?.Gifted && (
                          <TableCell align="center">
                            {props?.Earned
                              ? item?.catererName
                              : props?.Redeemed
                              ? item?.voucherCode
                              : props?.Gifted && item?.caterCashSrc === "Gifted"
                              ? `#${item?.giftNo}`
                              : item?.caterCashSrc === "GiftCard"
                              ? "---"
                              : item?.caterCashSrc === "Gifted"
                              ? "---"
                              : item?.caterCashSrc === "Review"
                              ? "---"
                              : item?.caterCashSrc === "Referred"
                              ? "---"
                              : formatter.format(item?.orderTotalAmt)}
                          </TableCell>
                        )}

                        <TableCell align="center" style={{color:item?.caterCashSrc==="GiftCard"||item?.caterCashSrc==="Refund"?"red":""}}>
                          {item?.caterCashSrc==="GiftCard"||item?.caterCashSrc==="Refund"?`-${formatter.format(item?.amount)}`:formatter.format(item?.amount)}
                        </TableCell>
                      </TableRow>

                    ))): (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <div
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <p>No list added Yet!</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="table-pagenation mt-2 inside">
          <div className="rowCount">
            <Select
              onChange={props?.handlePageInputChange}
              value={props?.rowsPerPage}
            >
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(props?.data?.listCaterCash?.count / props?.rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={props?.page}
              onChange={props?.handleChangePage}
            />
          </Stack>
        </div>
        </div>
    )
}