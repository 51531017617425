import React from "react";

export enum CardProvider {
  Visa = "Visa",
  Amex = "Amex",
  Discover = "Discover",
  Master = "Master",
  default = "default",
  empty = "empty",
}

enum CardImage {
  Visa = "../assets/images/cc-visa.svg",
  Amex = "../assets/images/cc-amex.svg",
  Discover = "../assets/images/cc-discover.svg",
  Master = "../assets/images/cc-master.svg",
  default = "../assets/images/card-1.jpg",
  empty = "../assets/images/card-2.jpg",
}

interface IPaymentCardProvider {
  provider: CardProvider;
}

export function PaymentCardProvider({ provider }: IPaymentCardProvider) {
  return (
    <>
      <img src={CardImage[provider]} className="img-fluid" alt="card-provider" />
    </>
  );
}
