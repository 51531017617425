import React, { useState,useEffect } from "react";
import LoggedHeader from "../Common/loggedHeader";
import SideMenu from "../../components/organisms/sidebar";
import CustomerHeader from "../Common/customerHeader";
import CaterCashSummery from "./CaterCashSum";
import CaterCashList from "./CaterCashList";
import GiftVoucher from "./GIftVoucher";
import { LIST_CATER_CASH,TOTLA_CATERCASH,CATER_CASH_BALANCE } from "../../Graphql/queries";
import { useQuery } from "@apollo/client";
import { userDeatils,authVar } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import MobileHeader from "../Common/mobileHeader";
import { useNavigate } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function CaterCash(props) {
  const user = useReactiveVar(userDeatils);
  const navigate = useNavigate()
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [search,setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [voucherData,setVoucherData]= useState(null)
  
  let currentLocation = window.location.pathname;
  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;
  const userId =
    user?.data?.login?._id ||
    user?.data?.currentUserDetails?._id ||
    user?.data?.createUserIdentity?._id;
  const caterCashVariables = {
    skip: (page - 1) * rowsPerPage,
    limit: rowsPerPage,
    search:search,
    customerId: props?.id?props?.id: userType === "Customer" ? userId : "",
    tabValue:userType === "Admin"&&currentLocation==="/CaterCash"&&value===0?"Earned":value===1?"Redeemed":value===2?"Gifted":"",
    caterCashType:userType === "Admin"&&currentLocation==="/CaterCash"&&value===0?"Earned":value===1?"Redeemed":value===2?"Gifted":""
  };
  const { data, loading,error } = useQuery(LIST_CATER_CASH, { fetchPolicy:"cache-and-network",
    variables: caterCashVariables,
  });
  const {data:caterCashTotal} = useQuery(TOTLA_CATERCASH,{ fetchPolicy:"cache-and-network",
    variables: {customerId: props?.id?props?.id: userType === "Customer" ? userId : ""}
  })
  const { data: totalData } = useQuery( CATER_CASH_BALANCE, {
    skip: !userType || userType === 'Customer',
  } );

  useEffect(()=>{
    if(error&&error.message==="Not authenticated"){
        localStorage.clear();
        authVar({
          loaded: true,
          auth: false,
        });
        userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
    }
  },[error&&error])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };
  return (
    <div>
      {userType === "Customer" ? (
        <>
        <CustomerHeader title="CaterCash"/>
        <MobileHeader
        selected={"CaterCash"}
        />
        </>
      ) : (
        <LoggedHeader title={props?.customerTab?props?.username:"CaterCash" }/>
      )}
      {/* Side menu for different options for the Customer */}
      <SideMenu
        selected={props?.customerTab?"Customers":"CaterCash"}
        user={userType === "Customer" ? "customer" : "admin"}
      />
      {userType === "Customer" ? (
        <div className="contentWrap">
          <div className="contentWrapInner cater-summary">
            {userType === "Customer" && voucherData&&<GiftVoucher voucherData={voucherData} />}
            <CaterCashSummery 
            userType={"Customer"} 
            caterCashTotal={caterCashTotal}
            setVoucherData={setVoucherData}
            />
            <CaterCashList
              data={data}
              loading={loading}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              handlePageInputChange={handlePageInputChange}
              handleChangePage={handleChangePage}
              caterCashTotal={caterCashTotal}
            />
          </div>
        </div>
      ) :props?.customerTab?(
        <div>
          <div>
            <CaterCashList
              data={data}
              loading={loading}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              handlePageInputChange={handlePageInputChange}
              handleChangePage={handleChangePage}
              customerTab
              username={props?.username}
              userFirstName={props?.userFirstName}
              email={props?.email}
            />
          </div>
        </div>
      ): (
        <div className="contentWrap">
          <div>
            <div className="cater-tab">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Earned" {...a11yProps(0)} />
                <Tab label="Redeemed" {...a11yProps(1)} />
                <Tab label="Gifted" {...a11yProps(2)} />
              </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <CaterCashSummery 
               caterCashTotal={caterCashTotal}
               totalData={totalData}
                />
              <CaterCashList
                data={data}
                loading={loading}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handlePageInputChange={handlePageInputChange}
                handleChangePage={handleChangePage}
                Earned
                search={search}
                setSearch={setSearch}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CaterCashSummery
                caterCashTotal={caterCashTotal}
                totalData={totalData}
                />
              <CaterCashList
                data={data}
                loading={loading}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handlePageInputChange={handlePageInputChange}
                handleChangePage={handleChangePage}
                Redeemed
                search={search}
                setSearch={setSearch}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CaterCashSummery 
                caterCashTotal={caterCashTotal}
                totalData={totalData}
                />
              <CaterCashList
                data={data}
                loading={loading}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handlePageInputChange={handlePageInputChange}
                handleChangePage={handleChangePage}
                Gifted
                search={search}
                setSearch={setSearch}
              />
            </TabPanel>
          </div>
        </div>
      )}
    </div>
  );
}
