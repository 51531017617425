import { TextField } from "@mui/material";
import React, { useCallback } from "react";

const MAX_INSTRUCTION_LENGTH = 150;

export function CartItemInstruction({ instruction, setInstruction }) {
  const onChangeInstruction = useCallback(
    (event) => {
      setInstruction(event.target.value);
    },
    [setInstruction],
  );
  return (
    <>
      <div className="textBox pt-4 pt-md-5">
        <h6 className="h6">Special Instructions</h6>
        <TextField
          placeholder="Add any request here"
          defaultValue={instruction}
          multiline
          rows={4}
          className="w-100"
          inputProps={{ maxLength: MAX_INSTRUCTION_LENGTH }}
          onChange={onChangeInstruction}
        />
      </div>
      <p className="error-msg" style={{ color: "#9F9E9E" }}>
        Maximum {MAX_INSTRUCTION_LENGTH} characters
      </p>
    </>
  );
}
