import React from "react";

import { Checkbox, FormControlLabel, FormGroup, styled } from "@mui/material";
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";

import { IAddonTypeItemResponse, ISubAddonItemResponse } from "../../cart-item.typings";
import { formatter } from "Modules/Common/commonUtils";

interface ICartItemSubAddonItemProps {
  subAddon: ISubAddonItemResponse;
  addon: IAddonTypeItemResponse;
  isSubAddonSelected(addon: IAddonTypeItemResponse, subAddon: ISubAddonItemResponse): boolean;
  onSelectSubAddon(
    isChecked: boolean,
    item: IAddonTypeItemResponse,
    subItem: ISubAddonItemResponse,
  ): void;
}

const StyledCheckbox = styled(Checkbox)`
  svg {
    font-size: 22px;
  }
`;

export function CartItemSubAddonItem({
  subAddon,
  addon,
  isSubAddonSelected,
  onSelectSubAddon,
}: ICartItemSubAddonItemProps) {
  const onChange = (event) => {
    onSelectSubAddon(event.target.checked, addon, subAddon);
  };
  return (
    <FormGroup className="col-md-4">
      <FormControlLabel
        control={
          <StyledCheckbox
            checked={isSubAddonSelected(addon, subAddon)}
            onChange={onChange}
            color={"primary"}
            icon={<CheckCircleOutline style={{ color: "#9F9E9E" }} />}
            checkedIcon={<CheckCircle />}
          />
        }
        label={
          <p>
            {subAddon.subAddOnName}
            {subAddon.subAddOnPrice !== 0 && (
              <span className="extra-charges">{`(+${formatter.format(
                subAddon.subAddOnPrice,
              )})`}</span>
            )}
          </p>
        }
      />
    </FormGroup>
  );
}
