// this page is for editing business details page
import React from "react";

import Drawer from "@mui/material/Drawer";
import {
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  OutlinedInput,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { userDeatils, toggleSnackbar, authVar } from "../../../ReactiveVariables/index";
import { useForm } from "react-hook-form";
import { useMutation, useLazyQuery } from "@apollo/client/react/hooks";
import { UPDATE_CATERE } from "../../../Graphql/mutations";
import { IMAGE_UPLOAD } from "../../../Graphql/queries";
import { useReactiveVar } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import CropImage from "../../Common/cropImage";
import throttle from "lodash/throttle";
import axios from "axios";
import { useState, useRef, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// we use material ui autocomplete location for fetching the location.
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function BusinessDetails(props) {
  const navigate = useNavigate();
  const user = useReactiveVar(userDeatils);
  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;
  const [imageDeleteCheck, setImageDeleteCheck] = useState(false);
  const theme = useTheme();
  const currentData = props?.data?.getOneCaterer;
  const userPhone = props?.userData?.phoneNumber;
  const [value, setValue] = useState(currentData?.locationName ?? null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [cuisineName, setCuisineName] = useState(currentData?.cuisineType ?? []);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [cordinates, setCordinates] = useState(currentData?.location?.coordinates ?? "");
  const [zip, setZip] = useState(currentData?.catererZipCode ?? "");
  const [city, SetCity] = useState(currentData?.catererCity ?? "");
  const [stateShortName, setStateShortName] = useState(currentData?.catererStateShort ?? "");
  const [deliveryOptions, setDeliveryOptions] = useState(
    currentData?.deliveryOption ?? "Pickup and Delivery",
  );
  const [businessPhone, setBusinessPhone] = useState(currentData?.businessPhone);
  const [additionalPhone, setAdditionalPhone] = useState(currentData?.additionalPhone);

  const [contactNumber, setContactNumber] = useState(currentData?.contactNumber);
  const [sameEmail, setSameEmail] = useState(currentData?.sameEmail ?? false);
  const [emailArr, setEmailArr] = useState(
    currentData?.deliveryEmail && currentData?.deliveryEmail?.length > 0
      ? currentData?.deliveryEmail
      : [""],
  );
  const [emailValidation, setEmailValidation] = useState(Array(emailArr.length).fill(""));
  const loaded = useRef(false);
  const schema = yup.object().shape({
    businessName: yup.string().required("Business Name is required"),
    contactNumber: yup
      .string()
      .test("contactNumber", "The phone number must be 10 digits.", (value) => {
        if (value && value.length < 14) return false;
        else return true;
      }),
    locationName: yup.string().nullable().required("Location is required"),
    businessPhone: yup
      .string()
      .required("Phone number is required")
      .min(14, "The phone number must be 10 digits."),
    additionalPhone:
      props.userData.userType === "Admin"
        ? yup.string().min(14, "The phone number must be 10 digits.")
        : yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: newSetValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      businessPhone: currentData?.businessPhone,
      deliveryFeeType: currentData?.deliveryFeeType ?? "Percentage",
      convenienceFeeType: currentData?.convenienceFeeType ?? "Percentage",
      driverTipType: currentData?.driverTipType ?? "Percentage",
      locationName: currentData?.locationName,
    },
  });

  const [updateCaterer, { loading }] = useMutation(UPDATE_CATERE, {
    refetchQueries: ["GetOneCaterer"],
    onCompleted: (res) => {
      props.setOpenBusiness(false);
    },
  });

  const uplaodImageVariables = {
    originalFilename: selectedImage?.fileName,
    path: "/userprofile",
  };

  const [upload, { data: uploadImageData }] = useLazyQuery(IMAGE_UPLOAD, {
    fetchPolicy: "network-only",
    variables: uplaodImageVariables,
  });

  function handleUploadClick(imagefile) {
    let obj = {};
    obj["fileName"] = imagefile.name;
    obj["url"] = URL.createObjectURL(imagefile);
    obj["file"] = imagefile;
    setSelectedImage(obj);
    upload();
  }

  function checkImage(e) {
    let file = e.target.files[0];

    if (
      !(file?.type === "image/jpeg" || file?.type === "image/png" || file?.type === "image/jpg")
    ) {
      toggleSnackbar({
        status: true,
        message: "This file format is not supported. Please upload only .png or .jpg files",
        variant: "error",
      });
      return false;
    } else if (file.size > 5242880) {
      toggleSnackbar({
        status: true,
        message: "Please upload a file smaller than 5 MB",
        variant: "error",
      });
      return false;
    } else {
      setCropImage(file);
      setCropOpen(true);
    }
  }

  function handelCropDone(result) {
    setCropImage(null);
    handleUploadClick(result);
  }

  async function uploadImage(params) {
    setImageUploadLoading(true);

    if (selectedImage) {
      const url = uploadImageData?.imageUpload?.signedUrl;
      const response = await axios.put(url, selectedImage.file, {
        headers: {
          "Content-Type": selectedImage?.file?.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "X-Requested-With",
        },
      });
    }

    setImageUploadLoading(false);
    onSubmit(params);
  }

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps",
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  function onSubmit(params) {
    const location = {
      type: "Point",
      coordinates: cordinates,
    };

    const newImage = uploadImageData?.imageUpload?.publicUrl;

    // Location name does not need zip since it is saved as its own field
    // This cleans the zip from the location name if it exists.
    const locationName = params?.locationName || "";
    const cleanedLocationName = zip ? locationName.replace(zip, "").trim() : locationName;

    const data = {
      ...params,
      cuisineType: cuisineName,
      businessPhone,
      contactNumber,
      sameEmail,
      catererZipCode: zip,
      catererCity: city,
      catererStateShort: stateShortName,
      location,
      deliveryOption: deliveryOptions,
      deliveryEmail: emailArr,
      locationName: cleanedLocationName,
      ...(newImage && { imageUrl: newImage }),
    };

    try {
      updateCaterer({
        variables: {
          id: props?.userData?._id,
          catererId:
            props?.data?.getOneCaterer?.catererUsers[0]?.catererId || props?.userData?.catererId,
          data,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Business details updated successfully",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;

          if (message === "Not authenticated") {
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            userType === "Admin"
              ? navigate("/admin", { state: "noAuth" })
              : navigate("/login", { state: "noAuth" });
          } else {
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
        });
    } catch (err) {
      const message = err && err.message;

      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-,(,), ]+/g, "");
    let x;

    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6);
    } else {
      x = "(" + newNum.slice(0, 3) + ") " + newNum.slice(3, 6) + "-" + newNum.slice(6, 10);
    }

    return x;
  };

  async function handleValueSelect(value) {
    SetCity(
      value?.terms.length === 4
        ? value?.terms[1].value
        : value?.terms[2].value === "FL"
        ? value?.terms[1].value
        : value?.terms[2].value,
    );
    setStateShortName(
      value?.terms.length === 4
        ? value?.terms[2].value
        : value?.terms[2].value === "FL"
        ? value?.terms[2].value
        : value?.terms[3].value,
    );
    setValue(value);

    const config = {
      method: "get",
      url: `https://maps.googleapis.com/maps/api/geocode/json?place_id=${value?.place_id}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        let cordArray = [];
        let lat = response?.data?.results[0]?.geometry?.location?.lat;
        let lng = response?.data?.results[0]?.geometry?.location?.lng;
        let zip = "";

        cordArray.push(lng, lat);
        setCordinates(cordArray);

        let arr = response?.data?.results?.[0]?.address_components;
        let position = arr.findIndex(
          (element) => element.types && element.types[0] === "postal_code",
        );

        if (position !== -1) {
          zip = response?.data?.results?.[0]?.address_components?.[position]?.["long_name"];
        }

        setZip(zip);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCuisineName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const reglocation = register("locationName");

  function handleDeleteImage() {
    setImageDeleteCheck(true);
    setSelectedImage(null);
  }

  return (
    <Drawer
      className="common-drawer"
      anchor={"right"}
      open={props.openBusiness}
      onClose={() => props.setOpenBusiness(false)}>
      <CropImage
        open={cropOpen}
        setOpen={setCropOpen}
        image={cropImage}
        handelCropDone={handelCropDone}
        aspectRatio={16 / 9}
      />
      <input
        type={"file"}
        className="d-none"
        id="add-user-input"
        accept=".jpg, .png, .jpeg"
        onChange={checkImage}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <form onSubmit={handleSubmit(uploadImage)}>
        <div className="drawer-head">
          <h4 className="h4">
            {" "}
            <img
              src="../assets/images/icon-business.svg"
              className="img-fluid"
              alt="Business Details Icon"
            />
            Business Details
          </h4>
          <span style={{ cursor: "pointer" }} onClick={() => props.setOpenBusiness(false)}>
            <img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" />
          </span>
        </div>
        <div className="create-step-form">
          <div className="container-fluid">
            <div className="row">
              <h6 className="h6 mb-4">Business Contact Details</h6>
              <div className="text-left">
                <div className="upload-img-wrap">
                  <img
                    alt="Remy Sharp"
                    src={
                      selectedImage
                        ? selectedImage.url
                        : currentData?.imageUrl === "#"
                        ? "../assets/images/upload-image.svg"
                        : currentData?.imageUrl && !imageDeleteCheck
                        ? currentData?.imageUrl
                        : "../assets/images/upload-image.svg"
                    }
                    className={
                      currentData?.imageUrl || selectedImage
                        ? "upload-img img-fluid"
                        : "upload-img img-fluid upload-no-image"
                    }
                    onClick={(e) => document.getElementById("add-user-input").click()}
                  />
                  {(selectedImage === null ||
                    currentData?.imageUrl === null ||
                    (currentData?.imageUrl && imageDeleteCheck === true)) && (
                    <img
                      onClick={(e) => document.getElementById("add-user-input").click()}
                      src="../assets/images/icon-upload-plus.svg"
                      alt="uploadImage"
                      className="upload-icon img-fluid"
                    />
                  )}
                  {(selectedImage || (currentData?.imageUrl && imageDeleteCheck === false)) && (
                    <img
                      alt="closeIconImage"
                      onClick={() => handleDeleteImage()}
                      src="../assets/images/close-black.svg"
                      className="upload-icon img-fluid"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-12 textBox">
                <TextField
                  id="outlined-basic"
                  autoFocus
                  label="BUSINESS NAME*"
                  defaultValue={currentData?.businessName}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("businessName")}
                />
                {errors.businessName && (
                  <span className="error-msg">{errors.businessName.message}</span>
                )}
              </div>

              <div className="col-md-12 textBox">
                <TextField
                  id="outlined-basic"
                  label="Phone Number (For SMS Updates)*"
                  variant="outlined"
                  //disabled={sameContact}
                  name="businessPhone"
                  className="textField allFeild"
                  value={businessPhone}
                  onChange={(e) => {
                    if (
                      !isNaN(e.target.value.replace(/[-,(,), ]+/g, "")) &&
                      e.target.value.replace(/[-,(,), ]+/g, "").length <= 10
                    ) {
                      register("businessPhone").onChange({
                        target: {
                          value: e.target.value,
                          name: "businessPhone",
                        },
                      });
                      setBusinessPhone(phoneNumberFormat(e.target.value));
                    }
                  }}
                />
                {errors.businessPhone && (
                  <span className="error-msg">{errors.businessPhone.message}</span>
                )}
              </div>
              {props.userData.userType === "Admin" && (
                <div className="col-md-12 textBox">
                  <TextField
                    id="outlined-basic"
                    label="Additional Phone"
                    variant="outlined"
                    name="businessPhone"
                    className="textField allFeild"
                    value={additionalPhone}
                    onChange={(e) => {
                      if (
                        !isNaN(e.target.value.replace(/[-,(,), ]+/g, "")) &&
                        e.target.value.replace(/[-,(,), ]+/g, "").length <= 10
                      ) {
                        register("additionalPhone").onChange({
                          target: {
                            value: e.target.value,
                            name: "additionalPhone",
                          },
                        });
                        setAdditionalPhone(phoneNumberFormat(e.target.value));
                      }
                    }}
                  />
                  {errors.additionalPhone && (
                    <span className="error-msg">{errors.additionalPhone.message}</span>
                  )}
                </div>
              )}
              <div className="col-md-12 textBox">
                <Autocomplete
                  className="textField allFeild"
                  id="google-map-demo"
                  sx={{ width: 300 }}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.description.replace(", USA", "")
                  }
                  noOptionsText={"Enter your location"}
                  filterOptions={(x) => x}
                  options={options ?? []}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  {...reglocation}
                  value={value}
                  onChange={(event, newValue) => {
                    setOptions(newValue && newValue != null ? [newValue, ...options] : options);
                    setValue(newValue?.description.replace(", USA", ""));
                    reglocation.onChange({
                      target: {
                        value: newValue?.description.replace(", USA", ""),
                        name: "locationName",
                      },
                    });
                    handleValueSelect(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="LOCATION*" fullWidth />}
                  renderOption={(props, option) => {
                    const matches = option?.structured_formatting?.main_text_matched_substrings;
                    if (!matches) return false;
                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map((match) => [match.offset, match.offset + match.length]),
                    );

                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Box
                              component={LocationOnIcon}
                              sx={{ color: "text.secondary", mr: 2 }}
                            />
                          </Grid>
                          <Grid item xs>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}>
                                {part.text}
                              </span>
                            ))}

                            <Typography variant="body2" color="text.secondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
                {errors.locationName && (
                  <span className="error-msg">{errors.locationName.message}</span>
                )}
              </div>

              <div className="col-md-12 textBox">
                <TextField
                  id="outlined-basic"
                  label="TAGLINE"
                  defaultValue={currentData?.tagLine}
                  variant="outlined"
                  className="textField allFeild"
                  {...register("tagLine")}
                  inputProps={{ maxLength: 40 }}
                />
                <p className="error-msg" style={{ color: "#9F9E9E" }}>
                  Maximum 40 characters
                </p>
              </div>
              <div className="col-md-12 textBox mt-2">
                <FormControl className="w-100">
                  <InputLabel id="demo-multiple-name-label">CUISINE</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={cuisineName}
                    onChange={handleChange}
                    input={<OutlinedInput label="Name" />}
                    MenuProps={MenuProps}>
                    {props?.cusineTypes?.listCuisineType?.data?.map((name) => (
                      <MenuItem
                        key={name}
                        value={name._id}
                        style={getStyles(name, cuisineName, theme)}>
                        {name.cuisineName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div>
              <hr />
            </div>
            <div className="row addBusinessSet">
              {emailArr.map((item, index) => (
                <div className="row align-items-center">
                  <div className="col-md-10 textBox">
                    <TextField
                      id="outlined-basic"
                      defaultValue={currentData?.deliveryEmail}
                      value={emailArr[index]}
                      type="email"
                      label="DELIVERY EMAIL ADDRESS"
                      variant="outlined"
                      className="textField allFeild"
                      onChange={(e) => {
                        const email = e.target.value;
                        let arr = [...emailArr];
                        arr[index] = email;
                        setEmailArr(arr);
                        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        const isValid = regex.test(email);

                        if (isValid) {
                          setEmailArr(arr);
                          setEmailValidation((prevState) => {
                            const newState = [...prevState];
                            newState[index] = "";
                            return newState;
                          });
                        } else {
                          setEmailValidation((prevState) => {
                            const newState = [...prevState];
                            newState[index] = "Please enter a valid email address";
                            return newState;
                          });
                        }
                      }}
                    />
                    {emailValidation[index] && (
                      <span className="error-msg">{emailValidation[index]}</span>
                    )}
                  </div>
                  <div className="col-md-2">
                    {index !== 0 && (
                      <a
                        className="more-delivery-address-email remove text-center"
                        onClick={() => {
                          let arr = [...emailArr];
                          arr.splice(index, 1);
                          setEmailArr(arr);
                        }}>
                        Remove
                      </a>
                    )}
                  </div>
                </div>
              ))}
              <div className="mb-3">
                <a
                  className="more-delivery-address-email"
                  onClick={() => {
                    let arr = [...emailArr];
                    arr.push("");
                    setEmailArr(arr);
                  }}>
                  Add more email addresses for delivery notifications
                </a>
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div className="row addBusinessSet">
              <h6 className="h6">Delivery Instructions</h6>
              <div className="col-md-12">
                <TextField
                  id="outlined-basic"
                  multiline
                  defaultValue={currentData?.specialDeliveryInstn}
                  label="Any special delivery instructions/any other information to share with the customer?"
                  variant="outlined"
                  className="textField allFeild"
                  {...register("specialDeliveryInstn")}
                />
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div className="row addBusinessSet"></div>
          </div>
        </div>
        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => {
              props.setOpenBusiness(false);
              setImageDeleteCheck(false);
            }}>
            Cancel
          </Button>
          <Button className="saveBtn" variant="contained" type="submit" color="primary">
            {loading || imageUploadLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              "Update"
            )}
          </Button>
        </div>
      </form>
    </Drawer>
  );
}
