// component for adding and editing addons
import React from 'react';

import { useState } from "react"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { toggleSnackbar,authVar,userDeatils} from "../../../ReactiveVariables/index";
import CircularProgress from "@mui/material/CircularProgress"
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useMutation } from "@apollo/client";
import { CREATE_ADDON_TYPE,UPDATE_ADDON_TYPE } from "../../../Graphql/mutations";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import * as yup from "yup";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: '0px 4px 22px rgba(0, 0, 0, 0.23)',
  borderRadius: '5px',
};

export default function AddOn(props) {
  const navigate= useNavigate()
  const user = useReactiveVar(userDeatils);
  const [type, setType] = useState(props?.selectedAddOn?.type??"Single")
  const schema = yup.object().shape({
    addOnTypeName: yup.string().required("Add On Type is required"),
    type: yup.string().required("Selection Type is required"),
    addOnTypeLimit: type === "Multiple"? yup.number().transform(value => (isNaN(value) ? undefined : value)).required("Add On Type Limit is required"):null,
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
   } = useForm({ resolver: yupResolver(schema) });

  const [createAddOnType,{loading}]= useMutation(CREATE_ADDON_TYPE,{ refetchQueries:['ListAddOnType'],
    onCompleted:(res)=>{
      props.setOpenAddon(false)
      props?.setSelectedAddOn('')
      props?.setNewlyAddedAddOn(res?.createAddOnType)
      props?.setCreateAddOnStatus(true)
    }
  })
  const [updateAddOnType,{loading:updateLoading}]= useMutation(UPDATE_ADDON_TYPE,{ refetchQueries:['ListAddOnType'],
  onCompleted:(res)=>{
    props.setOpenAddon(false)
    props?.setEditAddOnFlag(false)
    props?.setSelectedAddOn('')
  }
})
const userType =
user?.data?.login?.userType || user?.data?.currentUserDetails?.userType || user?.data?.createUserIdentity?.userType || user?.data?.createCaterer?.userType;
// getting count for multiple type
    var count= [""];
    let i ;
    for (i=1;i<=20;i++){
      count.push(i)
    }
  const onSubmit = (params) => {
    params["catererId"] = props?.data?.getOneMenu?.catererId;
    params["menuId"] = props?.data?.getOneMenu?._id;

    if(params["type"]==="Single"){
      params["addOnTypeLimit"]=null}

    if(props?.selectedAddOn!==""){
      params["_id"]=props?.selectedAddOn?._id
    }
    try{
      // checks if selected add is empty if empty created is called else update is called
      !props?.editAddOnFlag?createAddOnType({ variables: { data: params } })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Add On type added successfully",
          variant: 'success'
        });
      })
      .catch((err) => {
        const message = err && err.message;
        if(message==="Not authenticated"){
          localStorage.clear();
          authVar({
            loaded: true,
            auth: false,
          });
          userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
        }else{
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
      }):
      updateAddOnType({ variables: { data: params,id:props?.selectedAddOn?._id} })
      .then((res) => {
        toggleSnackbar({
          status: true,
          message: "Addon updated successfully",
          variant: 'success'
        });
      })
      .catch((err) => {
        const message = err && err.message;
        if(message==="Not authenticated"){
          localStorage.clear();
          authVar({
            loaded: true,
            auth: false,
          });
          userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
        }else{
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
      });
    }catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <div>
      <Modal
        open={props?.openAddon}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="menu-add-on-popup">
          <h4 className="h4">Add On</h4>
          <form onSubmit={handleSubmit(onSubmit)} noValidate  autoComplete="off">
            <div  className="menu-add-on-popup-field">
              <div className="textBox">
                <TextField fullWidth
                  id="createAddonName"
                  label="Add On Type *"
                  defaultValue={props?.selectedAddOn?.addOnTypeName}
                  autoFocus
                  variant="outlined"
                  className="textField allFeild"
                  {...register("addOnTypeName")}
                />
                {errors.addOnTypeName && <span className="error-msg">{errors.addOnTypeName.message}</span>}
              </div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Selection Type*
                </InputLabel>
                <Controller
                  control={control}
                  name="type"
                  defaultValue={props?.selectedAddOn?.type}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={value}
                      label="Selection Type"
                      onChange={(e) => {
                        onChange(e.target.value); setType(e.target.value)
                      }}
                    >

                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Multiple"}>Multiple</MenuItem>

                    </Select>
                  )}
                />
               {errors.type && <span className="error-msg">{errors.type.message}</span>}
              </FormControl>
              {type === "Multiple" && <div>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Add On Limit*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Add On Limit*"
                    defaultValue={props?.selectedAddOn?.addOnTypeLimit}
                    {...register("addOnTypeLimit")}
                  >

                    {count.map((item)=>(
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  {errors.addOnTypeLimit && <span className="error-msg">{errors.addOnTypeLimit.message}</span>}
                </FormControl>
              </div>}
            </div>
            <div className="createStepBtn">
              <Button
                className="cancelBtn"
                variant="outlined"
                color="secondary"
                onClick={() => { props.setOpenAddon(false);props?.setSelectedAddOn('') }}
              >
                Cancel
              </Button>
              <Button
                className="saveBtn"
                variant="contained"
                color="primary"
                type="submit"
              >
                {loading || updateLoading? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              props?.selectedAddOn === "" ? "Add" : "Update"
            )}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}