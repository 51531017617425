import React from "react";
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { PACKAGE_OPTIONS_LIST } from "../caterer-list";
import { ICatererListFilter } from "../caterer-list.typings";

interface ICatererListFilterProps extends ICatererListFilter{
  count: number;
  onOpenFilter(): void;
}

export function CatererListFilter({
  count,
  search,
  dietary,
  sortBy,
  packageOption,
  cuisine,
  cuisineList,
  setCuisine,
  setPackageOption,
  setSortBy,
  setDietary,
  setSearch,
  onOpenFilter,
  onClear,
}: ICatererListFilterProps) {
  return (
    <div className="listing-filter-secondary">
      <div className="container">
        <h3>{count} Caterers Found</h3>
        <IconButton
          onClick={onOpenFilter}
          className="d-block d-lg-none cater-list-filter-btn"
          title="Filter">
          <img src="../assets/images/icon-filter.svg" className="img-fluid" alt="Filter" />
        </IconButton>
        <div className="d-none d-lg-flex">
          <TextField
            className="form-control lfs-search lfs-item"
            placeholder="Search by Caterers Name"
            value={search}
            onChange={setSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src="../assets/images/search.svg" />
                </InputAdornment>
              ),
            }}
          />

          <FormControl className="lfs-deitary lfs-item">
            <Select
              labelId="Time"
              id="time"
              className="hours-dropdown"
              value={dietary}
              onChange={setDietary}>
              <MenuItem disabled className="lfs-menuitem" value={"Dietary"}>
                <img src="../assets/images/diatry.svg" className="img-fluid" alt="Head Count" />
                All Dietary
              </MenuItem>

              <MenuItem className="lfs-menuitem" value={"Vegetarian"}>
                <img src="../assets/images/diatry.svg" className="img-fluid" alt="Dietary Icon" />
                {"Vegetarian"}
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl className="lfs-option lfs-item">
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={packageOption}
              onChange={setPackageOption}
              input={<OutlinedInput />}
              renderValue={(selected) =>
                selected[0] === "options" && selected.length === 1
                  ? "All Options"
                  : selected.length - 1 + " Selected"
              }>
              {PACKAGE_OPTIONS_LIST.map((item) => (
                <MenuItem className="lfs-select-item" key={item?.value} value={item?.value}>
                  <Checkbox className="p-0" checked={packageOption.indexOf(item?.value) > -1} />
                  <ListItemText primary={item?.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="lfs-cuisnes lfs-item">
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={cuisine}
              onChange={setCuisine}
              input={<OutlinedInput />}
              renderValue={(selected) =>
                selected[0] === "All Cuisines" && selected.length === 1
                  ? "All Cuisines"
                  : selected.length - 1 + " Selected"
              }>
              {cuisineList
                ? cuisineList.map((item) => (
                    <MenuItem className="lfs-select-item" key={item?._id} value={item?._id}>
                      <Checkbox className="p-0" checked={cuisine.indexOf(item?._id) > -1} />
                      <ListItemText primary={item?.cuisineName} />
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>

          <FormControl className="lfs-sort lfs-item">
            <Select
              labelId="Time"
              id="time"
              className="hours-dropdown"
              value={sortBy}
              onChange={setSortBy}>
              <MenuItem disabled className="lfs-menuitem" value={"CaterCash"}>
                <p>Sort by&nbsp;:&nbsp;</p>CaterCash
              </MenuItem>
              <MenuItem className="lfs-menuitem" value={"1"}>
                High to Low
              </MenuItem>
              <MenuItem className="lfs-menuitem" value={"-1"}>
                Low to High
              </MenuItem>
            </Select>
          </FormControl>
          <Button className="lfs-clear-btn" variant="contained" color="primary" onClick={onClear}>
            Clear
          </Button>
        </div>
      </div>
    </div>
  );
}
