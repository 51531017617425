import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface IOrderTabProps {
  value: number;
  isActive: boolean;
  children: React.ReactNode;
}

export function OrderTab({ value, isActive, children, ...props }: IOrderTabProps) {
  return (
    <div
      role="tabpanel"
      hidden={!isActive}
      id={`simple-tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
      {...props}>
      {isActive && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
