// this drawer is for editing the catercash & commission
import React from 'react';

import Drawer from '@mui/material/Drawer';
import { Button, FormGroup, CircularProgress } from "@mui/material";
import { UPDATE_CATERE } from '../../../Graphql/mutations';
import { useMutation } from '@apollo/client';
import { toggleSnackbar,authVar } from "../../../ReactiveVariables/index";
import { useState } from 'react';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
export default function CaterCashDetails(props) {
    const navigate= useNavigate()
    const currentData = props?.data?.getOneCaterer

    const [caterCash, setCaterCash] = useState(currentData?.caterCash)
    const [commission, setcommission] = useState(currentData?.commission)

    const [updateCaterer, { loading }] = useMutation(UPDATE_CATERE, { refetchQueries:['GetOneCaterer'],
        onCompleted: (res) => {
            props?.setCaterCash(false)
        }
    });

    const handleCaterCashSliderChange = (event, newValue) => {
        setCaterCash(newValue);
    };

    const handlecommissionSliderChange = (event, newValue) => {
        setcommission(newValue);
    };
// function for updationg catercash
    function UpdateCaterCash(params) {
        let data = {
            caterCash: caterCash,
            commission: commission
        }
        try {
            updateCaterer({
                variables: {
                    id: props?.data?.getOneCaterer?.catererUsers[0]?.id || props?.userData?.id,
                    catererId: props?.data?.getOneCaterer?.catererUsers[0]?.catererId || props?.userData?.catererId,
                    data: data,
                },
            })
                .then((res) => {
                    toggleSnackbar({
                        status: true,
                        message: "CaterCash & Commission updated successfully",
                        variant: "success",
                    });
                })
                .catch((err) => {
                    const message = err && err.message;
                    if(message==="Not authenticated"){
                        localStorage.clear();
                        authVar({
                          loaded: true,
                          auth: false,
                        });
                        navigate("/admin", { state: "noAuth" })
                      }else{
                      toggleSnackbar({
                        status: true,
                        message: message,
                        variant: "error",
                      });
                    }
                });
        } catch (err) {
            const message = err && err.message;
            toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
            });
        }
    }
    return (
        <Drawer className='common-drawer'
            anchor={"right"}
            open={props.openCaterCash}
            onClose={() => props.setCaterCash(false)}
        >

            <div className='drawer-head'>
                <h4 className="h4"><img src="../assets/images/icon-cater-cash.svg" className="img-fluid" alt="Options Icon" />CaterCash & Commission</h4><span style={{ cursor: 'pointer' }} onClick={() => props.setCaterCash(false)}><img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" /></span>
            </div>
            <div className='create-step-form'>
                <div className='container-fluid'>
                    <div className="ld-options ld-options-edit-drawer">
                        <FormGroup>
                            <div className="location-details-tile">
                                <label style={{ fontSize: '12px', color: '#4C5A76' }}>Select the Percentage you want to offer as CaterCash to your Customers</label>
                                <div className="ld-slider">
                                    <h5>CaterCash</h5>
                                    <div className='w-100'>
                                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                        </Stack>
                                        <Slider
                                            value={typeof caterCash === 'number' ? caterCash : 5}
                                            onChange={handleCaterCashSliderChange}
                                            aria-labelledby="input-slider"
                                            size="large" defaultValue={8}
                                        />
                                    </div>
                                    <h6>{caterCash === null ? '5%' : `${caterCash}%`}</h6>
                                </div>
                                <div className="ld-slider">
                                    <h5>Commission</h5>
                                    <div className='w-100'>
                                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                        </Stack>
                                        <Slider
                                            value={typeof commission === 'number' ? commission : 8}
                                            onChange={handlecommissionSliderChange}
                                            aria-labelledby="input-slider"
                                        />
                                    </div>
                                    <h6>{commission === null ? '8%' : `${commission}%`}</h6>
                                </div>
                            </div>
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="createStepBtn">
                <Button
                    className="cancelBtn"
                    variant="contained"
                    color="secondary"
                    onClick={() => props.setCaterCash(false)}
                >
                    Cancel
                </Button>
                <Button
                    className="saveBtn"
                    variant="contained"
                    color="primary"
                    onClick={() => UpdateCaterCash()}
                >
                    {loading ? (
                        <CircularProgress color="inherit" size={20} />
                    ) : (
                        "Update"
                    )}
                </Button>
            </div>

        </Drawer>
    )
}