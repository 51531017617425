//this page shows the entire summery for the orders
import React from 'react'

import LoggedHeader from "../../Common/loggedHeader";
import SideMenu from "../../../components/organisms/sidebar";
import TotalSummeryBox from "./totalSummeryBox";
import UpcomingOrders from "./upcomingOrders";
import CaterCashHistory from "./caterCashHistory";
import LatestCaterers from "./latestCaterers";
import CustomerLists from "./customersList";
import { useQuery } from "@apollo/client/react/hooks";
import {
  LIST_ALL_ORDERS,
  LIST_CATER_CASH,
  LIST_CATERER,
  LIST_USERS,
  DASHBOARD_TOTAL,
  TOTLA_CATERCASH
} from "../../../Graphql/queries";

export default function DashBoard() {
  const orderVariable = {
    skip: 0,
    limit: 5,
    orderStatus: ["New", "Scheduled"],
  };
  const caterCashVariables = {
    skip: 0,
    limit: 5,
  };
  const catererVariables = {
    skip: 0,
    limit: 5,
  };
  const customerVariables = {
    userType: "Customer",
    limit: 5,
    skip: 0,
  };

  const { data: orderData, loading: orderLoading } = useQuery(LIST_ALL_ORDERS, {
    variables: orderVariable,
  });
  const { data: caterCashData, loading: caterCashLoading } = useQuery(
    LIST_CATER_CASH,
    {
      variables: caterCashVariables,
    }
  );
  const { data: catererListData, loading: catererLoading } = useQuery(
    LIST_CATERER,
    { variables: catererVariables }
  );
  const { data: customerData, loading: customerLoading } = useQuery(
    LIST_USERS,
    {
      variables: customerVariables,
    }
  );
  const { data: totalData } = useQuery( DASHBOARD_TOTAL );
  const {data:caterCashTotal} = useQuery(TOTLA_CATERCASH)
  return (
    <div>
      <LoggedHeader title="Dashboard" user="admin" />
      <SideMenu selected="Dashboard" user="admin" />
      <div className="contentWrap dashboard">
        <div className="contentWrapInner">
          <TotalSummeryBox 
          totalData={totalData}
          caterCashTotal={caterCashTotal}
          />
          <div className="row">
            <div className="col-md-6">
              <UpcomingOrders 
                orderData={orderData} 
                loading={orderLoading} 
              />
            </div>
            <div className="col-md-6">
              <CaterCashHistory
                caterCashData={caterCashData}
                loading={caterCashLoading}
              />
            </div>
            <div className="col-md-6">
              <LatestCaterers
                catererListData={catererListData}
                loading={catererLoading}
              />
            </div>
            <div className="col-md-6">
              <CustomerLists
                customerData={customerData}
                loading={customerLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
