// this page is for adding promo code
import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { TextField, Button, CircularProgress, Typography,InputAdornment } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toggleSnackbar ,authVar} from "../../../ReactiveVariables/index";
import { useMutation, useQuery } from '@apollo/client';
import { LIST_CATERER_SEARCH } from '../../../Graphql/queries';
import { CREATE_PROMOCODE, UPDATE_SETTINGS } from '../../../Graphql/mutations';
import Autocomplete from '@mui/material/Autocomplete';
import moment from "moment";
import {useNavigate } from "react-router-dom";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ?  'D34343': 'green',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#FD4E3B',
    boxSizing: 'border-box',
  },
}));
export default function EditSettings(props) {
  const navigate = useNavigate()

  const schema = yup.object().shape({
   // coupenCode: yup.string().required("Coupon Code name is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema),
     //defaultValues: { expDate:   props?.selectedItem?.expDate? new Date(props?.selectedItem?.expDate) :"" }
     });

  const [editSettings, { loading }] = useMutation(UPDATE_SETTINGS, {
    refetchQueries: ['ListSettings'],
    onCompleted: (res) => {
      props?.setSettings(false)
    }
  })


  function onSubmit(params) {
    
    params["_id"] = props?.selectedItem?._id
    try {
      props?.selectedItem?._id ? editSettings({ variables: { id: props?.selectedItem?._id, data: params } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Settings updated successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        }) : editSettings({ variables: { data: params } })
          .then((res) => {
            toggleSnackbar({
              status: true,
              message: "Settings created successfully",
              variant: 'success'
            });
          })
          .catch((err) => {
            const message = err && err.message
            if(message==="Not authenticated"){
              localStorage.clear();
              authVar({
                loaded: true,
                auth: false,
              });
              navigate("/admin", { state: "noAuth" })
            }else{
            toggleSnackbar({
              status: true,
              message: message,
              variant: "error",
            });
          }
          });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }
  return (
    <Drawer className='common-drawer'
      anchor={"right"}
      open={props.openSettings}
      onClose={() => props.setSettings(false)}
    >
      <div className='drawer-head'>
        <h4 className="h4"><img src="../assets/images/icon-contact-info.svg" className="img-fluid" alt="Contact Information Icon" />Edit Settings</h4><span style={{ cursor: 'pointer' }} onClick={() => { props.setSettings(false); props?.setSelectedItem(null) }}><img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" /></span>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <div className='create-step-form'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 textBox'>
                <TextField
                  id="createAccountAdminName"
                  defaultValue={props?.selectedItem?.title}
                  label="KEY"
                  autoFocus
                  variant="outlined"
                  className="textField allFeild"
                  {...register("title")}
                />
                {errors.title && <span className="error-msg">{errors.title.message}</span>}
              </div>
            
              <div className='col-md-12 textBox'>
                <TextField
                  id="createAccountAdminName"
                  defaultValue={props?.selectedItem?.value}
                  label="VALUE"
                  variant="outlined"
                
                  className="textField allFeild"
                  {...register("value")}
                />
                {errors.value && <span className="error-msg">{errors.value.message}</span>}
              </div>
              <div className='col-md-12 textBox'>
                <TextField
                  id="createAccountAdminName"
                  defaultValue={props?.selectedItem?.description}
                  label="DESCRIPTION"
                  variant="outlined"
                  className="textField allFeild"
                  {...register("description")}
                />
                {errors.description && <span className="error-msg">{errors.description.message}</span>}
              </div>
            
            </div>
          </div>
        </div>
        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => { props.setSettings(false); props?.setSelectedItem(null) }}
          >
            Cancel
          </Button>
          <Button
            className="saveBtn"
            variant="contained"
            type="submit"
            color="primary"
          >
            {loading ? <CircularProgress color="inherit" size={20} /> : props?.selectedItem?._id ? "Update" : "Add"}
          </Button>
        </div>
      </form>
    </Drawer>
  )
}