import React, { useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { useQuery } from "@apollo/client";
import CustomerHeader from "../Common/customerHeader";
import SideMenu from "../../components/organisms/sidebar";
import AdminProfileInfo from "../Location/locationEdits/adminProfileEdit";
import { PaymentCard } from "../../components/organisms/payment-card";
import MobileHeader from "../Common/mobileHeader";
import { userDeatils } from "../../ReactiveVariables/index";
import { LIST_CARDS } from "../../Graphql/queries";

export default function CustomerProfile(props) {
  const user = useReactiveVar(userDeatils);
  const [openProfile, setOpenProfile] = useState(false);
  const [checkedCard, setCheckedCard] = useState("");

  const { data: listCard } = useQuery(LIST_CARDS, {
    variables: {
      customerId:
        user?.data?.currentUserDetails?._id ||
        user?.data?.createUserIdentity?._id ||
        user?.data?.login?._id,
    },
  });
  
  return (
    <div>
      <CustomerHeader title="Profile" />
      <MobileHeader selected="Profile" />
      <SideMenu selected="Profile" user="customer" />
      <div className="contentWrap contentWrap-customer-profile">
        <div className="customer-profile-wrap">
          <div className="customer-profile mt-0 mb-0">
            {openProfile && (
              <AdminProfileInfo
                openProfile={openProfile}
                setOpenProfile={setOpenProfile}
                userData={user}
                fieldNameCustomer="CUSTOMER NAME"
                customerProfileEditName
              />
            )}
            <div className="customer-profile-title">
              <h4 className="h4">
                <img
                  src="../assets/images/icon-contact-info.svg"
                  className="img-fluid"
                  alt="Contact Information Icon"
                />
                Profile
              </h4>
              <a className="ld-edit" onClick={() => setOpenProfile(true)}>
                <img
                  src="../assets/images/icon-edit-2.svg"
                  className="img-fluid"
                  alt="Edit Icon"
                />
                Edit
              </a>
            </div>
            <div className="row customer-profile-details">
              <div className="col-md-6">
                <label>First Name</label>
                <p>
                  {user?.data?.currentUserDetails?.firstName ||
                    user?.data?.login?.firstName ||
                    user?.data?.createUserIdentity?.firstName}
                </p>
              </div>
              <div className="col-md-6">
                <label>Last Name</label>
                <p>
                  {user?.data?.currentUserDetails?.lastName ||
                    user?.data?.login?.lastName ||
                    user?.data?.createUserIdentity?.lastName}
                </p>
              </div>
              <div className="col-md-6">
                <label>Phone</label>
                <p>
                  {user?.data?.currentUserDetails?.phoneNumber ||
                    user?.data?.login?.phoneNumber ||
                    user?.data?.createUserIdentity?.phoneNumber}
                </p>
              </div>
              <div className="col-md-6">
                <label>Email</label>
                <p>
                  {user?.data?.currentUserDetails?.email ||
                    user?.data?.login?.email ||
                    user?.data?.createUserIdentity?.email}
                </p>
              </div>
            </div>
            <hr className="sepration-line" />
            <PaymentCard listCard={listCard} checkedCard={checkedCard} setCheckedCard={setCheckedCard} />
          </div>
        </div>
      </div>
    </div>
  );
}
