import { gql } from "@apollo/client";

export const UPDATE_CATERER_NOTE = gql`
  mutation UpdateCaterer($catererId: ID!, $id: ID!, $data: updateCatererInput!) {
    updateCaterer(_id: $id, catererId: $catererId, data: $data) {
      _id
      adminNote
    }
  }
`;

export const GET_CATERER_NOTE = gql`
  query GetOneCaterer($id: String, $agsType: String) {
    getOneCaterer(id: $id, agsType: $agsType) {
      _id
      adminNote
    }
  }
`;

export const GET_CUSTOMER_NOTE = gql`
  query GetOneUser($id: String!) {
    getOneUser(id: $id) {
      _id
      adminNote
    }
  }
`;

export const UPDATE_CUSTOMER_NOTE = gql`
  mutation UpdateUserIdentity($id: ID!, $data: updateUserIdentityInput!) {
    updateUserIdentity(_id: $id, data: $data) {
      _id
      adminNote
    }
  }
`;
