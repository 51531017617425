import { Button } from "@mui/material";
import moment from "moment";
import React from "react";

import { ORDER_DELIVERY_MODE, ORDER_STATUS } from "@constants/order.constants";
import { DATE_FORMATS } from "@constants/date-formats.constants";
import { getOrderStatusColor, deliveryWindow } from "@utils/order-format.utils";

interface IViewOrderTitleProps {
  deliveryMode: ORDER_DELIVERY_MODE;
  deliveryDate: string;
  deliveryTime: string;
  orderStatus: ORDER_STATUS;
  isRefunded: boolean;
}

export function ViewOrderTitle({
  deliveryMode,
  deliveryDate,
  deliveryTime,
  orderStatus,
  isRefunded,
}: IViewOrderTitleProps) {
  const parsedDeliveryWindow = deliveryWindow(deliveryTime);

  return (
    <div className="order-view-title">
      <h5 className="h5">
        {deliveryMode === "Delivery Only"
          ? "Delivery Window"
          : deliveryMode === "Pickup Only"
          ? "Pickup"
          : "Delivery Window"}
        {deliveryDate ? (
          <span>
            {moment(deliveryDate).format(DATE_FORMATS.viewOrderDate)} from {parsedDeliveryWindow?.start} - {parsedDeliveryWindow?.end}
          </span>
        ) : (
          <span>---</span>
        )}
      </h5>
      <Button
        className="status-btn"
        style={{
          cursor: "default",
          color: "#fff",
          backgroundColor: getOrderStatusColor(orderStatus, isRefunded),
        }}
        variant="contained">
        {isRefunded ? "Refunded" : orderStatus}
      </Button>
    </div>
  );
}
