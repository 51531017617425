import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
} from "@mui/material";

interface IUnavailableItemsModalProps {
  isOpen: boolean;
  items?: string[];
  onClose(): void;
}

export function UnavailableItemsModal({ isOpen, items, onClose }: IUnavailableItemsModalProps) {
  if (!isOpen || !items || !items.length) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="common-dialog-box">
        <DialogTitle id="alert-dialog-title">
          <div className="dialog-box-title text-center">
            <h5 className="h5 w-100">Unavailable</h5>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="dialog-box-text">
              <h5 className="h5">
                Sorry, the following menu items from your previous order are currently unavailable
                and cannot be added to your new order:
              </h5>
            </div>
            <div className="d-flex justify-content-center align-items-center reorder-list">
              {items.map((item) => (
                <Chip label={item} variant="outlined" className="ms-1" />
              ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="m-auto"
            variant="contained"
            color="primary"
            onClick={onClose}
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
