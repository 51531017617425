// this page is for showing all the list of caterer that are created all caterers in the system 
import React, { useState } from 'react';
import SideMenu from "../../components/organisms/sidebar"
import LoggedHeader from "../Common/loggedHeader"
import { Table, Select, MenuItem, Menu, Button } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";
import { formatter } from '../Common/commonUtils';
import { useQuery, useMutation } from '@apollo/client';
import { LIST_CATERER } from '../../Graphql/queries';
import Pagination from '@mui/material/Pagination';
import { userDeatils, authVar } from "../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import Stack from '@mui/material/Stack';
import DialogBox from "../Common/dialogBox";
import { toggleSnackbar } from "../../ReactiveVariables/index";
import AdminProfileInfo from "../Location/locationEdits/adminProfileEdit";
import {DELETE_CATERER, DELETE_USER_IDENTITY} from '../../Graphql/mutations';

export default function CatererListingScreen() {
    const user = useReactiveVar(userDeatils);
    const navigate = useNavigate()
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(null);
    const [statusSearch, setStatusSearch] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const catererVariables = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        userId: user?.data?.currentUserDetails?._id || user?.data?.login?._id || user?.data?.createCaterer?._id,
        search: search,
        status: statusSearch
    }
    const { data, loading } = useQuery(LIST_CATERER, { variables: catererVariables, fetchPolicy: "network-only" })
    const handleChangePage = (e, pages) => {
        setPage(pages);
    };
    const [deleteCaterer] = useMutation(DELETE_CATERER, {
        refetchQueries: ['ListCaterer'],
        onCompleted: (res) => {
            setSelectedItem(null)
        }
    })

    const [deleteUserIdentity] = useMutation(DELETE_USER_IDENTITY);

    // function for handling pagenation 
    function handlePageInputChange(event) {
        setRowsPerPage(Number(event.target.value));
        setPage(1);
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    //Caterer delete function
    function handleDelete() {
        try {
            const catererOwnerUser = selectedItem?.catererUsers.find(user => user.role === 'Owner');
            Promise.all([
                deleteUserIdentity({ variables: { id: catererOwnerUser?.userId } }),
                deleteCaterer({ variables: { catererId: selectedItem?._id } })
            ]).then((res) => {
                toggleSnackbar({
                    status: true,
                    message: "Caterer deleted successfully",
                    variant: 'success'
                });
            })
                .catch((err) => {
                    const message = err && err.message
                    if (message === "Not authenticated") {
                        localStorage.clear();
                        authVar({
                            loaded: true,
                            auth: false,
                        });
                        navigate("/admin", { state: "noAuth" })
                    } else {
                        toggleSnackbar({
                            status: true,
                            message: message,
                            variant: "error",
                        });
                    }
                });
        } catch (err) {
            const message = err && err.message;
            toggleSnackbar({
                status: true,
                message: message,
                variant: "error",
            });
        }
    }

    return (
        <div>
            {openProfile && (
                <AdminProfileInfo
                    openProfile={openProfile}
                    setOpenProfile={setOpenProfile}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    catererNames={true}
                />
            )}
            {openDialog && (<DialogBox
                setOpenDialog={setOpenDialog}
                handleDelete={handleDelete}
                openDialog={openDialog}
                title="Delete"
                discription={selectedItem?.totalOutstanding?.orderCount === 0 ? "Deleting this caterer will also delete all associated menu items. This action cannot be undone. Are you sure you want to proceed with the deletion?" : "Are you sure you want to delete this caterer?"}
            />
            )}
            {/* header view */}
            <LoggedHeader title="Caterers" user="admin" />
            {/* side menu for the admin */}
            <SideMenu selected="Caterers" user="admin" />

            <div className="contentWrap">
                <div className='contentWrapInner'>
                    {/* searchbar */}
                    <div className='location-head'>
                        <div className='location-head-title justify-content-between'>
                            {data?.listCaterer?.data !== undefined ? <h5 className='h5 mb-0'>{`${data?.listCaterer?.count} Caterers`}</h5> :
                                <p> Loading...</p>
                            }
                            <input
                                placeholder="Search by Store name and location"
                                className="form-control align-right"
                                value={search || ''}
                                onChange={(e) => {
                                    setPage(1);
                                    setSearch(e.target.value)
                                }}
                            />

                        </div>
                    </div>
                    {/* table view */}
                    <TableContainer>
                        <Table className='commonTabel' sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Profile Image</TableCell>
                                    <TableCell align="left">Store Name and Location</TableCell>
                                    <TableCell align="left">Contact Person</TableCell>
                                    <TableCell align="center">Orders</TableCell>
                                    <TableCell align="center">Revenue</TableCell>
                                    <TableCell align="center">CaterCash</TableCell>
                                    <TableCell align="center">Commission</TableCell>
                                    <TableCell align="center">Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <div style={{ display: "flex", justifyContent: "center" }} >
                                                <p> Loading...</p>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ) : data && data?.listCaterer?.data?.length > 0 ? (
                                    data && data?.listCaterer?.data?.map((item) => {
                                        const { status } = item;
                                        const isOpen = status === "Open";
                                        return (
                                            <TableRow style={{ cursor: "pointer" }} key={item?._id}>
                                                <TableCell align="center" onClick={() => navigate(`${item?._id}`, { state: "catererDetails" })}>
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        src={item.imageUrl === "#" ? '../assets/images/dummy-cater-profile-img.png' : item.imageUrl ? item.imageUrl : '../assets/images/dummy-cater-profile-img.png'}
                                                        className="img-fluid cater-profile-img"
                                                        alt={item.imageUrl}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" onClick={() => navigate(`${item?._id}`, { state: "catererDetails" })}>
                                                    <label style={{ cursor: "pointer", color: isOpen ? 'initial' : 'red' }}><b>{item?.businessName}</b></label>
                                                    <small style={{  }}>{item?.locationName}</small>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <label><b>{item?.catererUsers[0]?.userDtls?.name}</b></label>
                                                    <small>{item?.catererUsers[0]?.userDtls?.phoneNumber}</small>
                                                </TableCell>
                                                <TableCell align="center">{item?.totalOutstanding?.orderCount}</TableCell>
                                                <TableCell align="center">{formatter.format(item?.totalOutstanding?.revenueTotal)}</TableCell>
                                                <TableCell align="center">{item?.caterCash}%</TableCell>
                                                <TableCell align="center">{item?.commission}%</TableCell>
                                                <TableCell align="center">
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        onClick={(e) => { setSelectedItem(item); handleClick(e) }}
                                                        src="../assets/images/more-horizontal.svg"
                                                        alt="img"
                                                    />
                                                </TableCell>

                                            </TableRow>
                                        )
                                    }
                                    )) : search ? (
                                        <TableRow>
                                            <TableCell colSpan={12}>
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <p>No data Found</p>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <div
                                                style={{ display: "flex", justifyContent: "center" }}
                                            >
                                                <p>No list added Yet!</p>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='table-pagenation'>
                    <div className='rowCount'>
                        <Select
                            onChange={handlePageInputChange}
                            value={rowsPerPage}
                        >
                            <MenuItem value={10}>{10}</MenuItem>
                            <MenuItem value={25}>{25}</MenuItem>
                            <MenuItem value={50}>{50}</MenuItem>
                            <MenuItem value={100}>{100}</MenuItem>

                        </Select>
                    </div>
                    <Stack spacing={2}>
                        <Pagination
                            className='tableCount'
                            count={Math.ceil(data?.listCaterer?.count / rowsPerPage)}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            onChange={handleChangePage}
                        />
                    </Stack>
                </div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    className="menu-list-more"
                >
                    <MenuItem onClick={() => { navigate(`${selectedItem?._id}`); handleClose() }}>View</MenuItem>
                    {/* <MenuItem
            onClick={() => {
              setOpenProfile(true);
              handleClose();
            }}
          >
            Edit
          </MenuItem> */}
                    <MenuItem onClick={() => { setOpenDialog(true); handleClose() }}>Delete</MenuItem>
                </Menu>
            </div>
        </div >
    )
}